/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["self"] }] */
import { flow, getRoot, Instance, types, cast } from 'mobx-state-tree';
import { coursesApi } from 'api';
import {
  TCatalogResponse,
  TCatalogGroupAttendeesResponse,
  IVideo,
  TVideoResponse,
  TAttendeeResponse,
  TBookingsByIdsResponse,
} from 'types/coursesApiTypes';
import { MCatalog, MBookingsCatalog } from 'models/Catalogs';
import { ATTENDEES_PER_PAGE, CatalogElementTypes } from 'models/Catalogs/constants';
import { TCatalog, TCatalogBooking, TCatalogChild, TStore } from 'types';
import Profile from './Profile';

const Catalog = types
  .model({
    data: types.maybeNull(MCatalog),
    bookingsCatalog: types.maybeNull(MBookingsCatalog),
  })
  .views((self) => ({
    get profileStore(): Instance<typeof Profile> {
      return getRoot<TStore>(self).DomainStore.profile;
    },
  }))
  .actions((self) => {
    const getCatalogFilteredByGroups = (catalog: TCatalog) => {
      if (!catalog.children) {
        return catalog;
      }

      let children = catalog.children.filter((child: TCatalogChild) => {
        if (child.type === CatalogElementTypes.CATALOG) {
          const catalogChild = child as TCatalog;
          return catalogChild.groups && catalogChild.groups.length > 0
            ? catalogChild.groups.some((group) => self.profileStore.groupIds?.includes(group))
            : true;
        }

        return true;
      });

      // Рекурсивно вызываем для дочерних каталогов
      children = children.map((child) =>
        child.type === CatalogElementTypes.CATALOG ? getCatalogFilteredByGroups(child as TCatalog) : child,
      );

      return { ...catalog, children };
    };

    const getCatalogBookingsIds = (catalog: TCatalog | null) => {
      let bookingsIds: string[] = [];

      if (catalog && catalog.children) {
        const catalogWithBookingTypes = catalog.children
          .filter((child: TCatalogChild) => child.type === CatalogElementTypes.BOOKING)
          .map((elem: TCatalogBooking) => elem.bookingUuid);

        const childrenIds = catalog.children
          .filter((child) => child.type === CatalogElementTypes.CATALOG)
          .map((child) => getCatalogBookingsIds(child as TCatalog))
          .flat();
        bookingsIds = catalogWithBookingTypes.concat(childrenIds);
      }

      return bookingsIds.filter((element, index) => bookingsIds.indexOf(element) === index);
    };

    return {
      fetchCatalog: flow(function* fetchCatalog(catalogId: number, trackId = '') {
        const { data, hasError }: TCatalogResponse = yield coursesApi.getCatalog(catalogId, trackId);
        if (data && !hasError) {
          self.data = cast(getCatalogFilteredByGroups(data));
        }
        if (getCatalogBookingsIds(self.data).length) {
          const { data: dataBookings, hasError: hasErrorBookings }: TBookingsByIdsResponse =
            yield coursesApi.getBookingsByIds(getCatalogBookingsIds(self.data), trackId);
          if (dataBookings && !hasErrorBookings) {
            self.bookingsCatalog = dataBookings;
          }
        }
      }),
      getAllCatalogsInCurrentPath: (currentCatalogPathIds?: number[]) => {
        if (!self.data || !currentCatalogPathIds || currentCatalogPathIds[0] !== self.data.id) {
          return null;
        }
        let currentCatalog = self.data;
        const allCatalogsInPath = [self.data];
        for (let i = 1; i < currentCatalogPathIds.length; i += 1) {
          currentCatalog = currentCatalog.children?.find((child) => child.id === currentCatalogPathIds?.[i]);
          if (!currentCatalog) return null;
          allCatalogsInPath.push(currentCatalog);
        }
        return allCatalogsInPath;
      },
      fetchGroupAttendees: flow(function* fetchGroupAttendees(trackId: string, groupId: number, page?: number) {
        const { data, hasError }: TCatalogGroupAttendeesResponse = yield coursesApi.getGroupAttendees(
          trackId,
          groupId,
          page || 1,
          ATTENDEES_PER_PAGE,
        );
        if (data && !hasError) {
          return data;
        }
        return null;
      }),

      fetchAttendee: flow(function* fetchAttendee(id: number, trackId: string) {
        const { data, hasError }: TAttendeeResponse = yield coursesApi.getAttendee(id, trackId);
        if (data && !hasError) {
          return data;
        }
        return null;
      }),

      fetchVideo: flow(function* fetchVideo(
        id: number,
        trackId: string,
      ): Generator<Promise<TVideoResponse | null>, IVideo | null, TVideoResponse> {
        const { data, hasError }: TVideoResponse = yield coursesApi.getVideoById(id, trackId);
        if (data && !hasError) {
          return data;
        }
        return null;
      }),

      fetchAudio: flow(function* fetchAudio(
        id: number,
      ): Generator<Promise<TVideoResponse | null>, IVideo | null, TVideoResponse> {
        const { data, hasError }: TVideoResponse = yield coursesApi.getAudioById(id);
        if (data && !hasError) {
          return data;
        }
        return null;
      }),
    };
  });

export default Catalog;
