import { types } from 'mobx-state-tree';
import { TaskStatus } from './constants';

const MTaskCourseTrackInfo = types.model({
  id: types.identifierNumber,
  name: types.string,
});

const MTaskInfo = types.model({
  id: types.identifierNumber,
  name: types.string,
  status: types.enumeration<TaskStatus>('Status', Object.values(TaskStatus)),
  deadlineDate: types.maybeNull(types.string),
  taskContentId: types.number,
  course: types.maybeNull(MTaskCourseTrackInfo),
  track: types.maybeNull(MTaskCourseTrackInfo),
});

export default MTaskInfo;
