import { ComponentTypes, MenuRoutes } from '_constants';
import { TComponent } from 'types';
import { compile } from './pathToRegExp';

export const generatePathCatalogParam = (catalogIds: number[]): string => `/catalog/${catalogIds.join('/catalog/')}`;

export const getCatalogIdsFromCatalogParam = (catalogParam: string): number[] =>
  catalogParam
    ?.split('/catalog/')
    .slice(1)
    .map((id) => Number(id));

export const getFrameSlugFromLocation = (location: string): string => {
  const getFrameSlug = location?.split('/frame/')[1];
  return getFrameSlug?.split('/')[0];
};

export const getRootCatalogIdFromCatalogParam = (catalogParam: string): number =>
  Number(catalogParam?.split('/catalog/')[1]);

export const getComponentPath = (component: TComponent, alias: string): string => {
  const componentType = component?.type;
  const href = MenuRoutes[componentType]?.href;
  if (componentType === ComponentTypes.CATALOG) {
    return compile(href, { validate: false })({
      alias,
      catalog: generatePathCatalogParam([component.settings?.catalogId || 0]),
    });
  }
  if (componentType === ComponentTypes.MENU_POLL) {
    return compile(href, { validate: false })({
      alias,
      pollId: component.settings?.pollId || 0,
    });
  }
  if (componentType === ComponentTypes.FRAME) {
    return compile(href, { validate: false })({
      alias,
      frameSlug: component?.slug,
    });
  }
  return compile(href, { validate: false })({ alias });
};
