import { defineMessages } from 'react-intl';

export default defineMessages({
  makeCopy: {
    id: 'components.copyButton.copyStatus.makeCopy',
    defaultMessage: 'Скопировать',
  },
  copied: {
    id: 'components.copyButton.copyStatus.copied',
    defaultMessage: 'Скопировано',
  },
});
