import { defineMessages } from 'react-intl';

export default defineMessages({
  fileButtonTitle: {
    id: 'components.tasks.taskTypeSelector.fileButtonTitle',
    defaultMessage: 'Прикрепить файл',
  },
  linkButtonTitle: {
    id: 'components.tasks.taskTypeSelector.linkButtonTitle',
    defaultMessage: 'Указать ссылку',
  },
});
