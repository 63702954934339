import { useEffect } from 'react';
import store from 'store';

const useHeaderTitle = (title: string | false | null): void => {
  const { setTitle } = store.UIStore.layout;

  useEffect(() => {
    setTitle(title || null);

    return () => {
      setTitle(null);
    };
  }, [setTitle, title]);
};

export default useHeaderTitle;
