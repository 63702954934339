import { RefObject, useEffect } from 'react';
import useMediaQueries from './useMediaQueries';

type TProps = {
  ref: RefObject<HTMLDivElement>;
  isClosed: boolean;
  setIsClosed: (isClosed: boolean) => void;
};

const useClickOutside = ({ ref, isClosed, setIsClosed }: TProps): void => {
  const { isMobileLarge } = useMediaQueries();

  useEffect(() => {
    const onAnyClick = (e: MouseEvent) => {
      if (ref.current && e.target) {
        if (!ref.current.contains(e.target as Node)) {
          setIsClosed(true);
        }
      }
    };

    if (!isClosed && isMobileLarge) {
      window.addEventListener('click', onAnyClick);
    }

    return () => {
      window.removeEventListener('click', onAnyClick);
    };
  }, [isMobileLarge, ref, isClosed, setIsClosed]);
};

export default useClickOutside;
