import styled, { css } from 'styled-components';
import { Checkbox, Image as ImageBase } from 'antd';
import { Text, Title } from 'components';
import { CheckBoxOptions, IPercentColorBlock } from 'components/Polls/constants';
import { ReactComponent as CheckMarkSelect } from 'assets/icons/checkmark-check.svg';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { TTheme } from 'types';
import { ErrorImageOverlay } from 'components/Image/Image.styles';
import { MIXINS } from '_constants';
import { FullScreenIcon as FullScreenIconBase } from '../Polls.styles';

interface ICheckboxWrapper {
  disabled?: boolean;
}
interface IStyledBigImageCheckbox {
  disabled?: boolean;
  actionIcon?: string | null;
  percent?: boolean;
  variant?: CheckBoxOptions;
}

const getPercentColorBlockBg = (theme: TTheme, variant?: CheckBoxOptions) => {
  switch (variant) {
    case CheckBoxOptions.RIGHT:
      return theme.palette.success.accent;
    case CheckBoxOptions.WRONG:
      return theme.palette.error.secondary;
    default:
      return theme.palette.dark.dark_12;
  }
};

const getStyledBigImageCheckboxBg = (theme: TTheme, variant?: CheckBoxOptions) => {
  switch (variant) {
    case CheckBoxOptions.RIGHT:
      return theme.palette.success.secondary;
    case CheckBoxOptions.WRONG:
      return theme.palette.error.secondary;
    default:
      return theme.palette.dark.dark_4;
  }
};

const getStyledBigImageCheckboxColor = (theme: TTheme, variant?: CheckBoxOptions) => {
  switch (variant) {
    case CheckBoxOptions.RIGHT:
      return theme.palette.success.primary;
    case CheckBoxOptions.WRONG:
      return theme.palette.error.primary;
    default:
      return theme.palette.dark.main;
  }
};

const getCheckboxTextColor = (theme: TTheme, variant?: CheckBoxOptions) => {
  switch (variant) {
    case CheckBoxOptions.RIGHT:
      return theme.palette.success.primary;
    case CheckBoxOptions.WRONG:
      return theme.palette.error.primary;
    default:
      return theme.palette.dark.main;
  }
};

const getPercentOverlayColor = (theme: TTheme, variant?: CheckBoxOptions) => {
  switch (variant) {
    case CheckBoxOptions.RIGHT:
      return theme.palette.success.primary;
    case CheckBoxOptions.WRONG:
      return theme.palette.error.primary;
    default:
      return theme.palette.dark.dark_40;
  }
};

export const PercentOverlay = styled(Text)`
  &&& {
    position: absolute;
    top: 0;
    right: 16px;
    bottom: 0;
    z-index: 3;
    display: flex;
    align-items: center;
    width: 42px;
    margin: auto;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
  }
`;

export const PercentColorBlock = styled.div<IPercentColorBlock>`
  &&& {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: ${({ percent }) => `${percent}%`};
    height: 100%;
    background-color: ${({ theme, variant }) => getPercentColorBlockBg(theme, variant)};
  }
`;

export const PercentColorBlockOverlay = styled.div`
  &&&& {
    position: relative;
    display: flex;
    flex: auto;
  }
`;

export const OptionText = styled.div`
  position: relative;
  z-index: 3;
`;

export const ImageOverlay = styled.div`
  position: absolute;
  z-index: 7;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.mask.weaker};
  opacity: 0;
  cursor: pointer;
  transition: 0.3s opacity;
`;

export const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.dark.dark_4};
  min-height: 64px;
  :hover {
    ${ImageOverlay} {
      opacity: 1;
      transition: 0.3s opacity;
    }
  }
`;

export const CheckboxText = styled(Title)`
  &&&& {
    position: relative;
    font-weight: 500;
    word-break: break-word;
    transition: 0.3s background-color;
  }
`;

export const CheckboxWrapper = styled.div<ICheckboxWrapper>`
  && {
    position: relative;
    display: flex;
    overflow: hidden;
    width: 100%;
    border-radius: 8px;
  }
`;

export const FullScreenIcon = styled(FullScreenIconBase)``;

export const CheckMark = styled(CheckMarkSelect)`
  display: none;
  path {
    fill: ${({ theme }) => theme.palette.primary.main};
  }
  rect {
    stroke: ${({ theme }) => theme.palette.primary.main};
  }
  circle {
    fill: ${({ theme }) => theme.palette.white.primary};
    stroke: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const ActionIconWrapper = styled.div`
  width: 24px;
  height: 24px;
`;

export const StyledBigImageCheckbox = styled(Checkbox)<IStyledBigImageCheckbox>`
  && {
    width: 100%;
    border-radius: 8px;
    background-color: ${({ theme, variant }) => getStyledBigImageCheckboxBg(theme, variant)};
    color: ${({ theme, variant }) => getStyledBigImageCheckboxColor(theme, variant)};
    font-weight: 500;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    line-height: 24px;
    user-select: none;
    min-height: 64px;

    ${FullScreenIcon} {
      right: 8px;
      bottom: 8px;
    }
    ${ActionIconWrapper} {
      ${({ percent }) =>
        percent
          ? css`
              position: absolute;
              bottom: 0;
              top: 0;
              right: 0;
              margin: auto 71px auto 0;
            `
          : css`
              margin: auto 24px auto 0;
            `}
    }

    &::before {
      content: '';
      position: absolute;
      z-index: 2;
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 8px;
    }
    &&&& {
      ${CheckboxText} {
        color: ${({ theme, variant }) => getCheckboxTextColor(theme, variant)};
        padding: 18px 52px 18px 20px;
        ${({ percent }) =>
          percent &&
          css`
            padding: 18px 110px 18px 20px;
          `}

        flex: auto;
        display: flex;
        align-items: center;
      }
    }

    &:not(.ant-checkbox-wrapper-disabled):hover {
      ${CheckboxText} {
        background-color: ${({ theme }) => theme.palette.dark.dark_4};
        transition: 0.3s background-color;
      }
    }

    &.ant-checkbox-wrapper::after {
      display: none;
    }

    &.ant-checkbox-wrapper-checked {
      ${CheckMark} {
        display: initial;
      }
    }

    & span:not(.ant-checkbox) {
      padding: 0;
    }

    & .ant-checkbox {
      position: absolute;
      z-index: 5;
      width: 24px;
      height: 24px;
      margin: auto;
      top: 0;
      right: ${({ percent }) => (percent ? `71px` : `24px`)};
      bottom: 0;

      & .ant-checkbox-inner {
        position: relative;
        width: 100%;
        height: 100%;
        border: none;
        background-color: transparent;
        transition: none;

        &::after {
          display: none;
        }
      }

      &.ant-checkbox-checked {
        &::after {
          display: none;
        }

        &:not(.ant-checkbox-disabled) .ant-checkbox-inner {
          &::before {
            opacity: 0;
          }
        }
      }

      & + span {
        display: flex;
        flex-direction: row;
        overflow: hidden;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        min-height: 64px;
      }

      &.ant-checkbox-disabled + span {
        color: inherit;
      }
    }

    & ${ErrorImageOverlay} {
      position: relative;
      min-width: 114px;
      ${MIXINS.ratio16x9}

      svg {
        width: 50%;
      }
    }

    & .ant-image {
      position: relative;
      z-index: 6;
      width: 114px;
      height: 100%;
      max-height: 64px;
      cursor: pointer;

      & .ant-image-img {
        height: 100%;
        object-fit: cover;
      }
    }
    ${({ disabled }) =>
      disabled &&
      css`
        ${ImageWrapper}:hover {
          ${ImageOverlay} {
            opacity: 0;
          }
        }
      `};

    & .ant-modal-wrap {
      cursor: auto;
    }

    &&& {
      ${PercentOverlay} {
        color: ${({ theme, variant }) => getPercentOverlayColor(theme, variant)};
      }
    }
  }
`;

export const ImagePreview = styled(ImageBase)`
  &&& {
    cursor: auto;
  }
`;

export const Close = styled(CloseIcon)`
  margin-left: 16px;
`;
