/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["self"] }] */
import { types } from 'mobx-state-tree';

const MOption = types
  .model({
    id: types.number,
    optionData: types.maybeNull(types.string),
    order: types.number,
    rate: types.maybeNull(types.number),
    imageURL: types.maybeNull(types.string),
    isRight: types.boolean,
    createdDate: types.string,
    updatedDate: types.string,
  })
  .views((self) => ({
    get optionDataObject() {
      if (!self.optionData) return null;
      try {
        return JSON.parse(self.optionData) as { text?: string; current?: number };
      } catch (e) {
        return null;
      }
    },
  }))
  .views((self) => ({
    get text() {
      return self.optionDataObject?.text || null;
    },
    get maxRateCount() {
      return {
        current: self.optionDataObject?.current || 0,
      };
    },
  }));

export default MOption;
