import styled, { css } from 'styled-components';
import { Text } from 'components';
import { ReactComponent as CheckmarkIcon } from 'assets/icons/checkmarkEllipse.svg';

interface ICheckmarkIconStyled {
  $visible: boolean;
}

export const TextStyled = styled(Text)`
  &&& {
    color: ${({ theme }) => theme.palette.dark.main};
    font-weight: 500;
    user-select: none;
  }
`;

export const CheckmarkIconStyled = styled(CheckmarkIcon)<ICheckmarkIconStyled>`
  width: 0;
  margin-right: 0;
  opacity: 0;

  ${({ $visible }) =>
    $visible &&
    css`
      transition: all 0.4s;
      width: 20px;
      margin-right: 8px;
      opacity: 1;
    `}
`;
