import React from 'react';
import { ReactComponent as Star } from 'assets/icons/star.svg';
import { RateResultOverlay, RateResultText } from './RateResult.styles';

interface IProps {
  value?: number;
  max?: number;
  decimalPlaces?: number;
}

const RateResult: React.FC<IProps> = ({ value = 0, max = 0, decimalPlaces }) => (
  <RateResultOverlay>
    <Star />
    <RateResultText>
      {decimalPlaces ? parseFloat(value.toString()).toFixed(1) : value}/{max}
    </RateResultText>
  </RateResultOverlay>
);

export default RateResult;
