import React from 'react';
import { useIntl } from 'react-intl';
import { ReactComponent as FileLoader } from 'assets/icons/file-loader.svg';
import { ReactComponent as FileError } from 'assets/icons/file-error.svg';
import { ReactComponent as FileRemove } from 'assets/icons/file-remove.svg';
import { ReactComponent as FileDownload } from 'assets/icons/file-download.svg';
import { byteConverter } from 'utils';
import Spinner from 'components/Spinner';
import messages from './messages';
import {
  FileItemOverlay,
  FileItemIconOverlay,
  FileItemContent,
  FileItemButton,
  FileItemContentSize,
  FileItemContentText,
  FileItemSide,
  FileButtonTooltip,
} from './FileItem.styles';

export enum FileItemType {
  EDIT = 'edit',
  PREVIEW = 'preview',
}

interface IFileItem {
  name?: string;
  size?: number;
  type: FileItemType;
  isError?: boolean;
  isLoading?: boolean;
  onClickHandler?: () => void;
}

const FileItem: React.FC<IFileItem> = ({ name, size, type, isError = false, isLoading = false, onClickHandler }) => {
  const intl = useIntl();

  const isPreview = type === FileItemType.PREVIEW;
  const isPreviewError = isPreview && isError;

  const getButton = () => {
    if (type === FileItemType.EDIT) {
      return (
        <FileItemButton onClick={onClickHandler}>
          <FileRemove />
        </FileItemButton>
      );
    }

    if (type === FileItemType.PREVIEW) {
      if (isLoading) {
        return <Spinner size="small" />;
      }

      return (
        <FileItemButton onClick={onClickHandler}>
          <FileDownload />
        </FileItemButton>
      );
    }

    return null;
  };

  return (
    <>
      <FileItemOverlay isGreyBackground={isPreview}>
        <FileItemSide>
          <FileItemIconOverlay>
            {isError && <FileError />}
            {isLoading && type === FileItemType.EDIT && <Spinner size="small" />}
            {!isError && !(isLoading && type === FileItemType.EDIT) && <FileLoader />}
          </FileItemIconOverlay>

          <FileItemContent>
            {size && !isPreviewError && <FileItemContentSize>{byteConverter(size)}</FileItemContentSize>}
            <FileItemContentText>
              {isPreviewError ? intl.formatMessage(messages.fileErrorName) : name}
            </FileItemContentText>
          </FileItemContent>
        </FileItemSide>

        {!isPreviewError && (
          <FileButtonTooltip
            title={
              type === FileItemType.EDIT
                ? intl.formatMessage(messages.fileRemoveTooltip)
                : intl.formatMessage(messages.fileDownloadTooltip)
            }
            placement="right"
            getPopupContainer={(trigger) => trigger}
          >
            {getButton()}
          </FileButtonTooltip>
        )}
      </FileItemOverlay>
    </>
  );
};

export default FileItem;
