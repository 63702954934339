const byteConverter = (bytes: number): string => {
  if (bytes >= 1048576) {
    // Convert to MB
    const megabytes = (bytes / 1048576).toFixed(2);
    return `${megabytes} MB`;
  }

  if (bytes >= 1024) {
    // Convert to KB
    const kilobytes = (bytes / 1024).toFixed(2);
    return `${kilobytes} KB`;
  }

  return `${bytes} B`;
};

export default byteConverter;
