import { types } from 'mobx-state-tree';
import { ComponentTypes } from '_constants';

const MComponent = types.model({
  id: types.string,
  type: types.enumeration<typeof ComponentTypes[keyof typeof ComponentTypes]>('Type', Object.values(ComponentTypes)),
  name: types.string,
  icon: types.string,
  src: types.maybeNull(types.string),
  slug: types.maybeNull(types.string),
  settings: types.maybeNull(
    types.model({
      catalogId: types.maybeNull(types.number),
      pollId: types.maybeNull(types.number),
      openInNewTab: types.maybeNull(types.boolean),
      withCredentials: types.maybeNull(types.boolean),
    }),
  ),
});

export default MComponent;
