import { Tooltip, Typography } from 'antd';
import styled, { css } from 'styled-components';

import { TaskStatus } from 'models/Tasks';
import { ReactComponent as Info } from 'assets/icons/info-circle.svg';
import { Grid } from '_constants';

export const Task = styled.div`
  min-height: 88px;
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.palette.dark.dark_8};
  cursor: pointer;
  @media (max-width: ${Grid.MD}px) {
    padding: 16px;
    border-radius: 6px;
  }
  &:first-of-type {
    margin-top: 0;
  }

  &:hover {
    background: ${({ theme }) => theme.palette.dark.dark_4};
    border-color: ${({ theme }) => theme.palette.dark.dark_8};
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 506px;
  @media (max-width: ${Grid.MD}px) {
    width: 92%;
  }
`;

export const TaskInfoBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;

  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.01em;

  color: ${({ theme }) => theme.palette.dark.dark_50};
`;

export const TaskName = styled(Typography.Paragraph)`
  &&& {
    flex: 0 1 auto;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    line-height: 24px;
    word-break: break-word;
    color: ${({ theme }) => theme.palette.dark.main};
    margin-bottom: 0;
    @media (max-width: ${Grid.MD}px) {
      white-space: normal;
    }
  }
`;

interface IStatusLabel {
  status: TaskStatus;
}

export const StatusLabel = styled.div<IStatusLabel>`
  display: none;
  align-items: center;
  align-self: center;
  height: 24px;
  padding: 6px 8px;
  margin-right: 8px;
  border-radius: 32px;

  font-size: 12px;
  font-weight: 500;

  ${({ theme, status }) =>
    status === TaskStatus.NEW &&
    css`
      display: flex;
      background-color: ${theme.palette.notify};
      color: ${theme.palette.white.primary};
    `}

  ${({ theme, status }) =>
    (status === TaskStatus.IN_REVIEW || status === TaskStatus.SUBMITTED) &&
    css`
      display: flex;
      background-color: ${theme.palette.dark.dark_8};
      color: ${theme.palette.dark.main};
    `}

  ${({ theme, status }) =>
    status === TaskStatus.REJECTED &&
    css`
      display: flex;
      background-color: ${theme.palette.error.secondary};
      color: ${theme.palette.error.primary};
    `}
`;

export const TaskIcon = styled.div`
  width: 20px;
  height: 20px;
`;

export const StyledTooltip = styled(Tooltip)`
  .ant-tooltip-inner {
    width: 100%;
    padding: 12px;
    background-color: #fff;
    box-shadow: 0 0 20px ${({ theme }) => theme.palette.shadow.main};
    border-radius: 10px;
    color: ${({ theme }) => theme.palette.dark.main};
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    max-width: 228px;
  }
  .ant-tooltip-arrow {
    width: 17px;
    height: 17px;
    bottom: -9px;
  }
  .ant-tooltip-arrow-content {
    background-color: ${({ theme }) => theme.palette.white.primary};
    width: 12px;
    height: 12px;
    transform: translateY(-10px) rotate(45deg);
  }

  &:hover {
    svg {
      path:nth-child(2) {
        fill-opacity: 0.76;
      }
    }
  }
`;

export const IconOverlay = styled.span`
  display: inline-flex;
  cursor: pointer;
`;

export const InfoIcon = styled(Info)``;
