import { Image, Typography } from 'antd';
import styled from 'styled-components';
import { Text } from 'components';
import { MIXINS } from '_constants';
import { ReactComponent as VideoIcon } from 'assets/images/video.svg';
import { ReactComponent as PlayIcon } from 'assets/icons/play.svg';

export const StyledPlayIcon = styled(PlayIcon)`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  transform: translate(-50%, -50%);
  transition: transform 0.2s;
`;

export const PreloadImage = styled(Image)`
  position: absolute;
  z-index: 2;
`;

export const Label = styled(Text)`
  &&&& {
    position: absolute;
    z-index: 2;
    display: block;
    padding: 4px 16px 3px;
    border-radius: 5px;
    background-color: ${({ theme: { palette } }) => palette.primary.main};
    color: ${({ theme: { palette } }) => palette.white.primary};
  }
`;

export const ContentTypeLabel = styled(Label)`
  &&&& {
    top: 14px;
    right: 14px;
  }
`;

export const DurationLabel = styled(Label)`
  &&&& {
    bottom: 14px;
    right: 14px;
  }
`;

export const PlayerWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  overflow: hidden;
  border-radius: 8px;
  z-index: 4;
`;

export const StyledVideoIcon = styled(VideoIcon)`
  opacity: 0.2;
  color: ${({ theme }) => theme.palette.white.primary};
  width: 33%;
`;

export const VideoIconWrapper = styled.div`
  ${MIXINS.fullSizedAbsolute}
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.black.primary};
  ${MIXINS.mediaBackgroundColorTransition}
`;

export const IconOverlay = styled.div`
  ${MIXINS.fullSizedAbsolute}
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  pointer-events: none;
  ${MIXINS.mediaBackgroundColorTransition}
`;

export const PreloadWrapper = styled.div<{ resized: boolean }>`
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ resized }) => (resized ? '99.9%' : 'auto')};
  cursor: pointer;
  &:hover {
    ${IconOverlay} {
      background-color: ${({ theme }) => theme.palette.mask.light};
    }
    ${StyledPlayIcon} {
      transform: translate(-50%, -50%) scale(1.1);
    }
    ${VideoIconWrapper} {
      background-color: ${({ theme }) => theme.palette.mask.dark};
    }
  }
  ${MIXINS.ratio16x9}

  & .ant-image {
    ${MIXINS.fullSizedAbsolute}
    overflow: hidden;
  }
`;

export const VideoName = styled(Typography.Paragraph)`
  font-weight: 600;
  margin-top: 8px;
  margin-left: 4px;
  overflow: hidden;
`;
