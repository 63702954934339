import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import { TTaskAttachment, TTaskFormAttachmentItem } from 'types';

import { ReactComponent as CloudUpdate } from 'assets/icons/cloud-upload.svg';
import { UploadFileOptions } from 'types/tasksApiTypes';
import FileItem from '../FileItem';
import { FileItemType } from '../FileItem/FileItem';

import { Dragger, FileDraggerDrop, FileDraggerOverlay, FileDraggerText, FileDraggerTitle } from './FileDragger.styles';
import messages from './messages';
import AttachedFiles from '../AttachedFiles';

interface IFileDragger {
  value?: TTaskFormAttachmentItem[] | string;
  onChange?: (formFiles: TTaskFormAttachmentItem[]) => void;
  isError?: boolean;
  viewOnly?: boolean;
  uploadFile?: (options: UploadFileOptions<TTaskAttachment>) => Promise<void>;
  cancelFileUploading?: () => void;
}

const MAX_FILES_COUNT = 1;

const FileDragger: React.FC<IFileDragger> = ({
  value,
  onChange,
  isError = false,
  viewOnly = false,
  uploadFile,
  cancelFileUploading,
}) => {
  const intl = useIntl();
  const [filesCount, setFilesCount] = useState(0);
  const [fileListState, setFileListState] = useState<UploadFile<TTaskAttachment>[] | undefined>();
  const isMountedRef = useRef(false);

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  // Set init file list
  useEffect(() => {
    setFileListState((prev) => {
      if (prev === undefined && value !== undefined && Array.isArray(value)) {
        setFilesCount(value?.length ?? 0);
        return (
          value.map((formFileItem) => ({
            uid: formFileItem.attachment?.id.toString() || '',
            name: formFileItem.attachment?.name || '',
            size: formFileItem.attachment?.size || 0,
            response: formFileItem.attachment,
          })) ?? []
        );
      }
      return prev;
    });
  }, [value]);

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (!e.currentTarget.classList.contains('drag-over')) {
      e.currentTarget.classList.add('drag-over');
    }
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (e.currentTarget.classList.contains('drag-over')) {
      e.currentTarget.classList.remove('drag-over');
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.currentTarget.classList.remove('drag-over');
  };

  return (
    <>
      {viewOnly ? (
        <AttachedFiles
          files={typeof value === 'string' ? [] : value?.map((file) => file.attachment as TTaskAttachment) ?? []}
          shouldShowEmpty
          source="answers"
        />
      ) : (
        <FileDraggerOverlay>
          <FileDraggerDrop
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            className={isError ? 'drag-error' : undefined}
          >
            <Dragger
              fileList={fileListState}
              listType="picture"
              maxCount={MAX_FILES_COUNT}
              customRequest={uploadFile}
              showUploadList={{
                showDownloadIcon: true,
                showRemoveIcon: true,
              }}
              onChange={(info: UploadChangeParam<UploadFile<TTaskAttachment>>) => {
                if (!isMountedRef.current) {
                  return;
                }

                setFileListState(info.fileList);
                setFilesCount(info.fileList.length);

                if (info.file.status === 'removed') {
                  cancelFileUploading?.();
                  onChange?.([]);
                } else {
                  onChange?.([
                    {
                      attachment: info.file.response,
                      status: info.file.status ?? 'error',
                      error: info.file.error,
                    },
                  ]);
                }
              }}
              disabled={filesCount >= MAX_FILES_COUNT}
              itemRender={(originNode, file, fileList, actions) => {
                return (
                  <FileItem
                    isError={file.status === 'error'}
                    isLoading={file.status === 'uploading'}
                    name={file.name}
                    size={file.size}
                    type={FileItemType.EDIT}
                    onClickHandler={actions.remove}
                  />
                );
              }}
            >
              <CloudUpdate />
              <FileDraggerTitle>{intl.formatMessage(messages.title)}</FileDraggerTitle>
              <FileDraggerText>{intl.formatMessage(messages.text)}</FileDraggerText>
            </Dragger>
          </FileDraggerDrop>
        </FileDraggerOverlay>
      )}
    </>
  );
};

export default FileDragger;
