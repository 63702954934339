import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { TCatalogGroupAttendees, TCatalogGroup, TGroupAttendee } from 'types';
import { observer } from 'mobx-react';
import { cast } from 'mobx-state-tree';
import { List } from 'antd';
import { ChevronAnchor, Spinner } from 'components';
import store from 'store';
import { useStateIfMounted, useAppNavigator } from 'utils';
import AttendeeView from '../AttendeeView';
import ContactsModal from '../ContactsModal';
import { GroupWrapper, LoadMoreWrapper } from './AttendeesGroup.styles';
import messages from './messages';

interface IContactsModalState {
  visible: boolean;
  attendee: TGroupAttendee | null;
}

const initContactsModalState: IContactsModalState = {
  visible: false,
  attendee: null,
};

interface Props {
  group: TCatalogGroup;
}

const GroupAttendees: React.FC<Props> = ({ group }) => {
  const intl = useIntl();
  const { fetchGroupAttendees } = store.DomainStore.catalog;
  const { setLoadingCatalogGroupsId, removeLoadingCatalogGroupsId } = store.UIStore.course;

  const [contactsModalState, setContactsModalState] = useState<IContactsModalState>(initContactsModalState);
  const [attendees, setAttendees] = useStateIfMounted<TCatalogGroupAttendees | null>(null);
  const [isLoading, setLoading] = useStateIfMounted(false);
  const {
    params: { trackId },
  } = useAppNavigator();

  const onInfoClickHandler = (attendee: TGroupAttendee) => () => {
    setContactsModalState({ visible: true, attendee });
  };

  const init = useCallback(async () => {
    setLoading(true);
    setLoadingCatalogGroupsId(group.id);
    const data = await fetchGroupAttendees(trackId, group.groupId, 1);
    if (data) {
      setAttendees(data);
    }
    setLoading(false);
    removeLoadingCatalogGroupsId(group.id);
  }, [
    setLoading,
    setLoadingCatalogGroupsId,
    group.id,
    group.groupId,
    fetchGroupAttendees,
    trackId,
    removeLoadingCatalogGroupsId,
    setAttendees,
  ]);

  const loadMoreAttendees = useCallback(async () => {
    if (attendees) {
      setLoading(true);
      const data = await fetchGroupAttendees(trackId, group.groupId, attendees.context.page + 1);
      if (data) {
        setAttendees({ context: data.context, items: cast([...attendees.items, ...data.items]) });
      }
      setLoading(false);
    }
  }, [attendees, setLoading, fetchGroupAttendees, trackId, group.groupId, setAttendees]);

  useEffect(() => {
    init();
    return () => {
      removeLoadingCatalogGroupsId(group.id);
    };
  }, [init, group.id, removeLoadingCatalogGroupsId]);

  const hasMoreAttendees = attendees && attendees.context.page < attendees.context.pages;

  if (!attendees?.context.totalRows) {
    return null;
  }

  return (
    <GroupWrapper>
      <List
        itemLayout="horizontal"
        dataSource={attendees?.items}
        loading={{ indicator: <Spinner />, spinning: isLoading }}
        renderItem={(attendee: TGroupAttendee) => (
          <AttendeeView attendee={attendee} onInfoClick={onInfoClickHandler(attendee)} />
        )}
      />

      {hasMoreAttendees && (
        <LoadMoreWrapper>
          <ChevronAnchor
            name={intl.formatMessage(messages.loadMore)}
            chevronRotate="bottom"
            onClick={loadMoreAttendees}
          />
        </LoadMoreWrapper>
      )}

      <ContactsModal
        visible={contactsModalState.visible}
        attendee={contactsModalState.attendee}
        onClose={() =>
          setContactsModalState((prev: IContactsModalState) => ({ attendee: null, visible: !prev.visible }))
        }
      />
    </GroupWrapper>
  );
};

export default observer(GroupAttendees);
