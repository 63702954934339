import styled from 'styled-components';
import { Tooltip } from 'antd';

export const FileItemOverlay = styled.div<{ isGreyBackground: boolean }>`
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.palette.dark.dark_10};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 14px 4px 4px;
  margin-top: 8px;
  background-color: ${({ isGreyBackground, theme }) =>
    isGreyBackground ? theme.palette.dark.dark_4 : theme.palette.white.primary};
`;

export const FileItemIconOverlay = styled.div`
  width: 44px;
  height: 44px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.palette.dark.dark_12};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  flex-shrink: 0;
`;

export const FileItemContent = styled.div`
  width: calc(100% - 56px);
`;

export const FileItemButton = styled.div`
  margin-left: 12px;
  cursor: pointer;
  display: flex;
`;

export const FileItemContentSize = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: ${({ theme }) => theme.palette.dark.main};
  margin-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const FileItemContentText = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.14px;
  color: ${({ theme }) => theme.palette.dark.dark_50};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const FileItemSide = styled.div`
  display: flex;
  align-items: center;
  width: calc(100% - 36px);
`;

export const FileButtonTooltip = styled(Tooltip)`
  .ant-tooltip-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 12px;
    font-family: 'Roboto', sans-serif;
    background-color: #fff;
    border-radius: 10px;
    color: ${({ theme }) => theme.palette.dark.main};
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    white-space: normal;
  }
  .ant-tooltip-placement-top.ant-tooltip-arrow {
    transform: translateY(-2px);
  }
  .ant-tooltip-arrow-content {
    background-color: ${({ theme }) => theme.palette.white.primary};
    width: 8px;
    height: 8px;
  }
`;
