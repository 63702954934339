import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'components.tasks.fileDragger.title',
    defaultMessage: 'Загрузить файл',
  },
  text: {
    id: 'components.tasks.fileDragger.text',
    defaultMessage: 'Нажмите или перетащите файл в эту область для загрузки',
  },
});
