import React, { useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { Player } from 'components';
import { UIStore } from 'store';
import { getRootCatalogIdFromCatalogParam, sendAnalyticsEvent, useAppNavigator, useVisibility } from 'utils';
import playIcon from 'assets/icons/play.svg';
import { Container, VideoName, PlayIcon } from './YoutubeLink.styles';

interface Props {
  url: string;
  text: string;
}

const YoutubeLink: React.FC<Props> = ({ url, text }) => {
  const { visible: visibleIcon, hide: hideIcon } = useVisibility(true);
  const playerRef = useRef<HTMLVmPlayerElement>(null);
  const [analyticsEventSended, setAnalyticsEventSended] = useState(false);
  const {
    params: { catalog: catalogParam },
  } = useAppNavigator();

  const onPlay = () => {
    UIStore.layout.setActivePlayerRef(playerRef.current);
    if (visibleIcon) {
      hideIcon();
    }

    if (!analyticsEventSended && catalogParam) {
      sendAnalyticsEvent('Catalog: Play video', {
        media_type: 'youtube',
        media_url: url,
        media_name: text,
        catalogId: getRootCatalogIdFromCatalogParam(catalogParam),
      });
      setAnalyticsEventSended(true);
    }
  };

  return (
    <>
      <Container>
        <Player type="youtube" src={url} playerRef={playerRef} onPlay={onPlay} language={UIStore.locale} />
        <PlayIcon src={playIcon} alt="" style={{ opacity: visibleIcon ? 1 : 0 }} />
      </Container>
      <VideoName>{text}</VideoName>
    </>
  );
};

export default observer(YoutubeLink);
