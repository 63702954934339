import styled from 'styled-components';
import { Modal } from 'components';
import AttendeeView from '../AttendeeView';

export const StyledModal = styled(Modal)`
  &&& {
    .ant-modal-mask {
      z-index: 1031;
    }
    .ant-modal-wrap {
      z-index: 1032;
    }
    .ant-modal-content {
      overflow: initial;
      padding: 44px;
      padding-bottom: 25px;
      .ant-modal-body {
        display: block;
      }
    }
  }
`;

export const AttendeeStyled = styled(AttendeeView)`
  &&& {
    grid-row-gap: 0;
    padding: 0;
    border-bottom: none;
  }
`;

export const AttendeeWrapper = styled.div`
  padding-bottom: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.dark.dark_8};
`;

export const SpinnerWrapper = styled.div`
  height: 44px;
`;
