import { TResponded } from 'types';
import TProfileResponse from 'types/profileApiTypes';
import api from './api';
import { USER_METRICS_URL } from './constants';

const getProfile = (): Promise<TProfileResponse> => api.privateRequest('get', 'api/web/tlrocks/profile');

const sendUserActivity = (): Promise<TResponded<null>> =>
  api.privateRequest('post', USER_METRICS_URL, {
    json: {
      ActivityType: 0,
    },
  });

export default { getProfile, sendUserActivity };
