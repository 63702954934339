import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.dark.dark_4};
`;

export const ScreenWrapper = styled.div`
  display: flex;
  justify-content: center;
  overflow-y: scroll;
  width: 100%;
  padding: 24px;
`;

export const ScrollToTopOverlay = styled.div`
  position: fixed;
  right: 24px;
  bottom: 24px;
  z-index: 100;
`;
