import React, { useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { useIntl } from 'react-intl';
import { DomainStore } from 'store';
import { TGroupAttendee } from 'types';
import emptyThumbnail from 'assets/icons/avatar.svg';
import { Spinner, InfoButton } from 'components';
import {
  Row,
  Description,
  IconWrapper,
  Name,
  RoleDescription,
  AvatarStyled,
  SpinnerWrapper,
  Wrapper,
  DescriptionTooltip,
} from './AttendeeView.styles';
import messages from './messages';

interface Props {
  attendee: TGroupAttendee;
  onInfoClick?: () => void;
  className?: string;
  noIconButton?: boolean;
  isLoading?: boolean;
  isModal?: boolean;
}

const AttendeeView: React.FC<Props> = ({
  attendee,
  onInfoClick,
  className,
  noIconButton = false,
  isLoading = false,
  isModal = false,
}) => {
  const {
    eventSettings: { eventInfo },
  } = DomainStore;
  const intl = useIntl();
  const { firstName, lastName, company, thumbnail, position } = attendee;
  const [positionElement, setPositionElement] = useState<HTMLDivElement | null>(null);
  const [companyElement, setCompanyElement] = useState<HTMLDivElement | null>(null);

  // HACK: Ref для conditional элемента
  const companyRef = useCallback(
    (ref: HTMLDivElement) => {
      if (isModal) {
        setCompanyElement(ref);
      }
    },
    [isModal],
  );
  const positionRef = useCallback(
    (ref: HTMLDivElement) => {
      if (isModal) {
        setPositionElement(ref);
      }
    },
    [isModal],
  );

  const showPositionTooltip = useMemo(
    () =>
      isModal && !!attendee.position && !!positionElement && positionElement.scrollWidth > positionElement.clientWidth,
    [isModal, attendee.position, positionElement],
  );

  const showCompanyTooltip = useMemo(
    () => isModal && !!attendee.company && !!companyElement && companyElement.scrollWidth > companyElement.clientWidth,
    [isModal, attendee.company, companyElement],
  );

  return (
    <Wrapper>
      {isLoading && (
        <SpinnerWrapper>
          <Spinner fullscreen />
        </SpinnerWrapper>
      )}
      <Row className={className} infoCol={!noIconButton && !!onInfoClick} isLoading={isLoading}>
        <AvatarStyled size={60} src={thumbnail || emptyThumbnail} />
        <Description>
          <Name ellipsis>
            {eventInfo.attendeeNameOrder === 'lastName' ? `${lastName} ${firstName}` : `${firstName} ${lastName}`}
          </Name>
          {company && (
            <DescriptionTooltip
              title={showCompanyTooltip ? company : undefined}
              placement="bottomLeft"
              getPopupContainer={(trigger) => trigger}
            >
              <div ref={companyRef}>
                <RoleDescription ellipsis type="secondary">
                  {company}
                </RoleDescription>
              </div>
            </DescriptionTooltip>
          )}
          {position && (
            <DescriptionTooltip
              title={showPositionTooltip ? position : undefined}
              placement="bottomLeft"
              getPopupContainer={(trigger) => trigger}
            >
              <div ref={positionRef}>
                <RoleDescription ellipsis type="secondary">
                  {position}
                </RoleDescription>
              </div>
            </DescriptionTooltip>
          )}
        </Description>
        <IconWrapper>
          {!noIconButton && !!onInfoClick && (
            <InfoButton onClick={onInfoClick} name={intl.formatMessage(messages.infoPopoverName)} globalContainer />
          )}
        </IconWrapper>
      </Row>
    </Wrapper>
  );
};

export default observer(AttendeeView);
