import styled from 'styled-components';
import { Text } from 'components';

export const TextStyled = styled(Text)`
  &&& {
    transition: color 0.4s;
    letter-spacing: 0.2px;
    white-space: nowrap;
    &:hover {
      color: ${({ theme: { palette } }) => palette.dark.main};
    }
  }
`;

export default TextStyled;
