import React, { useRef } from 'react';
import styled from 'styled-components';

const DownloadLink = styled.a`
  display: none;
`;

interface IUseDownloadHiddenLinkResult {
  getComponent: () => React.ReactNode;
  download: (file: IDownloadFile) => void;
}

export interface IDownloadFile {
  fileName: string;
  downloadSrc: string;
}

export const useDownloadHiddenLink = (): IUseDownloadHiddenLinkResult => {
  const downloadLinkRef = useRef<HTMLAnchorElement>(null);

  const getComponent = () => <DownloadLink ref={downloadLinkRef} target="_blank" rel="noreferrer" />;
  const download = (file: IDownloadFile) => {
    if (downloadLinkRef.current) {
      downloadLinkRef.current.download = file.fileName;
      downloadLinkRef.current.href = file.downloadSrc;
      downloadLinkRef.current.click();
    }
  };

  return {
    getComponent,
    download,
  };
};

export default useDownloadHiddenLink;
