/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["self"] }] */
import { types, flow, cast } from 'mobx-state-tree';

import tasksApi from 'api/tasksApi';
import { MTask } from 'models';
import { TaskStatus } from 'models/Tasks';
import MTaskInfo from 'models/Tasks/MTaskInfo';
import {
  TTaskResponse,
  TTasksInfoResponse,
  TTaskUpdateSubmissionResponse,
  TTaskSendSubmissionRequest,
  TTaskStartSubmissionResponse,
  TTaskAttachmentResponse,
  TTaskAttachmentRequest,
  TFieldAnswerAttachmentRequest,
  TFeedbackAttachmentRequest,
} from 'types/tasksApiTypes';
import { MTaskStartSubmission } from 'models/Tasks/MTask';

const MyTasks = types
  .model({
    data: types.maybeNull(MTask),
    myTasks: types.optional(types.array(MTaskInfo), []),
    loading: types.optional(types.boolean, false),
    submissionsInfo: types.maybeNull(MTaskStartSubmission),
  })
  .views((self) => ({
    get activeTasks() {
      return self.myTasks.filter((task) => task.status !== TaskStatus.COMPLETED);
    },
    get completedTasks() {
      return self.myTasks.filter((task) => task.status === TaskStatus.COMPLETED);
    },
    get activeTasksCount() {
      return this.activeTasks.length;
    },
    get completedTasksCount() {
      return this.completedTasks.length;
    },
    get hasNewTasks() {
      return this.activeTasks.some((task) => task.status === TaskStatus.NEW);
    },
  }))
  .actions((self) => ({
    setLoading(loading: boolean) {
      self.loading = loading;
    },
  }))
  .actions((self) => ({
    startTask: flow(function* startTask(taskId: number) {
      const { data, hasError }: TTaskStartSubmissionResponse = yield tasksApi.startTask(taskId);
      if (data && !hasError) {
        self.submissionsInfo = cast(data);
      }
    }),
  }))
  .actions((self) => ({
    fetchTasks: flow(function* fetchTasks() {
      const { data, hasError }: TTasksInfoResponse = yield tasksApi.getTasksInfo();
      if (!hasError && data) {
        self.myTasks = cast(data);
      }
    }),
    fetchTask: flow(function* fetchTask(taskId) {
      const { data, hasError }: TTaskResponse = yield tasksApi.getTask(taskId);

      if (data && !hasError) {
        self.data = data;
        if (!data.lastSubmission) {
          yield self.startTask(taskId);
        }
      }
    }),
    getTaskAttachmentUrl: flow(function* getTaskAttachmentUrl(payload: TTaskAttachmentRequest) {
      const { data, hasError }: TTaskAttachmentResponse = yield tasksApi.getTaskAttachment(payload);
      if (!hasError && data) {
        return data.url;
      }
      return null;
    }),
    getFieldAnswerAttachmentUrl: flow(function* getFieldAnswerAttachmentUrl(payload: TFieldAnswerAttachmentRequest) {
      const { data, hasError }: TTaskAttachmentResponse = yield tasksApi.getFieldAnswerAttachment(payload);
      if (!hasError && data) {
        return data.url;
      }
      return null;
    }),
    getFeedbackAttachmentUrl: flow(function* getFeedbackAttachmentUrl(payload: TFeedbackAttachmentRequest) {
      const { data, hasError }: TTaskAttachmentResponse = yield tasksApi.getFeedbackAttachment(payload);
      if (!hasError && data) {
        return data.url;
      }
      return null;
    }),
  }))
  .actions((self) => ({
    sendTask: flow(function* sendTask(taskRequestData: TTaskSendSubmissionRequest) {
      yield tasksApi.sendTask(taskRequestData);
    }),
    updateTask: flow(function* updateTask(taskId: number, submissionId: number) {
      const { data }: TTaskUpdateSubmissionResponse = yield tasksApi.updateTask(taskId, submissionId);
      if (data && self.data) {
        self.data.setStatus(data.status);
      }
    }),
  }));

export default MyTasks;
