const convertSecondsToHMSFormat = (sec: number): string => {
  const hours = Math.floor(sec / 3600);
  const minutes = Math.floor((sec - hours * 3600) / 60);
  const seconds = Math.floor(sec - hours * 3600 - minutes * 60);
  const resultHours = hours < 10 ? `0${hours}` : `${hours}`;
  const resultMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
  const resultSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
  // NOTE: Убираем часы из строки, если они отсутствуют
  return `${resultHours}:${resultMinutes}:${resultSeconds}`.replace(/^00:?/, ''); // Return is HH : MM : SS
};

export default convertSecondsToHMSFormat;
