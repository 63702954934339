import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'views.learningTracks.completedLabel.title',
    defaultMessage: 'Завершено',
  },
  result: {
    id: 'views.learningTracks.completedLabel.result',
    defaultMessage: '{resultText} {passedStageCount} из {stageCount}',
  },
});
