import React from 'react';
import { ReactComponent as CheckMarkRight } from 'assets/icons/checkmark-right.svg';
import { ReactComponent as CheckMarkGrey } from 'assets/icons/checkmark-chosen.svg';
import { ReactComponent as CrossMark } from 'assets/icons/cross.svg';
import { CheckBoxOptions } from 'components/Polls/constants';
import {
  StyledTextCheckbox,
  PercentOverlay,
  PercentColorBlock,
  OptionText,
  CheckMarkSelect,
} from './TextCheckbox.styles';

const CheckboxIcon = {
  [CheckBoxOptions.RIGHT]: <CheckMarkRight />,
  [CheckBoxOptions.WRONG]: <CrossMark />,
  [CheckBoxOptions.SECONDARY_RIGHT]: <CheckMarkGrey />,
  [CheckBoxOptions.NORMAL]: <CheckMarkSelect />,
};

interface IProps {
  option?: string;
  value: string;
  percent?: number | null;
  disable?: boolean;
  variant?: CheckBoxOptions;
}

const TextCheckbox: React.FC<IProps> = ({ disable, option, value, percent, variant }) => {
  const actionIcon = CheckboxIcon[variant || CheckBoxOptions.NORMAL];
  return (
    <StyledTextCheckbox disabled={disable} variant={variant} value={value} percent={typeof percent === 'number'}>
      <OptionText>{option}</OptionText>
      {typeof percent === 'number' && (
        <>
          <PercentOverlay>{percent}%</PercentOverlay>
          <PercentColorBlock percent={percent} variant={variant} />
        </>
      )}
      {actionIcon}
    </StyledTextCheckbox>
  );
};

export default TextCheckbox;
