import { types } from 'mobx-state-tree';
import { CatalogElementTypes } from './constants';

const MCatalogBooking = types
  .model({
    id: types.identifierNumber,
    catalogId: types.number,
    bookingId: types.number,
    type: types.enumeration('Element type', [CatalogElementTypes.BOOKING]),
    order: types.number,
    bookingUuid: types.string,
  })
  .named('CatalogBooking');

export default MCatalogBooking;
