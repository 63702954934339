import { types } from 'mobx-state-tree';

export enum ConfirmationTypes {
  SMS = 'sms',
  EMAIL = 'email',
}

export const MEventInfoFirebaseConfig = types.model({
  apiKey: types.string,
  authDomain: types.string,
  databaseUrl: types.string,
  projectId: types.string,
  storageBucket: types.string,
  messagingSenderId: types.string,
  appId: types.string,
  measurementId: types.string,
});

const MEventInfo = types.model({
  eventId: types.maybeNull(types.number),
  eventTitle: types.maybeNull(types.string),
  eventPublicId: '',
  accentColor: '',
  backgroundImageUrl: types.maybeNull(types.string),
  dominantColor: types.maybeNull(types.string),
  logoImageUrl: types.maybeNull(types.string),
  additionalBackgroundImageUrl: types.maybeNull(types.string),
  additionalDominantColor: types.maybeNull(types.string),
  additionalLogoImageUrl: types.maybeNull(types.string),
  notificationsEnabled: false,
  hideTalentRocksLogo: false,
  confirmationTypes: types.array(
    types.enumeration<ConfirmationTypes>('ConfirmationTypes', Object.values(ConfirmationTypes)),
  ),
  registrationClosed: false,
  yandexMetrikaEnabled: false,
  firebaseAnalyticsEnabled: false,
  firebaseConfig: types.maybeNull(MEventInfoFirebaseConfig),
  attendeeNameOrder: types.maybeNull(types.string),
});

export default MEventInfo;
