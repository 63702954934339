import { types } from 'mobx-state-tree';

export enum CurrentStageType {
  PROCESSING = 'Processing',
  PASSED = 'Passed',
  FAILED = 'Failed',
}

const MNextStageInfo = types.model({
  id: types.number,
  isCompleted: types.boolean,
});

export const MNextStage = types.model({
  currentStageStatus: types.maybeNull(types.enumeration<CurrentStageType>('Type', Object.values(CurrentStageType))),
  nextStage: types.maybeNull(MNextStageInfo),
});

export default MNextStage;
