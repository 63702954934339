import React from 'react';
import { useIntl } from 'react-intl';
import { StyledModal, ModalContent, ModalText, ModalTitle, StyledSmileFolder } from './AdminModal.styles';
import messages from './messages';

interface IProps {
  visible: boolean;
  onClose: () => void;
}

const AdminModal: React.FC<IProps> = ({ visible, onClose }) => {
  const intl = useIntl();

  return (
    <StyledModal visible={visible} onClose={onClose} onOk={onClose} okText={intl.formatMessage(messages.modalButton)}>
      <ModalContent>
        <StyledSmileFolder />
        <ModalTitle level={2}>{intl.formatMessage(messages.modalTitle)}</ModalTitle>
        <ModalText>{intl.formatMessage(messages.modalText)}</ModalText>
      </ModalContent>
    </StyledModal>
  );
};

export default AdminModal;
