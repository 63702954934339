import { useState, useMemo, useCallback } from 'react';

const useVisibility = (initialState = false): UseVisibility => {
  const [visible, setVisible] = useState(initialState);

  const show = useCallback(() => setVisible(true), [setVisible]);
  const hide = useCallback(() => setVisible(false), [setVisible]);
  const toggle = useCallback(() => setVisible((prev) => !prev), [setVisible]);

  return useMemo(
    () => ({
      visible,
      show,
      hide,
      toggle,
    }),
    [hide, show, toggle, visible],
  );
};

export type UseVisibility = {
  visible: boolean;
  show: () => void;
  hide: () => void;
  toggle: () => void;
};

export default useVisibility;
