export enum LearningTracksStatuses {
  NOT_STARTED = 'notStarted',
  IN_PROGRESS = 'inProgress',
  COMPLETED = 'completed',
}

export enum LearningTrackFormat {
  CLASSIC = 'classic',
  GAME = 'game',
}

export enum LearningTrackStepsStatuses {
  CLOSED = 'closed',
  NOT_AVAILABLE = 'notAvailable',
  NOT_STARTED = 'notStarted',
  IN_PROGRESS = 'inProgress',
  COMPLETED = 'completed',
}

export enum LearningTrackStepsContentTypes {
  /**
   * Пока только один тип - course
   */
  COURSE = 'course',
}
