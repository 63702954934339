export enum NotificationButtonsTypes {
  CANCEL = 'cancel',
  CONFIRM = 'confirm',
}

export type TNotificationConfig = {
  placement?: string;
  style?: React.CSSProperties;
  prefixCls?: string;
  className?: string;
  top?: number;
  bottom?: number;
  onClose?: () => void;
  onClick?: () => void;
};

export type TNotificationButton = {
  title: string;
  action: () => void;
  type: NotificationButtonsTypes.CANCEL | NotificationButtonsTypes.CONFIRM;
};

export type TNotification = {
  id?: string;
  title?: string;
  description?: string;
  type: string;
  buttons?: TNotificationButton[];
  duration?: number | null;
  closable?: boolean;
  notificationConfig?: TNotificationConfig;
};
