import styled from 'styled-components';
import { Text } from 'components';
import { Grid } from '_constants';

export const RateResultOverlay = styled.div`
  display: flex;
  align-items: center;
`;

export const RateResultText = styled(Text)`
  margin-left: 14px;
  &&& {
    color: ${({ theme }) => theme.palette.dark.main};
    font-size: 24px;
    line-height: 32px;
    @media (max-width: ${Grid.MD}px) {
      font-size: 16px;
      font-weight: 500;
      line-height: 16px;
    }
  }
`;
