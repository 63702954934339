import styled from 'styled-components';
import { ReactComponent as CheckmarkIcon } from 'assets/icons/checkmark-circle-check.svg';
import { Typography } from 'antd';
import { Grid } from '_constants';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 12px 24px 16px 24px;
  background-color: ${({ theme }) => theme.palette.success.primary};
  border-radius: 16px;
`;

export const Content = styled.div`
  max-width: calc(100% - 32px);
`;

export const Title = styled.div`
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  color: ${({ theme }) => theme.palette.white.primary};
  @media (max-width: ${Grid.MD}px) {
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
  }
`;

export const Result = styled(Typography.Paragraph)`
  &&&& {
    margin-top: 4px;
    margin-bottom: 0;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 16px;
    font-weight: 400;
    color: ${({ theme }) => theme.palette.white.primary};
  }
`;

export const Checkmark = styled(CheckmarkIcon)`
  &&& {
    flex-shrink: 0;
    margin-left: 8px;
    path {
      fill: ${({ theme }) => theme.palette.success.primary};
    }
    path + rect {
      stroke: ${({ theme }) => theme.palette.success.primary};
    }
  }
`;
