import styled from 'styled-components';
import { Tooltip } from 'components';
import { Grid } from '_constants';

export const LinkStyled = styled.a`
  .ant-typography-single-line {
    @media (max-width: ${Grid.MD}px) {
      white-space: normal;
    }
  }
`;

export const StyledTooltip = styled(Tooltip)`
  .ant-tooltip-inner {
    width: 320px;
  }
`;
