import styled, { css } from 'styled-components';
import { Button } from 'components';
import { ReactComponent as ChevronIcon } from 'assets/icons/chevron.svg';
import { TChevronDirection, OChevronDirection } from '../constants';

interface IButtonStyled {
  $chevronDirection: TChevronDirection;
  $size: number;
}

const chevronDirectionStyles = {
  [OChevronDirection.LEFT]: css`
    transform: rotate(180deg);
  `,
  [OChevronDirection.RIGHT]: css`
    order: 1;
    margin-right: -8px;
    margin-left: 4px;
    transform: rotate(0deg);
  `,
  [OChevronDirection.TOP]: css`
    position: relative;
    top: 1px;
    transform: rotate(270deg);
  `,
  [OChevronDirection.BOTTOM]: css`
    position: relative;
    top: -1px;
    transform: rotate(90deg);
  `,
};

export const ChevronIconStyled = styled(ChevronIcon)`
  transition: inherit;
  path {
    transition: inherit;
  }
`;

export const ButtonStyled = styled(Button)<IButtonStyled>`
  &&& {
    &.ant-btn {
      width: auto;
      height: ${({ $size }) => `${$size}px`};
      padding: 0 24px;
      border: 1px solid ${({ theme }) => theme.palette.dark.dark_8};
      border-radius: ${({ $size }) => `${$size}px`};
      background: ${({ theme }) => theme.palette.white.primary};
      color: ${({ theme }) => theme.palette.dark.main};
      font-size: 14px;
      font-weight: 400;
    }

    ${ChevronIconStyled} {
      margin-right: 4px;
      margin-left: -8px;
      ${({ $chevronDirection }) => chevronDirectionStyles[$chevronDirection]};
      path {
        fill: ${({ theme }) => theme.palette.dark.dark_30};
      }
    }

    &:hover {
      background: ${({ theme }) => theme.palette.dark.dark_4};
    }
  }
`;
