import styled, { css } from 'styled-components';
import { Modal } from 'antd';
import { Button } from 'components';
import { Grid, MIXINS } from '_constants';
import { ReactComponent as Close } from 'assets/icons/close-circle.svg';

interface IButtonWrapper {
  allSpace?: boolean;
}

export const StyledModal = styled(Modal)`
  top: 0;
  max-height: 100%;
  padding: 10px;
`;

export const Wrapper = styled.div`
  &&& {
    .ant-modal-header {
      padding: 0 16px 24px 16px;
      border-bottom: none;
    }
    .ant-modal-body {
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 0 48px 0 0;
      max-height: calc(100vh - 200px);
      margin-right: -48px;
      overflow: auto;
      ${MIXINS.scrollbar}
    }
    .ant-modal-wrap {
      z-index: 9999;
      display: flex;
      align-items: center;
    }
    .ant-modal-content {
      padding: 44px 24px 24px;
      overflow: hidden;
      border-radius: 16px;
    }
    .ant-modal-mask {
      z-index: 8888;
      background-color: ${({ theme: { palette } }) => palette.mask.main};
    }
    .ant-modal-close-x {
      width: auto;
      height: auto;
      line-height: initial;
    }
    .ant-modal-footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 16px 0 0 0;
      text-align: center;
      border: none;
    }

    .ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
      margin-left: 12px;
    }
  }
`;

export const CloseIcon = styled(Close)`
  margin: 12px;
  circle {
    transition: fill 0.4s;
  }
  :hover {
    circle {
      fill: ${({ theme: { palette } }) => palette.dark.dark_8};
    }
  }
`;

export const StyledButton = styled(Button)`
  &&&& {
    flex-basis: auto;
    flex-grow: 0;
    flex-shrink: 1;
    width: auto;
    @media (max-width: ${Grid.XS}px) {
      padding: 12px 22px;
    }
  }
`;

export const ButtonWrapper = styled.div<IButtonWrapper>`
  display: flex;
  justify-content: center;
  width: 100%;

  ${({ allSpace }) =>
    allSpace &&
    css`
      &&& ${StyledButton} {
        flex-grow: 1;
        flex-shrink: 0;
        padding: 12px 16px;
      }
    `}
`;
