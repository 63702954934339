import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { ModalProps } from 'antd';
import { StyledModal, Wrapper, CloseIcon, ButtonWrapper, StyledButton } from './Modal.styles';
import messages from './messages';

export interface IModal extends ModalProps {
  onClose?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onConfirm?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  confirmText?: string;
}

const Modal: React.FC<IModal> = (props) => {
  const {
    visible,
    className,
    footer,
    okText,
    onOk,
    onCancel,
    cancelText,
    confirmText,
    closeIcon,
    onClose,
    onConfirm,
    okButtonProps,
    cancelButtonProps,
  } = props;
  const { formatMessage } = useIntl();
  const addScrollBlock = () => {
    const html = document.querySelector('html');
    if (!html) return;
    html.style.width = `calc(100% - ${window.innerWidth - html.offsetWidth}px)`;
    html.classList.add('global-scrollblock');
  };
  const removeScrollBlock = () => {
    const html = document.querySelector('html');
    if (!html) return;
    html.classList.remove('global-scrollblock');
    html.style.width = '';
  };

  useEffect(() => {
    if (visible) addScrollBlock();
  }, [visible]);

  const afterClose = () => {
    removeScrollBlock();
    props.afterClose?.();
  };

  return (
    <Wrapper className={className}>
      <StyledModal
        {...props}
        onCancel={onClose || onCancel}
        getContainer={false}
        afterClose={afterClose}
        closeIcon={closeIcon || <CloseIcon />}
        footer={
          footer !== false &&
          (footer || (
            <ButtonWrapper allSpace={!!(onCancel && onOk)}>
              {onCancel && (
                <StyledButton type="secondary" onClick={onCancel} {...cancelButtonProps}>
                  {cancelText || formatMessage(messages.cancelButtonText)}
                </StyledButton>
              )}
              {onConfirm && (
                <StyledButton type="danger" onClick={onConfirm} {...cancelButtonProps}>
                  {confirmText || formatMessage(messages.confirmButtonText)}
                </StyledButton>
              )}
              {onOk && (
                <StyledButton type="primary" onClick={onOk} {...okButtonProps}>
                  {okText || formatMessage(messages.okButtonText)}
                </StyledButton>
              )}
            </ButtonWrapper>
          ))
        }
      />
    </Wrapper>
  );
};

export default Modal;
