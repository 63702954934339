import { api } from 'api';
import {
  TActivityAttendeesCountResponse,
  TActivityAttendeesResponse,
  TActivityResponse,
  TAssignedCoursesCountResponse,
  TAssignedTracksCountResponse,
  TAttendeesResponse,
  TCourseAttendeeResponse,
  TCourseDetailsResponse,
  TGroupsResponse,
  TMyTeamCourseResponse,
  TMyTeamTrackResponse,
  TRolesAllResponse,
} from 'types/myTeamApiTypes';
import { ActivityRange, CourseUserStatus, GroupUserStatus } from '_constants';
import { TResponded } from 'types';
import { setLearningTracksHeaders } from './api';

interface IProfileGroup {
  id: number;
  roles: number[];
}

let userGroups: IProfileGroup[] | null;

export const setUserGroups = (groups: IProfileGroup[] | null): void => {
  userGroups = groups;
};

const getRoleIdsForAnalytics = (groups: IProfileGroup[] | null, id: number): number[] | undefined =>
  (groups?.find((group) => group.id === id) || {}).roles;

const getGroupActivity = (groupId: number): Promise<TActivityResponse> =>
  api.privateRequest('get', `api/web/tlrocks/courses/myteam/activity/${groupId}`);

const getAllActivity = (): Promise<TActivityResponse> =>
  api.privateRequest('get', 'api/web/tlrocks/courses/myteam/activity/all');

const getGroupActivityAttendeesCount = (
  groupId: number,
  range: ActivityRange,
): Promise<TActivityAttendeesCountResponse> =>
  api.privateRequest('get', `api/web/tlrocks/courses/myteam/activity/${groupId}/count`, { searchParams: { range } });

const getGroupActivityAttendees = (
  groupId: number,
  range: ActivityRange,
  isActive: boolean,
  page = 1,
): Promise<TActivityAttendeesResponse> =>
  api.privateRequest('get', `api/web/tlrocks/courses/myteam/activity/${groupId}/attendees`, {
    searchParams: {
      isActive,
      range,
      page,
    },
  });

const getAllActivityAttendeesCount = (range: ActivityRange): Promise<TActivityAttendeesCountResponse> =>
  api.privateRequest('get', 'api/web/tlrocks/courses/myteam/activity/all/count', { searchParams: { range } });

const getAllActivityAttendees = (
  range: ActivityRange,
  isActive: boolean,
  page = 1,
): Promise<TActivityAttendeesResponse> =>
  api.privateRequest('get', 'api/web/tlrocks/courses/myteam/activity/all/attendees', {
    searchParams: {
      isActive,
      range,
      page,
    },
  });

const getAttendees = (
  groupId: number,
  page = 1,
  filterByAssignments: GroupUserStatus,
  roleIds?: number[],
): Promise<TAttendeesResponse> => {
  const roleIdsForAnalytics = getRoleIdsForAnalytics(userGroups, groupId);
  return api.privateRequest('get', 'api/web/tlrocks/courses/myteam/attendees', {
    searchParams: {
      groupId,
      filterByAssignments,
      page,
      ...(roleIds?.length ? { roleIds: roleIds.join(',') } : {}),
      ...(roleIdsForAnalytics?.length ? { roleIdsForAnalytics: roleIdsForAnalytics.join(',') } : {}),
    },
  });
};

const getAssignCoursesCount = (groupId: number): Promise<TAssignedCoursesCountResponse> => {
  const roleIdsForAnalytics = getRoleIdsForAnalytics(userGroups, groupId);
  return api.privateRequest('get', 'api/web/tlrocks/courses/myteam/courses/count', {
    searchParams: {
      aclGroupId: groupId,
      ...(roleIdsForAnalytics?.length ? { roleIdsForAnalytics: roleIdsForAnalytics.join(',') } : {}),
    },
  });
};

const getCourseDetails = (courseId: number, groupId: number, trackId = ''): Promise<TCourseDetailsResponse> => {
  const roleIdsForAnalytics = getRoleIdsForAnalytics(userGroups, groupId);
  return api.privateRequest('get', `api/web/tlrocks/courses/myteam/courses/${courseId}/details`, {
    searchParams: {
      aclGroupId: groupId,
      ...(roleIdsForAnalytics?.length ? { roleIdsForAnalytics: roleIdsForAnalytics.join(',') } : {}),
    },
    headers: { ...setLearningTracksHeaders(trackId) },
  });
};

const getCourseAttendees = (
  courseId: number,
  groupId: number,
  userStatus: CourseUserStatus,
  trackId = '',
  page = 1,
  roleIds?: number[],
): Promise<TCourseAttendeeResponse> => {
  const roleIdsForAnalytics = getRoleIdsForAnalytics(userGroups, groupId);
  return api.privateRequest('get', `api/web/tlrocks/courses/myteam/courses/${courseId}/attendees`, {
    searchParams: {
      groupId,
      page,
      userStatusFilter: userStatus,
      ...(roleIds?.length ? { roleIds: roleIds.join(',') } : {}),
      ...(roleIdsForAnalytics?.length ? { roleIdsForAnalytics: roleIdsForAnalytics.join(',') } : {}),
    },
    headers: { ...setLearningTracksHeaders(trackId) },
  });
};

const getAssignCourses = (groupId: number): Promise<TMyTeamCourseResponse> => {
  const roleIdsForAnalytics = getRoleIdsForAnalytics(userGroups, groupId);
  return api.privateRequest('get', 'api/web/tlrocks/courses/myteam/courses', {
    searchParams: {
      aclGroupId: groupId,
      ...(roleIdsForAnalytics?.length ? { roleIdsForAnalytics: roleIdsForAnalytics.join(',') } : {}),
    },
  });
};

const getAssignTracksCount = (groupId: number): Promise<TAssignedTracksCountResponse> => {
  const roleIdsForAnalytics = getRoleIdsForAnalytics(userGroups, groupId);
  return api.privateRequest('get', 'api/web/tlrocks/v1/tracks/myteam/tracks/count', {
    searchParams: {
      aclGroupId: groupId,
      ...(roleIdsForAnalytics?.length ? { roleIdsForAnalytics: roleIdsForAnalytics.join(',') } : {}),
    },
  });
};

const getTrackSteps = (trackId: string, groupId: number): Promise<TCourseDetailsResponse> => {
  const roleIdsForAnalytics = getRoleIdsForAnalytics(userGroups, groupId);
  return api.privateRequest('get', `api/web/tlrocks/v1/tracks/myteam/tracks/${trackId}/steps`, {
    searchParams: {
      aclGroupId: groupId,
      ...(roleIdsForAnalytics?.length ? { roleIdsForAnalytics: roleIdsForAnalytics.join(',') } : {}),
    },
  });
};

const getAssignTracks = (groupId: number): Promise<TMyTeamTrackResponse> => {
  const roleIdsForAnalytics = getRoleIdsForAnalytics(userGroups, groupId);
  return api.privateRequest('get', 'api/web/tlrocks/v1/tracks/myteam/tracks', {
    searchParams: {
      aclGroupId: groupId,
      ...(roleIdsForAnalytics?.length ? { roleIdsForAnalytics: roleIdsForAnalytics.join(',') } : {}),
    },
  });
};

const generateReport = (): Promise<TResponded<null>> => api.privateRequest('post', 'api/web/tlrocks/courses/report');

const getGroups = (): Promise<TGroupsResponse> => api.privateRequest('get', 'api/web/tlrocks/courses/myteam/groups');

const getRoles = (): Promise<TRolesAllResponse> => api.privateRequest('get', 'api/web/tlrocks/roles/all');

export default {
  getAttendees,
  getAllActivity,
  getAllActivityAttendeesCount,
  getAllActivityAttendees,
  generateReport,
  groupApi: {
    getRoles,
    getGroups,
    getGroupActivity,
    getGroupActivityAttendeesCount,
    getGroupActivityAttendees,
  },
  courseApi: {
    getAssignCoursesCount,
    getCourseDetails,
    getCourseAttendees,
    getAssignCourses,
  },
  tracksApi: {
    getAssignTracksCount,
    getTrackSteps,
    getAssignTracks,
  },
};
