import React, { ReactNode, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { ImageProps } from 'antd';
import useResizeObserver from 'use-resize-observer';
import { Spinner } from 'components';
import { ReactComponent as ErrorImage } from 'assets/images/placeholder-card.svg';
import { ImageOverlay, StyledImage, ErrorImageOverlay, SpinnerWrapper } from './Image.styles';

type TProps = {
  fallback?: ReactNode;
  onBeforeLoading?: () => void;
  onResize?: (size: { width?: number; height?: number }) => void;
} & Omit<ImageProps, 'fallback'>;

const Image: React.FC<TProps> = ({
  placeholder = <Spinner />,
  fallback = <ErrorImage />,
  onBeforeLoading,
  onResize,
  ...rest
}) => {
  const [imageError, setImageError] = useState(false);
  const placeholderWrapperRef = useRef<HTMLDivElement>(null);
  const { ref, height, width } = useResizeObserver<HTMLDivElement>();
  const [initialized, init] = useState(false);

  useLayoutEffect(() => {
    if (!initialized) {
      onBeforeLoading?.();
      init(true);
    }
  }, [initialized, onBeforeLoading]);

  useEffect(() => {
    if (onResize && height && Number(height) > Number(placeholderWrapperRef?.current?.clientHeight)) {
      onResize({
        width,
        height,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [height, width]);

  return (
    <ImageOverlay ref={ref}>
      {imageError ? (
        <ErrorImageOverlay>{fallback}</ErrorImageOverlay>
      ) : (
        <StyledImage
          placeholder={<SpinnerWrapper ref={placeholderWrapperRef}>{placeholder}</SpinnerWrapper>}
          onError={() => setImageError(true)}
          {...rest}
        />
      )}
    </ImageOverlay>
  );
};

export default Image;
