import React from 'react';
import { observer } from 'mobx-react';
import { ReactComponent as IconClosed } from 'assets/icons/closed-circle.svg';
import { ReactComponent as IconPending } from 'assets/icons/clock-unnacented.svg';
import { ReactComponent as ChevronRight } from 'assets/icons/chevron-right.svg';
import { ReactComponent as IconCompleted } from 'assets/icons/completed.svg';
import { ReactComponent as IconWarning } from 'assets/icons/warning-grey.svg';
import { BookingStatus } from 'models/Booking';
import { useIntl } from 'react-intl';
import messages from './messages';
import {
  Booking,
  BookingIcon,
  BookingName,
  BookingStatusOverlay,
  BookingStatusText,
  BookingText,
  StyledLargeTooltip,
} from './BookingElement.styles';

interface IBookingElement {
  title?: string;
  status?: BookingStatus;
  onClick?: () => void;
}

const BookingElement: React.FC<IBookingElement> = ({ title, status, ...rest }) => {
  const intl = useIntl();

  const getBookingStatusText = (bookingStatus?: BookingStatus) => {
    switch (bookingStatus) {
      case BookingStatus.REGISTERED:
        return {
          text: intl.formatMessage(messages.registeredText),
          icon: <IconCompleted />,
        };
      case BookingStatus.REGISTERED_WAIT_LIST:
        return {
          text: intl.formatMessage(messages.registeredWaitListText),
          icon: <IconPending />,
        };
      case BookingStatus.CAN_REGISTER:
        return {
          text: intl.formatMessage(messages.canRegisterText),
          icon: <ChevronRight />,
        };
      case BookingStatus.CAN_REGISTER_WAIT_LIST:
        return {
          text: intl.formatMessage(messages.canRegisterWaitListText),
          icon: <ChevronRight />,
        };
      case BookingStatus.CANNNOT_REGISTER:
        return {
          text: intl.formatMessage(messages.cannotRegisterText),
          icon: <IconClosed />,
        };
      case BookingStatus.CANNNOT_REGISTER_LIMIT:
        return {
          text: intl.formatMessage(messages.cannotRegisterLimitText),
          icon: <IconWarning />,
          tooltip: intl.formatMessage(messages.cannotRegisterLimitTooltip),
        };
      default:
        return null;
    }
  };

  return (
    <Booking {...rest}>
      <BookingText>
        <BookingName ellipsis={{ rows: 2 }}>{title}</BookingName>
        <BookingStatusOverlay>
          <BookingStatusText status={status}>{getBookingStatusText(status)?.text}</BookingStatusText>
          {getBookingStatusText(status)?.tooltip && (
            <StyledLargeTooltip text={getBookingStatusText(status)?.tooltip || ''} />
          )}
        </BookingStatusOverlay>
      </BookingText>
      <BookingIcon>{getBookingStatusText(status)?.icon}</BookingIcon>
    </Booking>
  );
};

export default observer(BookingElement);
