import styled, { css } from 'styled-components';
import { Button } from 'antd';
import { Text } from 'components';
import { ReactComponent as ChevronIcon } from 'assets/icons/chevron.svg';
import { TChevronDirection, OChevronDirection } from '../constants';

interface IButtonStyled {
  $chevronDirection: TChevronDirection;
  $size: number;
}

export const ChevronIconStyled = styled(ChevronIcon)``;

export const TextStyled = styled(Text)`
  &&& {
    color: ${({ theme }) => theme.palette.dark.main};
    font-weight: 500;
  }
`;

const chevronDirectionStyles = {
  [OChevronDirection.LEFT]: css`
    left: 1px;
    transform: rotate(180deg);
  `,
  [OChevronDirection.RIGHT]: css`
    left: -1px;
    transform: rotate(0deg);
  `,
  [OChevronDirection.TOP]: css`
    top: 1px;
    transform: rotate(270deg);
  `,
  [OChevronDirection.BOTTOM]: css`
    top: -1px;
    transform: rotate(90deg);
  `,
};

export const ButtonStyled = styled(Button)<IButtonStyled>`
  &&& {
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${({ $size }) => `${$size}px`};
    height: ${({ $size }) => `${$size}px`};
    border: ${({ theme }) => `1px solid ${theme.palette.dark.dark_8}`};
    background: ${({ theme }) => theme.palette.white.primary};
    box-shadow: none;
    ${ChevronIconStyled} {
      position: relative;
      transition: inherit;
      ${({ $chevronDirection }) => chevronDirectionStyles[$chevronDirection]};
      path {
        fill: ${({ theme }) => theme.palette.dark.dark_30};
        transition: inherit;
      }
    }

    &:hover,
    &.ant-popover-open {
      background: ${({ theme }) => theme.palette.dark.dark_4};
    }

    &[disabled] {
      &:hover,
      &.ant-popover-open {
        background: ${({ theme }) => theme.palette.white.primary};
        box-shadow: none;
      }
      ${ChevronIconStyled} {
        path {
          fill: ${({ theme }) => theme.palette.dark.dark_16};
        }
      }
    }
  }
`;
