/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["self"] }] */
import { types } from 'mobx-state-tree';
import MNotification from 'models/MNotification';
import { TNotification, TNotificationButton } from 'types';

const Notifications = types
  .model({
    items: types.array(MNotification),
  })
  .views((self) => ({
    get notification() {
      const activeNotifications = self.items.filter(({ isActive }) => isActive);
      return activeNotifications.concat().pop();
    },
  }))
  .actions((self) => ({
    setButtonsActions(buttons: TNotificationButton[]) {
      self.notification?.buttons.forEach(({ title, type, set }, i) => set(title, type, buttons[i].action));
    },
  }))
  .actions((self) => ({
    setNotification(notification: TNotification) {
      const id = notification.id || String(self.items?.length || 0);
      const isConfigExists = Object.keys(notification?.notificationConfig || {}).length;
      const config = isConfigExists ? { ...notification.notificationConfig } : null;

      self.items.push({
        ...notification,
        id,
        isActive: true,
        closable: Boolean(notification?.closable),
        notificationConfig: config,
      });

      if (self.notification?.buttons.length && notification?.buttons?.length) {
        self.setButtonsActions(notification.buttons);
      }

      if (self.notification?.notificationConfig && notification?.notificationConfig) {
        self.notification.notificationConfig.set(notification?.notificationConfig);
      }
    },
  }));

export default Notifications;
