/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["self"] }] */
import { flow, getRoot, Instance, types } from 'mobx-state-tree';
import { api, authApi, registrationApi, StatusCodes } from 'api';
import {
  CodeStepStatus,
  LimitStatus,
  RegisterStatus,
  SendTypes,
  SignUpStatus,
  TRegisterResponse,
  TSignUpResponse,
  TVerifyResponse,
  VerifyStatus,
} from 'types/registrationApiTypes';
import { AuthorizationStatus, TStore } from 'types';
import { TSignInResponse } from 'types/authApiTypes';
import Auth from './Auth';

const Registration = types
  .model({
    verificationToken: '',
    codeScreenType: types.maybeNull(
      types.enumeration('codeScreenType', [
        CodeStepStatus.REGISTERED_EMAIL,
        CodeStepStatus.REGISTERED_PHONE,
        CodeStepStatus.REGISTERED_PHONE_SEND_BY_EMAIL,
        CodeStepStatus.CODE_EMAIL,
        CodeStepStatus.CODE_PHONE,
      ]),
    ),
    blockedTime: types.maybeNull(types.number),
    sentTo: types.maybeNull(types.string),
    registeredType: types.maybeNull(
      types.enumeration('registeredType', [RegisterStatus.REGISTERED, LimitStatus.SMS_LIMIT_EXCEEDED]),
    ),
    isPremoderation: types.maybeNull(types.boolean),
  })
  .views((self) => ({
    get regUIStore() {
      return getRoot<TStore>(self).UIStore.registration;
    },
    get authDomainStore(): Instance<typeof Auth> {
      return getRoot<TStore>(self).DomainStore.auth;
    },
  }))
  .actions((self) => ({
    setCodeScreenType: (sendType?: SendTypes, status?: SignUpStatus, limitStatus?: LimitStatus) => {
      if (sendType === SendTypes.PHONE && status === SignUpStatus.USER_EXISTS)
        self.codeScreenType = CodeStepStatus.REGISTERED_PHONE;
      if (sendType === SendTypes.EMAIL && status === SignUpStatus.USER_EXISTS)
        self.codeScreenType = CodeStepStatus.REGISTERED_EMAIL;
      if (
        sendType === SendTypes.EMAIL &&
        status === SignUpStatus.USER_EXISTS &&
        limitStatus === LimitStatus.SMS_LIMIT_EXCEEDED
      )
        self.codeScreenType = CodeStepStatus.REGISTERED_PHONE_SEND_BY_EMAIL;
      if (sendType === SendTypes.PHONE && status === SignUpStatus.SUCCESS)
        self.codeScreenType = CodeStepStatus.CODE_PHONE;
      if (sendType === SendTypes.EMAIL && status === SignUpStatus.SUCCESS)
        self.codeScreenType = CodeStepStatus.CODE_EMAIL;
    },
    setRegisteredType: (limitStatus?: LimitStatus) => {
      if (limitStatus === LimitStatus.SMS_LIMIT_EXCEEDED) self.registeredType = LimitStatus.SMS_LIMIT_EXCEEDED;
      else self.registeredType = RegisterStatus.REGISTERED;
    },
    setIsPremoderation: (isPremoderation: boolean) => {
      self.isPremoderation = isPremoderation;
    },
  }))
  .actions((self) => ({
    sendLoginForm: flow(function* sendLoginForm() {
      self.regUIStore.setLoginFetching(true);
      const { data }: TSignUpResponse = yield registrationApi.signUp({
        login: self.regUIStore.login,
      });
      const isSMSLimitExceeded =
        data?.status === SignUpStatus.SUCCESS &&
        data?.limitStatus === LimitStatus.SMS_LIMIT_EXCEEDED &&
        data?.sendType === SendTypes.PHONE;

      const isSendFormValid =
        (data?.status === SignUpStatus.SUCCESS && !isSMSLimitExceeded) || data?.status === SignUpStatus.USER_EXISTS;
      self.regUIStore.setLoginFormValid(isSendFormValid || isSMSLimitExceeded);
      self.regUIStore.setOtpFormValid(isSendFormValid || isSMSLimitExceeded);
      self.verificationToken = data?.verificationToken ?? '';
      self.blockedTime = data?.blockedForSeconds ?? null;
      self.sentTo = data?.sentTo ?? null;
      if (data?.status) {
        self.setCodeScreenType(data?.sendType, data?.status, data?.limitStatus);
      }
      self.regUIStore.setLoginFetching(false);
      return {
        isValid: isSendFormValid,
        isNotValid: data?.success === false,
        isBlocked: data?.status === SignUpStatus.BLOCKED,
        isBlockedByEmail: data?.status === SignUpStatus.BLOCKED_REGISTRATION_BY_EMAIL,
        isBlockedByPhone: data?.status === SignUpStatus.BLOCKED_REGISTRATION_BY_PHONE,
        isSMSLimitExceeded,
        blockedForSeconds: data?.blockedForSeconds ?? null,
        sentTo: data?.sentTo ?? null,
      };
    }),
    getOtpStatus: flow(function* getOtpStatus() {
      self.regUIStore.setFetching(true);
      const { data }: TVerifyResponse = yield registrationApi.verify({
        otp: self.regUIStore.otp,
        verificationToken: self.verificationToken,
      });
      self.regUIStore.setOtpStatus(data?.status ?? null);
      self.regUIStore.setOtpFormValid(data?.status === VerifyStatus.SUCCESS);
      self.regUIStore.setFetching(false);
      return {
        isValid: self.regUIStore.otpStatus === VerifyStatus.SUCCESS,
        isNotValidOtp: self.regUIStore.otpStatus === VerifyStatus.INVALID_OTP,
        isNotValidToken: self.regUIStore.otpStatus === VerifyStatus.INVALID_VERIFICATION_TOKEN,
        isBlocked: self.regUIStore.otpStatus === VerifyStatus.BLOCKED,
        blockedForSeconds: data?.blockedForSeconds ?? null,
      };
    }),
    sendAuthForm: flow(function* sendAuthForm() {
      self.regUIStore.setFetching(true);
      const { data, response }: TSignInResponse = yield authApi.signIn({
        login: self.regUIStore.login,
        password: self.regUIStore.otp,
      });
      self.regUIStore.setOtpFormValid(
        data?.status === AuthorizationStatus.REGISTERED || data?.status === AuthorizationStatus.PREMODERATION,
      );
      self.setIsPremoderation(data?.status === AuthorizationStatus.PREMODERATION);
      api.token = data?.token ?? '';
      self.authDomainStore.setAutoLogin(data?.autoLogin ? data.autoLogin : '');
      self.regUIStore.setFetching(false);
      return {
        isValid: self.regUIStore.isOtpFormValid,
        isUnauthorized: response?.status === StatusCodes.Unauthorized,
        isPremoderation: data?.status === AuthorizationStatus.PREMODERATION,
      };
    }),
    sendNameForm: flow(function* sendNameForm() {
      self.regUIStore.setFetching(true);
      const { data }: TRegisterResponse = yield registrationApi.register({
        firstName: self.regUIStore.firstName,
        lastName: self.regUIStore.lastName,
        verificationToken: self.verificationToken,
      });
      self.regUIStore.setNameFormValid(
        data?.status === RegisterStatus.REGISTERED || data?.status === RegisterStatus.PREMODERATION,
      );
      self.setIsPremoderation(data?.status === RegisterStatus.PREMODERATION);
      if (data?.status && self.regUIStore.isNameFormValid) {
        self.setRegisteredType(data?.limitStatus);
      }

      api.token = data?.token ?? '';
      self.regUIStore.setFetching(false);
      return {
        isValid: self.regUIStore.isNameFormValid,
        isNotValidToken: data?.status === RegisterStatus.VERIFICATION_TOKEN_EXPIRED,
      };
    }),

    onAuth: () => {
      self.authDomainStore.setToken(api.token);
      self.regUIStore.resetFormFields();
    },
  }));

export default Registration;
