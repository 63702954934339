/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["self"] }] */
import { types, cast, flow } from 'mobx-state-tree';
import { notificationsApi } from 'api';
import { MFeed, MLastPostDate } from 'models';
import { TFeedsResponse, TLastPostDatesResponse } from 'types/feedApiTypes';
import { TFeed, TLastPostDate } from 'types';

const Feeds = types
  .model({
    data: types.array(MFeed),
    lastPostDates: types.array(MLastPostDate),
    readLastPostDates: types.map(MLastPostDate),
  })
  .views((self) => ({
    get unreadMessages(): Record<number, boolean> {
      const feedHasUnreadMessages = (feed: TFeed) => {
        const { themeId } = feed;
        const lastPostDate = self.lastPostDates.find((item) => item.themeId === themeId)?.date;
        const readLastPostDate = self.readLastPostDates.get(String(themeId))?.date;
        if (!lastPostDate) return false;
        if (!readLastPostDate) return true;
        if (new Date(lastPostDate) > new Date(readLastPostDate)) return true;
        return false;
      };
      return self.data.reduce((prevValue, feed) => {
        return {
          ...prevValue,
          [feed.themeId]: feedHasUnreadMessages(feed),
        };
      }, {});
    },
  }))
  .views((self) => ({
    get hasUnreadMessages() {
      return Object.values(self.unreadMessages).includes(true);
    },
  }))
  .actions((self) => ({
    fetchData: flow(function* fetchCourses() {
      const { data, hasError }: TFeedsResponse = yield notificationsApi.getFeeds();
      if (data && !hasError) {
        self.data = cast(data.feeds);
      }
      const { data: lastPostDatesData, hasError: lastPostDatesError }: TLastPostDatesResponse =
        yield notificationsApi.getLastPostDates();
      if (lastPostDatesData && !lastPostDatesError) {
        self.lastPostDates = cast(lastPostDatesData.items);
      }
    }),
    setFeedLastPostDate: function setLastPostDate(lastPostDate: TLastPostDate) {
      self.readLastPostDates.set(String(lastPostDate.themeId), lastPostDate);
    },
  }));

export default Feeds;
