export enum TaskStatus {
  NEW = 'new',
  STARTED = 'started',
  SUBMITTED = 'submitted',
  IN_REVIEW = 'inReview',
  COMPLETED = 'completed',
  REJECTED = 'rejected',
}

export enum LastSubmissionStatus {
  STARTED = 'started',
  SUBMITTED = 'submitted',
  IN_REVIEW = 'inReview',
  COMPLETED = 'completed',
  REJECTED = 'rejected',
}

export enum FieldType {
  STRING = 'string',
  TEXT = 'text',
  LINK = 'link',
  ATTACHMENT = 'file',
  ATTACHMENT_OR_LINK = 'fileOrUrl',
}
