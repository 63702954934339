import styled from 'styled-components';
import { PopoverButton } from 'components';
import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';

export const PopoverButtonStyled = styled(PopoverButton)`
  &&& {
    .ant-popover-inner {
      .ant-popover-inner-content {
        display: flex;
        align-items: center;
      }
    }
  }
`;

export const CopyIconStyled = styled(CopyIcon)`
  path {
    transition: fill 0.4s;
  }
`;

export const CopyIconWrapper = styled.div`
  display: inline-flex;
  margin: -7px;
  padding: 7px;
  cursor: pointer;
  &:hover,
  &.ant-popover-open {
    ${CopyIconStyled} {
      path {
        fill: ${({ theme }) => theme.palette.primary.main};
      }
    }
  }
`;
