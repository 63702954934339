import { types } from 'mobx-state-tree';

const MCourseAttendee = types.model({
  id: types.number,
  eventId: types.number,
  firstName: types.string,
  lastName: types.string,
  thumbnail: types.maybeNull(types.string),
  finishedStagesCount: types.number,
  stagesCount: types.number,
  points: types.number,
});

export default MCourseAttendee;
