// TODO: модель для тестирования UI, изменить по договоренности с бэком
import { types } from 'mobx-state-tree';
import { PollActivationMode, PollShowRightAnswerType, PollStatus, PollValueType } from './constants';

const MPollInfo = types.model({
  id: types.number,
  name: types.maybeNull(types.string),
  eventId: types.number,
  status: types.enumeration<PollStatus>('Status', Object.values(PollStatus)),
  isCompleted: types.boolean,
  activationMode: types.enumeration<PollActivationMode>('ActivationMode', Object.values(PollActivationMode)),
  activationDate: types.maybeNull(types.string),
  overrideGlobalSettings: types.maybeNull(types.boolean),
  sendPushOnStart: types.boolean,
  showResults: types.boolean,
  isAuthRequired: types.boolean,
  canSkipQuestions: types.boolean,
  uuid: types.string,
  publishReason: types.array(types.string),
  showRightAnswerAfterAnswerComplete: types.boolean,
  showRightAnswerAfterTestComplete: types.boolean,
  showRightAnswerTypeValue: types.enumeration<PollShowRightAnswerType>(
    'ShowRightAnswerType',
    Object.values(PollShowRightAnswerType),
  ),
  typeValue: types.enumeration<PollValueType>('PollValueType', Object.values(PollValueType)),
  type: types.number,
  createdDate: types.string,
  updatedDate: types.string,
});

export default MPollInfo;
