import { types } from 'mobx-state-tree';

export enum DashboardElementTypes {
  RATING = 'rating',
  COURSES = 'courses',
  TRACKS = 'tracks',
}

const MDashboard = types.model({
  type: types.maybeNull(types.enumeration<DashboardElementTypes>('Type', Object.values(DashboardElementTypes))),
  name: types.maybeNull(types.string),
  settings: types.maybeNull(
    types.model({
      showScore: types.maybeNull(types.boolean),
      showPositionInRating: types.maybeNull(types.boolean),
    }),
  ),
});

export const MDashboardInfo = types.array(MDashboard);

export const MDashboardRating = types.model({
  score: types.maybeNull(types.number),
  rating: types.maybeNull(types.number),
  scoreUnit: types.maybeNull(types.string),
});

export enum DashboardCourseTrackStatuses {
  NOT_STARTED = 'NotStarted',
  IN_PROGRESS = 'InProgress',
  COMPLETED = 'Completed',
}

export const MDashboardCourseInfo = types.model({
  id: types.number,
  openDate: types.maybeNull(types.string),
  assignmentDate: types.maybeNull(types.string),
  completionDate: types.maybeNull(types.string),
  coverUrl: types.maybeNull(types.string),
  coverThumbnailUrl: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  stagesCount: types.number,
  stagesPassed: types.number,
  status: types.maybeNull(
    types.enumeration<DashboardCourseTrackStatuses>('Status', Object.values(DashboardCourseTrackStatuses)),
  ),
  viewInAdminMode: types.maybeNull(types.boolean),
});

export const MDashboardTrackInfo = types.model({
  id: types.string,
  name: types.string,
  coverUrl: types.string,
  coverThumbnailUrl: types.string,
  status: types.enumeration<DashboardCourseTrackStatuses>('Status', Object.values(DashboardCourseTrackStatuses)),
  totalSteps: types.number,
  completedSteps: types.number,
  viewInAdminMode: types.boolean,
  openDate: types.maybeNull(types.string),
  completionDate: types.maybeNull(types.string),
});
