import { FC, useEffect } from 'react';
import { ArgsProps, NotificationInstance } from 'antd/lib/notification';
import { notification } from 'antd';

interface IProps {
  type: keyof NotificationInstance;
  notificationOptions: Omit<ArgsProps, 'message' | 'description'>;
  message?: ArgsProps['message'];
  description: ArgsProps['description'];
}

const defaultConfig: Omit<ArgsProps, 'message'> = {
  duration: 4,
};

const NotificationMessage: FC<IProps> = ({ type, notificationOptions, message, description }) => {
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    if (type) {
      api[type]({
        ...defaultConfig,
        ...notificationOptions,
        message,
        description,
      });
    }
  }, [message, description, api, notificationOptions, type]);

  return contextHolder;
};

export default NotificationMessage;
