/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["self"] }] */
import { types } from 'mobx-state-tree';

const MyTeam = types
  .model({
    isFetching: false,
    isFetchingAttendee: false,
    isFetchingGroups: false,
  })
  .actions((self) => ({
    setIsFetching: (state: boolean) => {
      self.isFetching = state;
    },
    setIsFetchingGroups: (state: boolean) => {
      self.isFetchingGroups = state;
    },
    setIsFetchingAttendee: (state: boolean) => {
      self.isFetchingAttendee = state;
    },
  }));

export default MyTeam;
