import styled from 'styled-components';
import { Tooltip } from 'antd';

export const StyledTooltip = styled(Tooltip)`
  .ant-tooltip-inner {
    width: 100%;
    padding: 9px 12px;
    background-color: ${({ theme }) => theme.palette.white.primary};
    box-shadow: 0 0 20px ${({ theme }) => theme.palette.shadow.main};
    border-radius: 8px;
    color: ${({ theme }) => theme.palette.dark.main};
    font-weight: 500;
  }
  .ant-tooltip-arrow {
    left: 160px;
  }
  .ant-tooltip-arrow-content {
    background-color: ${({ theme }) => theme.palette.white.primary};
  }
`;

export default StyledTooltip;
