/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["self"] }] */
import { types } from 'mobx-state-tree';
import { Locales, currentLocale } from '_constants';
import { MTheme } from 'models';
import DefaultTheme from 'themes';
import Auth from './Auth';
import Registration from './Registration';
import Notifications from './Notifications';
import Course from './Course';
import Layout from './Layout';
import Gamification from './Gamification';
import MyTeam from './MyTeam';
import MyBookings from './MyBookings';
import LearningTrack from './LearningTrack';

const UIStore = types
  .model({
    locale: types.optional(types.enumeration<Locales>('Locale', Object.values(Locales)), currentLocale),
    theme: types.optional(MTheme, DefaultTheme),
    auth: types.optional(Auth, {}),
    registration: types.optional(Registration, {}),
    course: types.optional(Course, {}),
    myTeam: types.optional(MyTeam, {}),
    layout: types.optional(Layout, {}),
    gamification: types.optional(Gamification, {}),
    groupGamification: types.optional(Gamification, {}),
    notifications: types.optional(Notifications, {
      items: [],
    }),
    isOnlineNetworkStatus: true,
    myBookings: types.optional(MyBookings, {}),
    learningTrack: types.optional(LearningTrack, {}),
  })
  .actions((self) => ({
    setLocale: (locale: Locales) => {
      self.locale = locale;
    },
    setNetworkStatus: () => {
      self.isOnlineNetworkStatus = navigator.onLine;
    },
  }));

export default UIStore;
