import styled from 'styled-components';
import { Input } from 'antd';

export const TextArea = styled(Input.TextArea)`
  &&&& {
    width: 100%;

    &.ant-input-textarea-show-count::after {
      margin-top: 6px;
      color: ${({ theme: { palette } }) => palette.dark.dark_40};
      font-size: 14px;
      font-family: 'Roboto', sans-serif;
      line-height: 22px;
    }
    &.ant-input-textarea {
      position: relative;
      background-color: ${({ theme: { palette } }) => palette.dark.dark_4};
      border-radius: 8px;
    }

    .ant-input {
      min-height: 144px;
      padding: 12px 16px;
      border: 1px solid ${({ theme: { palette } }) => palette.dark.dark_8};
      background-color: ${({ theme: { palette } }) => palette.mask.transparent};
      color: ${({ theme: { palette } }) => palette.dark.main};
      border-radius: 8px;
      box-shadow: none;
      outline: none;
      font-size: 16px;
      font-family: 'Roboto', sans-serif;
      line-height: 24px;

      &::placeholder {
        color: ${({ theme: { palette } }) => palette.dark.dark_40};
      }

      &:focus {
        border-color: ${({ theme: { palette } }) => palette.dark.dark_16};
        &::placeholder {
          color: transparent;
        }
      }
    }
  }
`;
export default TextArea;
