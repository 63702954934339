import styled from 'styled-components';
import { Typography } from 'antd';

export const StyledText = styled(Typography.Text)`
  &&& {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    color: ${({ theme }) => theme.palette.dark.dark_50};
  }
`;

export default StyledText;
