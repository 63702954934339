import { defineMessages } from 'react-intl';

export default defineMessages({
  fileErrorName: {
    id: 'components.tasks.fileItem.fileErrorName',
    defaultMessage: 'Файл не загружен',
  },
  fileRemoveTooltip: {
    id: 'components.tasks.fileItem.fileRemoveTooltip',
    defaultMessage: 'Открепить',
  },
  fileDownloadTooltip: {
    id: 'components.tasks.fileItem.fileDownloadTooltip',
    defaultMessage: 'Скачать',
  },
});
