import { types } from 'mobx-state-tree';

const MGroupAttendee = types.model({
  id: types.identifierNumber,
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
  company: types.maybeNull(types.string),
  thumbnail: types.maybeNull(types.string),
  position: types.maybeNull(types.string),

  authorized: types.maybe(types.boolean),
  eventId: types.maybe(types.number),
  hidden: types.maybe(types.boolean),
  locale: types.maybe(types.string),
  phone: types.maybe(types.string),
  email: types.maybe(types.string),
});

export default MGroupAttendee;
