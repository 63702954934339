import React from 'react';
import { getLandingUrl } from 'env';

const LandingRedirect: React.FC = () => {
  if (process.env.NODE_ENV === 'development') return <>In prod there is a redirect to the landing</>;
  window.location.href = getLandingUrl();
  return null;
};

export default LandingRedirect;
