import { types } from 'mobx-state-tree';
import { CatalogElementTypes } from './constants';

const MCatalogPoll = types
  .model({
    id: types.identifierNumber,
    order: types.number,
    catalogId: types.number,
    type: types.enumeration('Element type', [CatalogElementTypes.POLL]),
    name: types.string,
    pollId: types.number,
    pollType: types.number,
  })
  .named('CatalogPoll');

export default MCatalogPoll;
