import { defineMessages } from 'react-intl';

export default defineMessages({
  completed: {
    id: 'components.learningTracks.trackCard.completed',
    defaultMessage: 'Завершено',
  },
  min: {
    id: 'components.learningTracks.trackCard.min',
    defaultMessage: 'мин',
  },
  new: {
    id: 'components.learningTracks.trackCard.new',
    defaultMessage: 'New',
  },
  admin: {
    id: 'components.learningTracks.trackCard.admin',
    defaultMessage: 'Admin',
  },
  stagesCount: {
    id: 'components.learningTracks.trackCard.stagesCount',
    defaultMessage: '{count,plural,=0{нет шагов}one{# шаг}few{# шага}other{# шагов}}',
  },
  completedOf: {
    id: 'components.learningTracks.trackCard.completedOf',
    defaultMessage: '{stagesPassed} из {stagesCount,plural,=0{шагов}one{# шага}other{# шагов}}',
  },
});
