import styled from 'styled-components';
import { Rate } from 'antd';

export const StyledRate = styled(Rate)`
  height: 32px;
  color: ${({ theme }) => theme.palette.rating};
  font-size: 32px;

  & .ant-rate-star-first .anticon,
  .ant-rate-star-second .anticon {
    vertical-align: top;
  }

  & .ant-rate-star-first,
  .ant-rate-star-second {
    height: 32px;
  }

  & .ant-rate-star-zero .ant-rate-star-first,
  .ant-rate-star-zero .ant-rate-star-second {
    color: ${({ theme }) => theme.palette.dark.dark_8};
  }

  & .ant-rate-star > div {
    outline: none;
  }
`;

export default StyledRate;
