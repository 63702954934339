import { types } from 'mobx-state-tree';
import Domain from './Domain/Domain';
import UI from './UI/UI';

const Store = types.model({
  DomainStore: types.optional(Domain, {}),
  UIStore: types.optional(UI, {}),
});

export default Store;
