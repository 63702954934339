import React, { useEffect, useState } from 'react';
import { NavigationButton } from 'components';
import { useIntl } from 'react-intl';
import messages from './messages';

interface IProps {
  pageOffsetForVisible: number;
  target?: HTMLElement | null;
}

const ScrollToTop: React.FC<IProps> = ({ pageOffsetForVisible, target }) => {
  const [isVisible, setIsVisible] = useState(false);
  const intl = useIntl();

  const scrollToTop = () => {
    target?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const toggleVisibility = () => {
      if ((target?.scrollTop || 0) > pageOffsetForVisible) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
    target?.addEventListener('scroll', toggleVisibility);
    return () => target?.removeEventListener('scroll', toggleVisibility);
  }, [pageOffsetForVisible, target]);

  return (
    <>
      {isVisible && (
        <NavigationButton
          popoverText={intl.formatMessage(messages.scrollToTopTitle)}
          chevronDirection="top"
          onClick={scrollToTop}
        />
      )}
    </>
  );
};

export default ScrollToTop;
