import React, { useMemo } from 'react';
import { Tooltip } from 'components';
import { BreadcrumbItem, LinkStyled } from '../Breadcrumb.styles';
import { TextStyled } from './BreadcrumbItemLink.styles';

interface IBreadcrumbItemLink {
  name: string;
  location: string | null;
}

const MIN_LENGTH = 20;
const MAX_LENGTH = 100;
const ELLIPSIS_MARK = '...';

const ellipsisText = (text: string, length: number): string => {
  const regex = /[а-яa-z]/gi;
  const newText = text.substring(0, length);

  const removeCount = newText
    .split('')
    .reduceRight((acc: number | null | false, value: string, index: number): number | null | false => {
      return acc || (regex.test(value) && index + 1);
    }, null);

  return removeCount ? `${newText.substring(0, removeCount)}${ELLIPSIS_MARK}` : `${newText}${ELLIPSIS_MARK}`;
};

const BreadcrumbItemLink: React.FC<IBreadcrumbItemLink> = ({ name, location }) => {
  const nameComponent = useMemo(
    () => (
      <TextStyled ellipsis={name.length > MIN_LENGTH}>
        {name.length > MAX_LENGTH ? ellipsisText(name, MAX_LENGTH) : name}
      </TextStyled>
    ),
    [name],
  );

  return (
    <Tooltip title={name} placement="bottom" getPopupContainer={(trigger) => trigger}>
      <BreadcrumbItem fixMinWidth={name.length < MIN_LENGTH}>
        {location ? <LinkStyled to={location}>{nameComponent}</LinkStyled> : <>{nameComponent}</>}
      </BreadcrumbItem>
    </Tooltip>
  );
};

export default BreadcrumbItemLink;
