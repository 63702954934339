import { SnapshotOut } from 'mobx-state-tree';
import { matchPath } from 'react-router-dom';
import store from 'store';
import { RouteTemplates, PollLocationType } from '_constants';
import { TCatalogPoll } from 'types';
import { MScreen } from 'models/Polls';
import { getCatalogIdsFromCatalogParam } from 'utils';
import { MQuestionsCurrentAnswers } from 'store/Domain/Poll';

export const sortPollsByName = (a: { name: string }, b: { name: string }): number => {
  if (a.name.toLowerCase() < b.name.toLowerCase()) {
    return -1;
  }
  if (a.name.toLowerCase() > b.name.toLowerCase()) {
    return 1;
  }
  return 0;
};

export const getMenuPollIdFromLocation = (location: string): number => {
  const getPollId = location?.split('/poll/')[1];
  return Number(getPollId?.split('/')[0]);
};

export const getPollLocationType = (pollId?: number, catalogParam?: string): PollLocationType => {
  const {
    catalog: { getAllCatalogsInCurrentPath },
  } = store.DomainStore;
  const menuCatalogRoute = !!matchPath(window.location.pathname, { path: RouteTemplates.menuCatalog });

  if (catalogParam) {
    const catalogPathIds = getCatalogIdsFromCatalogParam(catalogParam);
    const currentPathCatalogs = getAllCatalogsInCurrentPath(catalogPathIds);
    const currentCatalog = currentPathCatalogs?.[currentPathCatalogs.length - 1];

    if (currentCatalog?.children) {
      const catalogChildPoll = JSON.parse(JSON.stringify(currentCatalog?.children)).find(
        (child: TCatalogPoll) => child.pollId === pollId,
      );
      if (!menuCatalogRoute && catalogChildPoll?.pollId === pollId) {
        if (matchPath(window.location.pathname, { path: RouteTemplates.myLearningTrackCatalog }))
          return PollLocationType.TRACK_CONTENT_POLL;

        return PollLocationType.STAGE_CONTENT_POLL;
      }
    }
  }
  if (matchPath(window.location.pathname, { path: RouteTemplates.myPolls })) return PollLocationType.MY_POLL;
  if (matchPath(window.location.pathname, { path: RouteTemplates.menuPoll })) return PollLocationType.MENU_POLL;
  if (menuCatalogRoute) return PollLocationType.MENU_CATALOG_POLL;
  if (matchPath(window.location.pathname, { path: RouteTemplates.myLearningTrackCatalog }))
    return PollLocationType.TRACK_POLL;

  return PollLocationType.STAGE_POLL;
};

interface WithOrder {
  order: number;
}

export const getShuffledArray = <T>(array: T[]): T[] => {
  return array.slice().sort(() => Math.random() - 0.5);
};

export const getOrderedArray = <T extends WithOrder>(array: T[]): T[] => {
  return array.slice().sort((a, b) => a.order - b.order);
};

const compareOrdersMap = <T extends WithOrder>(a?: T, b?: T) => {
  if (a && b) {
    return a.order - b.order;
  }
  if (a) {
    return -1;
  }
  if (b) {
    return 1;
  }

  return Math.random() - 0.5;
};

export const getArraySortedByOrderMap = <T extends { id: number }, K extends WithOrder>(
  array: T[],
  ordersMap: Map<string, K>,
): T[] => {
  return array.slice().sort((a, b) => {
    const aObject = ordersMap.get(String(a.id));
    const bObject = ordersMap.get(String(b.id));
    return compareOrdersMap(aObject, bObject);
  });
};

export const getShuffledScreensArray = (
  array: SnapshotOut<typeof MScreen>[],
  answers?: Map<string, SnapshotOut<typeof MQuestionsCurrentAnswers>> | null,
): SnapshotOut<typeof MScreen>[] => {
  return array.slice().sort((a, b) => {
    if (answers) {
      const aHasUnansweredQuestions = a.questions.some((question) => !answers.get(String(question.id)));
      const bHasUnansweredQuestions = b.questions.some((question) => !answers.get(String(question.id)));
      if (aHasUnansweredQuestions !== bHasUnansweredQuestions) {
        if (aHasUnansweredQuestions) return 1;
        return -1;
      }
    }
    return Math.random() - 0.5;
  });
};

export const getScreensArraySortedByOrderMap = <K extends WithOrder>(
  array: SnapshotOut<typeof MScreen>[],
  ordersMap: Map<string, K>,
  answers?: Map<string, SnapshotOut<typeof MQuestionsCurrentAnswers>> | null,
): SnapshotOut<typeof MScreen>[] =>
  array.slice().sort((a, b) => {
    if (answers) {
      const aHasUnansweredQuestions = a.questions.some((question) => !answers.get(String(question.id)));
      const bHasUnansweredQuestions = b.questions.some((question) => !answers.get(String(question.id)));
      if (aHasUnansweredQuestions !== bHasUnansweredQuestions) {
        if (aHasUnansweredQuestions) return 1;
        return -1;
      }
    }

    const aObject = ordersMap.get(String(a.id));
    const bObject = ordersMap.get(String(b.id));
    return compareOrdersMap(aObject, bObject);
  });

export const getOrderedScreensArray = (
  array: SnapshotOut<typeof MScreen>[],
  answers?: Map<string, SnapshotOut<typeof MQuestionsCurrentAnswers>> | null,
): SnapshotOut<typeof MScreen>[] => {
  return array.slice().sort((a, b) => {
    if (answers) {
      const aHasUnansweredQuestions = a.questions.some((question) => !answers.get(String(question.id)));
      const bHasUnansweredQuestions = b.questions.some((question) => !answers.get(String(question.id)));
      if (aHasUnansweredQuestions !== bHasUnansweredQuestions) {
        if (aHasUnansweredQuestions) return 1;
        return -1;
      }
    }

    return a.order - b.order;
  });
};
