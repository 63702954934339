/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["self"] }] */
import { flow, types, getRoot, Instance } from 'mobx-state-tree';
import { coursesApi, api } from 'api';
import { MScorm } from 'models';
import { TScormResponse } from 'types/coursesApiTypes';
import { LOCALIZATION_CODES_MAP } from '_constants';
import { TStore } from 'types';
import EventSettings from './EventSettings';

const Scorm = types
  .model({
    data: types.maybeNull(MScorm),
  })
  .views((self) => ({
    get UIStore() {
      return getRoot<TStore>(self).UIStore;
    },
    get EventStore(): Instance<typeof EventSettings> {
      return getRoot<TStore>(self).DomainStore.eventSettings;
    },
  }))
  .actions((self) => ({
    fetchScorm: flow(function* fetchScorm(
      courseId: number,
      stageId: number,
      scormId: number,
      trackId: string,
      isViewMode: boolean,
      title: string,
    ) {
      const { data, hasError }: TScormResponse = yield coursesApi.getScorm(scormId, trackId);
      if (data && !hasError) {
        const fullScormUrl = new URL(data.url);

        const courseDirectory = fullScormUrl.searchParams.get('courseDirectory');
        const courseDirectoryUrl = courseDirectory ? new URL(courseDirectory) : null;

        const { eventId } = self.EventStore.eventInfo;

        const cdnParams = {
          signature: courseDirectoryUrl?.searchParams.get('Signature') || '',
          awsAccessKeyId: courseDirectoryUrl?.searchParams.get('AWSAccessKeyId') || '',
          expires: courseDirectoryUrl?.searchParams.get('Expires') || '',
        };

        fullScormUrl.searchParams.set('scormId', scormId.toString());
        fullScormUrl.searchParams.set('baseUrl', api.prefixUrl);
        fullScormUrl.searchParams.set('courseId', courseId.toString());
        fullScormUrl.searchParams.set('stageId', stageId.toString());
        fullScormUrl.searchParams.set('isViewMode', isViewMode.toString());
        fullScormUrl.searchParams.set('locale', LOCALIZATION_CODES_MAP[self.UIStore.locale]);
        fullScormUrl.searchParams.set('primaryColor', self.UIStore.theme.palette.primary.main);
        fullScormUrl.searchParams.set('title', title);
        fullScormUrl.searchParams.set('stageUrl', window.location.href);

        if (trackId) {
          fullScormUrl.searchParams.set('trackId', trackId);
        }

        if (eventId) {
          fullScormUrl.searchParams.set('eventId', eventId.toString());
        }

        courseDirectoryUrl?.searchParams.delete('Signature');
        courseDirectoryUrl?.searchParams.delete('AWSAccessKeyId');
        courseDirectoryUrl?.searchParams.delete('Expires');
        fullScormUrl.searchParams.set('courseDirectory', courseDirectoryUrl?.href || '');

        self.data = {
          ...data,
          url: fullScormUrl.toString(),
          cdnParams,
        };
      }
    }),
  }));

export default Scorm;
