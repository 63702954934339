import React, { useState } from 'react';
import { Image, MediaModal } from 'components';
import { ReactComponent as CheckMarkRight } from 'assets/icons/checkmark-circle-right.svg';
import { ReactComponent as CrossMark } from 'assets/icons/cross-circle.svg';
import { ReactComponent as CheckMarkGrey } from 'assets/icons/checkmark-circle-chosen.svg';
import { CheckBoxOptions } from 'components/Polls/constants';
import {
  StyledImageCheckbox,
  ImageOverlay,
  Close,
  FullScreenIcon,
  CheckboxWrapper,
  CheckMark,
  ActionIconWrapper,
  ImageOverloadByIconHover,
} from './ImageCheckbox.styles';

const CheckboxIcon = {
  [CheckBoxOptions.RIGHT]: <CheckMarkRight />,
  [CheckBoxOptions.WRONG]: <CrossMark />,
  [CheckBoxOptions.SECONDARY_RIGHT]: <CheckMarkGrey />,
  [CheckBoxOptions.NORMAL]: <CheckMark />,
};

interface IProps {
  value: string;
  variant: CheckBoxOptions;
  img?: string;
  disable?: boolean;
}

const ImageCheckbox: React.FC<IProps> = ({ disable, value, variant, img }) => {
  const actionIcon = CheckboxIcon[variant];
  const [preview, setPreview] = useState<{ visible: boolean; src: string } | null>(null);
  const onClosePreview = () => setPreview({ visible: false, src: '' });

  return (
    <CheckboxWrapper disabled={disable}>
      {img && (
        <ImageOverloadByIconHover>
          <FullScreenIcon onClick={() => setPreview({ visible: true, src: img })} />
        </ImageOverloadByIconHover>
      )}
      <StyledImageCheckbox disabled={disable} variant={variant} value={value}>
        {img && (
          <ImageOverlay>
            <Image src={img} alt="option image" preview={false} />
          </ImageOverlay>
        )}
        <ActionIconWrapper>{actionIcon}</ActionIconWrapper>
      </StyledImageCheckbox>
      {preview && img && (
        <MediaModal visible={preview.visible} footer={false} closeIcon={<Close />} onCancel={onClosePreview}>
          <Image src={preview.src} alt="image" preview={false} />
        </MediaModal>
      )}
    </CheckboxWrapper>
  );
};

export default ImageCheckbox;
