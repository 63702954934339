/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["self"] }] */
import { types } from 'mobx-state-tree';

const Gamification = types
  .model({
    isRatingsLoading: false,
    isRatingsDetailsLoading: false,
    isGamificationDataLoading: false,
  })
  .views((self) => ({
    get isPrizesLoading() {
      return self.isGamificationDataLoading;
    },
    get isRulesLoading() {
      return self.isGamificationDataLoading;
    },
  }))
  .actions((self) => ({
    setGamificationDataLoading: (state: boolean) => {
      self.isGamificationDataLoading = state;
    },
    setRatingsLoading: (state: boolean) => {
      self.isRatingsLoading = state;
    },
    setPersonalRatingsDetailsLoading: (state: boolean) => {
      self.isRatingsDetailsLoading = state;
    },
  }));

export default Gamification;
