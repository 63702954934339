enum CheckBoxOptions {
  RIGHT = 'right',
  WRONG = 'wrong',
  NORMAL = 'normal',
  SECONDARY_RIGHT = 'secondaryRight',
}

enum PollType {
  GROUP = 'group',
  RESULT = 'result',
}

interface IPercentColorBlock {
  percent: number;
  variant?: CheckBoxOptions;
}

export { CheckBoxOptions, PollType };
export type { IPercentColorBlock };
