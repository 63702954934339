export enum Locales {
  RU = 'ru',
  EN = 'en',
  //  Для локализации на Украинский и Казахский
  // KZ = 'kk',
  // UK = 'uk',
}

export const LOCALIZATION_CODES_MAP = {
  [Locales.RU]: 'ru-RU',
  [Locales.EN]: 'en-US',
  //  Для локализации на Украинский и Казахский
  // [Locales.KZ]: 'kk-KZ',
  // [Locales.UK]: 'uk-UA',
};

const currentBrowserLocale = navigator.language.toLowerCase().substring(0, 2);
const locales = Object.values(Locales);
export const currentLocale = locales.find((locale) => locale === currentBrowserLocale) || Locales.EN;
