import { types } from 'mobx-state-tree';
import { CatalogElementTypes, ViewOptions } from './constants';
import MImage from './MImage';

const MCatalogLink = types
  .model({
    id: types.identifierNumber,
    catalogId: types.number,
    name: types.maybeNull(types.string),
    value: types.string,
    type: types.enumeration('Element type', [CatalogElementTypes.CATALOG, CatalogElementTypes.LINK]),
    viewOptions: types.enumeration('View option', [ViewOptions.MEDIA, ViewOptions.IMAGE, ViewOptions.TEXT]),
    textLogoImage: types.maybeNull(MImage),
    textLogoThumbnail: types.maybeNull(MImage),
    fullLogoImage: types.maybeNull(MImage),
    fullLogoThumbnail: types.maybeNull(MImage),
    order: types.number,
    parentCatalogId: types.maybeNull(types.number),
    isOffline: types.boolean,
  })
  .named('CatalogElement');

export default MCatalogLink;
