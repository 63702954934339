import React from 'react';
import { InfoIconStyled, LearningTrackIconStyled, ButtonStyled } from './InfoButton.styles';

interface IInfoButton {
  text: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  active: boolean;
}
const InfoButton: React.FC<IInfoButton> = ({ text, onClick, active }) => (
  <ButtonStyled onClick={onClick} icon={active ? <LearningTrackIconStyled /> : <InfoIconStyled />}>
    {text}
  </ButtonStyled>
);

export default InfoButton;
