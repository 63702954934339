import { defineMessages } from 'react-intl';

export default defineMessages({
  completed: {
    id: 'components.courseCard.completed',
    defaultMessage: 'Завершено',
  },
  min: {
    id: 'components.courseCard.min',
    defaultMessage: 'мин',
  },
  new: {
    id: 'components.courseCard.new',
    defaultMessage: 'New',
  },
  admin: {
    id: 'components.courseCard.admin',
    defaultMessage: 'Admin',
  },
  stagesCount: {
    id: 'components.courseCard.stagesCount',
    defaultMessage: '{count,plural,=0{нет этапов}one{# этап}few{# этапа}other{# этапов}}',
  },
  completedOf: {
    id: 'components.courseCard.completedOf',
    defaultMessage: '{stagesPassed} из {stagesCount} этапов',
  },
});
