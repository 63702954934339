import React from 'react';
import { PopoverButton } from 'components';
import { TChevronDirection } from '../constants';
import { ChevronIconStyled, TextStyled, ButtonStyled } from './IconButton.styles';

interface IIconButton {
  text?: string;
  chevronDirection: TChevronDirection;
  size: number;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  disable?: boolean;
}

const IconButton: React.FC<IIconButton> = ({ text, chevronDirection, size, onClick, disable }) => (
  <PopoverButton onClick={onClick} name={text ? <TextStyled>{text}</TextStyled> : undefined}>
    <ButtonStyled
      $chevronDirection={chevronDirection}
      $size={size}
      shape="circle"
      icon={<ChevronIconStyled width={24} height={24} />}
      disabled={disable}
    />
  </PopoverButton>
);

export default IconButton;
