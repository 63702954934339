import React, { useMemo } from 'react';
import { PopoverButton } from 'components';
import { TextStyled, InfoIconStyled, InfoIconWrapper } from './InfoButton.styles';

interface IInfoButton {
  name?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  globalContainer?: boolean;
}

const InfoButton: React.FC<IInfoButton> = (props) => {
  const { onClick, name, globalContainer = false } = props;
  const button = useMemo(
    () => (
      <InfoIconWrapper>
        <InfoIconStyled />
      </InfoIconWrapper>
    ),
    [],
  );
  const nameElement = useMemo(() => (name ? <TextStyled>{name}</TextStyled> : undefined), [name]);

  return (
    <PopoverButton name={nameElement} onClick={onClick} globalContainer={globalContainer}>
      {button}
    </PopoverButton>
  );
};

export default InfoButton;
