import { types } from 'mobx-state-tree';

const MBookingTypes = types.array(
  types.model({
    id: types.number,
    name: types.string,
    usersBookingLimit: types.maybeNull(types.number),
  }),
);

export default MBookingTypes;
