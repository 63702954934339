import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { FileItem } from 'components';
import { FileItemType } from 'components/Tasks/FileItem/FileItem';
import store from 'store';
import { useAppNavigator } from 'utils';
import { TTaskAttachment } from 'types';
import downloadFile from 'utils/downloadFile';

const AttachmentsSource = {
  taskContent: 'taskContent',
  answers: 'answers',
  feedback: 'feedback',
} as const;

type TAttachmentsSource = typeof AttachmentsSource[keyof typeof AttachmentsSource];

interface IAttachedFiles {
  files?: TTaskAttachment[];
  shouldShowEmpty?: boolean;
  source?: TAttachmentsSource;
}

const AttachedFiles: React.FC<IAttachedFiles> = ({
  files,
  shouldShowEmpty = false,
  source = AttachmentsSource.taskContent,
}) => {
  const { getTaskAttachmentUrl, getFieldAnswerAttachmentUrl, getFeedbackAttachmentUrl } = store.DomainStore.myTasks;

  const {
    params: { taskId },
  } = useAppNavigator();

  const [loadingFiles, setLoadingFiles] = useState<{ [key in number]: boolean }>({});

  const loadAttachment = async (file: TTaskAttachment) => {
    setLoadingFiles((prev) => ({ ...prev, [file.id]: true }));

    const { id: attachmentId, name: fileName, fieldId, submissionId } = file;

    let url: string | null = null;

    if (source === AttachmentsSource.taskContent) {
      url = await getTaskAttachmentUrl({ taskId, attachmentId });
    }

    if (source === AttachmentsSource.answers) {
      url =
        fieldId && submissionId
          ? await getFieldAnswerAttachmentUrl({ taskId, attachmentId, fieldId, submissionId })
          : null;
    }

    if (source === AttachmentsSource.feedback) {
      url = submissionId ? await getFeedbackAttachmentUrl({ taskId, attachmentId, submissionId }) : null;
    }

    if (url) {
      await downloadFile({ fileName, downloadSrc: url });
    }

    setLoadingFiles((prev) => ({ ...prev, [attachmentId]: false }));
  };

  if (shouldShowEmpty && !(files && files.length)) {
    return <FileItem isError type={FileItemType.PREVIEW} />;
  }

  return (
    <>
      {!!files &&
        files.map((file) => {
          return (
            <FileItem
              key={file.id}
              name={file.name}
              size={file.size}
              type={FileItemType.PREVIEW}
              isLoading={loadingFiles?.[file.id]}
              onClickHandler={() => {
                loadAttachment(file);
              }}
            />
          );
        })}
    </>
  );
};

export default observer(AttachedFiles);
