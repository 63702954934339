import styled from 'styled-components';
import Title from '../../Title';

interface IStyledTitle {
  withIcon?: boolean;
}

export const StyledTitle = styled(Title)<IStyledTitle>`
  &&& {
    h3& {
      max-width: 320px;
      margin-left: ${({ withIcon }) => (withIcon ? '36px' : '0px')};
      color: ${({ theme }) => theme.palette.dark.main};
      font-weight: 500;
    }
  }
`;

export default StyledTitle;
