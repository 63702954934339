import { types } from 'mobx-state-tree';

const MScorm = types.model({
  url: types.optional(types.string, ''),
  expires: types.optional(types.string, ''),
  cdnParams: types.model({
    signature: types.optional(types.string, ''),
    awsAccessKeyId: types.optional(types.string, ''),
    expires: types.optional(types.string, ''),
  }),
});

export default MScorm;
