import styled, { css, keyframes } from 'styled-components';
import { ReactComponent as ArrowDown } from 'assets/icons/chevron-down.svg';
import { Grid } from '_constants';

const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
`;

export const ContainerOverlay = styled.div<{ $isExpanded: boolean; $maxHeight: number }>`
  overflow: hidden;
  height: ${(props) => (props.$isExpanded ? 'auto' : `${props.$maxHeight}px`)};
  transition: height 0.3s ease;
`;
export const Container = styled.div``;

export const ButtonOverlay = styled.div<{ isShown: boolean }>`
  display: ${(props) => (props.isShown ? 'inline-flex' : 'none')};
  cursor: pointer;
  margin-top: 12px;
  align-self: flex-start;
`;

export const DefaultButtonBlock = styled.div`
  display: inline-flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const ButtonText = styled.span`
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  margin-right: 4px;
  @media (max-width: ${Grid.MD}px) {
    font-size: 14px;
  }
`;

export const Icon = styled(ArrowDown)<{ $isExpanded: boolean }>`
  display: inline-flex;
  path {
    fill: ${({ theme }) => theme.palette.primary.main};
  }
  ${(props) =>
    props.$isExpanded &&
    css`
      animation: ${css`
        ${rotateAnimation} 0.3s linear forwards
      `};
    `}
`;
