import styled from 'styled-components';

export const CustomPlaybackControlWrapper = styled.div`
  z-index: 60;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  width: 100%;
  height: 100%;
`;

export default { CustomPlaybackControlWrapper };
