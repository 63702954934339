import React, { useLayoutEffect, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import { observer } from 'mobx-react';
import store from 'store';
import { RouteTemplates } from '_constants';
import CourseRouter from 'views/Course/CourseRouter';

const Courses = lazy(() => import('views/Courses'));

const CoursesRouter: React.FC = () => {
  const { fetchCourses } = store.DomainStore.courses;

  useLayoutEffect(() => {
    fetchCourses();
  }, [fetchCourses]);

  return (
    <Switch>
      <Route path={RouteTemplates.course} component={CourseRouter} />
      <Route
        path={[
          RouteTemplates.allCourses,
          RouteTemplates.inProgressCourses,
          RouteTemplates.availableCourses,
          RouteTemplates.completedCourses,
        ]}
      >
        <Route path={[RouteTemplates.coursesStatus]} component={Courses} />
      </Route>
    </Switch>
  );
};

export default observer(CoursesRouter);
