import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { StyledTitle } from './NotificationTitle.styles';
import messages from './messages';

interface INotificationMessage {
  title?: string | null;
  type: string;
  withIcon: boolean;
}

const NotificationTitle: FC<INotificationMessage> = ({ title, type, withIcon }) => {
  const intl = useIntl();

  if (!title && type === 'error')
    return (
      <StyledTitle withIcon={withIcon} level={3}>
        {intl.formatMessage(messages.errorTitle)}
      </StyledTitle>
    );

  if (!title) return null;

  return (
    <StyledTitle withIcon={withIcon} level={3}>
      {title}
    </StyledTitle>
  );
};

export default NotificationTitle;
