import React from 'react';
import { LinkStyled, Name, ChevronIconStyled, TChevronRotate, OChevronRotate } from './ChevronAnchor.styles';

interface IChevronAnchor {
  name: string;
  onClick?: () => void;
  chevronRotate?: TChevronRotate;
}

const ChevronAnchor: React.FC<IChevronAnchor> = (props) => {
  const { name, onClick, chevronRotate = OChevronRotate.DEFAULT } = props;

  return (
    <LinkStyled onClick={onClick}>
      <Name>{name}</Name>
      <ChevronIconStyled rotate={chevronRotate} />
    </LinkStyled>
  );
};

export default ChevronAnchor;
