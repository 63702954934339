import React, { FC } from 'react';
import { ReactComponent as QuestionCircle } from 'assets/icons/question-circle.svg';
import { StyledLargeTooltip, IconOverlay } from './LargeTooltip.styles';

interface ILargeTooltip {
  text: string;
}

const LargeTooltip: FC<ILargeTooltip> = ({ text, ...rest }) => {
  return (
    <StyledLargeTooltip title={text} placement="top" getPopupContainer={(trigger) => trigger} {...rest}>
      <IconOverlay>
        <QuestionCircle />
      </IconOverlay>
    </StyledLargeTooltip>
  );
};

export default LargeTooltip;
