import styled from 'styled-components';
import { Modal, Text, Title } from 'components';
import { ReactComponent as SmileFolder } from 'assets/images/scorm.svg';
import { StyledButton } from 'components/Modal/Modal.styles';

export const StyledModal = styled(Modal)`
  &&&& ${StyledButton} {
    width: 100%;
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ModalTitle = styled(Title)`
  &&&& {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 12px;
  }
`;

export const ModalText = styled(Text)`
  &&&& {
    margin-bottom: 8px;
    line-height: 24px;
    text-align: center;
  }
`;

export const StyledSmileFolder = styled(SmileFolder)`
  &&& {
    margin-top: 4px;
    margin-bottom: 24px;
  }
`;
