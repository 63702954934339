import { types } from 'mobx-state-tree';

export const MFeed = types.model({
  id: types.number,
  iconUrl: types.string,
  name: types.string,
  themeId: types.identifierNumber,
});

export const MLastPostDate = types.model({
  themeId: types.identifierNumber,
  date: types.string,
  feedId: types.number,
});
