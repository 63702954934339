import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Title, RichText, Image, MediaModal } from 'components';

import { useEventListener } from 'utils';
import store from 'store';
import { TCatalog } from 'types';
import { KeyboardControlKeyCodes } from 'components/Player';
import EmptyPlaceholder from 'components/EmptyPlaceholder';
import CatalogElement from './CatalogElement';

import { CatalogImageOverlay, ImagePreviewWrapper, Close, DescriptionWrapper } from './Catalog.styles';

interface ICatalog {
  catalog: TCatalog;
}

const Catalog: React.FC<ICatalog> = ({ catalog: currentCatalog }) => {
  const [preview, setPreview] = useState<{ visible: boolean; src: string } | null>(null);
  const onClosePreview = () => setPreview({ visible: false, src: '' });

  const { activePlayerRef, setActivePlayerRef } = store.UIStore.layout;

  useEventListener('keypress', (e) => {
    if (!activePlayerRef) return;
    switch (e.code) {
      case KeyboardControlKeyCodes.PLAY:
        if (activePlayerRef.paused) {
          activePlayerRef.play();
        } else {
          activePlayerRef.pause();
        }
        break;
      case KeyboardControlKeyCodes.MUTE:
        activePlayerRef.muted = !activePlayerRef.muted;
        break;
      case KeyboardControlKeyCodes.PICTURE_IN_PICTURE:
        if (activePlayerRef.isPiPActive) {
          activePlayerRef.exitPiP();
        } else {
          activePlayerRef.enterPiP();
        }
        break;
      case KeyboardControlKeyCodes.FULLSCREEN:
        if (activePlayerRef.isFullscreenActive) {
          activePlayerRef.exitFullscreen();
        } else {
          activePlayerRef.enterFullscreen();
        }
        break;
      default:
    }
  });

  useEffect(() => () => setActivePlayerRef(null), [setActivePlayerRef]);

  const thumbnail = currentCatalog.coverImage?.url;
  const cover = currentCatalog.fullCoverImage?.url;

  return (
    <>
      {thumbnail && cover && (
        <CatalogImageOverlay>
          <Image
            src={thumbnail}
            preview={false}
            onClick={() => {
              setPreview({ visible: true, src: cover });
            }}
          />
          {preview && (
            <ImagePreviewWrapper>
              <MediaModal visible={preview.visible} footer={false} closeIcon={<Close />} onCancel={onClosePreview}>
                <Image src={preview.src} preview={false} />
              </MediaModal>
            </ImagePreviewWrapper>
          )}
        </CatalogImageOverlay>
      )}
      <Title level={1} ellipsis={{ rows: 4 }}>
        {currentCatalog.name}
      </Title>
      {currentCatalog.description && (
        <DescriptionWrapper>
          <RichText content={currentCatalog.description} />
        </DescriptionWrapper>
      )}
      {!currentCatalog.children?.length && <EmptyPlaceholder showDescription />}

      {currentCatalog.sortedChildren.map((catalogItem) => (
        <CatalogElement key={catalogItem.id} catalogElement={catalogItem} />
      ))}
    </>
  );
};

export default observer(Catalog);
