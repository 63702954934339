import { types } from 'mobx-state-tree';
import { BookingStatus } from 'models/Booking';

const MBookingsCatalog = types.array(
  types.model({
    id: types.string,
    title: types.string,
    status: types.enumeration<BookingStatus>('Status', Object.values(BookingStatus)),
  }),
);

export default MBookingsCatalog;
