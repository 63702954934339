import { types } from 'mobx-state-tree';

export enum RoleSystemNames {
  curator = 'Curator',
  leader = 'Leader',
}

const MRole = types.model({
  id: types.number,
  name: types.string,
  systemName: types.enumeration<RoleSystemNames>('RoleSystemNames', Object.values(RoleSystemNames)),
});

export default MRole;
