import styled from 'styled-components';
import { Grid } from '_constants';

export const CatalogElementWrapper = styled.div`
  margin-top: 32px;
  @media (max-width: ${Grid.MD}px) {
    margin-top: 20px;
  }
`;

export const GroupRowWrapper = styled.div`
  margin-top: 32px;
  @media (max-width: ${Grid.MD}px) {
    margin-top: 20px;
  }
  & + & {
    margin-top: 0;
  }
`;

export const TextRowWrapper = styled.div`
  margin-top: 32px;
  @media (max-width: ${Grid.MD}px) {
    margin-top: 20px;
  }
  & + & {
    margin-top: 0;
  }
`;

export const ImageRowWrapper = styled.div`
  margin-top: 32px;
  @media (max-width: ${Grid.MD}px) {
    margin-top: 20px;
  }
  & + & {
    margin-top: 0;
  }
`;

export const BookingElementWrapper = styled.div``;

export const BookingElementInnerWrapper = styled.div`
  margin-top: 32px;
  @media (max-width: ${Grid.MD}px) {
    margin-top: 20px;
  }
  ${BookingElementWrapper} + ${BookingElementWrapper} & {
    margin-top: 16px;
  }
`;
