import styled from 'styled-components';
import { Grid } from '_constants';
import { ReactComponent as ChevronIcon } from 'assets/icons/chevron.svg';
import { Typography } from 'antd';
import { Tooltip } from 'components';
import { TextRowWrapper } from '../../CatalogElement.styles';

interface IImageWrapper {
  defaultColor?: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  height: 72px;
  ${TextRowWrapper} + ${TextRowWrapper} > & {
    margin-top: 16px;
  }
`;

export const ImageWrapper = styled.div<IImageWrapper>`
  position: relative;
  flex: 0 0 auto;
  overflow: hidden;
  height: 100%;
  width: 130px;
  background: ${({ defaultColor, theme }) => (defaultColor ? theme.palette.dark.dark_8 : theme.palette.dark.dark_4)};

  @media (max-width: ${Grid.MD}px) {
    min-width: 70px;
  }
  @media (max-width: ${Grid.XXS}px) {
    width: 70px;
  }

  svg {
    width: 40px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 0 0;
  padding: 10px 16px 10px 20px;
  @media (max-width: ${Grid.XXS}px) {
    padding: 10px 16px 10px 10px;
  }
`;

export const Name = styled(Typography.Paragraph)`
  &&& {
    flex: 0 1 auto;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    line-height: 24px;
    word-break: break-word;
    color: ${({ theme }) => theme.palette.dark.main};
    margin-bottom: 0;
  }
`;

export const ChevronIconStyled = styled(ChevronIcon)`
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
  path {
    fill: ${({ theme }) => theme.palette.dark.dark_20};
    transition: fill 0.4s;
  }
  margin-left: 16px;
  @media (max-width: ${Grid.XXS}px) {
    margin-left: 0;
  }
`;

export const CardWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex: 0 0 100%;
  overflow: hidden;
  max-width: 100%;
  background-color: ${({ theme }) => theme.palette.white.primary};
  border: 1px solid ${({ theme }) => theme.palette.dark.dark_8};
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.4s;

  &:hover {
    background: ${({ theme }) => theme.palette.dark.dark_4};
    ${ChevronIconStyled} {
      path {
        fill: ${({ theme }) => theme.palette.dark.dark_40};
      }
    }
  }
`;

export const MediaFolderTooltip = styled(Tooltip)`
  .ant-tooltip-inner {
    width: 320px;
  }
`;
