import { makeAutoObservable, toJS } from 'mobx';
import store from 'store';
import { api } from 'api';
import { SCORM_MESSAGES } from '_constants';

interface ICurrentScormTab {
  scormId: string | null;
  window: Window | null;
}

class ScormTabStore {
  private scormId: string | null;

  private window: Window | null;

  constructor() {
    makeAutoObservable(this);

    this.scormId = null;
    this.window = null;

    window.addEventListener('beforeunload', () => {
      this.window?.close();
    });

    window.addEventListener('message', (event: MessageEvent) => {
      if (event.data?.event === SCORM_MESSAGES.BEFOREUNLOAD && event.data?.scormId === this.scormId) {
        this.scormId = null;
        this.window = null;
      }

      if (
        event.data?.event === SCORM_MESSAGES.NEED_CREDENTIALS &&
        this.window &&
        event.data?.scormId === this.scormId
      ) {
        const { data } = store.DomainStore.scorm;

        if (data) {
          const { cdnParams } = data;

          this.window?.postMessage(
            {
              event: SCORM_MESSAGES.SET_CREDENTIALS,
              value: {
                cdnParams: toJS(cdnParams),
                auth: api.token,
                event: api.publicId,
                user: {
                  autoLogin: store.DomainStore.auth.autoLogin,
                  id: store.DomainStore.profile.data?.id,
                  firstName: store.DomainStore.profile.data?.firstName,
                  lastName: store.DomainStore.profile.data?.lastName,
                  email: store.DomainStore.profile.data?.email,
                  phone: store.DomainStore.profile.data?.phone,
                },
              },
            },
            '*',
          );
        }
      }
    });
  }

  get scormTab(): ICurrentScormTab {
    return {
      scormId: this.scormId,
      window: this.window,
    };
  }

  setScormTab(scormTab: ICurrentScormTab): void {
    this.scormId = scormTab.scormId;
    this.window = scormTab.window;
  }

  closeScorm(): void {
    this.window?.close();
    this.scormId = null;
    this.window = null;
  }
}

export default ScormTabStore;
