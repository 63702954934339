import React from 'react';
import { ButtonProps as ButtonPropsOriginal, ButtonType as ButtonTypeOriginal } from 'antd/lib/button/button';
import { StyledButton } from './Button.styles';

const customTypeArray = ['secondary', 'success', 'danger'] as const;

type ButtonType = ButtonTypeOriginal | typeof customTypeArray[number];
type ButtonProps = Omit<ButtonPropsOriginal, 'type'> & {
  type?: ButtonType;
};

type IButtonProps = Partial<ButtonProps & ButtonPropsOriginal>;

const Button: React.FC<ButtonProps> = (props) => {
  return <StyledButton {...(props as IButtonProps)} />;
};

export default Button;
