import { types } from 'mobx-state-tree';
import { CatalogElementTypes } from './constants';

const MCatalogText = types
  .model({
    id: types.identifierNumber,
    catalogId: types.number,
    value: types.maybeNull(types.string),
    type: types.enumeration('Element type', [CatalogElementTypes.TEXT]),
    order: types.number,
  })
  .named('CatalogText');

export default MCatalogText;
