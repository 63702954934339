import styled from 'styled-components';
import { Typography } from 'antd';
import { Card } from 'components';
import { Grid } from '_constants';

export const Wrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  margin: 24px auto;
  max-width: 100%;
  @media (max-width: ${Grid.MD}px) {
    margin: 16px auto;
    padding: 32px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`;

export const Percent = styled.p`
  margin: 0 0 0 16px;
  color: ${({ theme }) => theme.palette.dark.main};
  font-weight: 700;
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;
  line-height: 24px;
`;

export const Title = styled(Typography.Paragraph)`
  && {
    margin: 0;
    color: ${({ theme }) => theme.palette.dark.dark_50};
    font-weight: 400;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    line-height: 24px;
  }
`;
