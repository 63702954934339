import { RouteTemplates, ComponentTypes } from '_constants';

const MenuRoutes = {
  [ComponentTypes.MY_COURSES]: {
    href: RouteTemplates.allCourses,
    match: [
      RouteTemplates.allCourses,
      RouteTemplates.inProgressCourses,
      RouteTemplates.availableCourses,
      RouteTemplates.completedCourses,
      RouteTemplates.course,
    ],
  },
  [ComponentTypes.MY_POLLS]: {
    href: RouteTemplates.myPolls,
    match: [RouteTemplates.myPolls],
  },
  [ComponentTypes.MENU_POLL]: {
    href: RouteTemplates.menuPoll,
    match: [RouteTemplates.menuPoll],
  },
  [ComponentTypes.CATALOG]: {
    href: RouteTemplates.menuCatalog,
    match: [RouteTemplates.menuCatalog],
  },
  [ComponentTypes.GAMIFICATION]: {
    href: RouteTemplates.gamification,
    match: [RouteTemplates.gamification],
  },
  [ComponentTypes.GROUP_GAMIFICATION]: {
    href: RouteTemplates.groupGamification,
    match: [RouteTemplates.groupGamification],
  },
  [ComponentTypes.MY_TEAM]: {
    href: RouteTemplates.myTeam,
    match: [RouteTemplates.myTeam],
  },
  [ComponentTypes.MY_BOOKINGS]: {
    href: RouteTemplates.myBookings,
    match: [RouteTemplates.myBookings],
  },
  [ComponentTypes.FRAME]: {
    href: RouteTemplates.frame,
    match: [RouteTemplates.frame],
  },
  [ComponentTypes.MY_TASKS]: {
    href: RouteTemplates.myTasks,
    match: [RouteTemplates.myTasks],
  },
  [ComponentTypes.SCHEDULE]: {
    href: RouteTemplates.schedule,
    match: [RouteTemplates.schedule],
  },
  [ComponentTypes.MY_LEARNING_TRACKS]: {
    href: RouteTemplates.myLearningTracksAll,
    match: [
      RouteTemplates.myLearningTracksAll,
      RouteTemplates.myLearningTracksInProgress,
      RouteTemplates.myLearningTracksCompleted,
      RouteTemplates.myLearningTracksAvailable,
      RouteTemplates.myLearningTrack,
    ],
  },
  [ComponentTypes.DASHBOARD]: {
    href: RouteTemplates.dashboard,
    match: [RouteTemplates.dashboard],
  },
  // TODO: Добавить пути по мере добавление разделов меню
} as const;

export default MenuRoutes;
