import React, { useEffect, useCallback } from 'react';
import { useIntl } from 'react-intl';
import store from 'store';
import messages from './messages';

const ShowOfflineNotification: React.FC = () => {
  const intl = useIntl();
  const { setNetworkStatus } = store.UIStore;

  const handleOnlineStatus = useCallback(() => {
    setNetworkStatus();
    store.UIStore.notifications.setNotification({
      title: intl.formatMessage(messages.connectionRestore),
      type: 'info',
      closable: true,
      notificationConfig: {
        className: 'tl-rocks-notice-online',
      },
    });
  }, [intl, setNetworkStatus]);

  const handleOfflineStatus = useCallback(() => {
    setNetworkStatus();
    store.UIStore.notifications.setNotification({
      title: intl.formatMessage(messages.noInternetConnection),
      type: 'info',
      closable: true,
      notificationConfig: {
        className: 'tl-rocks-notice-offline',
      },
    });
  }, [intl, setNetworkStatus]);

  useEffect(() => {
    window.addEventListener('online', handleOnlineStatus);
    window.addEventListener('offline', handleOfflineStatus);
    return () => {
      window.removeEventListener('online', handleOnlineStatus);
      window.removeEventListener('offline', handleOfflineStatus);
    };
  }, [handleOfflineStatus, handleOnlineStatus]);

  return <></>;
};

export default ShowOfflineNotification;
