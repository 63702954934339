import React from 'react';
import { ReactComponent as SpinnerIcon } from 'assets/icons/spinner.svg';
import { Wrapper, Spin, IWrapper } from './Spinner.styles';

enum SpinnerSize {
  small = 24,
  normal = 44,
}

interface ISpinner extends IWrapper {
  fullscreen?: boolean;
  size?: keyof typeof SpinnerSize;
}

const Spinner: React.FC<ISpinner> = ({
  fullscreen = false,
  absolute = false,
  transparent = false,
  size = 'normal',
}) => {
  const spinner = (
    <Spin className="anticon-spin" size={SpinnerSize[size]}>
      <SpinnerIcon />
    </Spin>
  );
  return fullscreen ? (
    <Wrapper absolute={absolute} transparent={transparent}>
      {spinner}
    </Wrapper>
  ) : (
    spinner
  );
};

export default Spinner;
