import { TRatingsResponse, TRatingDetailsResponse, TGamificationResponse } from 'types/gamificationApiTypes';
import { GamificationTypes } from 'types/gamificationTypes';
import api from './api';

const getRatings = (type: GamificationTypes, page: number, pageSize: number): Promise<TRatingsResponse> =>
  api.privateRequest('get', `api/web/tlrocks/ratings/${type}`, {
    searchParams: {
      skip: page * pageSize,
      take: pageSize,
    },
  });

const getSearchedRatings = (type: GamificationTypes, search: string): Promise<TRatingsResponse> =>
  api.privateRequest('get', `api/web/tlrocks/ratings/${type}/search`, {
    searchParams: {
      search,
    },
  });

const getGamificationData = (type: GamificationTypes): Promise<TGamificationResponse> =>
  api.privateRequest('get', `api/web/tlrocks/gamification/${type}`);

const getPersonalRatingsDetails = (): Promise<TRatingDetailsResponse> =>
  api.privateRequest('get', `api/web/tlrocks/ratings/personal/detail`);

export default {
  getRatings,
  getPersonalRatingsDetails,
  getSearchedRatings,
  getGamificationData,
};
