/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["self"] }] */
import { types } from 'mobx-state-tree';
import MThemePalette from './MThemePalette';

const MTheme = types
  .model({
    spacing: types.number,
    palette: MThemePalette,
  })
  .actions((self) => ({
    setAccentColor: (color: string) => {
      self.palette.primary.main = color;
    },
    setDominantColor: (color: string) => {
      self.palette.dominant = color;
    },
    setAdditionalDominantColor: (color: string) => {
      self.palette.additionalDominant = color;
    },
  }));
export default MTheme;
