import React, { useMemo } from 'react';
import { TCatalogBooking } from 'types';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import store from 'store';
import { getCatalogIdsFromCatalogParam, useAppNavigator } from 'utils';
import { BookingElement } from 'components';
import { BookingElementInnerWrapper } from '../CatalogElement.styles';

interface Props {
  booking: TCatalogBooking;
}

const CatalogBooking: React.FC<Props> = ({ booking }) => {
  const {
    catalog: { bookingsCatalog },
  } = store.DomainStore;
  const {
    params: { catalog, courseId, trackStepId },
    goToMenuCatalogBooking,
    goToCourseBooking,
    goToLearningTrackCourseBooking,
  } = useAppNavigator();
  const catalogIds = getCatalogIdsFromCatalogParam(catalog);

  const currentBooking = useMemo(() => {
    return computed(() => bookingsCatalog?.find((bookingCatalog) => bookingCatalog.id === booking.bookingUuid));
  }, [bookingsCatalog, booking.bookingUuid]).get();

  const onClickBooking = () => {
    if (courseId) {
      if (trackStepId) {
        goToLearningTrackCourseBooking(booking.bookingUuid);
      } else {
        goToCourseBooking(booking.bookingUuid);
      }
    } else {
      goToMenuCatalogBooking(catalogIds, booking.bookingUuid);
    }
  };

  if (!currentBooking) return null;

  return (
    <BookingElementInnerWrapper>
      <BookingElement title={currentBooking.title} status={currentBooking.status} onClick={onClickBooking} />
    </BookingElementInnerWrapper>
  );
};

export default observer(CatalogBooking);
