import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { RouteTemplates } from '_constants';
import { matchPath, useLocation } from 'react-router-dom';
import store from 'store';
import { NotificationInstance, NotificationPlacement } from 'antd/lib/notification';
import { ReactComponent as ErrorIcon } from 'assets/icons/cross-circle.svg';
import NotificationMessage from 'components/Notification/NotificationMessage';
import { ReactComponent as SuccessIcon } from 'assets/icons/checkmark-circle-right.svg';
import { ReactComponent as WarnIcon } from 'assets/icons/warn.svg';
import { ReactComponent as OfflineIcon } from 'assets/icons/no-connection.svg';
import { TNotificationConfig } from 'types';
import { IButton } from './NotificationButtons/NotificationButtons';
import NotificationTitle from './NotificationTitle';
import NotificationDescription from './NotificationDescription';
import { StyledCloseIcon, FakeCloseIcon, NotificationStyles } from './ShowNotification.styles';

interface ICloseIconProps {
  withCloseButton: boolean;
}

const CloseIcon: FC<ICloseIconProps> = ({ withCloseButton }) => {
  return withCloseButton ? <StyledCloseIcon /> : <FakeCloseIcon />;
};

const NOTIFICATION_ICON: Record<string, JSX.Element> = {
  error: <ErrorIcon />,
  success: <SuccessIcon />,
  warning: <WarnIcon />,
  info: <OfflineIcon />,
};

const NOTIFICATION_CLASSNAME = {
  WITH_CLOSE_BTN: 'tl-rocks-notice-with-close',
  WITHOUT_CLOSE_BTN: 'tl-rocks-notice-without-close',
};

interface IProps {
  type: string;
  title: string | null;
  id: string;
  description: string | null;
  buttons: IButton[];
  closeNotification: (id: string) => void;
  closable: boolean;
  duration?: number | null;
  notificationConfig?: TNotificationConfig;
  onClose: () => void;
}

export const ShowNotification: FC<IProps> = ({
  type,
  title,
  id,
  description,
  buttons,
  closeNotification,
  duration,
  closable,
  notificationConfig,
  onClose,
}) => {
  const location = useLocation();
  const topPosition = matchPath(location.pathname, { path: RouteTemplates.auth }) ? undefined : 76;

  return (
    <>
      <NotificationStyles />
      <NotificationMessage
        type={type as keyof NotificationInstance}
        message={<NotificationTitle title={title} type={type} withIcon={Boolean(NOTIFICATION_ICON[type])} />}
        description={
          <NotificationDescription
            id={id}
            isTitleExists={Boolean(title)}
            description={description}
            buttons={buttons}
            closeNotification={closeNotification}
            closable={closable}
            withIcon={Boolean(NOTIFICATION_ICON[type])}
            type={type}
          />
        }
        notificationOptions={{
          closeIcon: <CloseIcon withCloseButton={closable} />,
          icon: NOTIFICATION_ICON[type],
          duration,
          key: id,
          style: notificationConfig?.style || {},
          className:
            notificationConfig?.className ||
            (closable ? NOTIFICATION_CLASSNAME.WITH_CLOSE_BTN : NOTIFICATION_CLASSNAME.WITHOUT_CLOSE_BTN),
          placement: (notificationConfig?.placement as NotificationPlacement) || undefined,
          bottom: notificationConfig?.bottom || undefined,
          top: notificationConfig?.top || topPosition,
          prefixCls: notificationConfig?.prefixCls || undefined,
          onClick: notificationConfig?.onClick,
          onClose,
        }}
      />
    </>
  );
};

const ShowNotificationContainer: FC = () => {
  const { notification } = store.UIStore.notifications;
  if (!notification) return null;

  const {
    id,
    type,
    title,
    description,
    buttons,
    closable,
    duration,
    closeNotification,
    notificationConfig = {},
  } = notification;

  const onClose = () => {
    notification.notificationConfig?.onClose();
    notification.setAsInactive();
  };

  return (
    <ShowNotification
      id={id}
      type={type}
      title={title}
      description={description}
      duration={duration}
      buttons={buttons.slice()}
      closable={closable}
      closeNotification={closeNotification}
      notificationConfig={{ ...notificationConfig }}
      onClose={onClose}
    />
  );
};

export default observer(ShowNotificationContainer);
