/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["self"] }] */
import { types } from 'mobx-state-tree';
import { TLastSubmission } from 'types';
import { FieldType, LastSubmissionStatus } from './constants';

export const MTaskField = types.model({
  id: types.identifierNumber,
  name: types.maybeNull(types.string),
  hint: types.maybeNull(types.string),
  type: types.enumeration<FieldType>('Type', Object.values(FieldType)),
  required: types.boolean,
});

export const MFinalScreen = types.model({
  completedTitle: types.maybeNull(types.string),
  rejectedTitle: types.maybeNull(types.string),
  inReviewTitle: types.maybeNull(types.string),
});

export const MTaskAttachment = types.model({
  id: types.identifierNumber,
  name: types.string,
  size: types.number,
});

export const MTaskFieldAnswers = types.model({
  id: types.identifierNumber,
  fieldId: types.number,
  value: types.maybeNull(types.string),
  attachment: types.maybeNull(MTaskAttachment),
});

export const MTaskFeedback = types.model({
  comment: types.maybeNull(types.string),
  attachments: types.maybeNull(types.array(MTaskAttachment)),
});

export const MLastSubmission = types.model({
  id: types.identifierNumber,
  date: types.string,
  status: types.enumeration('LastSubmissionStatus', Object.values(LastSubmissionStatus)),
  score: types.maybeNull(types.number),
  canBeEdited: types.boolean,
  answers: types.array(MTaskFieldAnswers),
  feedback: types.maybeNull(MTaskFeedback),
});

export const MTaskStartSubmission = types.model({
  id: types.identifierNumber,
  date: types.string,
  status: types.enumeration('LastSubmissionStatus', Object.values(LastSubmissionStatus)),
});

const MTask = types
  .model({
    id: types.identifierNumber,
    name: types.string,
    coverUrl: types.maybeNull(types.string),
    description: types.string,
    canBeRetaken: types.boolean,
    maxScore: types.number,
    fields: types.array(MTaskField),
    finalScreen: MFinalScreen,
    lastSubmission: types.maybeNull(MLastSubmission),
    lastFinalizedSubmission: types.maybeNull(MLastSubmission),
    attachments: types.maybeNull(types.array(MTaskAttachment)),
  })
  .views((self) => ({
    get mySubmission(): TLastSubmission | null {
      if (
        !!self.lastFinalizedSubmission &&
        self.lastFinalizedSubmission?.status === LastSubmissionStatus.REJECTED &&
        self.lastSubmission?.status !== LastSubmissionStatus.SUBMITTED
      ) {
        return self.lastFinalizedSubmission;
      }

      if (self.lastSubmission?.answers.length) {
        return self.lastSubmission;
      }

      return null;
    },
    get fieldTypes(): { [key in number]: FieldType } {
      const fieldTypes: { [key in number]: FieldType } = {};

      self.fields.forEach((field) => {
        fieldTypes[field.id] = field.type;
      });

      return fieldTypes;
    },
  }))
  .views((self) => ({
    getFieldType(fieldId: number): FieldType | undefined {
      return self.fieldTypes[fieldId];
    },
  }))
  .actions((self) => ({
    setStatus(status: LastSubmissionStatus.COMPLETED | LastSubmissionStatus.SUBMITTED | LastSubmissionStatus.STARTED) {
      if (!self.lastSubmission) return;

      self.lastSubmission = { ...self.lastSubmission, status };
    },
  }));

export default MTask;
