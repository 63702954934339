import styled from 'styled-components';
import { ReactComponent as ChevronIcon } from 'assets/icons/chevron.svg';

export const OChevronRotate = {
  DEFAULT: 'default',
  BOTTOM: 'bottom',
} as const;
export type TChevronRotate = typeof OChevronRotate[keyof typeof OChevronRotate];

interface IChevronIconStyled {
  rotate: TChevronRotate;
}

export const Name = styled.span`
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: ${({ theme: { palette } }) => palette.primary.main};
  transition: color 0.4s;
  user-select: none;
`;

export const ChevronIconStyled = styled(ChevronIcon)<IChevronIconStyled>`
  ${({ rotate }) =>
    rotate === OChevronRotate.BOTTOM &&
    `transform: rotate(90deg); 
        margin-bottom: 3px;
        margin-left: 3px;`}
  path {
    fill: ${({ theme: { palette } }) => palette.primary.main};
    transition: fill 0.4s;
  }
`;

export const LinkStyled = styled.span`
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    & ${Name} {
      color: ${({ theme: { palette } }) => palette.primary.main};
      filter: brightness(1.13);
    }

    & ${ChevronIconStyled} {
      path {
        fill: ${({ theme: { palette } }) => palette.primary.main};
        filter: brightness(1.13);
      }
    }
  }
`;
