import { BookingStatus } from 'models/Booking';
import { TTheme } from 'types';
import styled from 'styled-components';
import { LargeTooltip } from 'components';
import { Typography } from 'antd';

interface IBookingStatusText {
  status?: BookingStatus;
}

const getBookingTextColor = (theme: TTheme, status?: BookingStatus) => {
  switch (status) {
    case BookingStatus.REGISTERED:
      return theme.palette.success.primary;
    case BookingStatus.REGISTERED_WAIT_LIST:
    case BookingStatus.CAN_REGISTER_WAIT_LIST:
    case BookingStatus.CANNNOT_REGISTER:
      return theme.palette.dark.dark_40;
    case BookingStatus.CANNNOT_REGISTER_LIMIT:
      return theme.palette.error.primary;
    case BookingStatus.CAN_REGISTER:
      return theme.palette.primary.main;
    default:
      return theme.palette.dark.dark_40;
  }
};

export const StyledLargeTooltip = styled(LargeTooltip)`
  margin-left: 4px;
`;

export const BookingText = styled.div``;

export const BookingStatusOverlay = styled.div`
  display: flex;
  align-items: center;
`;

export const BookingStatusText = styled.div<IBookingStatusText>`
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: ${({ theme, status }) => getBookingTextColor(theme, status)};
`;

export const Booking = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 24px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.palette.dark.dark_8};
  cursor: pointer;

  &:first-of-type {
    margin-top: 0;
  }

  &:hover {
    background: ${({ theme }) => theme.palette.dark.dark_4};
    border-color: ${({ theme }) => theme.palette.dark.dark_8};
  }
`;

export const BookingName = styled(Typography.Paragraph)`
  &&& {
    flex: 0 1 auto;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    line-height: 24px;
    word-break: break-word;
    color: ${({ theme }) => theme.palette.dark.main};
    margin-bottom: 0;
  }
`;

export const BookingIcon = styled.div`
  width: 20px;
  height: 20px;
`;
