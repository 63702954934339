import styled, { css } from 'styled-components';
import { Progress } from 'antd';

export const StyledProgress = styled(Progress)`
  &&& {
    top: 0;
    left: 0;
    line-height: 0;
  }
`;

export const ProgressOverlay = styled.div<{ visible: boolean }>`
  &&&& {
    .ant-progress-inner > .ant-progress-circle {
      display: none;
      ${({ visible }) =>
        visible &&
        css`
          display: flex;
        `}
    }
  }
`;

export default StyledProgress;
