import styled from 'styled-components';
import { Input } from 'antd';

export const StyledInput = styled(Input)`
  &&& {
    &:-webkit-autofill::first-line {
      color: ${({ theme }) => theme.palette.dark.main};
      font-weight: 400;
      font-size: 16px;
      font-family: 'Roboto', sans-serif;
    }

    &.ant-input {
      height: 48px;
      padding: 12px 16px;
      border: solid 1px ${({ theme }) => theme.palette.dark.dark_8};
      border-radius: 10px;
      color: ${({ theme }) => theme.palette.dark.main};
      font-weight: 400;
      font-size: 16px;
      font-family: 'Roboto', sans-serif;
      &[disabled] {
        background-color: ${({ theme: { palette } }) => palette.dark.dark_4};
      }
      &::placeholder {
        color: ${({ theme }) => theme.palette.dark.dark_30};
      }
      &:focus {
        box-shadow: 0 0 0 2px ${({ theme: { palette } }) => palette.primary.main};
        &::placeholder {
          color: ${({ theme }) => theme.palette.dark.dark_20};
        }
      }
      &.ant-input-affix-wrapper-focused {
        box-shadow: none;
      }
    }
  }
`;

export default StyledInput;
