/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["self"] }] */
import { types, cast, flow, getRoot } from 'mobx-state-tree';
import { coursesApi } from 'api';
import { MCourseInfo, CourseStatuses } from 'models';
import { TCourseInfo, TStore } from 'types';
import { TCoursesInfoResponse } from 'types/coursesApiTypes';

const compareCoursesByOpen = () => (a: TCourseInfo, b: TCourseInfo) => {
  if (!a.openDate || !b.openDate || a.openDate === b.openDate) {
    return 0;
  }
  return a.openDate > b.openDate ? -1 : 1;
};

const compareCoursesByAssignment = () => (a: TCourseInfo, b: TCourseInfo) => {
  if (!a.assignmentDate || !b.assignmentDate || a.assignmentDate === b.assignmentDate) {
    return 0;
  }
  return a.assignmentDate > b.assignmentDate ? -1 : 1;
};

const compareCoursesByEndCourse = () => (a: TCourseInfo, b: TCourseInfo) => {
  if (!a.completionDate || !b.completionDate || a.completionDate === b.completionDate) {
    return 0;
  }
  return a.completionDate > b.completionDate ? -1 : 1;
};

const Courses = types
  .model({
    data: types.array(MCourseInfo),
  })
  .views((self) => ({
    get courseUIStore() {
      return getRoot<TStore>(self).UIStore.course;
    },
    get inProgress() {
      return self.data.filter((course) => course.status === CourseStatuses.IN_PROGRESS).sort(compareCoursesByOpen());
    },
    get available() {
      return self.data
        .filter((course) => course.status === CourseStatuses.NOT_STARTED)
        .sort(compareCoursesByAssignment());
    },
    get completed() {
      return self.data.filter((course) => course.status === CourseStatuses.COMPLETED).sort(compareCoursesByEndCourse());
    },
    get allCount() {
      return self.data.length;
    },
  }))
  .actions((self) => ({
    fetchCourses: flow(function* fetchCourses() {
      self.courseUIStore.setFetchingCourses(true);
      const { data, hasError }: TCoursesInfoResponse = yield coursesApi.getCoursesInfo();
      if (!hasError && data) {
        self.data = cast(data.items);
      }
      self.courseUIStore.setFetchingCourses(false);
    }),
  }));

export default Courses;
