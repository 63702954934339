import { TResponded } from 'types';

export enum CodeStepStatus {
  REGISTERED_EMAIL = 'registeredEmail',
  REGISTERED_PHONE = 'registeredPhone',
  REGISTERED_PHONE_SEND_BY_EMAIL = 'registeredPhoneSendByEmail',
  CODE_EMAIL = 'codeEmail',
  CODE_PHONE = 'codePhone',
}

export enum SignUpStatus {
  SUCCESS = 'success',
  USER_EXISTS = 'userExists',
  BLOCKED = 'blocked',
  BLOCKED_REGISTRATION_BY_EMAIL = 'blockedRegistrationByEmail',
  BLOCKED_REGISTRATION_BY_PHONE = 'blockedRegistrationByPhone',
}

export enum SendTypes {
  EMAIL = 'email',
  PHONE = 'phone',
}

export enum LimitStatus {
  SMS_LIMIT_EXCEEDED = 'smsLimitExceeded',
}

export type TSignUpResponse = TResponded<{
  status: SignUpStatus;
  verificationToken?: string;
  blockedForSeconds?: number;
  sendType?: SendTypes;
  code?: string;
  message?: string;
  success?: false;
  sentTo?: string;
  limitStatus?: LimitStatus;
}>;

export enum VerifyStatus {
  SUCCESS = 'success',
  INVALID_OTP = 'invalidOtp',
  INVALID_VERIFICATION_TOKEN = 'invalidVerificationToken',
  BLOCKED = 'blocked',
}

export type TVerifyResponse = TResponded<{
  status: VerifyStatus;
  code?: string;
  message?: string;
  success?: false;
  blockedForSeconds?: number;
}>;

export enum RegisterStatus {
  REGISTERED = 'registered',
  PREMODERATION = 'premoderation',
  INVALID_VERIFICATION_TOKEN = 'invalidVerificationToken',
  VERIFICATION_TOKEN_EXPIRED = 'verificationTokenExpired',
}

export type TRegisterResponse = TResponded<{
  status: RegisterStatus;
  limitStatus?: LimitStatus;
  attendeeId?: number;
  token?: string;
  code?: string;
  message?: string;
  success?: false;
}>;
