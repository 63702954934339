import React, { useState } from 'react';
import { CopyIconWrapper, CopyIconStyled, PopoverButtonStyled } from './CopyButton.styles';
import CopyStatus, { StatusesEnum } from './CopyStatus';

interface ICopyButton {
  copyText: string;
  showPopover?: boolean;
  copyIconWrapper?: React.ReactElement;
}

const CopyButton: React.FC<ICopyButton> = ({ showPopover = true, copyText, copyIconWrapper }) => {
  const [copyStatus, setCopyStatus] = useState<StatusesEnum>(StatusesEnum.MAKE_COPY);

  const onClickHandler = () => {
    navigator.clipboard.writeText(copyText).then(() => {
      setCopyStatus(StatusesEnum.COPIED);
    });
  };

  const onPopoverVisibleChangeHandler = (visible: boolean) => {
    if (visible) {
      setCopyStatus(StatusesEnum.MAKE_COPY);
    }
  };

  return (
    <PopoverButtonStyled
      name={showPopover ? <CopyStatus status={copyStatus} /> : undefined}
      onClick={onClickHandler}
      onVisibleChange={onPopoverVisibleChangeHandler}
    >
      {copyIconWrapper || (
        <CopyIconWrapper>
          <CopyIconStyled />
        </CopyIconWrapper>
      )}
    </PopoverButtonStyled>
  );
};

export default CopyButton;
