import { defineMessages } from 'react-intl';

export default defineMessages({
  showLessText: {
    id: 'components.showMoreBlock.showLessText',
    defaultMessage: 'Читать далее',
  },
  showMoreText: {
    id: 'components.showMoreBlock.showMoreText',
    defaultMessage: 'Свернуть',
  },
});
