import styled from 'styled-components';
import { Divider } from 'antd';

export const StyledDivider = styled(Divider)`
  &&& {
    &.ant-divider {
      margin: 36px 0;
      border-width: 2px;
      border-color: ${({ theme }) => theme.palette.dark.dark_8};
    }
    &.ant-divider-vertical {
      height: 100%;
      margin: 0 8px;
      border-width: 1px;
    }
  }
`;

export default StyledDivider;
