/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["self"] }] */
import { cast, flow, types } from 'mobx-state-tree';
import { MDashboardCourseInfo, MDashboardTrackInfo, MDashboardInfo, MDashboardRating } from 'models';
import dashboardApi from 'api/dashboardApi';
import { TDashboardInfoResponse } from 'types/dashboardApiTypes';
import { DashboardElementTypes } from 'models/MDashboard';

const Dashboard = types
  .model({
    data: types.maybeNull(MDashboardInfo),
    rating: types.maybeNull(MDashboardRating),
    courses: types.maybeNull(types.array(MDashboardCourseInfo)),
    tracks: types.maybeNull(types.array(MDashboardTrackInfo)),
  })
  .views((self) => ({
    get ratingInfo() {
      return self.data?.find((block) => block.type === DashboardElementTypes.RATING);
    },
    get coursesInfo() {
      return self.data?.find((block) => block.type === DashboardElementTypes.COURSES);
    },
    get tracksInfo() {
      return self.data?.find((block) => block.type === DashboardElementTypes.TRACKS);
    },
  }))
  .actions((self) => ({
    fetchDashboardInfo: flow(function* fetchDashboardInfo() {
      const { data, hasError }: TDashboardInfoResponse = yield dashboardApi.getDashboardInfo();
      if (!hasError && data) {
        self.data = cast(data);
      }
      const ratingPromise = self.ratingInfo ? dashboardApi.getDashboardRating() : Promise.resolve(null);
      const coursesPromise = self.coursesInfo ? dashboardApi.getDashboardCourses() : Promise.resolve(null);
      const tracksPromise = self.tracksInfo ? dashboardApi.getDashboardTracks() : Promise.resolve(null);

      const [ratingResponse, coursesResponse, tracksResponse] = yield Promise.all([
        ratingPromise,
        coursesPromise,
        tracksPromise,
      ]);

      if (ratingResponse && !ratingResponse.hasError && ratingResponse.data) {
        self.rating = cast(ratingResponse.data);
      }

      if (coursesResponse && !coursesResponse.hasError && coursesResponse.data) {
        self.courses = cast(coursesResponse.data);
      }

      if (tracksResponse && !tracksResponse.hasError && tracksResponse.data) {
        self.tracks = cast(tracksResponse.data);
      }
    }),
  }));

export default Dashboard;
