import React, { useMemo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Button } from 'components';
import { ReactComponent as Placeholder404 } from 'assets/images/404.svg';
import { ReactComponent as Placeholder404Mini } from 'assets/images/404mini.svg';
import { ReactComponent as Placeholder403 } from 'assets/images/403.svg';
import { ReactComponent as Placeholder403Mini } from 'assets/images/403mini.svg';
import { ReactComponent as NetworkErrorPlaceholder } from 'assets/images/networkErrorPlaceholder.svg';
import {
  Wrapper,
  Content,
  Title,
  ButtonWrapper,
  ImageWrapper,
  ImageWrapperMini,
  ButtonWrapperMini,
} from './ProblemPlaceholder.styles';
import { TProblemPlaceholderTypes } from './ProblemPlaceholderTypes';
import messages from './messages';

type TProblemPlaceholder = {
  type: TProblemPlaceholderTypes;
  onRefresh?: () => void;
};

const ProblemPlaceholder: React.FC<TProblemPlaceholder> = ({ type, onRefresh }) => {
  const { formatMessage } = useIntl();

  const onBack = () => {
    window.location.reload();
  };

  useEffect(() => {
    window.onpopstate = onBack;
  }, []);

  const data = useMemo(() => {
    switch (type) {
      case 'error403':
        return {
          image: <Placeholder403 />,
          imageMini: <Placeholder403Mini />,
          title: messages.error403Title,
          buttonTitle: messages.error403TitleButtonTitle,
        };
      case 'error404':
        return {
          image: <Placeholder404 />,
          imageMini: <Placeholder404Mini />,
          title: messages.error404Title,
          buttonTitle: messages.error404TitleButtonTitle,
        };
      case 'networkError':
      default:
        return {
          image: <NetworkErrorPlaceholder />,
          imageMini: <NetworkErrorPlaceholder />,
          title: messages.networkErrorTitle,
          buttonTitle: messages.networkErrorButtonTitle,
        };
    }
  }, [type]);

  return (
    <Wrapper>
      <Content>
        <ImageWrapper>{data?.image}</ImageWrapper>
        <ImageWrapperMini>{data?.imageMini}</ImageWrapperMini>
        <Title>{formatMessage(data.title)}</Title>
        <ButtonWrapper>
          <Button size="large" type="primary" onClick={onRefresh}>
            {formatMessage(data.buttonTitle)}
          </Button>
        </ButtonWrapper>
        <ButtonWrapperMini>
          <Button size="small" type="primary" onClick={onRefresh}>
            {formatMessage(data.buttonTitle)}
          </Button>
        </ButtonWrapperMini>
      </Content>
    </Wrapper>
  );
};

export default ProblemPlaceholder;
