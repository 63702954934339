import { types } from 'mobx-state-tree';

const MGroup = types.model({
  id: types.number,
  roles: types.array(types.number),
});

const MProfile = types.model({
  id: types.maybeNull(types.number),
  firstName: '',
  lastName: '',
  avatarUrl: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  phone: types.maybeNull(types.string),
  groups: types.maybeNull(types.array(MGroup)),
});

export default MProfile;
