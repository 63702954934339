import styled from 'styled-components';
import { Text, Title } from 'components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledTitle = styled(Title)`
  &&&& {
    margin-bottom: 16px;
    font-weight: 600;
  }
`;

export const StyledText = styled(Text)`
  &&&& {
    margin-bottom: 8px;
    line-height: 22px;
    text-align: center;
    color: ${({ theme: { palette } }) => palette.dark.main};
  }
`;
