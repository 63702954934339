import { observer } from 'mobx-react';
import React from 'react';
import { generatePath, Redirect, Route, RouteProps } from 'react-router-dom';
import { DomainStore } from 'store';
import { useAppNavigator } from 'utils';
import { RouteTemplates } from '_constants';

const PrivateRoute: React.FC<RouteProps> = (props) => {
  const { hasAuth } = DomainStore;
  const {
    params: { alias },
  } = useAppNavigator();
  return hasAuth ? <Route {...props} /> : <Redirect to={generatePath(RouteTemplates.authSignIn, { alias })} />;
};

export default observer(PrivateRoute);
