/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["self"] }] */
import { cast, flow, types } from 'mobx-state-tree';
import { myTeamApi } from 'api';
import { TManagerResponse } from 'types/myTeamApiTypes';
import { GroupUserStatus } from '_constants';
import MPaginationContext from './MPaginationContext';
import MManager from './MManager';

const MManagers = types
  .model({
    managers: types.maybeNull(types.array(MManager)),
    context: types.optional(MPaginationContext, {}),
    groupId: types.maybeNull(types.number),
    roleIds: types.array(types.number),
    isFetching: false,
  })
  .actions((self) => ({
    fetchManagers: flow(function* getManagers(groupId: number, roleIds?: number[]) {
      self.isFetching = true;
      const { data, hasError }: TManagerResponse = yield myTeamApi.getAttendees(
        groupId,
        1,
        GroupUserStatus.ALL,
        roleIds,
      );
      if (!!data && !hasError) {
        self.managers = cast(data.items);
        self.context = data.context;
        self.groupId = groupId;
        self.roleIds = cast(roleIds || []);
      }
      self.isFetching = false;
      return data;
    }),
    fetchMoreManagers: flow(function* fetchMoreManagers() {
      if (!self.groupId) return null;
      self.isFetching = true;
      const { data, hasError }: TManagerResponse = yield myTeamApi.getAttendees(
        self.groupId,
        self.context.page + 1,
        GroupUserStatus.ALL,
        self.roleIds,
      );
      if (!!data && !hasError) {
        self.managers = cast(self.managers?.concat(data.items));
        self.context = data.context;
      }
      self.isFetching = false;
      return data;
    }),
  }));

export default MManagers;
