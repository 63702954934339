/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["self"] }] */
import { types, cast } from 'mobx-state-tree';

const MyBookings = types
  .model({
    activeBookingPanelKeys: types.optional(types.maybeNull(types.array(types.string)), null),
  })
  .actions((self) => ({
    setActiveBookingPanelKeys: (keys: string[]) => {
      self.activeBookingPanelKeys = cast(keys);
    },
  }));
export default MyBookings;
