import {
  TBookingsResponse,
  TBookingResponse,
  TBookingStatusResponse,
  TBookingRegistrationsResponse,
  TBookingTypesResponse,
} from 'types/bookingApiTypes';
import api from './api';
import { TResponded } from '../types';

const getBookings = (includeAll: boolean): Promise<TBookingsResponse> =>
  api.privateRequest('get', `api/web/tlrocks/v1/booking`, {
    searchParams: {
      includeAll,
    },
  });

const getBookingTypes = (): Promise<TBookingTypesResponse> =>
  api.privateRequest('get', `api/web/tlrocks/v1/booking/types`, {});

const getBooking = (bookingId: string): Promise<TBookingResponse> =>
  api.privateRequest('get', `api/web/tlrocks/v1/booking/${bookingId}`, {});

const getBookingRegistrations = (
  bookingId: string,
  page: number,
  pageSize: number,
): Promise<TBookingRegistrationsResponse> =>
  api.privateRequest('get', `api/web/tlrocks/v1/booking/${bookingId}/registrations`, {
    searchParams: {
      skip: page * pageSize,
      take: pageSize,
    },
  });

const sendBooking = (bookingId: string): Promise<TBookingStatusResponse> =>
  api.privateRequest('post', `api/web/tlrocks/v1/booking/${bookingId}`, {});

const cancelBooking = (bookingId: string): Promise<TResponded<null>> =>
  api.privateRequest('delete', `api/web/tlrocks/v1/booking/${bookingId}`, {});

export default {
  getBookings,
  getBookingTypes,
  getBooking,
  sendBooking,
  cancelBooking,
  getBookingRegistrations,
};
