import React from 'react';
import { TChevronDirection } from '../constants';
import { ButtonStyled, ChevronIconStyled } from './InlineButton.styles';

interface IInlineButton {
  text: string;
  chevronDirection: TChevronDirection;
  size: number;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

const InlineButton: React.FC<IInlineButton> = ({ text, chevronDirection, size, onClick }) => (
  <ButtonStyled onClick={onClick} $chevronDirection={chevronDirection} $size={size}>
    <ChevronIconStyled width={24} height={24} /> {text}
  </ButtonStyled>
);

export default InlineButton;
