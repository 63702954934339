/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["self"] }] */
import { types } from 'mobx-state-tree';

export enum StageType {
  INFO = 'info',
  SURVEY = 'survey',
  TEST = 'test',
  TASK = 'task',
}

export const MStage = types
  .model({
    id: types.number,
    name: types.maybeNull(types.string),
    description: types.maybeNull(types.string),
    type: types.maybeNull(types.enumeration<StageType>('Type', Object.values(StageType))),
    showFinalMessage: types.boolean,
    finalMessageTitle: types.maybeNull(types.string),
    finalMessageText: types.maybeNull(types.string),
    catalogId: types.maybeNull(types.number),
    pollId: types.maybeNull(types.number),
    scormId: types.maybeNull(types.number),
    taskId: types.maybeNull(types.number),
    points: types.number,
    isClosed: types.boolean,
    isCompleted: types.boolean,
    pollButtonNameOverride: types.maybeNull(types.string),
  })
  .actions((self) => ({
    setIsCompleted: (isCompleted: boolean) => {
      self.isCompleted = isCompleted;
    },
  }));

export default MStage;
