import { types } from 'mobx-state-tree';

const MCurrentUserAnswers = types.model({
  screenId: types.number,
  questionId: types.number,
  optionId: types.maybeNull(types.number),
  text: types.maybeNull(types.string),
  starLevel: types.maybeNull(types.number),
  isSkipped: false,
});

export default MCurrentUserAnswers;
