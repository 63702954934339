/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["self"] }] */
import { myTeamApi } from 'api';
import { flow, types, getRoot } from 'mobx-state-tree';
import { TStore } from 'types';
import { TCourseDetailsResponse } from 'types/myTeamApiTypes';
import { CourseUserStatus } from '_constants';
import MCourseAttendees from './MCourseAttendees';
import MCourseDetails from './MCourseDetails';

const MCourse = types
  .model({
    courseId: types.identifierNumber,
    courseDetails: types.maybeNull(MCourseDetails),
    attendees: types.map(
      types.model({
        key: types.identifier,
        data: MCourseAttendees,
      }),
    ),
  })
  .views((self) => ({
    get myTeamUIStore() {
      return getRoot<TStore>(self).UIStore.myTeam;
    },
  }))
  .actions((self) => ({
    fetchCourseDetails: flow(function* fetchCourseDetails(courseId: number, groupId: number, trackId?: string) {
      const { data, hasError }: TCourseDetailsResponse = yield myTeamApi.courseApi.getCourseDetails(
        courseId,
        groupId,
        trackId,
      );
      if (data) {
        self.courseDetails = data;
      }
      return !hasError;
    }),
  }))
  .actions((self) => ({
    fetchCourseRequiredData: flow(function* fetchCourseRequiredData(
      courseId: number,
      groupId: number,
      trackId?: string,
    ) {
      self.myTeamUIStore.setIsFetching(true);
      const getAttendees = async (key: CourseUserStatus) => {
        self.attendees.put({ key, data: MCourseAttendees.create() });
        return self.attendees.get(key)?.data.fetchAttendees(courseId, groupId, key, trackId);
      };
      const responses: boolean[] = yield Promise.all([
        self.fetchCourseDetails(courseId, groupId, trackId),
        getAttendees(CourseUserStatus.ASSIGNED),
        getAttendees(CourseUserStatus.FINISHED),
        getAttendees(CourseUserStatus.NOT_STARTED),
        getAttendees(CourseUserStatus.STARTED),
      ]);
      self.myTeamUIStore.setIsFetching(false);
      return responses.every((r) => r);
    }),
  }));

export default MCourse;
