/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["self"] }] */
import { types, cast, flow, getRoot } from 'mobx-state-tree';
import { eventSettingsApi } from 'api';
import { MComponent } from 'models';
import { TComponentsResponse } from 'types/eventSettingsApiTypes';
import { AvailableComponentTypes, ComponentTypes } from '_constants';
import { TStore } from 'types';

const Components = types
  .model({
    data: types.array(MComponent),
  })
  .views((self) => ({
    get layoutUIStore() {
      return getRoot<TStore>(self).UIStore.layout;
    },
    get myCoursesComponent() {
      return self.data.find((component) => component.type === ComponentTypes.MY_COURSES) || null;
    },
    get myPollsComponent() {
      return self.data.find((component) => component.type === ComponentTypes.MY_POLLS) || null;
    },
    get gamificationComponent() {
      return self.data.find((component) => component.type === ComponentTypes.GAMIFICATION) || null;
    },
    get groupGamificationComponent() {
      return self.data.find((component) => component.type === ComponentTypes.GROUP_GAMIFICATION) || null;
    },
    get myTeamComponent() {
      return self.data.find((component) => component.type === ComponentTypes.MY_TEAM) || null;
    },
    get myBookingsComponent() {
      return self.data.find((component) => component.type === ComponentTypes.MY_BOOKINGS) || null;
    },
    get myTasksComponent() {
      return self.data.find((component) => component.type === ComponentTypes.MY_TASKS) || null;
    },
    get scheduleComponent() {
      return self.data.find((component) => component.type === ComponentTypes.SCHEDULE) || null;
    },
    get myLearningTracksComponent() {
      return self.data.find((component) => component.type === ComponentTypes.MY_LEARNING_TRACKS) || null;
    },
    get dashboardComponent() {
      return self.data.find((component) => component.type === ComponentTypes.DASHBOARD) || null;
    },
  }))
  .views((self) => ({
    get isMyCoursesAvailable() {
      return self.myCoursesComponent !== null;
    },
  }))
  .views((self) => ({
    get isMyPollsAvailable() {
      return self.myPollsComponent !== null;
    },
  }))
  .views((self) => ({
    get isGamificationAvailable() {
      return self.gamificationComponent !== null;
    },
  }))
  .views((self) => ({
    get isGroupGamificationAvailable() {
      return self.groupGamificationComponent !== null;
    },
  }))
  .views((self) => ({
    get isMyTeamAvailable() {
      return self.myTeamComponent !== null;
    },
  }))
  .views((self) => ({
    get isMyBookingsAvailable() {
      return self.myBookingsComponent !== null;
    },
  }))
  .views((self) => ({
    get isMyTasksAvailable() {
      return self.myTasksComponent !== null;
    },
    get isScheduleAvailable() {
      return self.scheduleComponent !== null;
    },
  }))
  .views((self) => ({
    get isMyLearningTracksAvailable() {
      return self.myLearningTracksComponent !== null;
    },
  }))
  .views((self) => ({
    get isDashboardAvailable() {
      return self.dashboardComponent !== null;
    },
  }))
  .actions((self) => ({
    fetchComponents: flow(function* fetchComponents() {
      self.layoutUIStore.setFetchingComponents(true);
      const { data, hasError }: TComponentsResponse = yield eventSettingsApi.getComponents();
      if (!hasError && data) {
        self.data = cast(
          data.components.filter((component) => {
            if (component?.type) {
              return AvailableComponentTypes.includes(component.type);
            }
            return false;
          }),
        );
      }
      self.layoutUIStore.setFetchingComponents(false);
    }),
  }));

export default Components;
