import { Dispatch, SetStateAction, useState, useRef, useEffect, useCallback } from 'react';

const useStateIfMounted = <S>(initialValue: S | (() => S)): [S, Dispatch<SetStateAction<S>>] => {
  const isComponentMounted = useRef(false);
  useEffect(() => {
    isComponentMounted.current = true;
    return () => {
      isComponentMounted.current = false;
    };
  }, []);

  const [state, setState] = useState(initialValue);

  const newSetState = useCallback((value: SetStateAction<S>) => {
    if (isComponentMounted.current) {
      setState(value);
    }
  }, []);

  return [state, newSetState];
};

export default useStateIfMounted;
