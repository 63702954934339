import { css } from 'styled-components';

export const scrollbar = css`
  scrollbar-width: thin;
  scrollbar-color: ${({ theme: { palette } }) => palette.scrollbar} transparent;

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 9px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme: { palette } }) => palette.scrollbar};
    border-radius: 18px;
    border: 2px solid ${({ theme: { palette } }) => palette.dark.dark_4};
  }
`;

export const ratio16x9 = css`
  &::before {
    content: '';
    display: block;
    padding-bottom: 56.25%;
  }
`;

export const mediaBackgroundColorTransition = css`
  transition: background-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
`;

export const fullSizedAbsolute = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
