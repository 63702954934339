import React from 'react';
import { useIntl } from 'react-intl';
import { ModalProps } from 'antd';
import RiveComponent from '@rive-app/react-canvas';
import { LearningTracksModalOptions } from 'types/completeModalLearningTracks';
import messages from './messages';
import { ModalContent, ModalText, ModalTitle, StyledModal, AnimationOverlay } from './FinishModal.styles';

interface IProps extends ModalProps {
  title?: string | null;
  text?: string | null;
  buttonText?: string | null;
  visible: boolean;
  onClose: () => void;
  onOk: () => void;
  option: LearningTracksModalOptions;
}

const MODAL_ANIMATION = {
  [LearningTracksModalOptions.COURSE]: '/animations/cup2.riv',
  [LearningTracksModalOptions.TRACK]: '/animations/cup1.riv',
};

const FinishModal: React.FC<IProps> = ({ visible, onClose, onOk, title, text, buttonText, option, ...rest }) => {
  const intl = useIntl();
  return (
    <StyledModal
      visible={visible}
      onClose={onClose}
      onOk={onOk}
      okText={buttonText || intl.formatMessage(messages.modalButton)}
      width="auto"
      {...rest}
    >
      <ModalContent>
        <AnimationOverlay>
          <RiveComponent src={MODAL_ANIMATION[option]} />
        </AnimationOverlay>
        <ModalTitle level={2}>{title || intl.formatMessage(messages.modalTitle)}</ModalTitle>
        {text && <ModalText>{text}</ModalText>}
      </ModalContent>
    </StyledModal>
  );
};

export default FinishModal;
