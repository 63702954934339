import styled from 'styled-components';
import { Input } from 'antd';

export const StyledPassword = styled(Input.Password)`
  &&& {
    & .ant-input-suffix {
      position: absolute;
      right: 16px;
      height: 100%;
    }
    &.ant-input-password {
      overflow: hidden;
      height: 48px;
      padding: 0;
      border: solid 1px ${({ theme }) => theme.palette.dark.dark_8};
      border-radius: 10px;
      font-family: 'Roboto', sans-serif;
      &.ant-input-affix-wrapper-focused {
        box-shadow: 0 0 0 2px ${({ theme: { palette } }) => palette.primary.main};
      }
      & .ant-input {
        color: ${({ theme }) => theme.palette.dark.main};
        font-weight: 400;
        font-size: 16px;
        transition: none;
        &::placeholder {
          color: ${({ theme }) => theme.palette.dark.dark_30};
          font-size: 16px;
          font-family: 'Roboto', sans-serif;
          letter-spacing: initial;
        }
        &:focus {
          box-shadow: 0 0 0 2px ${({ theme: { palette } }) => palette.primary.main};
          &::placeholder {
            color: ${({ theme }) => theme.palette.dark.dark_20};
          }
        }
      }
      & input {
        padding-right: 44px;
        padding-left: 16px;
      }
      & input[type='password'] {
        ${({ value }) =>
          value &&
          `font-family: Verdana, sans-serif;
          font-size: 24px;
          letter-spacing: -1px;`}
      }
      & .ant-input-password-icon {
        path {
          fill: ${({ theme }) => theme.palette.dark.dark_16};
        }
        :hover {
          path {
            fill: ${({ theme }) => theme.palette.dark.dark_40};
          }
        }
      }
    }
    &.ant-input-affix-wrapper-disabled {
      background-color: ${({ theme: { palette } }) => palette.dark.dark_4};
    }
  }
`;

export default StyledPassword;
