import styled from 'styled-components';
import { Modal } from 'components';
import { ReactComponent as Close } from 'assets/icons/close.svg';

export const StyledModal = styled(Modal)`
  &&&& {
    .ant-modal {
      padding-right: 45px;
      padding-left: 45px;
    }
    .ant-modal-content {
      overflow: initial;
      padding: 0;
    }
    .ant-modal-mask {
      background-color: ${({ theme: { palette } }) => palette.mask.dark};
    }
    .ant-modal-body {
      display: flex;
      padding: 0;
      max-height: none;
      margin-right: 0;
      overflow: hidden;
    }
    .ant-modal-close {
      top: 0;
      right: auto;
      left: 100%;
      .ant-modal-close-x {
        width: auto;
        height: auto;
        line-height: initial;
      }
    }
    .ant-image {
      width: 100%;

      img {
        width: auto;
        height: auto;
        max-width: calc(100vw - 122px);
        max-height: calc(100vh - 20px);
      }
    }
  }
`;

export const CloseIcon = styled(Close)``;
