import { types } from 'mobx-state-tree';

const MAttendee = types.model({
  id: types.number,
  firstName: types.string,
  lastName: types.string,
  thumbnail: types.maybeNull(types.string),
  finishedCoursesCount: types.number,
  assignedCoursesCount: types.number,
  // TODO: Убрать значения по умолчанию
  assignedTracksCount: types.optional(types.number, 0),
  finishedTracksCount: types.optional(types.number, 0),
});

export default MAttendee;
