import React from 'react';
import { useIntl } from 'react-intl';
import { TextStyled, CheckmarkIconStyled } from './CopyStatus.styles';
import messages from './messages';

export enum StatusesEnum {
  MAKE_COPY,
  COPIED,
}

interface ICopyStatus {
  status: StatusesEnum;
}

interface IStatusMessages {
  [StatusesEnum.MAKE_COPY]: string;
  [StatusesEnum.COPIED]: string;
}

const CopyStatus: React.FC<ICopyStatus> = ({ status }) => {
  const intl = useIntl();

  const statusMessages: IStatusMessages = {
    [StatusesEnum.MAKE_COPY]: intl.formatMessage(messages.makeCopy),
    [StatusesEnum.COPIED]: intl.formatMessage(messages.copied),
  };

  return (
    <>
      <CheckmarkIconStyled $visible={status === StatusesEnum.COPIED} />
      <TextStyled>{statusMessages[status]}</TextStyled>
    </>
  );
};

export default CopyStatus;
