import React from 'react';
import { useIntl } from 'react-intl';
import { Wrapper, Title, Checkmark, Content, Result } from './CompletedLabel.styles';
import messages from './messages';

interface ICompletedLabel {
  resultText: string | null;
  passedStageCount?: number;
  stageCount?: number;
}

const CompletedLabel: React.FC<ICompletedLabel> = ({ resultText, passedStageCount, stageCount }) => {
  const intl = useIntl();
  return (
    <Wrapper>
      <Content>
        <Title>{intl.formatMessage(messages.title)}</Title>
        <Result title={intl.formatMessage(messages.result, { resultText, passedStageCount, stageCount })} ellipsis>
          {intl.formatMessage(messages.result, { resultText, passedStageCount, stageCount })}
        </Result>
      </Content>
      <Checkmark />
    </Wrapper>
  );
};

export default CompletedLabel;
