export enum PollStatus {
  PENDING = 'pending',
  ACTIVE = 'active',
  FINISHED = 'finished',
}

export enum PollActivationMode {
  PENDING = 'auto',
  ACTIVE = 'manual',
  FINISHED = 'bySchedule',
}

export enum PollShowRightAnswerType {
  ONLY_ATTENDEE = 'onlyAttendee',
  ATTENDEE_WITH_TOTAL = 'attendeeWithTotal',
}

export enum PollValueType {
  // NOTE: в рамках talent.rocks могут быть только COMMON, TEST_WITH_ANSWERS, TEST_WITHOUT_ANSWERS
  COMMON = 'common',
  SESSION_RATING = 'sessionRating',
  TEST_WITH_ANSWERS = 'testWithAnswers',
  TEST_WITHOUT_ANSWERS = 'testWithoutAnswers',
}

export enum QuestionType {
  FREE_TEXT = 'freetext',
  SINGLE_SELECT = 'singleselect',
  MULTISELECT = 'multiselect',
  STARS = 'stars',
}

export enum QuestionCheckboxType {
  IMAGE = 'image',
  TEXT = 'text',
  IMAGE_WITH_TEXT = 'imageWithText',
}
