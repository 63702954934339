/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["self"] }] */
import { types, cast, flow } from 'mobx-state-tree';
import { MLearningTrackInfo } from 'models/LearningTracks';
import { LearningTracksStatuses } from 'models/LearningTracks/constants';
import { TLearningTrackInfo } from 'types';
import { TLearningTracksInfoResponse } from 'types/learningTracksApiTypes';
import learningTracksApi from 'api/learningTracksApi';

const compareTracksByOpen = () => (a: TLearningTrackInfo, b: TLearningTrackInfo) => {
  if (!a.openDate || !b.openDate || a.openDate === b.openDate) {
    return 0;
  }
  return a.openDate > b.openDate ? -1 : 1;
};
const compareTracksByEndCourse = () => (a: TLearningTrackInfo, b: TLearningTrackInfo) => {
  if (!a.completionDate || !b.completionDate || a.completionDate === b.completionDate) {
    return 0;
  }
  return a.completionDate > b.completionDate ? -1 : 1;
};

const LearningTracks = types
  .model({
    tracksInfo: types.array(MLearningTrackInfo),
  })
  .views((self) => ({
    get inProgress() {
      return self.tracksInfo
        .filter((learningTrack) => learningTrack.status === LearningTracksStatuses.IN_PROGRESS)
        .sort(compareTracksByOpen());
    },
    get available() {
      return self.tracksInfo.filter((learningTrack) => learningTrack.status === LearningTracksStatuses.NOT_STARTED);
    },
    get completed() {
      return self.tracksInfo
        .filter((learningTrack) => learningTrack.status === LearningTracksStatuses.COMPLETED)
        .sort(compareTracksByEndCourse());
    },
    get allCount() {
      return self.tracksInfo.length;
    },
    isNewTrack(trackId: string) {
      return self.tracksInfo.filter((learningTrack) => learningTrack.id === trackId)[0].openDate === null;
    },
  }))
  .actions((self) => ({
    fetchLearningTracks: flow(function* fetchLearningTracks() {
      const { data, hasError }: TLearningTracksInfoResponse = yield learningTracksApi.getLearningTracksInfo();
      if (!hasError && data) {
        self.tracksInfo = cast(data);
      }
    }),
  }));

export default LearningTracks;
