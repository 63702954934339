import { TResponded } from 'types';
import {
  TPollResponse,
  TPollsResponse,
  TPostAnswerProps,
  TPostAnswersProps,
  TStatisticsResponse,
} from 'types/pollsApiTypes';
import api, { setLearningTracksHeaders } from './api';

const getPolls = (): Promise<TPollsResponse> => api.privateRequest('get', `api/web/tlrocks/v1/polls`);

const openPoll = (pollId: number, trackId?: string): Promise<TPollResponse> =>
  api.privateRequest('post', `api/web/tlrocks/v1/polls/${pollId}/open`, {
    headers: { ...setLearningTracksHeaders(trackId) },
  });

const finish = (
  { courseId, stageId, pollId, answers }: TPostAnswersProps,
  trackId?: string,
): Promise<TResponded<null>> =>
  api.privateRequest('post', `api/web/tlrocks/courses/${courseId}/stages/${stageId}/finish`, {
    json: {
      stageId,
      pollId,
      answers,
    },
    headers: { ...setLearningTracksHeaders(trackId) },
  });

const postAnswer = ({ answer: body, pollId }: TPostAnswerProps, trackId?: string): Promise<TResponded<null>> =>
  api.privateRequest('post', `api/web/tlrocks/v1/polls/${pollId}/answer`, {
    json: body,
    headers: { ...setLearningTracksHeaders(trackId) },
  });

const finishPollStageWithQuickResult = (
  pollId: number,
  trackId?: string,
  courseId?: number,
  stageId?: number,
): Promise<TResponded<null>> =>
  api.privateRequest('post', `api/web/tlrocks/v1/polls/${pollId}/complete`, {
    headers: { ...setLearningTracksHeaders(trackId) },
    searchParams:
      courseId && stageId
        ? {
            courseId,
            courseStageId: stageId,
          }
        : undefined,
  });

const getStatistics = (pollId: number, trackId?: string): Promise<TStatisticsResponse> =>
  api.privateRequest('get', `api/web/tlrocks/v1/polls/${pollId}/statistics`, {
    headers: { ...setLearningTracksHeaders(trackId) },
  });

const sendPollAnswers = ({ pollId, answers }: TPostAnswersProps, trackId?: string): Promise<TPollResponse> =>
  api.privateRequest('post', `api/web/tlrocks/v1/polls/${pollId}/answers`, {
    json: answers,
    headers: { ...setLearningTracksHeaders(trackId) },
  });

const restartPoll = (pollId: number, trackId?: string): Promise<TResponded<null>> =>
  api.privateRequest('post', `api/web/tlrocks/v1/polls/${pollId}/restart`, {
    headers: { ...setLearningTracksHeaders(trackId) },
  });

export default {
  getPolls,
  openPoll,
  finish,
  getStatistics,
  sendPollAnswers,
  postAnswer,
  finishPollStageWithQuickResult,
  restartPoll,
};
