import styled from 'styled-components';
import { Typography } from 'antd';
import { Grid } from '_constants';

export const StyledTitle = styled(Typography.Title)`
  &&& {
    h1& {
      margin: 0;
      color: ${({ theme }) => theme.palette.dark.main};
      font-weight: 700;
      font-size: 32px;
      font-family: 'Montserrat', sans-serif;
      line-height: 40px;
      @media (max-width: ${Grid.MD}px) {
        font-size: 18px;
        line-height: 24px;
      }
    }
    h2& {
      margin: 0;
      color: ${({ theme }) => theme.palette.dark.main};
      font-weight: 700;
      font-size: 24px;
      font-family: 'Montserrat', sans-serif;
      line-height: 30px;
      @media (max-width: ${Grid.MD}px) {
        font-size: 18px;
        line-height: 25px;
      }
    }
    h3& {
      margin: 0;
      color: ${({ theme }) => theme.palette.dark.dark_40};
      font-weight: 400;
      font-size: 16px;
      font-family: 'Roboto', sans-serif;
      line-height: 24px;
    }
    h5& {
      margin: 0;
      color: ${({ theme }) => theme.palette.dark.main};
      font-weight: 400;
      font-size: 14px;
      font-family: 'Roboto', sans-serif;
      line-height: 20px;
    }
  }
`;

export default StyledTitle;
