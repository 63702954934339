import { types } from 'mobx-state-tree';
import { CatalogElementTypes } from './constants';
import Image from './MImage';
import Video from './MVideo';

const MCatalogMedia = types
  .model({
    id: types.identifierNumber,
    type: types.enumeration('Element type', [CatalogElementTypes.AUDIO, CatalogElementTypes.VIDEO]),
    order: types.number,
    catalogId: types.maybe(types.number),
    name: types.maybeNull(types.string),
    fullLogoImage: types.maybeNull(Image),
    fullLogoThumbnail: types.maybeNull(Image),
    video: Video,
  })
  .named('CatalogMedia');

export default MCatalogMedia;
