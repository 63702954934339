import { Translation } from '@vime/react';

const translations: Record<string, Translation> = {
  ru: {
    play: 'Смотреть',
    pause: 'Пауза',
    playback: 'Playback',
    scrubber: 'Scrubber',
    scrubberLabel: '{currentTime} of {duration}',
    played: 'Played',
    duration: 'Duration',
    buffered: 'Buffered',
    close: 'Close',
    currentTime: 'Current time',
    live: 'LIVE',
    volume: 'Громкость',
    mute: 'Отключить звук',
    unmute: 'Включить звук',
    audio: 'Аудио',
    default: 'По умолчанию',
    captions: 'Captions',
    subtitlesOrCc: 'Субтитры',
    enableCaptions: 'Enable subtitles/captions',
    disableCaptions: 'Disable subtitles/captions',
    auto: 'Автонастройка',
    fullscreen: 'Fullscreen',
    enterFullscreen: 'Во весь экран',
    exitFullscreen: 'Выход из полноэкранного режима',
    settings: 'Настройки',
    seekForward: 'Seek forward',
    seekBackward: 'Seek backward',
    seekTotal: 'Seek total',
    normal: 'Обычная',
    none: 'Отсутствуют',
    playbackRate: 'Скорость воспроизведения',
    playbackQuality: 'Качество',
    loop: 'Loop',
    disabled: 'Disabled',
    off: 'Off',
    enabled: 'Enabled',
    pip: 'Picture-in-Picture',
    enterPiP: 'Мини-проигрыватель',
    exitPiP: 'Развернуть',
  },
  en: {
    play: 'Play',
    pause: 'Pause',
    playback: 'Playback',
    scrubber: 'Scrubber',
    scrubberLabel: '{currentTime} of {duration}',
    played: 'Played',
    duration: 'Duration',
    buffered: 'Buffered',
    close: 'Close',
    currentTime: 'Current time',
    live: 'LIVE',
    volume: 'Volume',
    mute: 'Mute',
    unmute: 'Unmute',
    audio: 'Audio',
    default: 'Default',
    captions: 'Captions',
    subtitlesOrCc: 'Subtitles/CC',
    enableCaptions: 'Enable subtitles/captions',
    disableCaptions: 'Disable subtitles/captions',
    auto: 'Auto',
    fullscreen: 'Fullscreen',
    enterFullscreen: 'Enter fullscreen',
    exitFullscreen: 'Exit fullscreen',
    settings: 'Settings',
    seekForward: 'Seek forward',
    seekBackward: 'Seek backward',
    seekTotal: 'Seek total',
    normal: 'Normal',
    none: 'None',
    playbackRate: 'Playback Rate',
    playbackQuality: 'Playback Quality',
    loop: 'Loop',
    disabled: 'Disabled',
    off: 'Off',
    enabled: 'Enabled',
    pip: 'Picture-in-Picture',
    enterPiP: 'Miniplayer',
    exitPiP: 'Expand',
  },
};

export default translations;
