/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["self"] }] */
import { onSnapshot, types, applySnapshot, SnapshotIn, getSnapshot } from 'mobx-state-tree';
import { setAnalyticsUserId, setAnalyticsUserProperties } from 'utils/analytics';
import { getObjectFromLocalStorage } from 'utils';
import { GamificationTypes } from 'types/gamificationTypes';
import Auth from './Auth';
import Courses from './Courses';
import Scorm from './Scorm';
import Catalog from './Catalog';
import Course from './Course';
import Poll from './Poll';
import Components from './Components';
import EventSettings from './EventSettings';
import Profile from './Profile';
import Gamification from './Gamification';
import MyTeam from './MyTeam';
import Registration from './Registration';
import Feeds from './Feeds';
import MyBookings from './MyBookings';
import LearningTracks from './LearningTracks';
import LearningTrack from './LearningTrack';
import MyTasks from './MyTasks';
import Dashboard from './Dashboard';

const DomainStore = types
  .model({
    auth: types.optional(Auth, {}),
    registration: types.optional(Registration, {}),
    course: types.optional(Course, {}),
    courses: types.optional(Courses, {}),
    scorm: types.optional(Scorm, {}),
    catalog: types.optional(Catalog, {}),
    poll: types.optional(Poll, {}),
    components: types.optional(Components, {}),
    eventSettings: types.optional(EventSettings, {}),
    profile: types.optional(Profile, {}),
    gamification: types.optional(Gamification, { gamificationType: GamificationTypes.PERSONAL }),
    groupGamification: types.optional(Gamification, { gamificationType: GamificationTypes.GROUP }),
    myTeam: types.optional(MyTeam, {}),
    feeds: types.optional(Feeds, {}),
    myBookings: types.optional(MyBookings, {}),
    learningTracks: types.optional(LearningTracks, {}),
    learningTrack: types.optional(LearningTrack, {}),
    myTasks: types.optional(MyTasks, {}),
    dashboard: types.optional(Dashboard, {}),
  })
  .views((self) => ({
    get hasAuth() {
      return Boolean(self.auth.token.length);
    },
  }))
  .actions((self) => ({
    removeStorageSnapshot: () => {
      const { eventPublicId } = self.eventSettings.eventInfo;
      if (eventPublicId) {
        localStorage.removeItem(eventPublicId);
      }
    },
    applyStorageSnapshot: () => {
      try {
        const domainSnapshot = getObjectFromLocalStorage<SnapshotIn<typeof DomainStore>>(
          self.eventSettings.eventInfo.eventPublicId,
        );
        if (!domainSnapshot) return;
        domainSnapshot.eventSettings = getSnapshot(self.eventSettings);
        // NOTE: Если схема снепшота соответствует текущему стору, то применяем, иначе пробуем применить сторы по отдельности
        if (DomainStore.is(domainSnapshot)) {
          applySnapshot(self, domainSnapshot);
        } else {
          Object.entries(DomainStore.properties).forEach(([key, store]) => {
            if (store.is(domainSnapshot[key])) {
              applySnapshot(self[key as keyof typeof self], domainSnapshot[key]);
            }
          });
        }
      } catch (e) {
        console.error(e);
      }
    },
  }))
  .actions((self) => ({
    clearAuthData: () => {
      self.removeStorageSnapshot();
      setAnalyticsUserId(null);
      setAnalyticsUserProperties({
        authorized_user: 'no',
      });
      applySnapshot(self, { eventSettings: getSnapshot(self.eventSettings) });
    },
    afterCreate: () => {
      onSnapshot(self, (snapshot) => {
        const { eventPublicId } = self.eventSettings.eventInfo;
        if (!eventPublicId || !self.auth.token) return;
        localStorage.setItem(eventPublicId, JSON.stringify(snapshot));
      });
    },
  }));

export default DomainStore;
