import React from 'react';
import { TextProps } from 'antd/lib/typography/Text';
import { StyledText } from './Text.styles';

type Props = {
  className?: string;
};

const Text: React.FC<TextProps & Props> = (props) => {
  return <StyledText {...props} />;
};

export default Text;
