import React, { Ref } from 'react';
import { usePlayerContext } from '@vime/react';
import { ReactComponent as PlayIcon } from 'assets/icons/play.svg';
import PiPIcon from 'assets/icons/equalizer.svg';
import { CustomPlaybackControlWrapper } from './CustomPlaybackControl.styles';

interface IProps {
  playerRef?: Ref<HTMLVmPlayerElement>;
}

const CustomPlaybackIcon: React.FC<IProps> = ({ playerRef }) => {
  const ref = playerRef as React.RefObject<HTMLVmPlayerElement>;
  const [isPiPActive] = usePlayerContext(ref, 'isPiPActive', false);
  const [paused] = usePlayerContext(ref, 'paused', false);

  const getIcon = () => {
    if (isPiPActive) {
      return <img src={PiPIcon} alt="" width="40px" />;
    }
    if (paused) {
      return <PlayIcon width="100" height="100" />;
    }
    return null;
  };

  return <CustomPlaybackControlWrapper>{getIcon()}</CustomPlaybackControlWrapper>;
};

export default CustomPlaybackIcon;
