import styled from 'styled-components';
import { Typography } from 'antd';
import { Tooltip } from 'components';
import { Grid } from '_constants';

const Title = styled(Typography.Paragraph)`
  color: ${({ theme }) => theme.palette.dark.main};
  font-weight: 500;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  line-height: 24px;
  word-break: break-word;
  transition: color 0.4s;
  &&& {
    margin-bottom: 0;
  }
`;

interface IWrapper {
  iconColor?: { main?: string; hover?: string };
}
export const Wrapper = styled.div<IWrapper>`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0 19px 0;
  border-bottom: ${({ theme }) => theme.palette.dark.dark_8} solid 1px;
  cursor: pointer;
  @media (max-width: ${Grid.MD}px) {
    gap: 0;
  }
  .ant-typography-single-line {
    @media (max-width: ${Grid.MD}px) {
      white-space: normal;
    }
  }

  ${Title} {
    margin-bottom: 0;
  }

  svg {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
    path {
      fill: ${({ iconColor, theme }) => iconColor?.main || theme.palette.dark.dark_20};
      transition: fill 0.4s;
    }
  }

  &:hover {
    svg {
      path {
        fill: ${({ iconColor, theme }) => iconColor?.hover || theme.palette.primary.main};
      }
    }
  }
`;

export const RowTooltip = styled(Tooltip)`
  .ant-tooltip-inner {
    width: 320px;
  }
`;
export default Title;
