/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["self"] }] */
import { Instance, types } from 'mobx-state-tree';
import { QuestionType } from './constants';
import MAnswer from './MAnswer';
import MQuestion from './MQuestion';

const MScreen = types
  .model({
    id: types.number,
    questions: types.array(MQuestion),
    pollId: types.number,
    title: types.maybeNull(types.string),
    canHaveMultipleQuestions: types.boolean,
    rate: types.maybeNull(types.number),
    order: types.number,
    createdDate: types.string,
    updatedDate: types.string,
  })
  .views((self) => ({
    get questionsForResult() {
      return self.questions.filter(({ type }) => type !== QuestionType.FREE_TEXT);
    },
    getUserScore(answers: Instance<typeof MAnswer>[], isQuestionRandomSetEnabled: boolean | null) {
      if (isQuestionRandomSetEnabled && self.rate) {
        const allCorrect = self.questions.every((question) => question.getAnswerInfo(answers)?.isCorrect ?? true);
        return allCorrect ? self.rate : 0;
      }
      return self.questions.reduce((acc, { getUserScore }) => acc + Number(getUserScore(answers)), 0);
    },
    getMaxScore(isQuestionRandomSetEnabled: boolean | null) {
      if (isQuestionRandomSetEnabled && self.rate) {
        return self.rate;
      }
      return self.questions.reduce((acc, { maxScore }) => acc + maxScore, 0);
    },
    get hasMainToastResult() {
      return !self.canHaveMultipleQuestions && self.questions.every(({ isSelectType }) => isSelectType);
    },
    get hasQuickResult() {
      return self.questions.some(({ isSelectType }) => isSelectType);
    },
  }));

export default MScreen;
