import React from 'react';
import EmptyImage from 'assets/images/empty.svg';
import { useIntl } from 'react-intl';
import { PlaceholderImage, PlaceholderTitle, Content, Text, Wrapper } from './EmptyPlaceholder.styles';
import messages from './messages';

interface Props {
  title?: string;
  showDescription?: boolean;
}

const EmptyPlaceholder: React.FC<Props> = ({ title, showDescription, children }) => {
  const intl = useIntl();
  return (
    <Wrapper>
      <Content>
        <PlaceholderImage src={EmptyImage} preview={false} />
        <PlaceholderTitle level={2}>{title || intl.formatMessage(messages.title)}</PlaceholderTitle>
        {(showDescription || children) && <Text level={3}>{children || intl.formatMessage(messages.text)}</Text>}
      </Content>
    </Wrapper>
  );
};

export default EmptyPlaceholder;
