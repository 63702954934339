import styled from 'styled-components';
import { Form } from 'antd';

export const StyledFormItem = styled(Form.Item)`
  &&& {
    &.ant-form-item-has-error .ant-input,
    &.ant-form-item-has-error .ant-input:focus,
    &.ant-form-item-has-error .ant-input-affix-wrapper,
    &.ant-form-item-has-error .ant-input-affix-wrapper:focus,
    &.ant-form-item-has-error .ant-input-focused,
    &.ant-form-item-has-error .ant-input-affix-wrapper-focused,
    &.ant-form-item-has-error .ant-input:hover,
    &.ant-form-item-has-error .ant-input-affix-wrapper:hover {
      box-shadow: none;
      border-color: ${({ theme }) => theme.palette.error.primary};
    }
    .ant-form-item-explain {
      position: absolute;
      top: 100%;
      min-height: initial;
      padding-top: 6px;
      font-family: 'Roboto', sans-serif;
      font-size: 12px;
      line-height: 16px;
    }
    .ant-form-item-explain.ant-form-item-explain-error {
      color: ${({ theme }) => theme.palette.error.primary};
      margin-top: 0;
    }
  }
`;

export default StyledFormItem;
