import { useEffect } from 'react';
import store from 'store';
import { TBreadcrumbItem } from 'types';

interface IItems {
  items: TBreadcrumbItem[];
  onBackLocation?: string;
}

type Props = IItems | false;

const useBreadcrumb = (props: Props): void => {
  const { showBreadcrumb, hideBreadcrumb } = store.UIStore.layout;
  const { items = null, onBackLocation = null } = typeof props === 'object' ? props : {};

  useEffect(() => {
    if (items?.length) {
      showBreadcrumb(items, onBackLocation);
    } else if (props === false) {
      hideBreadcrumb();
    }

    return () => {
      hideBreadcrumb();
    };
  }, [hideBreadcrumb, items, onBackLocation, props, showBreadcrumb]);
};

export default useBreadcrumb;
