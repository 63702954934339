/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["self"] }] */
import { cast, flow, types } from 'mobx-state-tree';
import { myTeamApi } from 'api';
import { TActivityAttendeesResponse } from 'types/myTeamApiTypes';
import { ActivityRange } from '_constants';
import MActivityAttendee from './MActivityAttendee';
import MPaginationContext from './MPaginationContext';

const MGroupActivityAttendees = types
  .model({
    attendees: types.maybeNull(types.array(MActivityAttendee)),
    range: types.optional(types.enumeration<ActivityRange>('Rage', Object.values(ActivityRange)), ActivityRange.WEEK),
    isActive: false,
    context: types.optional(MPaginationContext, {}),
    groupId: types.maybeNull(types.number),
    isFetching: false,
  })
  .actions((self) => ({
    fetchAttendees: flow(function* getAttendees(range: ActivityRange, isActive: boolean, groupId?: number) {
      self.isFetching = true;
      const { data, hasError }: TActivityAttendeesResponse = yield groupId
        ? myTeamApi.groupApi.getGroupActivityAttendees(groupId, range, isActive)
        : myTeamApi.getAllActivityAttendees(range, isActive);
      if (!!data && !hasError) {
        self.attendees = cast(data.items);
        self.context = data.context;
        self.range = range;
        self.isActive = isActive;
        self.groupId = groupId || null;
      }
      self.isFetching = false;
      return data;
    }),
    fetchMoreAttendees: flow(function* getMoreAttendees() {
      self.isFetching = true;
      const { data, hasError }: TActivityAttendeesResponse = yield self.groupId
        ? myTeamApi.groupApi.getGroupActivityAttendees(self.groupId, self.range, self.isActive, self.context.page + 1)
        : myTeamApi.getAllActivityAttendees(self.range, self.isActive, self.context.page + 1);
      if (!!data && !hasError) {
        self.attendees = cast(self.attendees?.concat(data.items));
        self.context = data.context;
      }
      self.isFetching = false;
    }),
  }));

export default MGroupActivityAttendees;
