import styled, { css } from 'styled-components';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as SearchIconBase } from 'assets/icons/search-short.svg';
import Input from '../Input';

export const ClearIcon = styled(CloseIcon)`
  box-sizing: border-box;
  width: 16px;
  color: ${({ theme }) => theme.palette.dark.dark_20};
  cursor: pointer;
  path {
    fill: currentColor;
    stroke: currentColor;
    stroke-width: 1px;
  }
  &:hover {
    color: ${({ theme }) => theme.palette.dark.dark_40};
  }
`;

export const SearchIcon = styled(SearchIconBase)`
  color: ${({ theme }) => theme.palette.dark.dark_20};
  width: 16px;
  height: 16px;
`;

export const StyledInput = styled(Input)`
  &&& {
    ${({ suffix }) =>
      suffix &&
      css`
         {
          height: 42px;
          padding: 12px 14px 12px 16px;
          border: solid 1px ${({ theme }) => theme.palette.dark.dark_8};
          color: ${({ theme }) => theme.palette.dark.main};
          font-weight: 400;
          font-size: 16px;
          border-radius: 10px;
          font-family: 'Roboto', sans-serif;
          &[disabled] {
            background-color: ${({ theme: { palette } }) => palette.dark.dark_4};
          }
          & .ant-input::placeholder {
            color: ${({ theme }) => theme.palette.dark.dark_24};
          }
          &.ant-input-affix-wrapper-focused {
            box-shadow: 0 0 0 2px ${({ theme: { palette } }) => palette.primary.main};
            & .ant-input::placeholder {
              color: ${({ theme }) => theme.palette.dark.dark_20};
            }
          }
          &:hover {
            border-color: ${({ theme }) => theme.palette.dark.dark_12};
          }
        }
      `}
  }
`;
