import React from 'react';
import { useIntl } from 'react-intl';
import Progress from 'components/Progress';
import { Wrapper, Title, Header, Percent } from './ProgressStage.styles';
import messages from './messages';

export interface IProgressStage {
  percent: number;
  title?: string | null;
  percentVisibleText?: number;
}

const ProgressStage: React.FC<IProgressStage> = ({ percent, title, percentVisibleText, ...rest }) => {
  const { formatMessage } = useIntl();
  return (
    <Wrapper {...rest}>
      <Header>
        <Title title={title || formatMessage(messages.title)} ellipsis>
          {title || formatMessage(messages.title)}
        </Title>
        <Percent>{percentVisibleText ?? percent}%</Percent>
      </Header>
      <Progress percent={percent} />
    </Wrapper>
  );
};

export default ProgressStage;
