import { defineMessages } from 'react-intl';

export default defineMessages({
  modalTitle: {
    id: 'components.learningTracks.finishModal.title',
    defaultMessage: 'Поздравляем!',
  },
  modalButton: {
    id: 'components.learningTracks.finishModal.button',
    defaultMessage: 'К следующему шагу',
  },
});
