import styled from 'styled-components';
import Button from '../../Button';

export const ButtonsGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const NotificationButton = styled(Button)`
  &&& {
    &.ant-btn {
      width: calc(50% - 6px);
      height: 36px;
      font-size: 14px;
      margin-top: 16px;
      line-height: 22px;
      text-transform: capitalize;
    }
  }
`;

export const CancelBtn = styled(NotificationButton)`
  &&& {
    &.ant-btn {
      background-color: ${({ theme: { palette } }) => palette.white.primary};
      color: ${({ theme: { palette } }) => palette.black.primary};

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 8px;
        background-color: ${({ theme: { palette } }) => palette.black.primary};
        filter: opacity(0.06);
      }

      &:hover::after {
        filter: opacity(0.1);
      }
      &:focus::after {
        filter: opacity(0.14);
      }
      &:active::after {
        filter: opacity(0.16);
      }
    }
  }
`;
