const openNewTab = (url: string): Promise<Window | null> =>
  new Promise((resolve, reject) => {
    try {
      resolve(window.open(url));
    } catch (e) {
      console.error(e);
      reject(new Error('window.open() exception'));
    }
  });

export default openNewTab;
