import { TCourseInfo, TDashboardCourseInfo } from 'types';
import { CourseStatuses } from 'models';
import { DashboardCourseTrackStatuses } from 'models/MDashboard';

export const isNewCourse = (course: TCourseInfo | TDashboardCourseInfo): boolean =>
  (course.status === CourseStatuses.NOT_STARTED || course.status === DashboardCourseTrackStatuses.NOT_STARTED) &&
  !course.openDate;

export const newCoursesCount = (courses: TCourseInfo[]): number =>
  courses.reduce((acc, course) => (isNewCourse(course) ? acc + 1 : acc), 0);
