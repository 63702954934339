import { types } from 'mobx-state-tree';

const MCourseDetails = types.model({
  id: types.number,
  name: types.string,
  coverThumbnailUrl: types.string,
  assignedNumber: types.number,
  notStartedNumber: types.number,
  inProgressNumber: types.number,
  finishedNumber: types.number,
  averagePoints: types.number,
  maxPoints: types.number,
});

export default MCourseDetails;
