import React, { FC } from 'react';
import { UIStore } from 'store';
import { useIntl } from 'react-intl';
import {
  CheckboxStyled,
  CheckboxIconWrapper,
  CheckboxBlock,
  CheckboxText,
  CheckboxTextLink,
} from './LoginCheckbox.styles';
import messages from './messages';

export interface ILoginCheckbox {
  isChecked?: boolean;
  onClickCheckbox?: () => void;
  className?: string;
}

const LoginCheckbox: FC<ILoginCheckbox> = ({ isChecked, onClickCheckbox, className }) => {
  const { locale } = UIStore;
  const intl = useIntl();

  // TODO: хардкод для версии 0.1.0
  const urlPrivacyPolicy = `https://eventicious.com/privacy#${locale || 'ru'}`;

  return (
    <CheckboxBlock className={className}>
      <CheckboxIconWrapper>
        <CheckboxStyled checked={isChecked} onChange={onClickCheckbox} />
      </CheckboxIconWrapper>
      <CheckboxText>
        {intl.formatMessage(messages.loginCheckboxTextAgree)}
        <CheckboxTextLink href={urlPrivacyPolicy} target="_blank">
          {intl.formatMessage(messages.loginCheckboxTextLink)}
        </CheckboxTextLink>
      </CheckboxText>
    </CheckboxBlock>
  );
};

export default LoginCheckbox;
