import { TResponded } from 'types';
import { TLearningTracksInfoResponse, TLearningTrackResponse } from 'types/learningTracksApiTypes';
import api from './api';

const getLearningTracksInfo = (): Promise<TLearningTracksInfoResponse> =>
  api.privateRequest('get', 'api/web/tlrocks/v1/tracks');

const getLearningTrack = (trackId: string): Promise<TLearningTrackResponse> =>
  api.privateRequest('get', `api/web/tlrocks/v1/tracks/${trackId}`);

const learningTrackOpened = (trackId: string): Promise<TResponded<null>> =>
  api.privateRequest('post', `api/web/tlrocks/v1/tracks/${trackId}/open`);

const learningTrackStepOpened = (trackId: string, stepId: string): Promise<TResponded<null>> =>
  api.privateRequest('post', `api/web/tlrocks/v1/tracks/${trackId}/steps/${stepId}/open`);

export default {
  getLearningTracksInfo,
  getLearningTrack,
  learningTrackOpened,
  learningTrackStepOpened,
};
