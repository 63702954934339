export interface IDownloadFile {
  fileName: string;
  downloadSrc: string;
}

const downloadFile = async (file: IDownloadFile): Promise<boolean> => {
  const getBlob = async () => {
    try {
      const response = await fetch(file.downloadSrc);
      const blob = await response.blob();
      return blob;
    } catch (error) {
      return false;
    }
  };

  const blob = await getBlob();
  if (blob) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = file.fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    return true;
  }

  return false;
};

export default downloadFile;
