/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["self"] }] */
import { types } from 'mobx-state-tree';

const MTrackInfo = types.model({
  id: types.string,
  name: types.string,
  coverThumbnailUrl: types.maybeNull(types.string),
  assignedCount: types.number,
  completedCount: types.number,
});

export default MTrackInfo;
