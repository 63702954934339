const ComponentTypes = {
  MY_COURSES: 'myCourses',
  CATALOG: 'catalog',
  MY_POLLS: 'myPolls',
  MENU_POLL: 'poll',
  GAMIFICATION: 'gamification',
  GROUP_GAMIFICATION: 'groupGamification',
  MY_TEAM: 'myTeam',
  MY_BOOKINGS: 'myBookings',
  FRAME: 'frame',
  MY_TASKS: 'myTasks',
  SCHEDULE: 'schedule',
  MY_LEARNING_TRACKS: 'myLearningTracks',
  DASHBOARD: 'dashboard',
  // TODO: Добавить типы по мере добавление разделов меню
} as const;

export const AvailableComponentTypes: typeof ComponentTypes[keyof typeof ComponentTypes][] = [
  ComponentTypes.MY_COURSES,
  ComponentTypes.MY_POLLS,
  ComponentTypes.MENU_POLL,
  ComponentTypes.CATALOG,
  ComponentTypes.GAMIFICATION,
  ComponentTypes.GROUP_GAMIFICATION,
  ComponentTypes.MY_TEAM,
  ComponentTypes.MY_BOOKINGS,
  ComponentTypes.FRAME,
  ComponentTypes.MY_TASKS,
  ComponentTypes.SCHEDULE,
  ComponentTypes.MY_LEARNING_TRACKS,
  ComponentTypes.DASHBOARD,
];

export default ComponentTypes;
