import React from 'react';
import { RateProps } from 'antd';
import { RateResult } from 'components';
import { StyledRate } from './Rate.styles';

interface IRate extends RateProps {
  isResult?: boolean;
  decimalPlaces?: number;
}

const Rate: React.FC<IRate> = ({ isResult, decimalPlaces, ...restProps }) =>
  isResult && !restProps.disabled ? (
    <RateResult decimalPlaces={decimalPlaces} value={restProps.value} max={restProps.count} />
  ) : (
    <StyledRate {...restProps} />
  );

export default Rate;
