import styled from 'styled-components';
import { Typography } from 'antd';
import { Grid } from '_constants';

export const ImageOverlay = styled.div`
  position: relative;
  flex-shrink: 0;
  width: 130px;
  height: 72px;
  background-color: ${({ theme }) => theme.palette.dark.dark_4};
  @media (max-width: ${Grid.MD}px) {
    width: 70px;
  }
  svg {
    width: 40px;
  }
`;

export const Text = styled(Typography.Paragraph)`
  &&& {
    color: ${({ theme }) => theme.palette.dark.main};
    margin-left: 21px;
    @media (max-width: ${Grid.MD}px) {
      margin-left: 0;
    }
    &.ant-typography {
      width: 100%;
      margin-bottom: 0;
      font-weight: 500;
      font-size: 16px;
      font-family: 'Roboto', sans-serif;
      @media (max-width: ${Grid.MD}px) {
        font-size: 12px;
      }
    }
  }
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  gap: 20px;
  align-items: center;
  overflow: hidden;
  height: 72px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.dark.dark_8};
  @media (max-width: ${Grid.MD}px) {
    gap: 10px;
  }
  & > svg {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
    path {
      fill: ${({ theme }) => theme.palette.dark.dark_20};
      transition: fill 0.4s;
    }
  }

  &:hover {
    & > svg {
      path {
        fill: ${({ theme }) => theme.palette.primary.main};
      }
    }
  }
`;
