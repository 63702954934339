const SectionKey = {
  ALL: 'all',
  IN_PROGRESS: 'in-progress',
  AVAILABLE: 'available',
  COMPLETED: 'completed',
} as const;

export type TSectionKey = typeof SectionKey[keyof typeof SectionKey];

export default SectionKey;
