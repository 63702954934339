import styled from 'styled-components';
import { Image } from 'antd';
import { Title } from 'components';

export const PlaceholderImage = styled(Image)`
  height: 120px;
  margin-bottom: 16px;
`;

export const PlaceholderTitle = styled(Title)`
  &&&& {
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 8px;
    line-height: 32px;
    color: ${({ theme }) => theme.palette.dark.main};
    white-space: pre-line;
  }
`;

export const Text = styled(Title)`
  text-align: center;
  white-space: pre-line;
  color: ${({ theme }) => theme.palette.dark.dark_50};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 340px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: auto;
  padding-top: 166px;
  padding-bottom: 176px;
`;
