import React from 'react';
import { IDownloadFile, useDownloadHiddenLink } from 'utils/useDownloadHiddenLink';
import { TCatalogFile } from 'types';
import { ReactComponent as FileIcon } from 'assets/icons/file-accent.svg';
// import { FileSize } from './File.styles';
import TextRow from '../TextRow';

interface Props {
  file: TCatalogFile;
}

const File: React.FC<Props> = ({ file: { name, value } }) => {
  const downloader = useDownloadHiddenLink();

  const onClickHandler = (file: IDownloadFile) => {
    downloader.download(file);
  };

  if (!name || !value) {
    return null;
  }

  return (
    <>
      <TextRow
        content={
          <>
            {name}
            {/* TODO Добавить после добавления параметра filesSize */}
            {/* {fileSize && <FileSize>3.2 MB</FileSize>} */}
          </>
        }
        icon={<FileIcon />}
        onClick={() => {
          onClickHandler({ fileName: name, downloadSrc: value });
        }}
        tooltipContent={name}
      />
      {downloader.getComponent()}
    </>
  );
};

export default File;
