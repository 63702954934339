import { types } from 'mobx-state-tree';

const MManager = types.model({
  assignedCoursesCount: types.number,
  finishedCoursesCount: types.number,
  // TODO: Убрать значения по умолчанию
  assignedTracksCount: types.optional(types.number, 0),
  finishedTracksCount: types.optional(types.number, 0),
  firstName: types.string,
  lastName: types.string,
  thumbnail: types.maybeNull(types.string),
  id: types.number,
  eventId: types.number,
  roleIds: types.array(types.number),
});

export default MManager;
