/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["self"] }] */
import { types } from 'mobx-state-tree';

const MModal = types
  .model({
    visible: false,
  })
  .actions((self) => ({
    show: () => {
      self.visible = true;
    },
    hide: () => {
      self.visible = false;
    },
    toggle: () => {
      self.visible = !self.visible;
    },
  }));

export default MModal;
