import { Upload } from 'antd';
import styled from 'styled-components';

import { Text } from 'components';

export const Dragger = styled(Upload.Dragger)`
  &&& {
    order: 2;
    background: ${({ theme }) => theme.palette.dark.dark_2};
    border: 1px dashed ${({ theme }) => theme.palette.dark.dark_20};
    border-radius: 12px;
    margin-top: 8px;
    max-height: 140px;
    transition: max-height 0.5s ease, opacity 0.5s ease, margin-top 0.5s ease;
    overflow: hidden;
  }

  &&&.ant-upload-disabled {
    max-height: 0;
    margin-top: 0;
    opacity: 0;
  }
`;

export const FileDraggerOverlay = styled.div`
  span:last-child {
    display: flex;
    flex-direction: column;
  }
`;

export const FileDraggerTitle = styled(Text)`
  &&& {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: ${({ theme }) => theme.palette.dark.main};
    margin-bottom: 4px;
    display: block;
  }
`;

export const FileDraggerText = styled(Text)`
  &&& {
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: ${({ theme }) => theme.palette.dark.dark_50};
    display: block;
  }
`;

export const FileDraggerDrop = styled.div`
  ${Dragger} {
    &.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
      border-color: ${({ theme }) => theme.palette.dark.dark_20};
      background: ${({ theme }) => theme.palette.dark.dark_4};
    }
  }

  &.drag-over {
    ${Dragger} {
      background: ${({ theme }) => theme.palette.violet.violet_4};
      border-color: ${({ theme }) => theme.palette.violet.violet_68};

      svg {
        path {
          stroke: ${({ theme }) => theme.palette.violet.violet_40};
          stroke-opacity: 1;
        }
      }
    }
  }

  &.drag-error {
    ${Dragger} {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.98) 0%, rgba(255, 255, 255, 0.98) 100%),
        ${({ theme }) => theme.palette.error.primary};
      border-color: ${({ theme }) => theme.palette.error.primary};

      svg {
        path {
          stroke: ${({ theme }) => theme.palette.error.error_40};
          stroke-opacity: 1;
        }
      }

      &.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
        border-color: ${({ theme }) => theme.palette.error.primary};
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.94) 0%, rgba(255, 255, 255, 0.94) 100%),
          ${({ theme }) => theme.palette.error.primary};
      }
    }
  }

  &.drag-error.drag-over {
    ${Dragger} {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.94) 0%, rgba(255, 255, 255, 0.94) 100%),
        ${({ theme }) => theme.palette.error.primary};
      border-color: ${({ theme }) => theme.palette.error.primary};
    }
  }
`;
