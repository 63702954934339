import React, { useState } from 'react';
import { ReactComponent as CheckMarkRight } from 'assets/icons/checkmark-right.svg';
import { ReactComponent as CrossMark } from 'assets/icons/cross.svg';
import { ReactComponent as CheckMarkGrey } from 'assets/icons/checkmark-chosen.svg';
import { MediaModal, Image } from 'components';
import { CheckBoxOptions } from 'components/Polls/constants';

import {
  StyledBigImageCheckbox,
  ImageWrapper,
  CheckboxText,
  PercentOverlay,
  Close,
  ImagePreview,
  PercentColorBlock,
  OptionText,
  PercentColorBlockOverlay,
  ImageOverlay,
  CheckboxWrapper,
  ActionIconWrapper,
  CheckMark,
  FullScreenIcon,
} from './SmallImageCheckbox.styles';

const CheckboxIcon = {
  [CheckBoxOptions.RIGHT]: <CheckMarkRight />,
  [CheckBoxOptions.WRONG]: <CrossMark />,
  [CheckBoxOptions.SECONDARY_RIGHT]: <CheckMarkGrey />,
  [CheckBoxOptions.NORMAL]: <CheckMark />,
};

interface IProps {
  option?: string;
  value: string;
  img?: string;
  percent?: number | null;
  disable?: boolean;
  variant?: CheckBoxOptions;
}

const SmallImageCheckbox: React.FC<IProps> = ({ option, value, variant, img, disable, percent }) => {
  const [preview, setPreview] = useState<{ visible: boolean; src?: string } | null>(null);
  const onClosePreview = () => setPreview({ visible: false, src: '' });
  const actionIcon = CheckboxIcon[variant || CheckBoxOptions.NORMAL];

  const openImageModal = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    setPreview({ visible: true, src: img });
  };

  return (
    <CheckboxWrapper disabled={disable}>
      <StyledBigImageCheckbox disabled={disable} variant={variant} value={value} percent={typeof percent === 'number'}>
        {img && (
          <ImageWrapper>
            <Image src={img} alt="option image" preview={false} />
            <FullScreenIcon onClick={openImageModal} />
            <ImageOverlay />
          </ImageWrapper>
        )}
        {option ? (
          <CheckboxText level={3}>
            <OptionText>{option}</OptionText>
            {typeof percent === 'number' && <PercentColorBlock percent={percent} variant={variant} />}
          </CheckboxText>
        ) : (
          <PercentColorBlockOverlay>
            {typeof percent === 'number' && <PercentColorBlock percent={percent} variant={variant} />}
          </PercentColorBlockOverlay>
        )}
        {typeof percent === 'number' && <PercentOverlay>{percent}%</PercentOverlay>}
        <ActionIconWrapper>{actionIcon}</ActionIconWrapper>
      </StyledBigImageCheckbox>
      {preview && img && (
        <MediaModal visible={preview.visible} footer={false} closeIcon={<Close />} onCancel={onClosePreview}>
          <ImagePreview src={preview.src} alt="option image preview" preview={false} />
        </MediaModal>
      )}
    </CheckboxWrapper>
  );
};

export default SmallImageCheckbox;
