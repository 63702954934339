import styled from 'styled-components';

interface ICardBody {
  isResultCourse?: boolean;
}

export const CardBox = styled.div``;

export const CardFooter = styled.div`
  position: sticky;
  bottom: 8px;
  z-index: 10;
  width: 100%;
`;

export const CardBody = styled.div<ICardBody>`
  width: 100%;
  padding-bottom: 92px;
`;
