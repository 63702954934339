import { types } from 'mobx-state-tree';

const MResultScreenSettings = types.model({
  settings: types.maybeNull(
    types.model({
      showRates: types.boolean,
      rateRanges: types.maybeNull(
        types.array(
          types.model({
            guid: types.string,
            start: types.number,
            end: types.maybeNull(types.number),
          }),
        ),
      ),
      localization: types.maybeNull(
        types.model({
          comment: types.maybeNull(types.string),
          topic: types.maybeNull(types.string),
          rateRanges: types.maybeNull(
            types.array(
              types.model({
                rangeGuid: types.string,
                comment: types.maybeNull(types.string),
              }),
            ),
          ),
        }),
      ),
    }),
  ),
});

export default MResultScreenSettings;
