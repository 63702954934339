export const OpenScormType = {
  OPEN_NEW_SCORM: 'openNewScorm',
  OPEN_SECOND_SCORM: 'openSecondScorm',
} as const;

export type TOpenScormType = typeof OpenScormType[keyof typeof OpenScormType];

export const SCORM_MESSAGES = {
  BEFOREUNLOAD: 'SCORM_WEBVIEW_BEFOREUNLOAD',
  NEED_CREDENTIALS: 'SCORM_WEBVIEW_NEED_CREDENTIALS',
  SET_CREDENTIALS: 'SCORM_WEBVIEW_SET_CREDENTIALS',
} as const;
