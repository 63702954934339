import { types } from 'mobx-state-tree';

const MAttendee = types.model({
  attendeeId: types.maybeNull(types.number),
  installationId: types.maybeNull(types.string),
});

const MStatistic = types.model({
  questionId: types.number,
  totalAnswers: types.number,
  stars: types.maybeNull(
    types.model({
      avg: types.number,
      stats: types.maybeNull(
        types.array(
          types.model({
            level: types.number,
            count: types.number,
            attendees: types.maybeNull(types.array(MAttendee)),
          }),
        ),
      ),
    }),
  ),
  selectedOptions: types.maybeNull(
    types.array(
      types.model({
        optionId: types.number,
        count: types.number,
        attendees: types.maybeNull(types.array(MAttendee)),
      }),
    ),
  ),
  texts: types.maybeNull(
    types.array(
      types.model({
        text: types.maybeNull(types.string),
        attendeeId: types.maybeNull(types.number),
        installationId: types.maybeNull(types.string),
      }),
    ),
  ),
});

export default MStatistic;
