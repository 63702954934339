/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["self"] }] */
import { types } from 'mobx-state-tree';
import { BookingStatus } from './constants';

const MBooking = types
  .model({
    id: types.string,
    registrationTime: types.maybeNull(types.string),
    title: types.string,
    status: types.enumeration<BookingStatus>('Status', Object.values(BookingStatus)),
    canViewRegistrations: types.boolean,
    canCancelRegistration: types.boolean,
    availableRegistrationCount: types.maybeNull(types.number),
    bookingTypeId: types.maybeNull(types.number),
  })
  .actions((self) => ({
    setStatus(status: BookingStatus) {
      self.status = status;
    },
  }));

export default MBooking;
