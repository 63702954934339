import React from 'react';
import { PasswordProps } from 'antd/lib/input';
import { ReactComponent as EyeIcon } from 'assets/icons/eye.svg';
import { ReactComponent as CloseEyeIcon } from 'assets/icons/close-eye.svg';
import { StyledPassword } from './Password.styles';

const Password: React.FC<PasswordProps> = (props) => {
  const { disabled } = props;
  const iconRender = (visible: boolean) => {
    if (disabled) return undefined;
    return visible ? <CloseEyeIcon /> : <EyeIcon />;
  };
  return <StyledPassword {...props} iconRender={iconRender} />;
};

export default Password;
