/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["self"] }] */
import { types } from 'mobx-state-tree';
import { isFieldHasValidCharactersLength } from 'utils';
import { ContactType } from 'types/authApiTypes';
import { InputBoundaries } from 'utils/validateFormField';

const Login = types
  .model({
    checkPrivacyPolicyToken: '',
    login: '',
    password: '',
    isLoginFormValid: true,
    isPremoderation: false,
    isRecoveryFormValid: true,
    isRecoveryFormSent: false,
    loginType: types.maybeNull(types.enumeration('LoginType', [ContactType.EMAIL, ContactType.SMS])),
    isFetching: false,
    sentTo: types.maybeNull(types.string),
  })
  .views((self) => {
    return {
      get isLoginFormAllowedToSubmit() {
        return (
          isFieldHasValidCharactersLength(self.login.length, InputBoundaries.LOGIN) &&
          isFieldHasValidCharactersLength(self.password.length, InputBoundaries.PASSWORD)
        );
      },
      get isRecoveryFormAllowedToSubmit() {
        return isFieldHasValidCharactersLength(self.login.length, InputBoundaries.LOGIN);
      },
    };
  })
  .actions((self) => ({
    resetFormValidationState: () => {
      if (!self.isRecoveryFormValid) {
        self.isRecoveryFormValid = true;
      }

      if (!self.isLoginFormValid) {
        self.isLoginFormValid = true;
      }
    },
    resetFormFields: () => {
      self.login = '';
      self.password = '';
      self.sentTo = null;
    },
    resetRestoreForm: () => {
      self.isRecoveryFormSent = false;
    },
    setCheckPrivacyPolicyToken: (token: string) => {
      self.checkPrivacyPolicyToken = token;
    },
    setFetching: (isFetching: boolean) => {
      self.isFetching = isFetching;
    },
    setLoginFormValid: (isLoginFormValid: boolean) => {
      self.isLoginFormValid = isLoginFormValid;
    },
    setIsPremoderation: (isPremoderation: boolean) => {
      self.isPremoderation = isPremoderation;
    },
    setRecoveryFormSent: (isRecoveryFormSent: boolean) => {
      self.isRecoveryFormSent = isRecoveryFormSent;
    },
    setRecoveryFormValid: (isRecoveryFormValid: boolean) => {
      self.isRecoveryFormValid = isRecoveryFormValid;
    },
    setLoginType: (loginType: ContactType | null) => {
      self.loginType = loginType;
    },
    setSentTo: (sentTo: string | null) => {
      self.sentTo = sentTo;
    },
  }))
  .actions((self) => ({
    clearCheckPrivacyPolicyToken: () => {
      self.setCheckPrivacyPolicyToken('');
    },
    setLogin: (login: string) => {
      self.login = login.trim();
      self.resetFormValidationState();
    },
    setPassword: (password: string) => {
      self.password = password.trim();
      self.resetFormValidationState();
    },
  }));

export default Login;
