/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["self"] }] */
import { cast, flow, getRoot, types } from 'mobx-state-tree';
import { MLearningTrack } from 'models/LearningTracks';
import learningTracksApi from 'api/learningTracksApi';
import { TLearningTrackResponse } from 'types/learningTracksApiTypes';
import { LearningTrackStepsStatuses } from 'models/LearningTracks/constants';
import { TStore } from 'types';

const LearningTrack = types
  .model({
    track: types.maybeNull(MLearningTrack),
  })
  .views((self) => ({
    get learningTrackUIStore() {
      return getRoot<TStore>(self).UIStore.learningTrack;
    },
    get completedSteps() {
      return self.track?.steps.filter((step) => step.progress === 1).length;
    },
    get totalSteps() {
      return self.track?.steps.length;
    },
    isShowStepClosedIcon(id: string) {
      if (self.track?.isFreeOrderAllowed || self.track?.viewInAdminMode) return true;
      if (self.track?.steps) {
        for (let i = 0; i < self.track?.steps.length; i += 1) {
          if (self.track?.steps[i].id === id) {
            return true;
          }
          if (self.track?.steps[i].status !== LearningTrackStepsStatuses.COMPLETED) {
            return false;
          }
        }
      }
      return false;
    },
  }))
  .actions((self) => ({
    fetchLearningTrack: flow(function* fetchLearningTrack(trackId: string) {
      const { data, hasError }: TLearningTrackResponse = yield learningTracksApi.getLearningTrack(trackId);
      if (!hasError && data) {
        self.track = cast(data);
      }
    }),
  }))
  .actions((self) => ({
    fetchRequiredData: flow(function* fetchRequiredData(trackId: string) {
      self.learningTrackUIStore.setFetchingTrack(true);
      yield self.fetchLearningTrack(trackId);
      yield learningTracksApi.learningTrackOpened(trackId);
      self.learningTrackUIStore.setFetchingTrack(false);
    }),
  }))
  .actions((self) => ({
    fetchIsLearningTrackStepOpened: flow(function* fetchRequiredData(trackId: string, trackStepId: string) {
      self.learningTrackUIStore.setFetchingTrack(true);
      const { hasError } = yield learningTracksApi.learningTrackStepOpened(trackId, trackStepId);
      self.learningTrackUIStore.setFetchingTrack(false);
      if (hasError) {
        yield self.fetchRequiredData(trackId);
      }
      return {
        isStepOpened: !hasError,
      };
    }),
  }));
export default LearningTrack;
