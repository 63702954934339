import { api } from 'api';
import { TEventInfoResponse, TComponentsResponse } from 'types/eventSettingsApiTypes';

export const getEventInfo = (alias: string): Promise<TEventInfoResponse> =>
  api.publicRequest('get', `api/web/tlrocks/event/info`, {
    searchParams: {
      alias,
    },
  });

export const getComponents = (): Promise<TComponentsResponse> =>
  api.privateRequest('get', 'api/web/tlrocks/event/components');

export default { getEventInfo, getComponents };
