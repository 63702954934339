import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import NotificationButtons from '../NotificationButtons';
import { IButton } from '../NotificationButtons/NotificationButtons';
import { DescriptionBox } from './NotificationDescription.styles';
import Text from '../../Text';
import messages from './messages';

interface INotificationDescriptionProps {
  description?: string | null;
  buttons?: IButton[];
  closeNotification: (id: string) => void;
  isTitleExists: boolean;
  id: string;
  closable: boolean;
  withIcon: boolean;
  type: string;
}

const NotificationDescription: FC<INotificationDescriptionProps> = ({
  description,
  buttons,
  isTitleExists,
  closeNotification,
  id,
  closable,
  withIcon,
  type,
}) => {
  const { formatMessage } = useIntl();

  return (
    <DescriptionBox
      isTitleExists={(isTitleExists || (!isTitleExists && type === 'error')) && Boolean(description)}
      closable={closable}
      withIcon={withIcon}
    >
      {description && <Text>{description}</Text>}
      {!description && type === 'error' && <Text>{formatMessage(messages.errorDescription)}</Text>}
      {buttons && Boolean(buttons?.length) && (
        <NotificationButtons buttons={buttons} close={closeNotification} id={id} />
      )}
    </DescriptionBox>
  );
};

export default NotificationDescription;
