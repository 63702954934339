/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["self"] }] */
import { types } from 'mobx-state-tree';
import { BookingStatus } from './constants';

export const MBookingAttendee = types.model({
  id: types.number,
  timestamp: types.maybeNull(types.string),
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
  companyName: types.maybeNull(types.string),
  position: types.maybeNull(types.string),
  imagePath: types.maybeNull(types.string),
  status: types.enumeration<BookingStatus>('Status', Object.values(BookingStatus)),
});

const MBookingRegistrations = types.model({
  attendees: types.array(MBookingAttendee),
  totalCount: types.number,
  totalWaitlistCount: types.number,
});
export default MBookingRegistrations;
