import styled from 'styled-components';
import { Text } from 'components';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';

export const TextStyled = styled(Text)`
  &&& {
    color: ${({ theme }) => theme.palette.dark.main};
    font-weight: 500;
  }
`;

export const InfoIconStyled = styled(InfoIcon)`
  path {
    fill: ${({ theme }) => theme.palette.dark.dark_20};
    transition: fill 0.4s;
  }
`;

export const InfoIconWrapper = styled.div`
  display: inline-flex;
  cursor: pointer;
  &:hover,
  &.ant-popover-open {
    ${InfoIconStyled} {
      path {
        fill: ${({ theme }) => theme.palette.primary.main};
      }
    }
  }
`;
