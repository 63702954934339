import { MIXINS } from '_constants';
import styled from 'styled-components';

interface IWrapper {
  imageSrc?: string;
}

export const Fill = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: transparent;
  transition: background-color 0.4s;
  z-index: 2;
`;

export const ImageOverlay = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
`;

export const Wrapper = styled.div<IWrapper>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: ${({ theme }) => theme.palette.dark.dark_4};
  cursor: pointer;

  ${MIXINS.ratio16x9}

  &:hover {
    & ${Fill} {
      background: ${({ theme }) => theme.palette.mask.weak};
    }
  }
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  padding: 8px;
  border-radius: 6px;
  display: flex;
  z-index: 2;

  background-color: ${({ theme }) => theme.palette.mask.light};
  @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    background-color: ${({ theme }) => theme.palette.mask.weak};
    backdrop-filter: blur(10px);
  }

  svg {
    path {
      fill: ${({ theme: { palette } }) => palette.white.primary};
    }
  }
`;
