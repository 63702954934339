import { types } from 'mobx-state-tree';
import { CatalogElementTypes } from './constants';

const CatalogAttendee = types
  .model({
    id: types.identifierNumber,
    catalogId: types.number,
    attendeeId: types.number,
    firstName: types.maybeNull(types.string),
    lastName: types.maybeNull(types.string),
    companyName: types.maybeNull(types.string),
    position: types.maybeNull(types.string),
    imagePath: types.maybeNull(types.string),
    type: types.enumeration('Element type', [CatalogElementTypes.ATTENDEE]),
    order: types.maybeNull(types.number),
    authorized: types.maybe(types.boolean),
    isDisabled: types.maybeNull(types.boolean),
    phone: types.maybe(types.string),
    email: types.maybe(types.string),
    eventId: types.maybe(types.number),
    company: types.maybeNull(types.string),
    thumbnail: types.maybeNull(types.string),
    hidden: types.maybe(types.boolean),
  })
  .named('CatalogAttendee');

export default CatalogAttendee;
