import { types } from 'mobx-state-tree';
import { CatalogElementTypes } from './constants';
import MGroupAttendee from './MGroupAttendee';

export const MCatalogGroupAttendees = types.maybeNull(
  types.model({
    context: types.model({
      page: types.number,
      pages: types.number,
      size: types.number,
      totalRows: types.number,
    }),
    items: types.array(MGroupAttendee),
  }),
);

const MCatalogGroup = types
  .model({
    id: types.identifierNumber,
    type: types.enumeration('Element type', [CatalogElementTypes.GROUP]),
    order: types.number,
    groupId: types.number,
    catalogId: types.number,
    name: types.maybe(types.string),
  })
  .named('CatalogGroup');

export default MCatalogGroup;
