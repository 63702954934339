import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { Popover } from 'antd';
import store from 'store';
import Wrapper from './PopoverButton.styles';
import './PopoverStyles.css';

interface IPopoverButton {
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  name?: JSX.Element;
  onVisibleChange?: (visible: boolean) => void;
  className?: string;
  globalContainer?: boolean;
}
const cssPopoverContainer = 'popover-button-container';

const PopoverButton: React.FC<IPopoverButton> = (props) => {
  const { children, onClick, name, onVisibleChange, className, globalContainer = false } = props;
  const { palette } = store.UIStore.theme;

  const overlayInnerStyle = useMemo(
    () => ({
      background: palette.white.primary,
      boxShadow: `0px 0px 20px ${palette.shadow.main}`,
      borderRadius: '8px',
    }),
    [palette],
  );

  return (
    <Wrapper onClick={onClick} className={className}>
      {name ? (
        <>
          <Popover
            placement="right"
            content={name}
            getPopupContainer={!globalContainer ? (trigger) => trigger : undefined}
            align={{
              offset: [0],
            }}
            onVisibleChange={onVisibleChange}
            overlayClassName={cssPopoverContainer}
            overlayInnerStyle={overlayInnerStyle}
            mouseEnterDelay={0}
          >
            {children}
          </Popover>
        </>
      ) : (
        <>{children}</>
      )}
    </Wrapper>
  );
};

export default observer(PopoverButton);
