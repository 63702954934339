import React, { useLayoutEffect, useCallback, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import { observer } from 'mobx-react';
import { RouteTemplates } from '_constants';
import LearningTrack from 'views/LearningTracks/LearningTrack';
import CourseRouter from 'views/Course/CourseRouter';
import { useAppNavigator } from 'utils';
import store from 'store';

const LearningTracks = lazy(() => import('views/LearningTracks'));

const LearningTracksRouter: React.FC = () => {
  const {
    params: { trackId },
  } = useAppNavigator();

  const {
    learningTrack: { fetchRequiredData },
  } = store.DomainStore;

  const init = useCallback(
    async (tId: string) => {
      fetchRequiredData(tId);
    },
    [fetchRequiredData],
  );

  useLayoutEffect(() => {
    if (trackId) {
      init(trackId);
    }
  }, [init, trackId]);

  return (
    <Switch>
      <Route path={RouteTemplates.myLearningTrackCourse} component={CourseRouter} />
      <Route path={RouteTemplates.myLearningTrack} component={LearningTrack} />
      <Route
        path={[
          RouteTemplates.myLearningTracksAll,
          RouteTemplates.myLearningTracksInProgress,
          RouteTemplates.myLearningTracksAvailable,
          RouteTemplates.myLearningTracksCompleted,
        ]}
      >
        <Route path={[RouteTemplates.myLearningTracksStatus]} component={LearningTracks} />
      </Route>
    </Switch>
  );
};

export default observer(LearningTracksRouter);
