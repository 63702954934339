import { types } from 'mobx-state-tree';
import { LearningTrackStepsStatuses } from './constants';
import MContent from './MContent';

const MStep = types.model({
  id: types.string,
  name: types.string,
  coverThumbnailUrl: types.string,
  status: types.enumeration<LearningTrackStepsStatuses>('Status', Object.values(LearningTrackStepsStatuses)),
  progress: types.number,
  content: MContent,
});

export default MStep;
