import { types } from 'mobx-state-tree';

const MPaginationContext = types.model({
  page: types.optional(types.number, 0),
  pages: types.optional(types.number, 0),
  size: types.maybeNull(types.number),
  totalRows: types.maybeNull(types.number),
});

export default MPaginationContext;
