import React from 'react';
import { observer } from 'mobx-react';
import { UIStore } from 'store';
import { ProblemPlaceholder } from 'components';
import { useAppNavigator } from 'utils';

const GlobalPlaceholder: React.FC = ({ children }) => {
  const { placeholder, resetPlaceholder } = UIStore.layout;
  const { goToAlias } = useAppNavigator();

  const onRefresh = () => {
    goToAlias();
    resetPlaceholder();
  };
  switch (placeholder) {
    case 'error403':
      return <ProblemPlaceholder type="error403" onRefresh={onRefresh} />;
    case 'error404':
      return <ProblemPlaceholder type="error404" onRefresh={onRefresh} />;
    default:
      return <>{children}</>;
  }
};

export default observer(GlobalPlaceholder);
