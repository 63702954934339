/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["self"] }] */
import { types, flow, cast } from 'mobx-state-tree';
import {
  TBookingResponse,
  TBookingsResponse,
  TBookingStatusResponse,
  TBookingRegistrationsResponse,
  TBookingTypesResponse,
} from 'types/bookingApiTypes';
import { bookingApi } from 'api';
import { MBookingTypes, MBooking, MBookings, MBookingRegistrations } from 'models';
import { BookingStatus } from 'models/Booking';
import { REGISTRATIONS_PAGE_SIZE } from 'views/MyBookings/constants';

const MyBookings = types
  .model({
    data: types.maybeNull(MBooking),
    myBookings: types.optional(MBookings, {}),
    registrations: types.maybeNull(MBookingRegistrations),
    myBookingTypes: types.maybeNull(MBookingTypes),
  })
  .views((self) => ({
    get registered() {
      return self.myBookings.bookings
        .filter((booking) => booking.status === BookingStatus.REGISTERED)
        .sort((a, b) => a.title.localeCompare(b.title));
    },
    get registeredWaitList() {
      return self.myBookings.bookings
        .filter((booking) => booking.status === BookingStatus.REGISTERED_WAIT_LIST)
        .sort((a, b) => a.title.localeCompare(b.title));
    },
    get canRegister() {
      return self.myBookings.bookings
        .filter((booking) => booking.status === BookingStatus.CAN_REGISTER)
        .sort((a, b) => a.title.localeCompare(b.title));
    },
    get canRegisterWaitList() {
      return self.myBookings.bookings
        .filter((booking) => booking.status === BookingStatus.CAN_REGISTER_WAIT_LIST)
        .sort((a, b) => a.title.localeCompare(b.title));
    },
    get cannotRegister() {
      return self.myBookings.bookings
        .filter((booking) => booking.status === BookingStatus.CANNNOT_REGISTER)
        .sort((a, b) => a.title.localeCompare(b.title));
    },
    get cannotRegisterLimit() {
      return self.myBookings.bookings
        .filter((booking) => booking.status === BookingStatus.CANNNOT_REGISTER_LIMIT)
        .sort((a, b) => a.title.localeCompare(b.title));
    },
    get attendeesTotalCount() {
      return self.registrations ? self.registrations.totalCount + self.registrations.totalWaitlistCount : 0;
    },
    get isBookingsExists() {
      return !!self.myBookings.bookings.length;
    },
  }))
  .views((self) => ({
    get currentBookings() {
      return self.registered.concat(self.registeredWaitList);
    },
    get availableBookings() {
      return self.canRegister.concat(self.canRegisterWaitList, self.cannotRegister, self.cannotRegisterLimit);
    },
    get registeredCount() {
      return self.registered.length;
    },
    get registeredWaitListCount() {
      return self.registeredWaitList.length;
    },
    get canRegisterCount() {
      return self.canRegister.length;
    },
    get canRegisterWaitListCount() {
      return self.canRegisterWaitList.length;
    },
    get cannotRegisterCount() {
      return self.cannotRegister.length;
    },
    get cannotRegisterLimitCount() {
      return self.cannotRegisterLimit.length;
    },
  }))
  .views((self) => ({
    getCurrentBookingsByType(typeId: number) {
      return self.currentBookings.filter((booking) => booking.bookingTypeId === typeId);
    },
    getAvailableBookingsByType(typeId: number) {
      return self.availableBookings.filter((booking) => booking.bookingTypeId === typeId);
    },
    get currentCount() {
      return self.registeredCount + self.registeredWaitListCount;
    },
    get availableCount() {
      return (
        self.canRegisterCount + self.canRegisterWaitListCount + self.cannotRegisterCount + self.cannotRegisterLimitCount
      );
    },
  }))
  .actions((self) => ({
    fetchBookings: flow(function* fetchBookings() {
      const { data, hasError }: TBookingsResponse = yield bookingApi.getBookings(true);
      if (!hasError && data) {
        self.myBookings = cast(data);
      }
    }),
    fetchBookingTypes: flow(function* fetchBookingTypes() {
      const { data, hasError }: TBookingTypesResponse = yield bookingApi.getBookingTypes();
      if (!hasError && data) {
        self.myBookingTypes = cast(data);
      }
    }),
    fetchBooking: flow(function* fetchBooking(bookingId: string) {
      const { data, hasError }: TBookingResponse = yield bookingApi.getBooking(bookingId);
      if (data && !hasError) {
        self.data = data;
      }
    }),
    sendBooking: flow(function* sendBooking(bookingId: string) {
      const { data }: TBookingStatusResponse = yield bookingApi.sendBooking(bookingId);
      if (data && self.data) {
        self.data.setStatus(data.status);
      }
    }),
    cancelBooking: flow(function* cancelBooking(bookingId: string) {
      yield bookingApi.cancelBooking(bookingId);
    }),
    getBookingRegistrations: flow(function* getBookingRegistrations(
      bookingId: string,
      pageSize: number = REGISTRATIONS_PAGE_SIZE,
    ) {
      const { data, hasError }: TBookingRegistrationsResponse = yield bookingApi.getBookingRegistrations(
        bookingId,
        0,
        pageSize,
      );
      if (data && !hasError) {
        self.registrations = cast(data);
      }
    }),
    getMoreBookingRegistrations: flow(function* getBookingRegistrations(
      bookingId: string,
      page: number,
      pageSize: number = REGISTRATIONS_PAGE_SIZE,
    ) {
      const { data, hasError }: TBookingRegistrationsResponse = yield bookingApi.getBookingRegistrations(
        bookingId,
        page,
        pageSize,
      );
      if (data && !hasError && self.registrations) {
        self.registrations.attendees = cast([...self.registrations.attendees, ...data.attendees]);
      }
    }),
  }));

export default MyBookings;
