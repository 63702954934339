import { defineMessages } from 'react-intl';

export default defineMessages({
  newStatus: {
    id: 'components.tasks.taskElement.newStatus',
    defaultMessage: 'New',
  },
  inReviewStatus: {
    id: 'components.tasks.taskElement.inReviewStatus',
    defaultMessage: 'На проверке',
  },
  rejectedStatus: {
    id: 'components.tasks.taskElement.rejectedStatus',
    defaultMessage: 'Не принято',
  },
  courseTrackName: {
    id: 'components.tasks.taskElement.courseTrackName',
    defaultMessage: 'В рамках обучения: {name}',
  },
});
