import React, { useEffect, useMemo, useState } from 'react';
import store from 'store';
import { useAppNavigator } from 'utils';

interface Props {
  available?: boolean;
}

const PrivateMenuRoute: React.FC<Props> = ({ children, available }) => {
  const {
    DomainStore: {
      components: { fetchComponents },
      eventSettings: { fetchEventSettings },
    },
    UIStore: {
      layout: { show403placeholder },
    },
  } = store;
  const {
    params: { alias },
  } = useAppNavigator();
  const [showChild, setShowChild] = useState(false);
  const isAvailable = useMemo(() => available || typeof available === 'undefined', [available]);

  useEffect(() => {
    const init = async () => {
      await Promise.all([fetchComponents(), fetchEventSettings(alias)]);
      setShowChild(true);
    };
    init();
  }, [fetchComponents, fetchEventSettings, alias, setShowChild]);

  useEffect(() => {
    if (showChild && !isAvailable) {
      show403placeholder();
    }
  }, [show403placeholder, isAvailable, showChild]);

  if (!showChild || !isAvailable) return null;

  return <>{children}</>;
};

export default PrivateMenuRoute;
