import styled from 'styled-components';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { Grid, MIXINS } from '_constants';
import { ErrorImageOverlay } from 'components/Image/Image.styles';
import { ReactComponent as FullScreen } from 'assets/icons/fullscreen.svg';

enum ReachTextClasses {
  HEADER = 'textheader',
  LARGE = 'textlarge',
  SMALL = 'textsmall',
}

export const Wrapper = styled.div``;

export const StyledText = styled.div`
  color: ${({ theme }) => theme.palette.dark.main};
  * {
    margin: 0;
    font-size: 17px;
    font-family: 'Roboto', sans-serif;
    line-height: 28px;
    overflow-wrap: break-word;
    @media (max-width: ${Grid.MD}px) {
      font-size: 14px;
      line-height: 21px;
    }
  }
  ul {
    padding-inline-start: 30px;
  }
  ol {
    padding-inline-start: 37px;
  }
  ul > li,
  ol > li {
    margin-top: 8px;
  }
  a {
    color: ${({ theme }) => theme.palette.primary.main};
    text-decoration: underline;
    & > img {
      margin: 32px 0;
    }
  }
  img {
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: auto;
    min-height: 52px;
    object-fit: cover;
  }
  .${ReachTextClasses.LARGE} {
    font-size: 32px;
    line-height: 40px;
    font-weight: 800;
    font-family: 'Montserrat', sans-serif;
    @media (max-width: ${Grid.MD}px) {
      font-size: 18px;
      line-height: 24px;
    }
  }
  .${ReachTextClasses.HEADER} {
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
    @media (max-width: ${Grid.MD}px) {
      font-size: 16px;
      line-height: 16px;
    }
  }
  .${ReachTextClasses.SMALL} {
    font-size: 13px;
    line-height: 22px;
    margin-bottom: 4px;
    display: block;
  }
`;

export const ImagePreviewWrapper = styled.div``;

export const FullScreenIcon = styled(FullScreen)`
  position: absolute;
  right: 10px;
  bottom: 10px;
  padding: 9px;
  z-index: 8;
  width: 32px;
  height: 32px;
  background-color: ${({ theme }) => theme.palette.mask.weaker};
  backdrop-filter: blur(10px);
  border-radius: 6px;
  opacity: 0;
  cursor: pointer;
  transition: 0.3s opacity;
`;

export const RichTextImageWrapper = styled.div`
  position: relative;
  ${ErrorImageOverlay} {
    position: relative;
    ${MIXINS.ratio16x9}
    border-radius: 8px;
    margin: 32px 0;
  }
  &:hover {
    cursor: pointer;
    ${FullScreenIcon} {
      opacity: 1;
    }
  }
`;

export const Close = styled(CloseIcon)`
  margin-left: 16px;
`;
