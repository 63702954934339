import {
  TDashboardInfoResponse,
  TDashboardRatingResponse,
  TDashboardCoursesInfoResponse,
  TDashboardTracksInfoResponse,
} from 'types/dashboardApiTypes';
import api from './api';

const getDashboardInfo = (): Promise<TDashboardInfoResponse> =>
  api.privateRequest('get', 'api/web/tlrocks/v1/dashboard/settings');

const getDashboardRating = (): Promise<TDashboardRatingResponse> =>
  api.privateRequest('get', 'api/web/tlrocks/v1/dashboard/data/rating');

const getDashboardCourses = (): Promise<TDashboardCoursesInfoResponse> =>
  api.privateRequest('get', 'api/web/tlrocks/v1/dashboard/data/courses');

const getDashboardTracks = (): Promise<TDashboardTracksInfoResponse> =>
  api.privateRequest('get', 'api/web/tlrocks/v1/dashboard/data/tracks');

export default {
  getDashboardInfo,
  getDashboardRating,
  getDashboardCourses,
  getDashboardTracks,
};
