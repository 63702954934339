import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.palette.white.primary};
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  overflow: hidden;
  span {
    position: absolute;
  }
`;

export default { Wrapper };
