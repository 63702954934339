import {
  TCoursesInfoResponse,
  TCourseResponse,
  TCompletedStagesResponse,
  TScormResponse,
  TCatalogResponse,
  TNextStageResponse,
  TCatalogGroupAttendeesResponse,
  TVideoResponse,
  TAttendeeResponse,
  TBookingsByIdsResponse,
} from 'types/coursesApiTypes';
import { TResponded } from 'types';
import api, { setLearningTracksHeaders } from './api';

const getCoursesInfo = (): Promise<TCoursesInfoResponse> => api.privateRequest('get', 'api/web/tlrocks/courses');

const courseOpened = (courseId: number, trackId: string): Promise<TResponded<null>> =>
  api.privateRequest('post', `api/web/tlrocks/course/${courseId}/open`, {
    headers: { ...setLearningTracksHeaders(trackId) },
  });

const getCourse = (courseId: number, trackId: string): Promise<TCourseResponse> =>
  api.privateRequest('get', `api/web/tlrocks/course/${courseId}`, {
    headers: { ...setLearningTracksHeaders(trackId) },
  });

const getCompletedStages = (courseId: number, trackId: string): Promise<TCompletedStagesResponse> =>
  api.privateRequest('get', `api/web/tlrocks/course/${courseId}/stages/completed`, {
    headers: { ...setLearningTracksHeaders(trackId) },
  });

const getScorm = (scormId: number, trackId: string): Promise<TScormResponse> =>
  api.privateRequest('get', `api/web/tlrocks/courses/scorm/${scormId}`, {
    headers: { ...setLearningTracksHeaders(trackId) },
  });

const getCatalog = (catalogId: number, trackId: string): Promise<TCatalogResponse> =>
  api.privateRequest('get', `api/web/tlrocks/catalogs/${catalogId}`, {
    headers: { ...setLearningTracksHeaders(trackId) },
  });

const getNextStage = (courseId: number, stageId: number, trackId: string): Promise<TNextStageResponse> =>
  api.privateRequest('get', `api/web/tlrocks/courses/${courseId}/stages/${stageId}/next`, {
    headers: { ...setLearningTracksHeaders(trackId) },
  });

const stageOpened = (courseId: number, stageId: number, trackId: string): Promise<TResponded<null>> =>
  api.privateRequest('post', `api/web/tlrocks/courses/${courseId}/stages/${stageId}/open`, {
    headers: { ...setLearningTracksHeaders(trackId) },
  });

const finishStage = (courseId: number, stageId: number, trackId: string): Promise<TResponded<null>> =>
  api.privateRequest('post', `api/web/tlrocks/courses/${courseId}/stages/${stageId}/finish`, {
    json: {},
    headers: { ...setLearningTracksHeaders(trackId) },
  });

const getGroupAttendees = (
  trackId: string,
  groupId: number,
  page: number,
  pageSize: number,
): Promise<TCatalogGroupAttendeesResponse> =>
  api.privateRequest('get', `api/web/tlrocks/attendees/byaclid`, {
    headers: { ...setLearningTracksHeaders(trackId) },
    searchParams: {
      aclId: groupId,
      page,
      size: pageSize,
    },
  });

const getAttendee = (id: number, trackId: string): Promise<TAttendeeResponse> =>
  api.privateRequest('get', `api/web/tlrocks/attendees/${id}`, {
    headers: { ...setLearningTracksHeaders(trackId) },
  });

const getVideoById = (id: number, trackId: string): Promise<TVideoResponse> =>
  api.privateRequest('get', `api/web/tlrocks/v2/videos/${id}`, {
    headers: { ...setLearningTracksHeaders(trackId) },
  });

// eslint-disable-next-line
const getAudioById = (id: number): Promise<TVideoResponse> => {
  // TODO:Убрать моки после добавления
  // api.privateRequest('get', `api/web/tlrocks/audios/${id}`);
  return new Promise((res) =>
    setTimeout(
      () =>
        res({
          response: null,
          hasError: false,
          data: {
            expires: '',
            headers: { cookie: '' },
            url: 'https://samplelib.com/lib/preview/mp3/sample-3s.mp3',
          },
        }),
      500,
    ),
  );
};

const getBookingsByIds = (bookingIds: string[], trackId: string): Promise<TBookingsByIdsResponse> =>
  api.privateRequest('post', `api/web/tlrocks/v1/catalogs/bookings`, {
    body: JSON.stringify({
      bookingIds,
    }),
    headers: { 'content-type': 'application/json', ...setLearningTracksHeaders(trackId) },
  });

export default {
  getCoursesInfo,
  courseOpened,
  getCourse,
  getCompletedStages,
  getScorm,
  getNextStage,
  stageOpened,
  finishStage,
  getCatalog,
  getGroupAttendees,
  getAttendee,
  getVideoById,
  getAudioById,
  getBookingsByIds,
};
