/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["self"] }] */
import { flow, types, cast, getRoot, Instance } from 'mobx-state-tree';
import { TCompletedStage, TNextStage, TStage, TStore } from 'types';
import { CourseStatuses, MCourse } from 'models';
import { CurrentStageType } from 'models/MNextStage';
import { coursesApi, learningTracksApi } from 'api';
import { StageTypes } from 'views/StageList/constants';
import { TCompletedStagesResponse, TCourseResponse, TNextStageResponse } from 'types/coursesApiTypes';
import { LearningTracksStatuses, LearningTrackStepsStatuses } from 'models/LearningTracks/constants';
import Courses from './Courses';
import LearningTrack from './LearningTrack';

const GET_NEXT_STAGE_POLLING_INTERVAL = 1000;

const CurrentCourse = types
  .model({
    data: types.maybeNull(MCourse),
  })
  .views((self) => ({
    get courseUIStore() {
      return getRoot<TStore>(self).UIStore.course;
    },
    get coursesDomainStore(): Instance<typeof Courses> {
      return getRoot<TStore>(self).DomainStore.courses;
    },
    get learningTrackUIStore() {
      return getRoot<TStore>(self).UIStore.learningTrack;
    },
    get learningTrackDomainStore(): Instance<typeof LearningTrack> {
      return getRoot<TStore>(self).DomainStore.learningTrack;
    },
  }))
  .views((self) => ({
    get courseInfo() {
      return self.coursesDomainStore.data.find((course) => course.id === self.data?.id);
    },
  }))
  .views((self) => ({
    get isCompleted() {
      if (self.data?.trackId && self.data?.trackStepId) {
        const { track } = self.learningTrackDomainStore;
        return (
          track?.steps.find((step) => step.id === self.data?.trackStepId)?.status ===
          LearningTrackStepsStatuses.COMPLETED
        );
      }
      return self.courseInfo?.status === CourseStatuses.COMPLETED;
    },
    get notStarted() {
      return self.courseInfo?.status === CourseStatuses.NOT_STARTED;
    },
    get inProgress() {
      return self.courseInfo?.status === CourseStatuses.IN_PROGRESS;
    },
  }))
  .views((self) => ({
    get stageCount() {
      return self.data?.stages?.length || 0;
    },
    // TODO: не учтён режим завершенного курса
    get passedStageCount() {
      return self.data?.stages.reduce((acc, stage) => acc + Number(stage.isCompleted), 0) || 0;
    },
    // NOTE: открытые по флоу прохождения этапы (т.е. без учёта isClosed)
    get waitingStages() {
      if (!self.data) return [];
      const { stages, isFreeOrderAllowed, isAdmin } = self.data;
      if (isAdmin || isFreeOrderAllowed || self.isCompleted) return stages;
      return stages.filter(
        (stage: TStage, index) => stage.isCompleted || index === 0 || !!stages[index - 1]?.isCompleted,
      );
    },
    get thumbnail() {
      return self.data?.coverThumbnailUrl || self.data?.coverUrl;
    },
    get cover() {
      return self.data?.coverUrl || self.data?.coverThumbnailUrl;
    },
    get isAllStagesCompleted() {
      return self.data?.stages.every((stage) => stage.isCompleted);
    },
  }))
  .views((self) => ({
    get completedPercent() {
      if (self.isCompleted) return 100;
      return self.stageCount && Math.round((self.passedStageCount / self.stageCount) * 100);
    },
    get allowedDirectingToNextStage() {
      return !self.data?.isAdmin && !self.data?.isFreeOrderAllowed && !self.isCompleted && !!self.stageCount;
    },
    // NOTE: открытые по флоу и доступные к прохождению этапы
    get availableStages() {
      if (!self.data) return [];
      const { stages, isAdmin } = self.data;
      if (isAdmin || self.isCompleted) return stages;
      return self.waitingStages.filter(({ isClosed }: TStage) => !isClosed);
    },
    getStageIsExist(stageId: number) {
      return !!self.data?.stages.some(({ id }) => id === stageId);
    },
  }))
  .views((self) => ({
    get nextStage() {
      // TODO: тестовый алгоритм, отложено до подробного обсуждения с БА
      if (!self.data || self.data.isFreeOrderAllowed) return null;
      return self.availableStages.filter((stage) => !stage.isCompleted).pop() || null;
    },
    get allowedDirectingToNextStage() {
      return !self.data?.isAdmin && !self.data?.isFreeOrderAllowed && !self.isCompleted && !!self.stageCount;
    },
  }))
  .actions((self) => ({
    fetchCourse: flow(function* fetchCourse(courseId: number, trackId: string, trackStepId: string) {
      const { data, hasError }: TCourseResponse = yield coursesApi.getCourse(courseId, trackId);
      if (!!data && !hasError) {
        self.data = cast({ ...data, trackId, trackStepId });
      }
    }),
    fetchCompletedStages: flow(function* fetchCompletedStages(courseId: number, trackId: string) {
      const { data, hasError }: TCompletedStagesResponse = yield coursesApi.getCompletedStages(courseId, trackId);
      return hasError || !data ? [] : data;
    }),
    setCompletedStages: (completedStages: TCompletedStage[]) => {
      const completedIds = completedStages.map((item) => item.stageId);

      if (self.data) {
        self.data.stages.forEach((stage) => {
          stage.setIsCompleted(completedIds.includes(stage.id));
        });
      }
    },
    getStage: (stageId: number) => {
      const stage = self.data?.stages.find((findStage) => findStage.id === stageId);
      return stage || null;
    },
    fetchNextStageByCurrent: flow(function* fetchNextStageByCurrent(currentStageId: number, trackId: string) {
      if (self.data?.id) {
        const { data, hasError }: TNextStageResponse = yield coursesApi.getNextStage(
          self.data?.id,
          currentStageId,
          trackId,
        );
        return { data, hasError };
      }
      return { hasError: true };
    }),

    stageIsWaiting: (stageId: number) => {
      return !!self.waitingStages?.find((stage) => stage.id === stageId);
    },
    stageIsAvailable: (stageId: number) => {
      return !!self.availableStages?.find((stage) => stage.id === stageId);
    },
  }))
  .actions((self) => ({
    getStageType({ id, isClosed, isCompleted }: TStage) {
      const isWaiting = self.stageIsWaiting(id);
      if (!isWaiting) return StageTypes.DISABLED;
      if (isClosed) {
        return isCompleted || self.isCompleted ? StageTypes.LOCKED_AFTER_COMPLETED : StageTypes.LOCKED;
      }
      return isCompleted || self.isCompleted ? StageTypes.COMPLETED : StageTypes.AVAILABLE;
    },
    updateCompletedStages: flow(function* updateCompletedStages(courseId: number, trackId = '') {
      const completedStages: TCompletedStage[] = yield self.fetchCompletedStages(courseId, trackId);
      self.setCompletedStages(completedStages);
      if (self.isAllStagesCompleted && !self.isCompleted) {
        if (trackId) {
          yield self.learningTrackDomainStore.fetchLearningTrack(trackId);
        } else {
          yield self.coursesDomainStore.fetchCourses();
        }
        if (self.isCompleted) {
          if (self.data?.finalScreen?.isEnabled) {
            self.courseUIStore.finishCourseModal.show();
          }
          if (trackId) {
            const { track } = self.learningTrackDomainStore;
            if (track?.finalScreen?.isEnabled && track?.status === LearningTracksStatuses.COMPLETED) {
              self.learningTrackUIStore.finishTrackModal.show();
              self.learningTrackUIStore.setFinishTrackId(trackId);
            }
          }
        }
      }
    }),
    fetchRequiredData: flow(function* fetchRequiredData(courseId: number, trackId: string, trackStepId: string) {
      self.courseUIStore.setFetchingCourseRequiredData(true);
      yield self.fetchCourse(courseId, trackId, trackStepId);
      if (trackId && trackStepId) {
        yield learningTracksApi.learningTrackStepOpened(trackId, trackStepId);
      }
      if (self.data) {
        const { data: completedStages, hasError }: TCompletedStagesResponse = yield coursesApi.getCompletedStages(
          courseId,
          trackId,
        );
        // TODO: если пользователю не назначен курс, то в getCompletedStages вернется 404. Убрать условие, если бэк добавит 403 в getCourse
        if (completedStages && !hasError) {
          self.setCompletedStages(completedStages);
          self.courseUIStore.setFetchingCourseRequiredData(false);
          return true;
        }
      }
      self.courseUIStore.setFetchingCourseRequiredData(false);
      return false;
    }),
    fetchNextStageLongPolling: flow(function* fetchNextStageLongPolling(currentStageId: number, trackId: string) {
      let nextStageStatus: TNextStage | undefined | null;
      while (nextStageStatus === undefined || nextStageStatus?.currentStageStatus === CurrentStageType.PROCESSING) {
        if (nextStageStatus) {
          yield new Promise((res) => setTimeout(res, GET_NEXT_STAGE_POLLING_INTERVAL));
        }
        const { data, hasError }: TNextStageResponse = yield self.fetchNextStageByCurrent(currentStageId, trackId);
        if (hasError || !data) {
          return { hasError };
        }
        nextStageStatus = data;
      }
      return { nextStage: nextStageStatus?.nextStage };
    }),
  }));
// TODO: нет секций в версии 0.1.0
// .views((self) => ({
// get singleDisabledCourses() {
//   return self.sections.filter((section) => !section.stages.length && !section.isCompleted).slice(1);
// },
// get singleCompletedCourses() {
//   return self.sections.filter((section) => !section.stages.length && section.isCompleted);
// },
// get singleCurrentCourse() {
//   return self.sections.filter((section) => !section.stages.length && !section.isCompleted)[0];
// },
// get collapsedDisabledCourses() {
//   return self.sections.filter((section) => section.stages.length && !section.isAllCompleted).slice(1);
// },
// get collapsedCompletedCourses() {
//   return self.sections.filter((section) => section.stages.length && section.isAllCompleted);
// },
// get collapsedCurrentCourse() {
//   return self.sections.filter((section) => section.stages.length && !section.isAllCompleted)[0];
// },
// }));

export default CurrentCourse;
