import styled from 'styled-components';
import { Radio } from 'antd';

export const ButtonsGroup = styled(Radio.Group)<{ hidden?: boolean }>`
  &&& {
    display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    border-radius: 12px;
    background-color: ${({ theme }) => theme.palette.dark.dark_4};
    margin-bottom: 20px;
  }
`;

export const RadioButton = styled(Radio.Button)`
  &&& {
    &.ant-radio-button-wrapper {
      width: calc(50% - 4px);
      display: flex;
      align-items: center;
      justify-content: center;
      height: 44px;
      margin: 2px;
      border: none;
      font-weight: 500;
      font-size: 16px;
      border-radius: 10px;
      font-family: 'Roboto', sans-serif;
      line-height: 16px;
      color: ${({ theme }) => theme.palette.dark.dark_50};
      background: none;
      :not(:first-child)::before {
        content: none;
      }
      &:not(.ant-radio-button-wrapper-disabled):hover {
        background: ${({ theme }) => theme.palette.white.primary};
        box-shadow: none;
        transition: background-color 0.4s, color 0.4s, border-color 0.4s, box-shadow 0.4s;
      }

      span {
        display: flex;
        align-items: center;
      }
    }
    &.ant-radio-button-wrapper-checked {
      background: ${({ theme }) => theme.palette.white.primary};
      color: ${({ theme }) => theme.palette.dark.main};
      box-shadow: none;
      span {
        z-index: 1;
        display: flex;
        align-items: center;
      }
      &:hover {
        background: ${({ theme }) => theme.palette.white.primary};
      }
    }
    &.ant-radio-button-wrapper-disabled {
      color: ${({ theme }) => theme.palette.dark.dark_50};
    }
  }
`;
