import { defineMessages } from 'react-intl';

export default defineMessages({
  backButtonText: {
    id: 'views.learningTracks.learningTrack.backButtonText',
    defaultMessage: 'Вернуться назад',
  },
  modalButtonText: {
    id: 'views.learningTracks.learningTrack.modalButtonText',
    defaultMessage: 'Вы — администратор',
  },
  showContentButtonText: {
    id: 'views.learningTracks.learningTrack.showContentButtonText',
    defaultMessage: 'К прохождению',
  },
  showDescriptionButtonText: {
    id: 'views.learningTracks.learningTrack.showDescriptionButtonText',
    defaultMessage: 'Описание',
  },
  submitButtonText: {
    id: 'views.learningTracks.learningTrack.submitButtonText',
    defaultMessage: 'Приступить',
  },
  defaultCompletedText: {
    id: 'components.learningTracks.learningTrack.defaultCompletedText',
    defaultMessage: 'Шагов завершено:',
  },
  completedOf: {
    id: 'components.learningTracks.learningTrack.completedOf',
    defaultMessage: '{completedText} {stagesPassed} из {stagesCount}',
  },
  finishModalOkButtonText: {
    id: 'components.learningTracks.learningTrack.finishModalOkButtonText',
    defaultMessage: 'OK',
  },
});
