import styled from 'styled-components';
import { Grid } from '_constants';

export const Content = styled.div`
  display: flex;
  flex: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 288px;
  @media only screen and (min-width: ${Grid.XS}px) {
    max-width: 388px;
  }
  @media only screen and (min-width: ${Grid.MD}px) {
    max-width: 840px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 40px;
  background-color: ${({ theme }) => theme.palette.hoverBackground};
`;

export const ButtonWrapper = styled.div`
  @media only screen and (max-width: ${Grid.MD}px) {
    display: none;
  }
`;

export const ButtonWrapperMini = styled.div`
  @media only screen and (min-width: ${Grid.MD + 1}px) {
    display: none;
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  margin-bottom: 48px;
  @media only screen and (max-width: ${Grid.MD}px) {
    display: none;
  }
  svg {
    max-width: 100%;
    height: 100%;
  }
`;

export const ImageWrapperMini = styled.div`
  display: flex;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  margin-bottom: 24px;
  @media only screen and (min-width: ${Grid.MD + 1}px) {
    display: none;
  }
  svg {
    max-width: 100%;
    height: 100%;
  }
`;

export const Title = styled.div`
  width: 100%;
  max-width: 240px;
  margin-bottom: 24px;
  color: ${({ theme }) => theme.palette.dark.main};
  font-weight: 600;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  line-height: 24px;
  text-align: center;

  @media only screen and (min-width: ${Grid.XS}px) {
    max-width: 340px;
    font-size: 24px;
    line-height: 34px;
  }
  @media only screen and (min-width: ${Grid.MD}px) {
    max-width: 600px;
    margin-bottom: 32px;
    font-size: 30px;
    line-height: 42px;
  }
`;
