import styled from 'styled-components';
import { Image } from 'antd';

export const ContactItemWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 0;

  &:not(&:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.palette.dark.dark_8};
  }

  & .ant-image {
    margin-right: 12px;
  }
`;

export const ContactItemText = styled.span`
  &&& {
    flex: 1 0 auto;
    padding-right: 12px;
    flex-shrink: 2;
    flex-grow: 2;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const Link = styled.a`
  color: ${({ theme }) => theme.palette.dark.main};
  font-weight: 500;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  line-height: 24px;
  &:hover {
    color: ${({ theme }) => theme.palette.dark.main};
  }
`;

export const ImageStyled = styled(Image)``;
