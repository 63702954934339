export const OChevronDirection = {
  TOP: 'top',
  RIGHT: 'right',
  LEFT: 'left',
  BOTTOM: 'bottom',
} as const;

export const OSize = {
  DEFAULT: 'default',
  SMALL: 'small',
} as const;

export type TChevronDirection = typeof OChevronDirection[keyof typeof OChevronDirection];
export type TSize = typeof OSize[keyof typeof OSize];

export const sizes = {
  [OSize.DEFAULT]: 48,
  [OSize.SMALL]: 32,
};
