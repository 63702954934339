import styled from 'styled-components';
import { Tooltip } from 'antd';

export const IconOverlay = styled.span`
  display: inline-flex;
  cursor: pointer;
`;

export const StyledLargeTooltip = styled(Tooltip)`
  .ant-tooltip-inner {
    width: 100%;
    padding: 12px;
    background-color: ${({ theme }) => theme.palette.white.primary};
    box-shadow: 0 0 20px ${({ theme }) => theme.palette.shadow.main};
    border-radius: 10px;
    color: ${({ theme }) => theme.palette.dark.main};
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    max-width: 228px;
  }
  .ant-tooltip-arrow {
    width: 17px;
    height: 17px;
    bottom: -9px;
  }
  .ant-tooltip-arrow-content {
    background-color: ${({ theme }) => theme.palette.white.primary};
    width: 12px;
    height: 12px;
    transform: translateY(-10px) rotate(45deg);
  }

  &:hover {
    svg {
      path:nth-child(2) {
        fill-opacity: 0.76;
      }
    }
  }
`;
