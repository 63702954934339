import styled, { createGlobalStyle } from 'styled-components';
import { ReactComponent as CloseIcon } from 'assets/icons/close-notification.svg';
import SuccessIcon from 'assets/icons/connection-restore.svg';

export const NotificationStyles = createGlobalStyle`
.tl-rocks-notice-without-close.ant-notification-notice, 
.tl-rocks-notice-with-close.ant-notification-notice,
.tl-rocks-notice-offline.ant-notification-notice,
.tl-rocks-notice-online.ant-notification-notice {
  width: 432px;
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.palette.dark.dark_4};
  border-radius: 8px;
  box-shadow: 0 0 20px 0 ${({ theme }) => theme.palette.shadow.main};
}

.tl-rocks-notice-with-close .ant-notification-notice-message,
.tl-rocks-notice-without-close .ant-notification-notice-message {
  margin-bottom: 0;
}

.tl-rocks-notice-without-close .ant-notification-notice-close {
  display: none;
}

.tl-rocks-notice-with-close .ant-notification-notice-close {
  top: 20px;
  right: 20px;
}

.tl-rocks-notice-with-close .ant-notification-notice-icon,
.tl-rocks-notice-without-close .ant-notification-notice-icon {
  margin-left: 0;
}

.tl-rocks-notice-offline.ant-notification-notice .ant-notification-notice-icon,
.tl-rocks-notice-offline .ant-notification-notice-with-icon .ant-notification-notice-message,
.tl-rocks-notice-online .ant-notification-notice-with-icon .ant-notification-notice-message  {
  margin-left: 0;
}

.tl-rocks-notice-with-close .ant-notification-notice-description,
.tl-rocks-notice-without-close .ant-notification-notice-description {
  margin: 0;
}

.tl-rocks-notice-with-close .ant-notification-notice-content .ant-notification-notice-with-icon .ant-notification-notice-message,
.tl-rocks-notice-without-close .ant-notification-notice-content .ant-notification-notice-with-icon .ant-notification-notice-message {
  margin-left: 0;
  padding: 0;
}

.tl-rocks-notice-without-close .ant-notification-notice-content .ant-notification-notice-with-icon .ant-notification-notice-message {
  padding-right: 0;
}

.tl-rocks-notice-online.ant-notification-notice {
  height: 64px;
  padding-left: 56px;
  background-image: url(${SuccessIcon});
  background-position: 23px center;  
  background-repeat: no-repeat;

  .ant-notification-notice-icon svg {
    display: none;
  }
  .ant-notification-notice-message .ant-typography {
    margin-left: 0;
  }
}

.tl-rocks-notice-offline.ant-notification-notice {
  height: 64px;
}

.tl-rocks-notice-offline.ant-notification-notice .ant-notification-notice-close,
.tl-rocks-notice-online.ant-notification-notice .ant-notification-notice-close {
  top: 20px;
  right: 18px;
}

.tl-rocks-notice-offline.ant-notification-notice .ant-notification-notice-message .ant-typography,
.tl-rocks-notice-online.ant-notification-notice .ant-notification-notice-message .ant-typography {
  font-weight: 500;
}
 

`;

export const StyledCloseIcon = styled(CloseIcon)`
  width: 24px;
  height: 24px;

  &:hover circle {
    fill: ${({ theme }) => theme.palette.dark.dark_8};
  }
`;

export const FakeCloseIcon = styled.div`
  display: none;
`;
