import { api } from 'api';
import { TSignUpResponse, TVerifyResponse, TRegisterResponse } from 'types/registrationApiTypes';

interface IRegParams {
  login: string;
}

interface IVerifyParams {
  otp: string;
  verificationToken: string;
}

interface IRegisterParams {
  firstName: string;
  lastName: string;
  verificationToken: string;
}

export const signUp = ({ login }: IRegParams): Promise<TSignUpResponse> =>
  api.publicRequest('post', 'api/web/tlrocks/v2/register/otp/send', { json: { login } });

export const verify = ({ otp, verificationToken }: IVerifyParams): Promise<TVerifyResponse> =>
  api.publicRequest('post', 'api/web/tlrocks/v2/register/otp/verify', { json: { otp, verificationToken } });

export const register = ({ firstName, lastName, verificationToken }: IRegisterParams): Promise<TRegisterResponse> =>
  api.publicRequest('post', 'api/web/tlrocks/v2/register', { json: { firstName, lastName, verificationToken } });

export default { signUp, verify, register };
