import styled, { css } from 'styled-components';

export interface IWrapper {
  absolute?: boolean;
  transparent?: boolean;
}
export const Wrapper = styled.div<IWrapper>`
  display: flex;
  flex: auto;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  ${({ absolute, theme: { palette }, transparent }) =>
    absolute &&
    css`
      position: absolute;
      z-index: 999;
      background: ${transparent ? palette.mask.whiteALittleTransparent : palette.dark.dark_4};
    `}
`;

interface ISpin {
  size?: number;
}

export const Spin = styled.span<ISpin>`
  ${({ size = 44 }) => css`
    width: ${size}px;
    height: ${size}px;
  `}
`;
