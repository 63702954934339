import { types } from 'mobx-state-tree';

const MAnswer = types.model({
  id: types.number,
  questionId: types.number,
  optionId: types.maybeNull(types.number),
  text: types.maybeNull(types.string),
  isSkipped: types.boolean,
  starLevel: types.maybeNull(types.number),
  attendeeId: types.maybeNull(types.number),
  deviceId: types.maybeNull(types.string),
  createdDate: types.string,
  updatedDate: types.string,
  rate: types.maybeNull(types.number),
});

export default MAnswer;
