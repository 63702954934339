import React from 'react';
import { Image } from 'components';
import { Wrapper, Fill, IconWrapper, ImageOverlay } from './ImageRow.styles';

export interface IImageFolder {
  onClick?: () => void;
  className?: string;
  imageSrc?: string | null;
  icon?: React.ReactNode;
}

const ImageFolder: React.FC<IImageFolder> = ({ imageSrc, onClick, className, icon }) =>
  imageSrc ? (
    <Wrapper imageSrc={imageSrc} onClick={onClick} className={className}>
      <ImageOverlay>
        <Image src={imageSrc} preview={false} />
      </ImageOverlay>
      <Fill />
      {icon && <IconWrapper>{icon}</IconWrapper>}
    </Wrapper>
  ) : null;
export default ImageFolder;
