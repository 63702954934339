import React, { ErrorInfo, ReactNode } from 'react';
import { ProblemPlaceholder } from 'components';

interface IState {
  hasError: false;
}

interface IProps {
  children: ReactNode;
}

class ErrorBoundary extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): { hasError: boolean } {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.error(error, errorInfo);
  }

  render(): React.ReactNode {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return <ProblemPlaceholder type="networkError" onRefresh={() => window.location.reload()} />;
    }
    return children;
  }
}

export default ErrorBoundary;
