import React from 'react';
import { TCatalog } from 'types';
import { Image } from 'components';
import fileIcon from 'assets/icons/file.svg';
import {
  Wrapper,
  CardWrapper,
  ImageWrapper,
  ContentWrapper,
  Name,
  ChevronIconStyled,
  MediaFolderTooltip,
} from './MediaFolder.styles';

interface IMediaFolder {
  catalog: TCatalog;
  onClick: (id: number) => void;
  className?: string;
  textFolder?: boolean;
  icon?: React.ReactNode;
}

const MediaFolder: React.FC<IMediaFolder> = ({ catalog, className, onClick, textFolder, icon }) => {
  return catalog.name.toString().length > 80 ? (
    <MediaFolderTooltip title={catalog.name} getPopupContainer={(trigger) => trigger}>
      <Wrapper className={className}>
        <CardWrapper onClick={() => onClick(catalog.id)}>
          {!textFolder && (
            <ImageWrapper defaultColor={!catalog.textLogoImage}>
              <Image src={catalog.textLogoImage?.url || fileIcon} preview={false} />
            </ImageWrapper>
          )}
          <ContentWrapper>
            <Name ellipsis={{ rows: 2 }}>{catalog.name}</Name>
            {icon || <ChevronIconStyled />}
          </ContentWrapper>
        </CardWrapper>
      </Wrapper>
    </MediaFolderTooltip>
  ) : (
    <Wrapper className={className}>
      <CardWrapper onClick={() => onClick(catalog.id)}>
        {!textFolder && (
          <ImageWrapper defaultColor={!catalog.textLogoImage}>
            <Image src={catalog.textLogoImage?.url || fileIcon} preview={false} />
          </ImageWrapper>
        )}
        <ContentWrapper>
          <Name ellipsis={{ rows: 2 }}>{catalog.name}</Name>
          {icon || <ChevronIconStyled />}
        </ContentWrapper>
      </CardWrapper>
    </Wrapper>
  );
};

export default MediaFolder;
