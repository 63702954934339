import { defineMessages } from 'react-intl';

export default defineMessages({
  noInternetConnection: {
    id: 'components.ShowOfflineNotification.noConnection',
    defaultMessage: 'Отсутствует интернет-соединение',
  },
  connectionRestore: {
    id: 'components.ShowOfflineNotification.connection',
    defaultMessage: 'Соединение восстановлено',
  },
});
