import React from 'react';
import { observer } from 'mobx-react';
import { ProgressProps } from 'antd';
import store from 'store';
import { StyledProgress, ProgressOverlay } from './CircleProgress.styles';

interface OwnProps {
  strokeWidth?: number;
  percentValue: number;
  isVisible?: boolean;
}

type Props = ProgressProps & OwnProps;

const CircleProgress: React.FC<Props> = ({ strokeWidth, percentValue, isVisible, ...rest }) => {
  const { palette } = store.UIStore.theme;
  return (
    <ProgressOverlay visible={isVisible ?? true}>
      <StyledProgress
        {...rest}
        type="circle"
        strokeWidth={strokeWidth || 3}
        percent={Math.max(percentValue, 0.5)}
        trailColor={palette.dark.dark_4}
        strokeColor={palette.success.primary}
      />
    </ProgressOverlay>
  );
};

export default observer(CircleProgress);
