import React from 'react';
import { ModalProps } from 'antd';
import { StyledModal, CloseIcon } from './MediaModal.styles';

const MediaModal: React.FC<ModalProps> = (props) => {
  const { closeIcon } = props;

  return <StyledModal {...props} closeIcon={closeIcon || <CloseIcon />} footer={false} width="auto" />;
};

export default MediaModal;
