import React from 'react';
import { TCatalogPoll } from 'types';
import { ReactComponent as IconPoll } from 'assets/icons/poll.svg';
import { useAppNavigator, getCatalogIdsFromCatalogParam, getPollLocationType } from 'utils';
import { PollLocationType } from '_constants';
import TextRow from '../TextRow';

interface Props {
  poll: TCatalogPoll;
}

const Poll: React.FC<Props> = ({ poll: { name, pollId } }) => {
  const {
    goToPoll,
    goToMenuCatalogPoll,
    goToLearningTrackPoll,
    params: { catalog: catalogParam },
  } = useAppNavigator();
  const catalogIds = getCatalogIdsFromCatalogParam(catalogParam);
  const pollLocationType = getPollLocationType(pollId, catalogParam);

  const onTextRowClick = () => {
    if (pollLocationType === PollLocationType.MENU_CATALOG_POLL) {
      goToMenuCatalogPoll(catalogIds, pollId);
    } else if (pollLocationType === PollLocationType.TRACK_CONTENT_POLL) {
      goToLearningTrackPoll(pollId);
    } else {
      goToPoll(pollId);
    }
  };

  return <TextRow content={name} icon={<IconPoll />} onClick={onTextRowClick} />;
};

export default Poll;
