/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["self"] }] */
import { myTeamApi } from 'api';
import { flow, types } from 'mobx-state-tree';
import { TActivityAttendeesCountResponse, TActivityResponse } from 'types/myTeamApiTypes';
import { ActivityRange } from '_constants';
import MActivity from './MActivity';
import MActivityAttendeesCount from './MActivityAttendeesCount';
import MGroupActivityAttendees from './MActivityAttendees';

const MActivityAttendees = types
  .model({
    key: types.identifier,
    count: types.maybeNull(MActivityAttendeesCount),
    active: types.optional(MGroupActivityAttendees, {}),
    inactive: types.optional(MGroupActivityAttendees, {}),
  })
  .actions((self) => ({
    fetchActiveAttendees: flow(function* fetchActiveAttendees(groupId?: number) {
      yield self.active.fetchAttendees(self.key as ActivityRange, true, groupId);
    }),
    fetchInactiveAttendees: flow(function* fetchInactiveAttendees(groupId?: number) {
      yield self.inactive.fetchAttendees(self.key as ActivityRange, false, groupId);
    }),
    fetchAttendeesCount: flow(function* fetchGroupActivityAttendeesCount(groupId?: number) {
      const { data, hasError }: TActivityAttendeesCountResponse = yield groupId
        ? myTeamApi.groupApi.getGroupActivityAttendeesCount(groupId, self.key as ActivityRange)
        : myTeamApi.getAllActivityAttendeesCount(self.key as ActivityRange);
      if (!!data && !hasError) {
        self.count = data;
      }
      return !data;
    }),
  }));

const MActivityInfo = types
  .model({
    activityCounter: types.maybeNull(MActivity),
    attendees: types.map(MActivityAttendees),
  })
  .actions((self) => ({
    createAttendeesGroup: (key: string) => {
      if (!self.attendees.get(key)) {
        self.attendees.put({
          key,
          inactive: MGroupActivityAttendees.create(),
          active: MGroupActivityAttendees.create(),
        });
      }
    },
    fetchActivity: flow(function* fetchGroupActivity(groupId?: number) {
      const { data, hasError }: TActivityResponse = yield groupId
        ? myTeamApi.groupApi.getGroupActivity(groupId)
        : myTeamApi.getAllActivity();
      if (!!data && !hasError) {
        self.activityCounter = data;
      }
      return data;
    }),
  }));

export default MActivityInfo;
