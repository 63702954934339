import { defineMessages } from 'react-intl';

export default defineMessages({
  video: {
    id: 'views.stage.catalogElement.video',
    defaultMessage: 'Видео',
  },
  min: {
    id: 'views.stage.catalogElement.min',
    defaultMessage: 'мин',
  },
});
