import React, { useState, useRef, useEffect } from 'react';
import { useIntl } from 'react-intl';
import {
  ContainerOverlay,
  Container,
  ButtonOverlay,
  ButtonText,
  Icon,
  DefaultButtonBlock,
} from './ShowMoreBlock.styles';
import messages from './messages';

interface IShowMoreBlockProps {
  content: React.ReactNode;
  maxHeight: number;
  buttonBlock?: React.ReactNode;
  isSingleOpening?: boolean;
  onButtonClickHandler?: () => void;
}

const ShowMoreBlock: React.FC<IShowMoreBlockProps> = ({
  content,
  maxHeight,
  buttonBlock,
  isSingleOpening,
  onButtonClickHandler,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [contentHeight, setContentHeight] = useState<number | null>(null);
  const [isHideExpanded, setHideExpanded] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const intl = useIntl();

  useEffect(() => {
    if (contentRef.current) {
      const height = contentRef.current.clientHeight;
      setContentHeight(height);
      setIsExpanded(height <= maxHeight);
    }
  }, [maxHeight]);

  const toggleExpand = () => {
    if (onButtonClickHandler) {
      onButtonClickHandler();
    }
    if (isSingleOpening) {
      setHideExpanded(!isHideExpanded);
    }
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <ContainerOverlay $isExpanded={isExpanded} $maxHeight={maxHeight}>
        <Container ref={contentRef}>{content}</Container>
      </ContainerOverlay>
      <ButtonOverlay isShown={!isHideExpanded && !!contentHeight && contentHeight > maxHeight} onClick={toggleExpand}>
        {buttonBlock || (
          <DefaultButtonBlock>
            <ButtonText>
              {isExpanded ? intl.formatMessage(messages.showMoreText) : intl.formatMessage(messages.showLessText)}
            </ButtonText>
            <Icon $isExpanded={isExpanded} />
          </DefaultButtonBlock>
        )}
      </ButtonOverlay>
    </>
  );
};

export default ShowMoreBlock;
