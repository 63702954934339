import { observer } from 'mobx-react';
import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { ReactComponent as ChevronRight } from 'assets/icons/chevron-right.svg';
import { ReactComponent as IconCompleted } from 'assets/icons/completed.svg';
import { TaskStatus } from 'models/Tasks';

import messages from './messages';
import {
  Task,
  TaskIcon,
  TaskName,
  Wrapper,
  TaskInfoBlock,
  InfoIcon,
  StyledTooltip,
  IconOverlay,
  StatusLabel,
} from './TaskElement.styles';

interface ITaskElement {
  title: string;
  status: TaskStatus;
  deadline: string | null;
  courseTrackName?: string;
  onClick?: () => void;
}

const TaskElement: React.FC<ITaskElement> = ({ title, status, deadline, courseTrackName, ...rest }) => {
  const intl = useIntl();

  const [isHovered, setIsHovered] = useState(false);

  const taskStatusText = useMemo(() => {
    switch (status) {
      case TaskStatus.NEW:
        return intl.formatMessage(messages.newStatus);
      case TaskStatus.SUBMITTED:
      case TaskStatus.IN_REVIEW:
        return intl.formatMessage(messages.inReviewStatus);
      case TaskStatus.REJECTED:
        return intl.formatMessage(messages.rejectedStatus);
      default:
        return null;
    }
  }, [intl, status]);

  const getTaskIcon = (taskStatus?: TaskStatus) => {
    if (taskStatus === TaskStatus.COMPLETED && !isHovered) {
      return <IconCompleted />;
    }
    return <ChevronRight />;
  };

  // Раскомментировать при появлении deadlineDate
  // const deadlineTime = deadline
  //   ? new Intl.DateTimeFormat('ru', { dateStyle: 'long' }).format(new Date(deadline))
  //   : null;

  const showTaskInfoBlock =
    !!courseTrackName || !!deadline || (status !== TaskStatus.STARTED && status !== TaskStatus.COMPLETED);

  return (
    <Task {...rest} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <Wrapper>
        {showTaskInfoBlock && (
          <TaskInfoBlock>
            <StatusLabel status={status}>{taskStatusText}</StatusLabel>
            {!!courseTrackName && (
              <StyledTooltip
                title={intl.formatMessage(messages.courseTrackName, { name: courseTrackName })}
                getPopupContainer={(trigger) => trigger}
              >
                <IconOverlay>
                  <InfoIcon />
                </IconOverlay>
              </StyledTooltip>
            )}
          </TaskInfoBlock>
        )}
        <TaskName ellipsis={{ rows: showTaskInfoBlock ? 1 : 2 }}>{title}</TaskName>
      </Wrapper>
      <TaskIcon>{getTaskIcon(status)}</TaskIcon>
    </Task>
  );
};

export default observer(TaskElement);
