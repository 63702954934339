import { defineMessages } from 'react-intl';

export default defineMessages({
  networkErrorTitle: {
    id: 'components.problemPlaceholder.networkErrorTitle',
    defaultMessage: 'Проверьте интернет-соединение и повторите попытку',
  },
  networkErrorButtonTitle: {
    id: 'components.problemPlaceholder.networkErrorButtonTitle',
    defaultMessage: 'Обновить',
  },
  error404Title: {
    id: 'components.problemPlaceholder.error404Title',
    defaultMessage: 'Упс! К сожалению, запрашиваемая Вами страница не найдена…',
  },
  error404TitleButtonTitle: {
    id: 'components.problemPlaceholder.error404TitleButtonTitle',
    defaultMessage: 'Назад',
  },
  error403Title: {
    id: 'components.problemPlaceholder.error403Title',
    defaultMessage: 'Ой! К сожалению, доступ к запрашиваемой Вами странице запрещен…',
  },
  error403TitleButtonTitle: {
    id: 'components.problemPlaceholder.error403TitleButtonTitle',
    defaultMessage: 'Назад',
  },
});
