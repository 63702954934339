import styled from 'styled-components';
import { Radio } from 'antd';
import { Grid } from '_constants';
import { Wrapper } from '../Notification/NotificationBadge/NotificationBadge.styles';

export const RadioGroup = styled(Radio.Group)`
  &&& {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -6px;
  }
`;

export const RadioButton = styled(Radio.Button)`
  &&& {
    &.ant-radio-button-wrapper {
      display: flex;
      align-items: center;
      height: 36px;
      margin: 0 6px;
      padding: 0 16px;
      border: 1px solid ${({ theme }) => theme.palette.dark.dark_8};
      font-weight: 500;
      font-size: 14px;
      border-radius: 8px;
      font-family: 'Roboto', sans-serif;
      line-height: 14px;
      color: ${({ theme }) => theme.palette.dark.main};
      :not(:first-child)::before {
        content: none;
      }
      &:not(.ant-radio-button-wrapper-disabled):hover {
        border-color: ${({ theme }) => theme.palette.primary.main};
        box-shadow: none;
        transition: background-color 0.4s, color 0.4s, border-color 0.4s, box-shadow 0.4s;
      }

      span {
        display: flex;
        align-items: center;
      }
      @media (max-width: ${Grid.XS}px) {
        margin: 0 3px;
        padding: 0 12px;
      }
    }
    &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      border-color: ${({ theme }) => theme.palette.primary.main};
      background: ${({ theme }) => theme.palette.primary.main};
      color: ${({ theme }) => theme.palette.white.primary};
      box-shadow: none;
      span {
        z-index: 1;
        display: flex;
        align-items: center;
      }
      & ${Wrapper} {
        background-color: ${({ theme }) => theme.palette.notify};
        color: ${({ theme }) => theme.palette.white.primary};
      }
      &:hover {
        background: linear-gradient(
            ${({ theme }) => theme.palette.white.white_12},
            ${({ theme }) => theme.palette.white.white_12}
          ),
          ${({ theme }) => theme.palette.primary.main};
      }
    }
    &.ant-radio-button-wrapper-disabled {
      background: ${({ theme }) => theme.palette.white.primary};
      color: ${({ theme }) => theme.palette.dark.dark_40};
    }
  }
`;
