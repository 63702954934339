/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["self"] }] */
import { Instance, types } from 'mobx-state-tree';
import MAnswer from './MAnswer';
import MScreen from './MScreen';
import MResultScreenSettings from './MResultScreenSettings';
import { PollActivationMode, PollShowRightAnswerType, PollStatus, PollValueType } from './constants';

const MPoll = types
  .model({
    id: types.number,
    name: types.string,
    eventId: types.number,
    status: types.enumeration<PollStatus>('Status', Object.values(PollStatus)),
    screens: types.array(MScreen),
    answers: types.array(MAnswer),
    resultScreenSettings: types.maybeNull(MResultScreenSettings),
    canBeRetaken: types.maybeNull(types.boolean),
    isQuestionRandomSetEnabled: types.maybeNull(types.boolean),
    isCompleted: types.boolean,
    activationMode: types.enumeration<PollActivationMode>('ActivationMode', Object.values(PollActivationMode)),
    activationDate: types.maybeNull(types.string),
    overrideGlobalSettings: types.maybeNull(types.boolean),
    sendPushOnStart: types.boolean,
    showResults: types.boolean,
    isAuthRequired: types.boolean,
    canSkipQuestions: types.boolean,
    uuid: types.string,
    publishReason: types.array(types.string),
    showRightAnswerAfterAnswerComplete: types.boolean,
    showRightAnswerAfterTestComplete: types.boolean,
    showRightAnswerTypeValue: types.enumeration<PollShowRightAnswerType>(
      'ShowRightAnswerType',
      Object.values(PollShowRightAnswerType),
    ),
    typeValue: types.enumeration<PollValueType>('PollValueType', Object.values(PollValueType)),
    createdDate: types.string,
    updatedDate: types.string,
    shuffleQuestions: types.maybe(types.boolean),
    shuffleOptions: types.maybe(types.boolean),
  })
  .views((self) => ({
    get firstScreen() {
      return self.screens[0] || null;
    },
    getUserScore(answers: Instance<typeof MAnswer>[]) {
      return self.screens.reduce(
        (acc, { getUserScore }) => acc + getUserScore(answers, self.isQuestionRandomSetEnabled),
        0,
      );
    },
    getUserScoreWithoutAnswers(answers: Instance<typeof MAnswer>[]) {
      const scoreWithoutAnswers = JSON.parse(JSON.stringify(answers)).reduce(
        (acc: number, currentValue: { rate: number }) => acc + currentValue.rate,
        0,
      );
      return scoreWithoutAnswers;
    },
    get maxScore() {
      return self.screens.reduce((acc, { getMaxScore }) => acc + getMaxScore(self.isQuestionRandomSetEnabled), 0);
    },
    get isActivePoll() {
      return self.status === PollStatus.ACTIVE;
    },
    get isPendingPoll() {
      return self.status === PollStatus.PENDING;
    },
    get isFinishedPoll() {
      return self.status === PollStatus.FINISHED;
    },
    get isCompletedPoll() {
      return self.status === PollStatus.FINISHED && self.isCompleted;
    },
    get isMissedPoll() {
      return self.status === PollStatus.FINISHED && !self.isCompleted;
    },
    getScreen: (screenId: number) => {
      return self.screens.find(({ id }) => id === screenId) || null;
    },
    getNextScreenByCurrent: (screenId: number) => {
      const currentIndex = self.screens.findIndex(({ id }) => id === screenId);
      return currentIndex === -1 ? null : self.screens[currentIndex + 1] || null;
    },
    getPreviousScreenByCurrent: (screenId: number) => {
      const index = self.screens.findIndex(({ id }) => id === screenId) - 1;
      return index < 0 ? null : self.screens[index];
    },
    getQuestionAnswers: (questionId: number) => {
      return self.answers.filter(({ questionId: id }) => questionId === id);
    },
  }));

export default MPoll;
