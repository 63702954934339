// @ts-nocheck : Used web-components for Vime js
import React, { Ref } from 'react';
import { Player as VimePlayer, ViewType, DefaultUi } from '@vime/react';
import CustomPlaybackControl from '../CustomPlaybackControl';
import translations from '../translations';

interface IYoutubePlayer {
  videoId: string;
  playerRef?: Ref<HTMLVmPlayerElement>;
  onPlay?: (e: CustomEvent<void>) => void;
  onPause?: (e: CustomEvent<boolean>) => void;
  language?: string;
}

const YoutubePlayer: React.FC<IYoutubePlayer> = ({ videoId, playerRef, language, onPlay, onPause }) => {
  const i18n = (language && translations[language]) || translations.ru;
  /* eslint-disable */
  return (
    /* HACK: Переписываем переводы для текущей локали,
     т.к. используем веб-компоненту и обычный способ локализации не работает */
    <VimePlayer ref={playerRef} viewType={ViewType.Video} onVmPlay={onPlay} onVmPausedChange={onPause} i18n={i18n}>
      <vm-youtube video-id={videoId}></vm-youtube>
      <DefaultUi>
        <CustomPlaybackControl playerRef={playerRef} />
      </DefaultUi>
    </VimePlayer>
  );
  /* eslint-enable */
};

export default YoutubePlayer;
