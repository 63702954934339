/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["self"] }] */
import { types } from 'mobx-state-tree';
import { MModal } from 'models';

const LearningTrack = types
  .model({
    finishTrackModal: types.optional(MModal, {}),
    finishTrackId: types.maybeNull(types.string),
    isFetchingTrack: false,
  })
  .actions((self) => ({
    setFetchingTrack: (state: boolean) => {
      self.isFetchingTrack = state;
    },
    setFinishTrackId: (trackId: string) => {
      self.finishTrackId = trackId;
    },
  }));

export default LearningTrack;
