import styled from 'styled-components';
import { Title, Text } from 'components';

export const StyledTitle = styled(Title)`
  &&&& {
    word-break: break-word;
    text-align: center;
  }
`;

export const StyledText = styled(Text)`
  &&&& {
    margin-top: 14px;
    margin-bottom: 16px;
    font-size: 16px;
    text-align: center;
    white-space: pre-line;
    word-break: break-word;
  }
`;

export const ImageWrapper = styled.div`
  margin-bottom: 32px;
`;
