import { initializeApp, FirebaseApp } from 'firebase/app';
import { getAnalytics, setUserProperties, setUserId, logEvent, Analytics } from 'firebase/analytics';
import { getMobileApiHost, isTestEnv } from 'env';
import { TEventInfoFirebaseConfig } from 'types';

declare global {
  interface Window {
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    ym?: any;
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    Ya?: any;
  }
}

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
type ParametersExceptFirst<T extends (...args: any) => any> = T extends (ignored: infer _, ...args: infer P) => any
  ? P
  : never;

const isAnalyticsDisabled = process.env.REACT_APP_ANALYTICS !== 'true';

const staticPlatformProperties = {
  platform: 'TR WEB',
  server_name: process.env.REACT_APP_ENV,
  server_type: getMobileApiHost(),
  is_test_event: isTestEnv(),
};

let firebaseApp: FirebaseApp | undefined;
let firebaseAnalytics: Analytics | undefined;

let isYandexMetrikaEnabled: boolean | undefined;
let isFirebaseAnalyticsEnabled: boolean | undefined;

export const setIsYandexMetrikaEnabled = (yandexMetrikaEnabled: boolean): void => {
  isYandexMetrikaEnabled = yandexMetrikaEnabled;
};

export const setIsFirebaseAnalyticsEnabled = (firebaseAnalyticsEnabled: boolean): void => {
  isFirebaseAnalyticsEnabled = firebaseAnalyticsEnabled;
};

export const initializeFirebaseAnalytics = (firebaseConfig: TEventInfoFirebaseConfig): void => {
  firebaseApp = initializeApp(firebaseConfig);
  firebaseAnalytics = getAnalytics(firebaseApp);
  setUserProperties(firebaseAnalytics, staticPlatformProperties);
};

let properties = staticPlatformProperties;

export const setAnalyticsUserId = (userId: string | null): void => {
  // Установка id пользователя для Яндекс.Метрики
  properties = { ...properties, ...{ user_id: userId } };
  // Установка id пользователя для Firebase Analytics
  if (firebaseAnalytics) {
    setUserId(firebaseAnalytics, userId);
  }
};

export const setAnalyticsUserProperties = (userProperties: {
  conf_id?: number | null;
  authorized_user?: string;
}): void => {
  // Установка свойств пользователя для Яндекс.Метрики
  properties = { ...properties, ...userProperties };
  // Установка свойств пользователя для Firebase Analytics
  if (firebaseAnalytics) {
    setUserProperties(firebaseAnalytics, userProperties);
  }
};

export const sendAnalyticsEvent = (...args: ParametersExceptFirst<typeof logEvent>): void => {
  if (isAnalyticsDisabled) return;

  const [target, params] = args;

  if (isYandexMetrikaEnabled) {
    // Получение ID счётчика Яндекс.Метрики
    // eslint-disable-next-line no-underscore-dangle
    const yaMetrikaCounterId = window.Ya?._metrika?.getCounters?.()[0]?.id;
    // Отправка события в Яндекс.Метрику
    // Для отладки можно к URL страницы добавить URL-параметр _ym_debug=1
    if (yaMetrikaCounterId) {
      window.ym?.(yaMetrikaCounterId, 'reachGoal', target, { ...params, ...properties });
    }
  }

  if (isFirebaseAnalyticsEnabled) {
    // Отправка события в Firebase Analytics
    // Для отладки в браузерах на основе Chromium можно установить расширение
    // https://chrome.google.com/webstore/detail/jnkmfdileelhofjcijamephohjechhna
    if (firebaseAnalytics) {
      logEvent(firebaseAnalytics, ...args);
    }
  }
};
