import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { TCatalog } from 'types';
import { ViewOptions } from 'models/Catalogs/constants';
import { ReactComponent as FolderIcon } from 'assets/icons/folder-closed.svg';
import ImageRow from '../ImageRow';
import MediaFolder from './MediaFolder';

interface Props {
  catalog: TCatalog;
}

const Catalog: React.FC<Props> = ({ catalog }) => {
  const history = useHistory();
  const location = useLocation();

  const onClickHandler = (): void => {
    history.push(`${location.pathname}/catalog/${catalog.id}`);
  };

  switch (catalog.viewOptions) {
    case ViewOptions.IMAGE:
      return (
        <ImageRow
          imageSrc={catalog.fullLogoImage?.url}
          onClick={onClickHandler}
          icon={<FolderIcon width={16} height={16} />}
        />
      );
    case ViewOptions.MEDIA:
      return <MediaFolder catalog={catalog} onClick={onClickHandler} />;
    case ViewOptions.TEXT:
      return <MediaFolder catalog={catalog} onClick={onClickHandler} textFolder />;
    default:
      return null;
  }
};

export default Catalog;
