import React, { useEffect, useState } from 'react';
import { RadioChangeEvent } from 'antd';
import { useIntl } from 'react-intl';
import { FieldType } from 'models/Tasks/constants';
import { TTaskFormField } from 'views/MyTasks/Task/components/TaskForm/TaskForm.types';
import { ButtonsGroup, RadioButton } from './TaskTypeSelector.styles';
import messages from './messages';

enum Tabs {
  ATTACHMENT = 'attachment',
  LINK = 'link',
}

interface ITaskTypeSelector {
  viewOnly?: boolean;
  children: (fieldType: FieldType) => React.ReactNode;
  fieldValue?: TTaskFormField;
  onChange?: () => void;
}

const TaskTypeSelector: React.FC<ITaskTypeSelector> = ({ viewOnly, children, fieldValue, onChange }) => {
  const intl = useIntl();
  const [activeTab, setActiveTab] = useState(Tabs.ATTACHMENT);

  useEffect(() => {
    if (fieldValue?.value) {
      setActiveTab(Array.isArray(fieldValue.value) ? Tabs.ATTACHMENT : Tabs.LINK);
    }
  }, [fieldValue]);

  const onSelectTab = ({ target: { value } }: RadioChangeEvent) => {
    setActiveTab(value);
    onChange?.();
  };

  const fieldType = activeTab === Tabs.LINK ? FieldType.LINK : FieldType.ATTACHMENT;

  return (
    <>
      <ButtonsGroup hidden={viewOnly} onChange={onSelectTab} value={activeTab}>
        {Object.values(Tabs).map((tab) => (
          <RadioButton key={tab} value={tab} checked={activeTab === tab}>
            {tab === Tabs.ATTACHMENT
              ? intl.formatMessage(messages.fileButtonTitle)
              : intl.formatMessage(messages.linkButtonTitle)}
          </RadioButton>
        ))}
      </ButtonsGroup>
      {children(fieldType)}
    </>
  );
};

export default TaskTypeSelector;
