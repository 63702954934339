/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["self"] }] */
import { types, cast, getRoot, Instance } from 'mobx-state-tree';
import { MBreadcrumb } from 'models';
import { TBreadcrumb, TBreadcrumbItem, TStore } from 'types';
import {
  TProblemPlaceholderTypes,
  ProblemPlaceholderTypes,
} from 'components/ProblemPlaceholder/ProblemPlaceholderTypes';
import Course from './Course';
import Gamification from './Gamification';
import MyTeam from './MyTeam';
import LearningTrack from './LearningTrack';

const initBreadcrumb: TBreadcrumb = {
  isShow: false,
  items: cast([]),
  onBackLocation: null,
};

const Layout = types
  .model({
    title: types.maybeNull(types.string),
    breadcrumb: types.optional(MBreadcrumb, initBreadcrumb),
    isMenuCollapsed: false,
    placeholder: types.maybeNull(
      types.enumeration<TProblemPlaceholderTypes>('Locale', Object.values(ProblemPlaceholderTypes)),
    ),
    isReceivedEventInfo: false,
    isFetchingProfile: false,
    isFetchingComponents: false,
    notFoundStatusReceived: false,
    isStageLoading: false,
    scrollPositions: types.map(types.model({ key: types.identifier, scrollTop: types.number })),
    isNotificationsCollapsed: true,
  })
  .volatile(() => ({
    activePlayerRef: null as HTMLVmPlayerElement | null,
  }))
  .views((self) => ({
    get courseUIStore(): Instance<typeof Course> {
      return getRoot<TStore>(self).UIStore.course;
    },
    get gamificationUIStore(): Instance<typeof Gamification> {
      return getRoot<TStore>(self).UIStore.gamification;
    },
    get groupGamificationUIStore(): Instance<typeof Gamification> {
      return getRoot<TStore>(self).UIStore.groupGamification;
    },
    get myTeamUIStore(): Instance<typeof MyTeam> {
      return getRoot<TStore>(self).UIStore.myTeam;
    },
    get learningTrackUIStore(): Instance<typeof LearningTrack> {
      return getRoot<TStore>(self).UIStore.learningTrack;
    },
  }))
  .views((self) => ({
    get isShowBreadcrumb() {
      return !!(self.breadcrumb?.isShow && self.breadcrumb?.items?.length);
    },
    get isShowMainSpinner() {
      const { isFetchingCourseRequiredData, isFetchingCourses, isFetchingPoll } = self.courseUIStore;
      const { isGamificationDataLoading } = self.gamificationUIStore;
      const { isGamificationDataLoading: isGroupGamificationDataLoading } = self.groupGamificationUIStore;
      const { isFetching: isFetchingMyTeam, isFetchingGroups: isFetchingMyTeamGroups } = self.myTeamUIStore;
      const { isFetchingTrack } = self.learningTrackUIStore;
      return (
        isFetchingCourseRequiredData ||
        isFetchingCourses ||
        isFetchingPoll ||
        self.isFetchingComponents ||
        isGamificationDataLoading ||
        isGroupGamificationDataLoading ||
        isFetchingMyTeam ||
        isFetchingMyTeamGroups ||
        isFetchingTrack
      );
    },
  }))
  .actions((self) => ({
    showBreadcrumb: (items: TBreadcrumbItem[], onBackLocation: string | null = null) => {
      self.breadcrumb = {
        isShow: true,
        items: cast(items),
        onBackLocation,
      };
    },
    hideBreadcrumb: () => {
      self.breadcrumb = {
        isShow: false,
        items: cast([]),
        onBackLocation: null,
      };
    },
    show404placeholder: () => {
      self.placeholder = ProblemPlaceholderTypes.ERROR_404;
    },
    show403placeholder: () => {
      self.placeholder = ProblemPlaceholderTypes.ERROR_403;
    },
    resetPlaceholder: () => {
      self.placeholder = null;
    },
    setTitle: (title: string | null) => {
      self.title = title;
    },
    setIsMenuCollapsed: (isCollapsed: boolean) => {
      self.isMenuCollapsed = isCollapsed;
    },
    toggleIsMenuCollapsed: () => {
      self.isMenuCollapsed = !self.isMenuCollapsed;
    },
    setReceivedEventInfo(isReceivedEventInfo: boolean) {
      self.isReceivedEventInfo = isReceivedEventInfo;
    },
    setFetchingProfile(isFetchingProfile: boolean) {
      self.isFetchingProfile = isFetchingProfile;
    },
    setFetchingComponents(isFetchingComponents: boolean) {
      self.isFetchingComponents = isFetchingComponents;
    },
    onNotFoundStatusReceived() {
      self.notFoundStatusReceived = true;
    },
    setStageLoading(isStageLoading: boolean) {
      self.isStageLoading = isStageLoading;
    },
    setActivePlayerRef(current: HTMLVmPlayerElement | null) {
      self.activePlayerRef = current;
    },
    setScrollPosition(key: string, scrollTop: number) {
      self.scrollPositions.set(key, { key, scrollTop });
    },
    setIsNotificationsCollapsed: (isCollapsed: boolean) => {
      self.isNotificationsCollapsed = isCollapsed;
    },
    hideNotificationsBar: () => {
      self.isNotificationsCollapsed = true;
    },
  }));

export default Layout;
