import React from 'react';
import { TGroupAttendee } from 'types';
import mailIcon from 'assets/icons/mail.svg';
import phoneIcon from 'assets/icons/phone.svg';
import { Spinner } from 'components';
import ContactItem from './ContactItem';
import NoItemsPlaceholder from './NoItemsPlaceholder';
import { StyledModal, AttendeeStyled, AttendeeWrapper, SpinnerWrapper } from './ContactsModal.styles';

interface IProps {
  visible: boolean;
  onClose: () => void;
  attendee: TGroupAttendee | null;
  isLoading?: boolean;
}

const ContactsModal: React.FC<IProps> = ({ visible, onClose, attendee, isLoading = false }) => (
  <StyledModal visible={visible} onCancel={onClose} onOk={onClose} footer={false}>
    {isLoading ? (
      <SpinnerWrapper>
        <Spinner fullscreen size="small" />
      </SpinnerWrapper>
    ) : (
      <>
        {attendee && (
          <>
            <AttendeeWrapper>
              <AttendeeStyled attendee={attendee} noIconButton isModal />
            </AttendeeWrapper>

            {!!attendee.phone || !!attendee.email ? (
              <>
                {!!attendee.phone && (
                  <ContactItem href={`tel:${attendee.phone}`} text={attendee.phone} icon={phoneIcon} />
                )}
                {!!attendee.email && (
                  <ContactItem href={`mailto:${attendee.email}`} text={attendee.email} icon={mailIcon} />
                )}
              </>
            ) : (
              <NoItemsPlaceholder />
            )}
          </>
        )}
      </>
    )}
  </StyledModal>
);

export default ContactsModal;
