import styled from 'styled-components';

interface IActive {
  active?: boolean;
}

export const Wrapper = styled.div<IActive>`
  height: 20px;
  padding: 0 8px;
  background-color: ${({ active, theme }) => (active ? theme.palette.white.primary : theme.palette.notify)};
  color: ${({ active, theme }) => (active ? theme.palette.dark.main : theme.palette.white.primary)};
  border-radius: 10px;
`;

export const Text = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
`;
