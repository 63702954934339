import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import chevronIcon from 'assets/icons/chevron-breadcrumb.svg';
import { Grid } from '_constants';
import { TextStyled } from './BreadcrumbItemLink/BreadcrumbItemLink.styles';

interface IBreadcrumbItem {
  fixMinWidth?: boolean;
}

export const LinkStyled = styled(Link)``;

export const BreadcrumbItem = styled.div<IBreadcrumbItem>`
  ${({ fixMinWidth }) =>
    fixMinWidth &&
    css`
      flex: 0 0 auto;
    `}
`;

export const BreadcrumbOverlay = styled.div`
  position: relative;
  z-index: 12;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  background-color: ${({ theme: { palette } }) => palette.white.primary};
  box-shadow: inset 0 -1px 0 0 ${({ theme: { palette } }) => palette.dark.dark_8};
`;

export const Breadcrumbs = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${Grid.MD}px) {
    overflow-x: scroll;
  }
  ${BreadcrumbItem} {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    @media (max-width: ${Grid.MD}px) {
      display: flex;
    }
    & > span {
      white-space: nowrap;
    }
    ${LinkStyled} {
      display: flex;
    }

    ${TextStyled} {
      color: ${({ theme: { palette } }) => palette.dark.dark_40};
    }

    &:last-child {
      ${TextStyled} {
        color: ${({ theme: { palette } }) => palette.dark.main};
      }
    }

    &:nth-child(n + 3) {
      grid-template-columns: 20px minmax(0, 1fr);
      grid-column-gap: 5px;
      &::before {
        content: '';
        width: 100%;
        height: 100%;
        background: url(${chevronIcon});
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        @media (max-width: ${Grid.MD}px) {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
`;

export const BackButton = styled(Button)`
  &&& {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    padding: 0;
    border: none;
    background-color: transparent;
    box-shadow: none;
    outline: none;
    path {
      fill: ${({ theme: { palette } }) => palette.dark.dark_20};
    }
    &:hover path {
      fill: ${({ theme: { palette } }) => palette.dark.dark_40};
    }
  }
`;
