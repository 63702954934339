/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["self"] }] */
import api from 'api/api';
import { flow, getRoot, types } from 'mobx-state-tree';
import {
  setIsYandexMetrikaEnabled,
  setIsFirebaseAnalyticsEnabled,
  initializeFirebaseAnalytics,
  setAnalyticsUserProperties,
} from 'utils/analytics';
import { eventSettingsApi } from 'api';
import { TEventInfoResponse } from 'types/eventSettingsApiTypes';
import { MEventInfo, ConfirmationTypes } from 'models';
import { TStore } from 'types';
import { autorun } from 'mobx';

const EventSettings = types
  .model({
    eventInfo: types.optional(MEventInfo, {}),
  })
  .views((self) => ({
    get layoutUIStore() {
      return getRoot<TStore>(self).UIStore.layout;
    },
    get themeUIStore() {
      return getRoot<TStore>(self).UIStore.theme;
    },
    get hasEmailConfirmation() {
      return self.eventInfo.confirmationTypes.includes(ConfirmationTypes.EMAIL);
    },
    get hasSmsConfirmation() {
      return self.eventInfo.confirmationTypes.includes(ConfirmationTypes.SMS);
    },
  }))
  .actions((self) => ({
    fetchEventSettings: flow(function* fetchEventSettings(alias: string) {
      self.layoutUIStore.setReceivedEventInfo(true);
      const { hasError, data }: TEventInfoResponse = yield eventSettingsApi.getEventInfo(alias);
      if (!hasError && data) {
        self.eventInfo = data;
        api.publicId = data.eventPublicId;
        self.themeUIStore.setAccentColor(data.accentColor);
        if (data.dominantColor) {
          self.themeUIStore.setDominantColor(data.dominantColor);
        }
        if (data.additionalDominantColor) {
          self.themeUIStore.setAdditionalDominantColor(data.additionalDominantColor);
        }
        setIsYandexMetrikaEnabled(data.yandexMetrikaEnabled);
        setIsFirebaseAnalyticsEnabled(data.firebaseAnalyticsEnabled);
        if (data.firebaseConfig) {
          initializeFirebaseAnalytics(data.firebaseConfig);
        }
        setAnalyticsUserProperties({
          conf_id: data.eventId,
        });
        if (data.eventTitle) {
          document.title = data.eventTitle;
        }
      }
    }),
    afterCreate() {
      autorun(() => {
        api.publicId = self.eventInfo.eventPublicId;
      });
    },
  }));

export default EventSettings;
