import { types } from 'mobx-state-tree';

const Image = types.model({
  id: types.number,
  url: types.string,
  width: types.number,
  height: types.number,
});

export default Image;
