import styled from 'styled-components';
import { Grid } from '_constants';
import { TextRowWrapper } from '../../CatalogElement.styles';

export const VideoName = styled.div`
  display: block;
  overflow: hidden;
  margin: 4px 0 10px 4px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.dark.main};
  transition: color 0.4s;
  @media (max-width: ${Grid.XS}px) {
    white-space: normal;
  }
`;

export const Container = styled.div`
  position: relative;
  cursor: pointer;
  border-radius: 8px;
  overflow: hidden;
  ${TextRowWrapper} + ${TextRowWrapper} > & {
    margin-top: 48px;
  }
`;

export const PlayIcon = styled.img`
  position: absolute;
  z-index: 40;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  transform: translate(-50%, -50%);
  transition: transform 0.2s, opacity 0.2s ease-in-out;
  pointer-events: none;
`;
