import styled from 'styled-components';
import { Image } from 'antd';

interface IImagePlaceholder {
  backgroundColor?: string | null;
}

export const BackgroundImageWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const StyledImage = styled(Image)`
  object-fit: cover;
`;

export const ImagePlaceholder = styled.div<IImagePlaceholder>`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: -1;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;
