/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["self"] }] */
import { flow, types, getRoot } from 'mobx-state-tree';
import { setAnalyticsUserId, setAnalyticsUserProperties } from 'utils/analytics';
import { setUserGroups } from 'api/myTeamApi';
import { profileApi } from 'api';
import { MProfile } from 'models';
import TProfileResponse from 'types/profileApiTypes';
import { TStore } from 'types';

const Profile = types
  .model({
    data: types.maybeNull(MProfile),
  })
  .views((self) => ({
    get layoutUIStore() {
      return getRoot<TStore>(self).UIStore.layout;
    },
    get groupIds() {
      return self.data?.groups?.map((group) => group.id) || [];
    },
  }))
  .actions((self) => ({
    fetchProfileInfo: flow(function* fetchProfileInfo() {
      self.layoutUIStore.setFetchingProfile(true);
      const { data, hasError }: TProfileResponse = yield profileApi.getProfile();
      if (!hasError && data) {
        self.data = data;
        setUserGroups(data.groups);
        setAnalyticsUserId(data.id?.toString() || null);
        setAnalyticsUserProperties({
          authorized_user: 'yes',
        });
      }
      self.layoutUIStore.setFetchingProfile(false);
    }),
  }));

export default Profile;
