import React, { useState } from 'react';
import { DomainStore } from 'store';
import { TCatalogAttendee, TGroupAttendee } from 'types';
import { ContactsModal, AttendeeView } from 'components';
import { useAppNavigator } from 'utils';
import { AttendeeWrapper } from './Attendee.styles';

interface IContactsModalState {
  visible: boolean;
  attendee: TGroupAttendee | null;
}

const initContactsModalState: IContactsModalState = {
  visible: false,
  attendee: null,
};

interface Props {
  attendee: TCatalogAttendee;
}

const AttendeeElement: React.FC<Props> = ({ attendee }) => {
  const [contactsModalState, setContactsModalState] = useState<IContactsModalState>(initContactsModalState);
  const [isLoading, setLoading] = useState(false);
  const [attendeeData, setAttendeeData] = useState<TGroupAttendee | null>(null);
  const { fetchAttendee } = DomainStore.catalog;
  const {
    params: { trackId },
  } = useAppNavigator();

  const onInfoClickHandler = async () => {
    if (attendeeData) {
      setContactsModalState({ visible: true, attendee: attendeeData });
      return;
    }
    setLoading(true);
    const data = await fetchAttendee(attendee.attendeeId, trackId);
    setLoading(false);
    if (data) {
      setAttendeeData(data);
      setContactsModalState({ visible: true, attendee: data });
    }
  };

  const mappedAttendee: TGroupAttendee = {
    ...attendee,
    company: attendee.companyName,
    thumbnail: attendee.imagePath,
    id: attendee.attendeeId,
    locale: undefined,
  };

  return (
    <>
      <AttendeeWrapper>
        <AttendeeView
          attendee={mappedAttendee}
          onInfoClick={onInfoClickHandler}
          isLoading={isLoading}
          noIconButton={!!attendee.isDisabled}
        />
      </AttendeeWrapper>

      <ContactsModal
        visible={contactsModalState.visible}
        attendee={contactsModalState.attendee}
        onClose={() =>
          setContactsModalState((prev: IContactsModalState) => ({ attendee: null, visible: !prev.visible }))
        }
      />
    </>
  );
};
export default AttendeeElement;
