import styled from 'styled-components';
import { Text } from 'components';

export const Wrapper = styled.div`
  padding-top: 20px;
  text-align: center;
`;

export const TextStyled = styled(Text)`
  &&& {
    font-size: 16px;
  }
`;
