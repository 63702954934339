/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["self"] }] */
import { types, cast, flow, getRoot } from 'mobx-state-tree';
import { MRating, MRule, MPrize, MRatingDetail } from 'models';
import { TRatingsResponse, TRatingDetailsResponse, TSearchedRatingsResponse } from 'types/gamificationApiTypes';
import { TStore } from 'types';
import { GamificationTypes } from 'types/gamificationTypes';
import { RATINGS_PAGE_SIZE } from 'views/Gamification/constants';
import { gamificationApi } from 'api';
import { TGamificationResponse } from '../../types/gamificationApiTypes';

const Gamification = types
  .model({
    gamificationType: types.enumeration<GamificationTypes>('GamificationType', Object.values(GamificationTypes)),
    ratings: types.array(MRating),
    ratingDetails: types.array(MRatingDetail),
    authorizedUserRating: types.maybeNull(MRating),
    rules: types.array(MRule),
    rulesHeader: '',
    pointsLabel: '',
    prizes: types.array(MPrize),
  })
  .views((self) => ({
    get allCount() {
      return self.ratings.length;
    },
    getPrize(id: number) {
      return self.prizes.find((prize) => prize.id === id) || null;
    },
    get gamificationUIStore() {
      const { gamification, groupGamification } = getRoot<TStore>(self).UIStore;
      return self.gamificationType === GamificationTypes.GROUP ? groupGamification : gamification;
    },
  }))
  .actions((self) => ({
    fetchRatings: flow(function* fetchRatings(pageSize: number = RATINGS_PAGE_SIZE) {
      self.gamificationUIStore.setRatingsLoading(true);
      const { data, hasError }: TRatingsResponse = yield gamificationApi.getRatings(self.gamificationType, 0, pageSize);
      if (!hasError && data) {
        self.ratings = cast(data.ratings);
        self.authorizedUserRating = data.authorizedUserRating;
      }
      self.gamificationUIStore.setRatingsLoading(false);
    }),
  }))
  .actions((self) => ({
    fetchSearchedRatings: flow(function* fetchSearchedRatings(search: string) {
      self.gamificationUIStore.setRatingsLoading(true);
      const { data, hasError }: TSearchedRatingsResponse = yield gamificationApi.getSearchedRatings(
        self.gamificationType,
        search,
      );
      if (!hasError && data) {
        self.ratings = cast(data.ratings);
      }
      self.gamificationUIStore.setRatingsLoading(false);
    }),
    fetchMoreRatings: flow(function* fetchMoreRatings(page: number, pageSize: number = RATINGS_PAGE_SIZE) {
      self.gamificationUIStore.setRatingsLoading(true);
      const { data, hasError }: TRatingsResponse = yield gamificationApi.getRatings(
        self.gamificationType,
        page,
        pageSize,
      );
      if (!hasError && data) {
        self.ratings = cast([...self.ratings, ...data.ratings]);
      }
      self.gamificationUIStore.setRatingsLoading(false);
    }),
    fetchRatingsDetails: flow(function* fetchRatingsDetails() {
      self.gamificationUIStore.setPersonalRatingsDetailsLoading(true);

      if (!self.ratings.length && !self.authorizedUserRating) yield self.fetchRatings();
      const { data, hasError }: TRatingDetailsResponse = yield gamificationApi.getPersonalRatingsDetails();

      if (!hasError && data) {
        self.ratingDetails = cast(data.pointsDetailRecords);
      }
      self.gamificationUIStore.setPersonalRatingsDetailsLoading(false);
    }),
    fetchGamificationData: flow(function* fetchGamificationData() {
      self.gamificationUIStore.setGamificationDataLoading(true);
      const { data, hasError }: TGamificationResponse = yield gamificationApi.getGamificationData(
        self.gamificationType,
      );
      if (!hasError && data) {
        self.rulesHeader = data.rulesHeader;
        self.pointsLabel = data.pointsLabel;
        self.rules = cast(data.rules);
        self.prizes = cast(data.prizes);
      }
      self.gamificationUIStore.setGamificationDataLoading(false);
    }),
  }));

export default Gamification;
