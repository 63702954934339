import styled from 'styled-components';
import { Text } from 'components';
import { Avatar, Tooltip, Typography } from 'antd';

interface IRow {
  infoCol: boolean;
  isLoading?: boolean;
}

export const Row = styled.div<IRow>`
  display: grid;
  grid-template-columns: 60px minmax(0, 1fr) ${({ infoCol }) => (infoCol ? '24px' : '0')};
  grid-gap: 16px;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid ${({ theme }) => theme.palette.dark.dark_8};
  opacity: ${({ isLoading }) => (isLoading ? 0.6 : 1)};
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Name = styled(Text)`
  &&& {
    color: ${({ theme }) => theme.palette.dark.main};
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
  }
`;

export const RoleDescription = styled(Typography.Paragraph)`
  &&& {
    margin-bottom: 0;
    padding-top: 2px;
    color: ${({ theme }) => theme.palette.dark.dark_40};
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    line-height: 22px;
  }
`;

export const DescriptionTooltip = styled(Tooltip)`
  .ant-tooltip-inner {
    width: 336px;
    padding: 9px 12px;
    border: 1px solid ${({ theme }) => theme.palette.dark.dark_8};
    border-radius: 8px;
    background-color: ${({ theme }) => theme.palette.white.primary};
    color: ${({ theme }) => theme.palette.dark.main};
    box-shadow: 0px 0px 20px ${({ theme }) => theme.palette.shadow.main};
    font-weight: 500;
  }
  .ant-tooltip-arrow {
    display: none;
  }
`;

export const AvatarStyled = styled(Avatar)``;

export const SpinnerWrapper = styled.div`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
`;

export const Wrapper = styled.div``;
