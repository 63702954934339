import React from 'react';
import { useIntl } from 'react-intl';
import { Wrapper, TextStyled } from './NoItemsPlaceholder.styles';
import messages from './messages';

const NoItemsPlaceholder: React.FC = () => {
  const intl = useIntl();

  return (
    <Wrapper>
      <TextStyled>{intl.formatMessage(messages.text)}</TextStyled>
    </Wrapper>
  );
};

export default NoItemsPlaceholder;
