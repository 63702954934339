/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["self"] }] */
import { myTeamApi } from 'api';
import { cast, flow, getRoot, types } from 'mobx-state-tree';
import { TStore } from 'types';
import { TMyTeamCourseResponse } from 'types/myTeamApiTypes';
import MCourse from './MCourse';
import MCourseInfo from './MCourseInfo';

const MTrack = types
  .model({
    id: types.identifier,
    coursesInfo: types.maybeNull(types.array(MCourseInfo)),
    courses: types.map(MCourse),
  })
  .views((self) => ({
    get myTeamUIStore() {
      return getRoot<TStore>(self).UIStore.myTeam;
    },
  }))
  .actions((self) => ({
    setCourseId: (id: number) => {
      if (!self.courses.get(String(id))) {
        self.courses.put(MCourse.create({ courseId: id }));
      }
    },
    fetchAssignCourses: flow(function* fetchAssignCourses(groupId: number, trackId: string) {
      self.myTeamUIStore.setIsFetching(true);
      const { data, hasError }: TMyTeamCourseResponse = yield myTeamApi.tracksApi.getTrackSteps(trackId, groupId);
      if (data) {
        self.coursesInfo = cast(data);
      }
      self.myTeamUIStore.setIsFetching(false);
      return !hasError;
    }),
  }));

export default MTrack;
