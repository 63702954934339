import { useEffect } from 'react';
import store from 'store';

const useMenuCollapsed = (isCollapsed = true): void => {
  const { setIsMenuCollapsed } = store.UIStore.layout;

  useEffect(() => {
    setIsMenuCollapsed(isCollapsed);
  }, [isCollapsed, setIsMenuCollapsed]);
};

export default useMenuCollapsed;
