import React from 'react';
import { TSize, OSize, sizes, TChevronDirection, OChevronDirection } from './constants';
import IconButton from './IconButton';
import InlineButton from './InlineButton';

interface INavigationButton {
  inlineText?: string;
  popoverText?: string;
  chevronDirection?: TChevronDirection;
  size?: TSize | number;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  disable?: boolean;
}

const NavigationButton: React.FC<INavigationButton> = ({
  inlineText,
  popoverText,
  chevronDirection = OChevronDirection.LEFT,
  size = OSize.DEFAULT,
  onClick,
  disable,
}) => {
  const sizeValue = typeof size === 'string' ? sizes[size] : size;

  return inlineText ? (
    <InlineButton size={sizeValue} chevronDirection={chevronDirection} text={inlineText} onClick={onClick} />
  ) : (
    <IconButton
      size={sizeValue}
      chevronDirection={chevronDirection}
      text={popoverText}
      onClick={onClick}
      disable={disable}
    />
  );
};

export default NavigationButton;
