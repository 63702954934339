import React, { useState } from 'react';
import { ImageProps } from 'antd';
import { BackgroundImageWrapper, ImagePlaceholder, StyledImage } from './BackgroundImage.styles';

type Props = ImageProps & { dominantColor?: string | null };

const BackgroundImage: React.FC<Props> = ({ src, dominantColor }) => {
  const [imageError, setImageError] = useState(false);
  return (
    <BackgroundImageWrapper>
      {imageError ? (
        <ImagePlaceholder backgroundColor={dominantColor} />
      ) : (
        <StyledImage
          width="100%"
          height="100%"
          preview={false}
          src={src}
          onError={() => setImageError(true)}
          placeholder={<ImagePlaceholder backgroundColor={dominantColor} />}
        />
      )}
    </BackgroundImageWrapper>
  );
};

export default BackgroundImage;
