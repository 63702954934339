import React from 'react';
import { ReactComponent as HexagonIcon } from 'assets/icons/hexagon.svg';
import { Wrapper } from './HexagonPlate.styles';

interface Props {
  children: React.ReactNode;
}

const HexagonPlate: React.FC<Props> = ({ children }) => {
  return (
    <Wrapper>
      <HexagonIcon width="100%" />
      <span>{children}</span>
    </Wrapper>
  );
};

export default HexagonPlate;
