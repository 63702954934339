const StageTypes = {
  AVAILABLE: 'available',
  COMPLETED: 'completed',
  LOCKED: 'locked',
  LOCKED_AFTER_COMPLETED: 'lockedAfterCompleted',
  DISABLED: 'disabled',
} as const;

export type TStageTypes = typeof StageTypes[keyof typeof StageTypes];

export default StageTypes;
