export const getObjectFromJson = <T>(rawInput: string): T | null => {
  try {
    const result = JSON.parse(rawInput);
    if (typeof result !== 'object') return null;
    return result;
  } catch (error) {
    console.error('Something went wrong ', error);
    return null;
  }
};

export const getObjectFromLocalStorage = <K>(storeKey: string): K | null => {
  const json = localStorage.getItem(storeKey);
  return json ? getObjectFromJson<K>(json) : null;
};
