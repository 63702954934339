import styled from 'styled-components';

interface IDescriptionBox {
  isTitleExists?: boolean;
  closable: boolean;
  withIcon: boolean;
}

export const DescriptionBox = styled.div<IDescriptionBox>`
  padding-top: ${({ isTitleExists }) => (isTitleExists ? '8px' : '0')};
  padding-right: ${({ closable }) => (closable ? '36px' : '0')};
  padding-left: ${({ withIcon }) => (withIcon ? '36px' : '0')};
`;

export default DescriptionBox;
