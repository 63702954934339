enum PollLocationType {
  TRACK_POLL = 'track course',
  TRACK_CONTENT_POLL = 'track course content',
  STAGE_POLL = 'course',
  STAGE_CONTENT_POLL = 'course content',
  MENU_POLL = 'menu',
  MY_POLL = 'my polls',
  MENU_CATALOG_POLL = 'catalog',
}

export default PollLocationType;
