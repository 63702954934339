import React from 'react';
import { Wrapper, Text } from './NotificationBadge.styles';

interface INotificationBadge {
  active?: boolean;
}

const NotificationBadge: React.FC<INotificationBadge> = ({ children, active }) => {
  return (
    <Wrapper active={active}>
      <Text>{children}</Text>
    </Wrapper>
  );
};

export default NotificationBadge;
