import { types } from 'mobx-state-tree';

const MCourseInfo = types.model({
  id: types.number,
  name: types.string,
  coverThumbnailUrl: types.maybeNull(types.string),
  assignedCount: types.number,
  completedCount: types.number,
});

export default MCourseInfo;
