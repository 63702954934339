/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["self"] }] */
import { types } from 'mobx-state-tree';
import { isFieldHasValidCharactersLength } from 'utils';
import { InputBoundaries } from 'utils/validateFormField';
import { VerifyStatus } from 'types/registrationApiTypes';

const Registration = types
  .model({
    login: '',
    otp: '',
    firstName: '',
    lastName: '',
    isPolicyAgreed: false,
    isLoginFormValid: true,
    isOtpFormValid: true,
    isNameFormValid: true,
    otpStatus: types.maybeNull(
      types.enumeration('otpStatus', [
        VerifyStatus.SUCCESS,
        VerifyStatus.INVALID_OTP,
        VerifyStatus.INVALID_VERIFICATION_TOKEN,
        VerifyStatus.BLOCKED,
      ]),
    ),
    isFetching: false,
    isLoginFetching: false,
    currentStep: 1,
  })
  .views((self) => {
    return {
      get isLoginFormAllowedToSubmit() {
        return isFieldHasValidCharactersLength(self.login.length, InputBoundaries.LOGIN);
      },
    };
  })
  .actions((self) => ({
    resetFormValidationState: () => {
      if (!self.isLoginFormValid) {
        self.isLoginFormValid = true;
      }
      if (!self.isOtpFormValid) {
        self.isOtpFormValid = true;
      }
      if (!self.isNameFormValid) {
        self.isNameFormValid = true;
      }
    },
    resetFormFields: () => {
      self.login = '';
      self.otp = '';
      self.firstName = '';
      self.lastName = '';
    },
    setCurrentStep: (currentStep: number) => {
      self.currentStep = currentStep;
    },
    setFetching: (isFetching: boolean) => {
      self.isFetching = isFetching;
    },
    setLoginFetching: (isLoginFetching: boolean) => {
      self.isLoginFetching = isLoginFetching;
    },
    setLoginFormValid: (isLoginFormValid: boolean) => {
      self.isLoginFormValid = isLoginFormValid;
    },
    setOtpFormValid: (isOtpFormValid: boolean) => {
      self.isOtpFormValid = isOtpFormValid;
    },
    setNameFormValid: (isNameFormValid: boolean) => {
      self.isNameFormValid = isNameFormValid;
    },
  }))
  .actions((self) => ({
    setLogin: (login: string) => {
      self.login = login.trim();
      self.resetFormValidationState();
    },
    setOtp: (otp: string) => {
      self.otp = otp;
      self.resetFormValidationState();
    },
    setOtpStatus: (otpStatus: typeof self.otpStatus) => {
      self.otpStatus = otpStatus;
    },
    setFirstName: (firstName: string) => {
      self.firstName = firstName.trim();
      self.resetFormValidationState();
    },
    setLastName: (lastName: string) => {
      self.lastName = lastName.trim();
      self.resetFormValidationState();
    },
    setAgreePolicy: (isPolicyAgreed: boolean) => {
      self.isPolicyAgreed = isPolicyAgreed;
    },
  }));

export default Registration;
