import { defineMessages } from 'react-intl';

export default defineMessages({
  registeredText: {
    id: 'components.bookings.bookingElement.registeredText',
    defaultMessage: 'Вы записаны',
  },
  registeredWaitListText: {
    id: 'components.bookings.bookingElement.registeredWaitListText',
    defaultMessage: 'Вы в списке ожидания',
  },
  canRegisterText: {
    id: 'components.bookings.bookingElement.canRegisterText',
    defaultMessage: 'Регистрация открыта',
  },
  canRegisterWaitListText: {
    id: 'components.bookings.bookingElement.canRegisterWaitListText',
    defaultMessage: 'Регистрация закрыта (Список ожидания)',
  },
  cannotRegisterText: {
    id: 'components.bookings.bookingElement.cannotRegisterText',
    defaultMessage: 'Регистрация закрыта',
  },
  cannotRegisterLimitText: {
    id: 'components.bookings.bookingElement.cannotRegisterLimitText',
    defaultMessage: 'Достигнут лимит записи',
  },
  cannotRegisterLimitTooltip: {
    id: 'components.bookings.bookingElement.cannotRegisterLimitTooltip',
    defaultMessage: 'Вы уже зарегистрированы на максимальное число событий такого вида',
  },
});
