import React from 'react';
import { useIntl } from 'react-intl';
import { Modal } from 'components';
import { TRejectTypes, RejectTypes } from 'views/LearningTracks/constants';
import { Content, StyledTitle, StyledText } from './TrackRejectModal.styles';
import messages from './messages';

interface IStageRejectModal {
  rejectType: TRejectTypes | null;
  visible: boolean;
  onClose: () => void;
}

const ModalContent = {
  [RejectTypes.CLOSED]: { title: messages.title, text: messages.isClosedText },
  [RejectTypes.YET_UNAVAILABLE]: { title: messages.title, text: messages.yetUnavailableText },
};

const TrackRejectModal: React.FC<IStageRejectModal> = ({ rejectType, visible, onClose }) => {
  const intl = useIntl();

  if (!rejectType) return null;
  return (
    <Modal visible={visible} onClose={onClose} onOk={onClose}>
      <Content>
        {!!ModalContent[rejectType]?.title && (
          <StyledTitle level={2}>{intl.formatMessage(ModalContent[rejectType].title)}</StyledTitle>
        )}
        {!!ModalContent[rejectType]?.text && (
          <StyledText>{intl.formatMessage(ModalContent[rejectType]?.text)}</StyledText>
        )}
      </Content>
    </Modal>
  );
};

export default TrackRejectModal;
