// TODO: модель для тестирования UI, изменить по договоренности с бэком
import { types } from 'mobx-state-tree';
import { MStage } from './MStage';

const MSection = types
  .model({
    // TODO: нет в версии 0.1.0
    // id: types.number,
    // name: types.maybeNull(types.string),
    // comment: types.maybeNull(types.string),
    // transitionCondition: types.maybeNull(types.number),
    // type: types.maybeNull(types.string),
    // order: types.maybeNull(types.number),
    // showFinalMessage: types.boolean,
    // finalMessageTitle: types.maybeNull(types.string),
    // finalMessageText: types.maybeNull(types.string),
    // catalogId: types.maybeNull(types.number),
    // pollId: types.maybeNull(types.number),
    // scormId: types.maybeNull(types.number),
    // points: types.maybeNull(types.number),
    // isClosed: types.boolean,
    // isCompleted: types.boolean,
    // isActive: false,
    // time: types.maybeNull(types.number),
    stages: types.array(MStage),
  })

  .views((self) => ({
    get stagesCompletedLength() {
      return self.stages.filter((stage) => stage.isCompleted).length;
    },
    get stagesLength() {
      return self.stages.length;
    },
    get isAllCompleted() {
      return self.stages.filter((stage) => stage.isCompleted).length === self.stages.length && self.stages.length !== 0;
    },
    get singleDisabledStages() {
      return self.stages.filter((stage) => !stage.isCompleted).slice(1);
    },
    get singleCompletedStages() {
      return self.stages.filter((stage) => stage.isCompleted);
    },
    get singleCurrentStage() {
      return self.stages.filter((stage) => !stage.isCompleted)[0];
    },
  }));

export default MSection;
