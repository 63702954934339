import { api } from 'api';
import { TTaskAttachment } from 'types';
import {
  UploadFileOptions,
  TTaskResponse,
  TTaskSendSubmissionRequest,
  TTaskSendSubmissionResponse,
  TTaskStartSubmissionResponse,
  TTaskUpdateSubmissionResponse,
  TTasksInfoResponse,
  TTaskAttachmentResponse,
  TTaskAttachmentRequest,
  TFieldAnswerAttachmentRequest,
  TFeedbackAttachmentRequest,
  FileSizeTooLargeError,
  FileNameTooLongError,
} from 'types/tasksApiTypes';

const MAX_UPLOAD_FILE_SIZE = 104857600; // 100 MB;
const MAX_FILE_NAME_LENGTH = 200;

const getTasksInfo = (): Promise<TTasksInfoResponse> => api.privateRequest('get', 'api/web/tlrocks/v1/tasks');

const getTask = (taskId: string): Promise<TTaskResponse> =>
  api.privateRequest('get', `api/web/tlrocks/v1/tasks/${taskId}`);

const startTask = (taskId: number): Promise<TTaskStartSubmissionResponse> =>
  api.privateRequest('post', `api/web/tlrocks/v1/tasks/${taskId}/submissions`);

const sendTask = ({
  taskId,
  submissionId,
  answers,
}: TTaskSendSubmissionRequest): Promise<TTaskSendSubmissionResponse> =>
  api.privateRequest('put', `api/web/tlrocks/v1/tasks/${taskId}/submissions/${submissionId}`, { json: { answers } });

const updateTask = (taskId: number, submissionId: number): Promise<TTaskUpdateSubmissionResponse> =>
  api.privateRequest('patch', `api/web/tlrocks/v1/tasks/${taskId}/submissions/${submissionId}`);

const uploadFile = async (
  options: UploadFileOptions<TTaskAttachment>,
  taskId: number,
  submissionId: number,
  fieldId: number,
  abortSignal: AbortSignal,
): Promise<void> => {
  const { onSuccess, onError, file } = options;

  if (file instanceof File) {
    if (file.size > MAX_UPLOAD_FILE_SIZE) {
      onError?.(new FileSizeTooLargeError());
      return;
    }

    if (file.name.length > MAX_FILE_NAME_LENGTH) {
      onError?.(new FileNameTooLongError());
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    const { data, hasError } = await api.privateRequest(
      'post',
      `api/web/tlrocks/v1/tasks/${taskId}/submissions/${submissionId}/fields/${fieldId}/attachments/upload`,
      {
        body: formData,
        signal: abortSignal,
      },
    );

    if (data && !hasError) {
      onSuccess?.(data);
    } else {
      onError?.(new Error());
    }
  } else {
    onError?.(new Error());
  }
};

const getTaskAttachment = ({ taskId, attachmentId }: TTaskAttachmentRequest): Promise<TTaskAttachmentResponse> =>
  api.privateRequest('get', `api/web/tlrocks/v1/tasks/${taskId}/attachments/${attachmentId}`);

const getFieldAnswerAttachment = ({
  taskId,
  submissionId,
  fieldId,
  attachmentId,
}: TFieldAnswerAttachmentRequest): Promise<TTaskAttachmentResponse> =>
  api.privateRequest(
    'get',
    `api/web/tlrocks/v1/tasks/${taskId}/submissions/${submissionId}/fields/${fieldId}/attachments/${attachmentId}`,
  );

const getFeedbackAttachment = ({
  taskId,
  submissionId,
  attachmentId,
}: TFeedbackAttachmentRequest): Promise<TTaskAttachmentResponse> =>
  api.privateRequest(
    'get',
    `api/web/tlrocks/v1/tasks/${taskId}/submissions/${submissionId}/reviews/attachments/${attachmentId}`,
  );

export default {
  getTasksInfo,
  getTask,
  startTask,
  sendTask,
  updateTask,
  uploadFile,
  getTaskAttachment,
  getFieldAnswerAttachment,
  getFeedbackAttachment,
};
