import styled from 'styled-components';
import { ImageOverlay } from 'components/Image/Image.styles';
import { MIXINS } from '_constants';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import FullScreenIcon from 'assets/icons/fullscreen.svg';

export const CatalogImageOverlay = styled.div`
  position: relative;
  margin-bottom: 24px;
  ${MIXINS.ratio16x9}

  & ${ImageOverlay} {
    height: auto;
  }
  & .ant-image {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      right: 10px;
      bottom: 10px;
      z-index: 3;
      width: 32px;
      height: 32px;
      background-image: url(${FullScreenIcon});
      background-color: ${({ theme }) => theme.palette.mask.weaker};
      backdrop-filter: blur(10px);
      border-radius: 6px;
      background-size: 14px 14px;
      background-repeat: no-repeat;
      background-position: center;
      opacity: 0.8;
      transition: 0.3s opacity;
    }
  }
  & .ant-image-error {
    pointer-events: none;

    &::before,
    &::after {
      display: none;
    }

    & .ant-image-img {
      object-fit: cover;
    }
  }
  & .ant-image:not(.ant-image-error) {
    & .ant-image-img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 8px;
    }
  }
`;

export const ImagePreviewWrapper = styled.div`
  &&& .ant-modal-content {
    border-radius: 0;
    background-color: transparent;
    box-shadow: none;
    & .ant-image {
      position: relative;
      display: block;
      min-width: 44px;
      min-height: 44px;
      border-radius: 0;
      cursor: auto;
      &::before,
      &::after {
        display: none;
      }
    }
  }
`;

export const Close = styled(CloseIcon)`
  margin-left: 16px;
`;

export const DescriptionWrapper = styled.div`
  margin-top: 32px;
`;
