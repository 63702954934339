export const REQUEST_TIMEOUT = 5 * 60 * 1000;
export const USER_METRICS_URL = 'api/web/tlrocks/metrics/user';

export enum StatusCodes {
  OK = 200,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  NotValid = 400,
  Blocked = 429,
}

export enum ApiHeaders {
  xPublicEventId = 'X-Public-Event-Id',
  authorization = 'Authorization',
  acceptLanguage = 'Accept-Language',
  xTrackId = 'X-Track-Id',
  // TODO: delete, it's headers for deprecated api
  eventId = 'eventId',
  installationId = 'installationId',
  xApiToken = 'x-api-token',
  xInstallationId = 'x-installation-id',
  xWebEventRocks = 'X-WebEventRocks',
}
