import React, { useEffect, useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import store from 'store';
import { ProblemPlaceholder } from 'components';
import { useAppNavigator } from 'utils';

const AliasApply: React.FC<{ fallback: React.ReactElement }> = ({ children, fallback }) => {
  const [isLoading, setLoading] = useState(true);
  const {
    eventInfo: { eventPublicId },
    fetchEventSettings,
  } = store.DomainStore.eventSettings;
  const { isReceivedEventInfo, notFoundStatusReceived } = store.UIStore.layout;
  const { applyStorageSnapshot } = store.DomainStore;
  const {
    params: { alias },
    goToAlias,
  } = useAppNavigator();
  const init = useCallback(
    async (eventAlias: string) => {
      setLoading(true);
      await fetchEventSettings(eventAlias);
      applyStorageSnapshot();
      setLoading(false);
    },
    [fetchEventSettings, applyStorageSnapshot],
  );

  const onBack = () => {
    goToAlias();
    window.location.reload();
  };

  useEffect(() => {
    init(alias);
  }, [alias, init]);

  if (isLoading) return fallback;

  if (notFoundStatusReceived || !isReceivedEventInfo || !eventPublicId) {
    return <ProblemPlaceholder type="error404" onRefresh={onBack} />;
  }

  return <>{children}</>;
};

export default observer(AliasApply);
