export enum InputBoundaries {
  LOGIN = 'login',
  ALIAS = 'alias',
  PASSWORD = 'password',
}

export const INPUT_CHARACTERS_LENGTH = {
  [InputBoundaries.LOGIN]: {
    MIN: 3,
    MAX: 150,
  },
  [InputBoundaries.ALIAS]: {
    MIN: 1,
    MAX: 20,
  },
  [InputBoundaries.PASSWORD]: {
    MIN: 3,
    MAX: 30,
  },
};

const isFieldHasValidCharactersLength = (characterLength: number, fieldType: InputBoundaries): boolean =>
  characterLength >= INPUT_CHARACTERS_LENGTH[fieldType].MIN && characterLength < INPUT_CHARACTERS_LENGTH[fieldType].MAX;

export default isFieldHasValidCharactersLength;
