import React from 'react';
import { InputProps } from 'antd';
import { ClearIcon, SearchIcon, StyledInput } from './SearchInput.styles';

type TProps = {
  onClear?: () => void;
} & InputProps;

const SearchInput: React.FC<TProps> = ({ value, onClear, ...restProps }) => {
  return <StyledInput value={value} suffix={value ? <ClearIcon onClick={onClear} /> : <SearchIcon />} {...restProps} />;
};

export default SearchInput;
