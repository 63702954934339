import styled from 'styled-components';
import { Button } from 'antd';
import { Grid } from '_constants';

export const StyledButton = styled(Button)`
  &&& {
    &.ant-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: auto;
      padding: 12px 64px;
      border: none;
      border-radius: 10px;
      background-color: ${({ theme }) => theme.palette.primary.main};
      box-shadow: none;
      font-weight: 500;
      font-size: 16px;
      font-family: 'Roboto', sans-serif;
      line-height: 24px;
      @media (max-width: ${Grid.MD}px) {
        padding: 12px 55px;
      }
      div,
      span,
      svg {
        z-index: 1;
      }
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        background-color: ${({ theme: { palette } }) => palette.white.primary};
        filter: opacity(0);
      }
      &:hover::after {
        filter: opacity(0.12);
      }
      &:focus::after {
        filter: opacity(0.24);
      }
      &:active::after {
        filter: opacity(0.32);
      }
      &:not(.ant-btn-secondary)[disabled],
      &:not(.ant-btn-text)[disabled] {
        &::after {
          filter: opacity(0.6);
        }
      }

      &.ant-btn-text::after {
        filter: opacity(0);
      }

      &.ant-btn-secondary,
      &.ant-btn-text {
        background-color: ${({ theme: { palette } }) => palette.white.primary};
        color: ${({ theme: { palette } }) => palette.black.primary};

        &::after {
          background-color: ${({ theme: { palette } }) => palette.black.primary};
          filter: opacity(0.06);
        }
        &:hover::after {
          filter: opacity(0.1);
        }
        &:focus::after {
          filter: opacity(0.14);
        }
        &:active::after {
          filter: opacity(0.16);
        }
        &[disabled] {
          span {
            filter: opacity(0.4);
          }
          &::after {
            filter: opacity(0.06);
          }
        }
      }

      &.ant-btn-text[disabled]::after {
        filter: opacity(0);
      }

      &.ant-btn-primary {
        background-color: ${({ theme }) => theme.palette.primary.main};
        color: ${({ theme }) => theme.palette.white.primary};
      }
      &.ant-btn-success {
        background-color: ${({ theme }) => theme.palette.success.primary};
        color: ${({ theme }) => theme.palette.white.primary};
      }
      &.ant-btn-danger {
        background-color: ${({ theme }) => theme.palette.error.primary};
        color: ${({ theme }) => theme.palette.white.primary};
      }
      &.ant-btn-loading {
        &:before {
          background: ${({ theme: { palette } }) => palette.mask.transparent};
        }
        span:not(:first-child) {
          display: none;
        }
      }
    }
    &.ant-btn-lg {
      padding: 16px 48px;
      font-weight: 500;
      font-size: 17px;
      line-height: 28px;
    }
    &.ant-btn-sm {
      padding: 7px 32px;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
    }
    &[ant-click-animating-without-extra-node='true']::after {
      opacity: 1;
      animation: none;
    }
  }
`;

export default StyledButton;
