import { TComponent } from 'types';
import { getMobileApiHost } from 'env';
import { LOCALIZATION_CODES_MAP } from '_constants';
import { DomainStore, UIStore } from 'store';

const getFrameLink = (component: TComponent): URL | undefined => {
  const { locale } = UIStore;
  const {
    auth,
    profile: { data: profile },
  } = DomainStore;

  if (component?.src) {
    const url = new URL(component.src);
    if (component.settings?.withCredentials) {
      url.searchParams.set('baseUrl', getMobileApiHost());
      url.searchParams.set('authToken', auth.token);
      url.searchParams.set('userId', `${profile?.id}`);
      url.searchParams.set('locale', LOCALIZATION_CODES_MAP[locale]);
      url.searchParams.set('lang', LOCALIZATION_CODES_MAP[locale]);
    }
    return url;
  }
  return undefined;
};

export default getFrameLink;
