import { defineMessages } from 'react-intl';

export default defineMessages({
  modalTitle: {
    id: 'components.learningTracks.adminModal.title',
    defaultMessage: 'Вы — администратор',
  },
  modalText: {
    id: 'components.learningTracks.adminModal.text',
    defaultMessage:
      'Вы являетесь куратором или руководителем группы обучения, которой назначена эта траектория обучения. Вам доступны все шаги этого траектории, а результаты вашего прохождения не попадут в общую статистику группы.',
  },
  modalButton: {
    id: 'components.learningTracks.adminModal.button',
    defaultMessage: 'Понятно',
  },
});
