import React from 'react';
import Title, { Wrapper, RowTooltip } from './TextRow.styles';

interface Props {
  content: React.ReactNode;
  icon?: React.ReactNode;
  iconColor?: {
    main?: string;
    hover?: string;
  };
  onClick?: () => void;
  rows?: number;
  tooltipContent?: string;
}

const TextRow: React.FC<Props> = ({ content, icon, iconColor, onClick, rows, tooltipContent }) => {
  const showTooltip =
    (tooltipContent && tooltipContent.length > (rows || 1) * 40) ||
    (content && content.toString().length > (rows || 1) * 40);
  return showTooltip ? (
    <RowTooltip title={content} getPopupContainer={(trigger) => trigger}>
      <Wrapper onClick={onClick} iconColor={iconColor}>
        <Title ellipsis={{ rows: rows || 1 }}>{content}</Title>
        {icon}
      </Wrapper>
    </RowTooltip>
  ) : (
    <Wrapper onClick={onClick} iconColor={iconColor}>
      <Title ellipsis={{ rows: rows || 1 }}>{content}</Title>
      {icon}
    </Wrapper>
  );
};

export default TextRow;
