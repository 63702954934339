import React, { FC } from 'react';
import { NotificationButtonsTypes } from 'types/notificationTypes';
import { ButtonsGroup, CancelBtn, NotificationButton } from './NotificationButtons.styles';

export interface IButton {
  type: string;
  action: () => void;
  title: string;
}

interface INotificationButtonsProps {
  buttons: IButton[];
  close: (id: string) => void;
  id: string;
}

const NotificationButtons: FC<INotificationButtonsProps> = ({ buttons, close, id }) => {
  const handleOnClick = (btn: IButton) => () => {
    btn.action();
    close(id);
  };

  return (
    <ButtonsGroup>
      {buttons.map((item) => {
        return (
          item.type === NotificationButtonsTypes.CANCEL && (
            <>
              <CancelBtn type="ghost" onClick={handleOnClick(item)} key={item.title}>
                {item.title}
              </CancelBtn>
              <NotificationButton type="primary" onClick={handleOnClick(item)} key={item.title}>
                {item.title}
              </NotificationButton>
            </>
          )
        );
      })}
    </ButtonsGroup>
  );
};

export default NotificationButtons;
