import styled from 'styled-components';
import { Checkbox } from 'antd';
import { ReactComponent as CheckMark } from 'assets/icons/checkmark-check.svg';
import { Text } from 'components';
import { TTheme } from 'types';
import { CheckBoxOptions, IPercentColorBlock } from 'components/Polls/constants';

interface IStyledTextCheckbox {
  percent?: boolean;
  variant?: CheckBoxOptions;
}

const getPercentColorBlockBg = (theme: TTheme, variant?: CheckBoxOptions) => {
  switch (variant) {
    case CheckBoxOptions.RIGHT:
      return theme.palette.success.accent;
    case CheckBoxOptions.WRONG:
      return theme.palette.error.secondary;
    default:
      return theme.palette.dark.dark_12;
  }
};

const getStyledTextCheckboxBg = (theme: TTheme, variant?: CheckBoxOptions) => {
  switch (variant) {
    case CheckBoxOptions.RIGHT:
      return theme.palette.success.secondary;
    case CheckBoxOptions.WRONG:
      return theme.palette.error.secondary;
    default:
      return theme.palette.dark.dark_4;
  }
};

const getStyledTextCheckboxColor = (theme: TTheme, variant?: CheckBoxOptions) => {
  switch (variant) {
    case CheckBoxOptions.RIGHT:
      return theme.palette.success.primary;
    case CheckBoxOptions.WRONG:
      return theme.palette.error.primary;
    default:
      return theme.palette.dark.main;
  }
};

const getStyledTextCheckboxHover = (theme: TTheme, variant?: CheckBoxOptions) => {
  switch (variant) {
    case CheckBoxOptions.RIGHT:
      return theme.palette.success.secondary;
    case CheckBoxOptions.WRONG:
      return theme.palette.error.secondary;
    default:
      return theme.palette.dark.dark_10;
  }
};

const getPercentOverlayColor = (theme: TTheme, variant?: CheckBoxOptions) => {
  switch (variant) {
    case CheckBoxOptions.RIGHT:
      return theme.palette.success.primary;
    case CheckBoxOptions.WRONG:
      return theme.palette.error.primary;
    default:
      return theme.palette.dark.dark_40;
  }
};

export const PercentOverlay = styled(Text)`
  &&& {
    position: absolute;
    top: 0;
    right: 16px;
    bottom: 0;
    z-index: 3;
    display: flex;
    align-items: center;
    width: 42px;
    margin: auto;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
  }
`;

export const PercentColorBlock = styled.div<IPercentColorBlock>`
  &&& {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: ${({ percent }) => `${percent}%`};
    height: 100%;
    background-color: ${({ theme, variant }) => getPercentColorBlockBg(theme, variant)};
  }
`;

export const OptionText = styled.div`
  position: relative;
  z-index: 3;
  word-break: break-word;
  margin-right: 14px;
`;

export const CheckMarkSelect = styled(CheckMark)`
  opacity: 0;
  visibility: hidden;
  path {
    fill: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const StyledTextCheckbox = styled(Checkbox)<IStyledTextCheckbox>`
  && {
    position: relative;
    display: flex;
    overflow: hidden;
    width: 100%;
    padding: ${({ percent }) => (percent ? `20px 71px 20px 20px` : `20px`)};
    border-radius: 8px;
    background: ${({ theme, variant }) => getStyledTextCheckboxBg(theme, variant)};
    color: ${({ theme, variant }) => getStyledTextCheckboxColor(theme, variant)};
    font-weight: 500;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    line-height: 24px;
    user-select: none;

    svg {
      flex-shrink: 0;
    }

    &.ant-checkbox-wrapper-checked {
      ${CheckMarkSelect} {
        opacity: 1;
        visibility: visible;
      }
    }

    &:not(.ant-checkbox-wrapper-disabled):hover {
      background: ${({ theme, variant }) => getStyledTextCheckboxHover(theme, variant)};
    }

    & span:not(.ant-checkbox) {
      padding: 0;
    }

    & .ant-checkbox {
      position: absolute;
      top: 0;
      right: ${({ percent }) => (percent ? `71px` : `24px`)};
      bottom: 0;
      z-index: 3;
      width: 24px;
      height: 24px;
      margin: auto;

      & .ant-checkbox-inner {
        display: none;
        &::after {
          display: none;
        }
      }

      &.ant-checkbox-checked {
        &::after {
          display: none;
        }
      }
      & + span {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }
      &.ant-checkbox-disabled + span {
        color: inherit;
      }
    }
    &&& {
      ${PercentOverlay} {
        color: ${({ percent, theme, variant }) =>
          percent ? getPercentOverlayColor(theme, variant) : theme.palette.dark.dark_40};
      }
    }
  }
`;
