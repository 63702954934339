import { types } from 'mobx-state-tree';

export const MBreadcrumbItem = types.model({
  name: types.string,
  location: types.optional(types.maybeNull(types.string), null),
});

export const MBreadcrumb = types.model({
  isShow: types.boolean,
  items: types.array(MBreadcrumbItem),
  onBackLocation: types.maybeNull(types.string),
});
