import { types } from 'mobx-state-tree';

const MActivityAttendee = types.model({
  id: types.number,
  firstName: types.string,
  lastName: types.string,
  thumbnail: types.maybeNull(types.string),
  eventId: types.number,
  lastActivityDate: types.maybeNull(types.string),
});

export default MActivityAttendee;
