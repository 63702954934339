import React from 'react';
import { useIntl } from 'react-intl';
import { Modal } from 'components';
import { ReactComponent as LikeImage } from 'assets/images/like.svg';
import { ReactComponent as CupImage } from 'assets/images/cup.svg';
import { ModalOptions } from 'types/completeModalTypes';
import { ImageWrapper, StyledText, StyledTitle } from './CompleteModal.styles';
import messages from './messages';

interface IProps {
  visible: boolean;
  onClose: () => void;
  onOk?: () => void;
  option: ModalOptions;
  withCancelBtn?: boolean;
  text?: string | null;
  title?: string | null;
}

const MODAL_IMAGE = {
  [ModalOptions.STAGE]: <LikeImage />,
  [ModalOptions.COURSE]: <CupImage />,
};

const CompleteModal: React.FC<IProps> = ({ visible, onClose, onOk, option, text, title, withCancelBtn = false }) => {
  const { formatMessage } = useIntl();
  return (
    <Modal
      visible={visible}
      onCancel={withCancelBtn ? onClose : undefined}
      onClose={onClose}
      onOk={onOk || onClose}
      okText={formatMessage(messages.okButtonText)}
    >
      <ImageWrapper>{MODAL_IMAGE[option]}</ImageWrapper>
      <StyledTitle level={2}>{title}</StyledTitle>
      {text && <StyledText>{text}</StyledText>}
    </Modal>
  );
};

export default CompleteModal;
