/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["self"] }] */
import { cast, types } from 'mobx-state-tree';
import { MModal } from 'models';

const Course = types
  .model({
    isFetchingCourseRequiredData: false,
    isFetchingCourses: false,
    isFetchingPoll: false,
    isFetchingPollAnswers: false,
    finishCourseModal: types.optional(MModal, {}),
    isLoadingCatalogThumbnail: false,
    loadingCatalogRichTextIds: types.array(types.number),
    loadingCatalogGroupsIds: types.array(types.number),
  })
  .volatile(() => ({
    scrollToTop: () => {},
  }))
  .views((self) => ({
    get isLoadingCatalogRichText() {
      return !!self.loadingCatalogRichTextIds.length;
    },
    get isLoadingCatalogGroup() {
      return !!self.loadingCatalogGroupsIds.length;
    },
  }))
  .actions((self) => ({
    setFetchingCourseRequiredData: (state: boolean) => {
      self.isFetchingCourseRequiredData = state;
    },
    setFetchingCourses: (state: boolean) => {
      self.isFetchingCourses = state;
    },
    setFetchingPoll: (state: boolean) => {
      self.isFetchingPoll = state;
    },
    setFetchingPollAnswers(state: boolean) {
      self.isFetchingPollAnswers = state;
    },
    setIsLoadingCatalogThumbnail(state: boolean) {
      self.isLoadingCatalogThumbnail = state;
    },
    setScrollToTop: (callback: () => void) => {
      self.scrollToTop = callback;
    },
    setLoadingCatalogRichTextId: (id: number) => {
      if (!self.loadingCatalogRichTextIds.find((catalogId) => catalogId === id)) {
        self.loadingCatalogRichTextIds = cast([...self.loadingCatalogRichTextIds, id]);
      }
    },
    removeLoadingCatalogRichTextId: (id: number) => {
      self.loadingCatalogRichTextIds = cast(self.loadingCatalogRichTextIds.filter((catalogId) => catalogId !== id));
    },
    setLoadingCatalogGroupsId: (id: number) => {
      if (!self.loadingCatalogGroupsIds.find((catalogId) => catalogId === id)) {
        self.loadingCatalogGroupsIds = cast([...self.loadingCatalogGroupsIds, id]);
      }
    },
    removeLoadingCatalogGroupsId: (id: number) => {
      self.loadingCatalogGroupsIds = cast(self.loadingCatalogGroupsIds.filter((catalogId) => catalogId !== id));
    },
  }));

export default Course;
