import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'components.emptyPlaceholder.title',
    defaultMessage: 'Ой, тут пусто',
  },
  text: {
    id: 'components.emptyPlaceholder.text',
    defaultMessage: 'Как только вам станут доступны новые материалы, вы увидите их здесь',
  },
});
