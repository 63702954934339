import styled from 'styled-components';
import checked from 'assets/icons/checked.svg';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { Text } from 'components';

export const CheckboxBlock = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
`;

export const CheckboxStyled = styled(Checkbox)`
  &&&& {
    .ant-checkbox-input {
      outline: none;
    }

    .ant-checkbox {
      border: 1px solid ${({ theme: { palette } }) => palette.dark.dark_16};
      border-radius: 6px;
    }

    .ant-checkbox-checked {
      border: 1px solid ${({ theme: { palette } }) => palette.primary.main};
      background-color: ${({ theme: { palette } }) => palette.primary.main};

      &:before,
      &:after {
        display: none;
        border: none;
      }
    }

    .ant-checkbox:hover.ant-checkbox-checked {
      border: 1px solid ${({ theme: { palette } }) => palette.primary.main};
      background: linear-gradient(
          ${({ theme }) => theme.palette.white.white_12},
          ${({ theme }) => theme.palette.white.white_12}
        ),
        ${({ theme }) => theme.palette.primary.main};
    }

    .ant-checkbox-inner {
      width: 20px;
      height: 20px;
      border: none;
      border-radius: 6px;
      background-position: center;
      background-repeat: no-repeat;
      outline: none;
      transition: none;

      &:before,
      &:after {
        display: none;
        border: none;
      }
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      border-color: ${({ theme: { palette } }) => palette.primary.main};
      border-radius: 3px;
      background-color: ${({ theme: { palette } }) => palette.primary.main};
      background-image: url(${checked});
    }

    .ant-checkbox:hover .ant-checkbox-inner {
      background-image: none;
    }
    .ant-checkbox:hover {
      border: 1px solid ${({ theme: { palette } }) => palette.primary.main};
      border-radius: 6px;
    }
    .ant-checkbox:hover.ant-checkbox-checked .ant-checkbox-inner {
      z-index: 1;
      background-color: ${({ theme: { palette } }) => palette.mask.transparent};
      background-image: url(${checked});
    }
  }
`;

export const CheckboxIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
`;

export const CheckboxText = styled(Text)`
  &&&& {
    color: ${({ theme: { palette } }) => palette.dark.main};
    line-height: 22px;
    text-align: start;
  }
`;

export const CheckboxTextLink = styled.a`
  &&& {
    font-family: 'Roboto', sans-serif;
    color: ${({ theme: { palette } }) => palette.violet.main};
    text-decoration: underline;

    &:hover {
      color: ${({ theme: { palette } }) => palette.violet.violet_76};
    }
  }
`;
