/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["self"] }] */
import { Instance, types } from 'mobx-state-tree';
import { TCurrentUserAnswer } from 'types';
import { QuestionType, QuestionCheckboxType } from './constants';
import MAnswer from './MAnswer';
import MOption from './MOption';

const MQuestion = types
  .model({
    id: types.number,
    screenId: types.number,
    text: types.maybeNull(types.string),
    order: types.number,
    type: types.enumeration<QuestionType>('Type', Object.values(QuestionType)),
    imageURL: types.maybeNull(types.string),
    options: types.array(MOption),
    createdDate: types.string,
    updatedDate: types.string,
  })
  .views((self) => ({
    get isSelectType() {
      return self.type === QuestionType.MULTISELECT || self.type === QuestionType.SINGLE_SELECT;
    },
  }))
  .views((self) => ({
    get singleSelectRightOption() {
      return self.options.find(({ isRight }) => isRight) || null;
    },
    get multiselectRightOptions() {
      return self.options.filter(({ isRight }) => isRight);
    },
    get multiselectWrongOptions() {
      return self.options.filter(({ isRight }) => !isRight);
    },
  }))
  .views((self) => ({
    getAnswerInfo: (answers: (TCurrentUserAnswer | Instance<typeof MAnswer>)[]) => {
      if (self.type === QuestionType.SINGLE_SELECT) {
        const rightOption = self.singleSelectRightOption;
        if (!rightOption) return null;
        return {
          isCorrect: !!answers.find(({ optionId }) => optionId === rightOption.id),
        };
      }
      if (self.type === QuestionType.MULTISELECT) {
        const answerOptions = answers.filter(({ questionId }) => questionId === self.id);
        const wrongOptions = self.multiselectWrongOptions;
        const rightOptions = self.multiselectRightOptions;
        const correctAnswerOptions = answerOptions.filter(({ optionId }) =>
          rightOptions.some(({ id }) => optionId === id),
        );
        const wrongAnswerOptions = answerOptions.filter(({ optionId }) =>
          wrongOptions.some(({ id }) => optionId === id),
        );
        const isCorrect = !!(correctAnswerOptions.length === rightOptions.length) && !wrongAnswerOptions.length;
        return {
          rightOptions,
          wrongOptions,
          correctAnswerOptions,
          isCorrect,
        };
      }
      return null;
    },
  }))
  .views((self) => ({
    getUserScore: (answers: TCurrentUserAnswer[] | Instance<typeof MAnswer>[]) => {
      if (self.type === QuestionType.SINGLE_SELECT) {
        if (!self.getAnswerInfo(answers)?.isCorrect) return 0;
        return Number(self.singleSelectRightOption?.rate);
      }
      if (self.type === QuestionType.MULTISELECT) {
        if (!self.getAnswerInfo(answers)?.isCorrect) return 0;
        return self.multiselectRightOptions.reduce((acc, { rate }) => acc + Number(rate), 0);
      }
      return null;
    },
    get maxScore() {
      switch (self.type) {
        case QuestionType.SINGLE_SELECT:
          return self.options.find(({ isRight }) => isRight)?.rate || 0;
        case QuestionType.MULTISELECT:
          return self.options.reduce((acc, { isRight, rate }) => acc + Number(isRight && rate), 0);
        default:
          return 0;
      }
    },
    get viewType() {
      const options = self.options.reduce(
        (acc, { text, imageURL }) => {
          return {
            hasText: acc.hasText || !!text,
            hasImage: acc.hasImage || !!imageURL,
          };
        },
        { hasText: false, hasImage: false },
      );
      if (options.hasText && options.hasImage) {
        return QuestionCheckboxType.IMAGE_WITH_TEXT;
      }
      if (!options.hasText && options.hasImage) {
        return QuestionCheckboxType.IMAGE;
      }
      return QuestionCheckboxType.TEXT;
    },
  }));

export default MQuestion;
