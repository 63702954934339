import React from 'react';
import { observer } from 'mobx-react';
import { ProgressProps } from 'antd';
import store from 'store';
import { StyledProgress } from './Progress.styles';

interface OwnProps {
  strokeWidth?: number;
}

type Props = ProgressProps & OwnProps;

const Progress: React.FC<Props> = ({ strokeWidth, ...rest }) => {
  const { palette } = store.UIStore.theme;
  return (
    <StyledProgress
      {...rest}
      strokeWidth={strokeWidth || 4}
      showInfo={false}
      trailColor={palette.dark.dark_4}
      strokeColor={palette.success.primary}
    />
  );
};

export default observer(Progress);
