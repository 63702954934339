import React, { RefObject, CSSProperties, useMemo } from 'react';
import { Player as VimePlayer, DefaultUi, Hls, Dash, Vimeo, Audio, Video } from '@vime/react';
import { getYoutubeVideoId, getVimeoVideoId } from 'utils';
import translations from './translations';
import CustomPlaybackControl from './CustomPlaybackControl';
import YoutubePlayer from './YoutubePlayer';

export interface IPlayer {
  src: string;
  type: 'youtube' | 'dash' | 'hls' | 'vimeo' | 'html5' | 'audio';
  poster?: string;
  config?: { [x: string]: unknown };
  playerRef?: RefObject<HTMLVmPlayerElement>;
  language?: string;
  onPiPChange?: (e: CustomEvent<boolean>) => void;
  onPlay?: (e: CustomEvent<void>) => void;
  onPause?: (e: CustomEvent<boolean>) => void;
  onError?: (e: CustomEvent<HTMLElement>) => void;
  style?: CSSProperties;
}

const Player: React.FC<IPlayer> = ({
  type,
  src,
  config,
  poster,
  playerRef,
  language,
  onPiPChange,
  onPause,
  onPlay,
  onError,
  style,
}) => {
  const currentLanguage = useMemo(() => (language && translations[language] ? language : 'ru'), [language]);

  if (type === 'youtube') {
    const id = getYoutubeVideoId(src);
    return id ? (
      <YoutubePlayer videoId={id} playerRef={playerRef} onPlay={onPlay} onPause={onPause} language={currentLanguage} />
    ) : null;
  }
  if (type === 'hls') {
    return (
      <VimePlayer
        autoplay
        ref={playerRef}
        onVmPiPChange={onPiPChange}
        style={style}
        onVmError={onError}
        onVmPlay={onPlay}
        language={currentLanguage}
        translations={translations}
      >
        <Hls key={src} version="1.4.14" config={config} poster={poster}>
          <source data-src={src} type="application/x-mpegURL" />
        </Hls>
        <DefaultUi>
          <CustomPlaybackControl playerRef={playerRef} />
        </DefaultUi>
      </VimePlayer>
    );
  }
  if (type === 'dash') {
    return (
      <VimePlayer
        autoplay
        ref={playerRef}
        onVmPlay={onPlay}
        onVmPiPChange={onPiPChange}
        language={currentLanguage}
        translations={translations}
      >
        <Dash src={src} version="3.2.2" config={config} poster={poster} />
        <DefaultUi />
      </VimePlayer>
    );
  }
  if (type === 'vimeo') {
    const id = getVimeoVideoId(src);
    return id ? (
      <VimePlayer autoplay onVmPlay={onPlay} ref={playerRef} language={currentLanguage} translations={translations}>
        <Vimeo videoId={id} poster={poster} />
        <DefaultUi />
      </VimePlayer>
    ) : null;
  }
  if (type === 'html5') {
    return (
      <VimePlayer
        autoplay
        ref={playerRef}
        onVmPlay={onPlay}
        onVmPiPChange={onPiPChange}
        language={currentLanguage}
        translations={translations}
      >
        <Video poster={poster}>
          <source data-src={src} />
        </Video>
        <DefaultUi />
      </VimePlayer>
    );
  }
  if (type === 'audio') {
    return (
      <VimePlayer
        ref={playerRef}
        onVmPlay={onPlay}
        onVmPausedChange={onPause}
        style={style}
        autoplay
        language={currentLanguage}
        translations={translations}
      >
        <Audio>
          <source data-src={src} type="audio/mp3" />
        </Audio>
        <DefaultUi />
      </VimePlayer>
    );
  }
  return null;
};

export default Player;
