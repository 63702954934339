import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'views.stageList.trackRejectModal.title',
    defaultMessage: 'Шаг недоступен',
  },
  yetUnavailableText: {
    id: 'views.stageList.trackRejectModal.yetUnavailableText',
    defaultMessage: 'Пройдите все предыдущие шаги, чтобы открыть этот шаг',
  },
  isClosedText: {
    id: 'views.stageList.trackRejectModal.isClosedText',
    defaultMessage: 'Организатор еще не открыл этот шаг для прохождения',
  },
});
