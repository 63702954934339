import styled from 'styled-components';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as LearningTrackIcon } from 'assets/icons/learning-track.svg';
import { Button } from 'antd';
import { Grid } from '_constants';

export const InfoIconStyled = styled(InfoIcon)``;

export const LearningTrackIconStyled = styled(LearningTrackIcon)``;

export const ButtonStyled = styled(Button)`
  &&& {
    position: absolute;
    top: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: auto;
    height: 48px;
    border: ${({ theme }) => `1px solid ${theme.palette.dark.dark_8}`};
    border-radius: 48px;
    background: ${({ theme }) => theme.palette.white.primary};
    color: ${({ theme }) => theme.palette.dark.main};
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    z-index: 11;
    font-weight: 400;
    box-shadow: none;
    ${InfoIconStyled}, ${LearningTrackIconStyled} {
      position: relative;
      transition: inherit;
      margin-right: 8px;
      path {
        fill: ${({ theme }) => theme.palette.dark.dark_24};
        transition: inherit;
      }
    }

    &:hover {
      path {
        fill: ${({ theme }) => theme.palette.dark.dark_40};
      }
    }

    &[disabled] {
      &:hover,
      &.ant-popover-open {
        background: ${({ theme }) => theme.palette.white.primary};
        box-shadow: none;
      }
      ${InfoIconStyled}, ${LearningTrackIconStyled} {
        path {
          fill: ${({ theme }) => theme.palette.dark.dark_16};
        }
      }
    }

    @media (max-width: ${Grid.XL}px) {
      right: 0;
      padding-right: 24px;
      width: 46%;
    }
  }
`;
