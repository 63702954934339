import React from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import store from 'store';
import { ReactComponent as BackIcon } from 'assets/icons/back.svg';
import BreadcrumbItemLink from './BreadcrumbItemLink';
import { BreadcrumbOverlay, Breadcrumbs, BackButton, BreadcrumbItem } from './Breadcrumb.styles';

const Breadcrumb: React.FC = () => {
  const { isShow, items, onBackLocation } = store.UIStore.layout.breadcrumb;
  const history = useHistory();

  const backButtonHandler = () => (onBackLocation ? history.push(onBackLocation) : history.goBack());

  return isShow ? (
    <BreadcrumbOverlay>
      <Breadcrumbs>
        <BreadcrumbItem fixMinWidth>
          <BackButton type="text" icon={<BackIcon />} onClick={backButtonHandler} />
        </BreadcrumbItem>

        {items.map(
          (item) => !!item.name && <BreadcrumbItemLink key={item.name} name={item.name} location={item.location} />,
        )}
      </Breadcrumbs>
    </BreadcrumbOverlay>
  ) : null;
};

export default observer(Breadcrumb);
