import { types } from 'mobx-state-tree';
import { CatalogElementTypes, ViewOptions } from './constants';
import MImage from './MImage';
import MCatalogAttendee from './MCatalogAttendee';
import MCatalogGroup from './MCatalogGroup';
import MCatalogMedia from './MCatalogMedia';
import MCatalogPoll from './MCatalogPoll';
import MCatalogText from './MCatalogText';
import MCatalogFile from './MCatalogFile';
import MCatalogLink from './MCatalogLink';
import MCatalogBooking from './MCatalogBooking';

const { ATTENDEE, POLL, GROUP, AUDIO, VIDEO, FILE, TEXT, LINK, CATALOG, BOOKING } = CatalogElementTypes;

const MCatalog = types
  .model({
    id: types.identifierNumber,
    name: types.string,
    value: types.maybeNull(types.string),
    type: types.enumeration('Element type', [CatalogElementTypes.CATALOG]),
    viewOptions: types.maybeNull(
      types.enumeration('View option', [ViewOptions.MEDIA, ViewOptions.IMAGE, ViewOptions.TEXT]),
    ),
    textLogoImage: types.maybeNull(MImage),
    textLogoThumbnail: types.maybeNull(MImage),
    fullLogoImage: types.maybeNull(MImage),
    fullLogoThumbnail: types.maybeNull(MImage),
    order: types.maybe(types.number),
    parentCatalogId: types.maybeNull(types.number),
    groups: types.maybeNull(types.array(types.number)),
    coverImage: types.maybeNull(MImage),
    fullCoverImage: types.maybeNull(MImage),
    description: types.maybeNull(types.string),
    children: types.maybeNull(
      types.array(
        // eslint-disable-next-line
        types.late((): any =>
          types.union(
            {
              dispatcher: (snapshot) => {
                switch (snapshot.type) {
                  case CATALOG:
                    return MCatalog;
                  case ATTENDEE:
                    return MCatalogAttendee;
                  case POLL:
                    return MCatalogPoll;
                  case GROUP:
                    return MCatalogGroup;
                  case AUDIO:
                    return MCatalogMedia;
                  case VIDEO:
                    return MCatalogMedia;
                  case TEXT:
                    return MCatalogText;
                  case FILE:
                    return MCatalogFile;
                  case LINK:
                    return MCatalogLink;
                  case BOOKING:
                    return MCatalogBooking;
                  default:
                    return MCatalogText;
                }
              },
            },
            MCatalog,
            MCatalogAttendee,
            MCatalogPoll,
            MCatalogGroup,
            MCatalogMedia,
            MCatalogText,
            MCatalogFile,
            MCatalogLink,
          ),
        ),
      ),
    ),
  })
  .views((self) => ({
    get sortedChildren() {
      return (self.children || []).slice().sort((a, b) => a.order - b.order);
    },
  }))
  .named('Catalog');

export default MCatalog;
