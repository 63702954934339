/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["self"] }] */
import { types } from 'mobx-state-tree';
import { notification as notificationApi } from 'antd';
import { TNotificationConfig } from 'types';
import { NotificationButtonsTypes, TNotificationButton } from 'types/notificationTypes';

export const MNotificationButton = types
  .model({
    title: types.string,
    type: types.enumeration('Type', [NotificationButtonsTypes.CANCEL, NotificationButtonsTypes.CONFIRM]),
  })
  .volatile(() => ({
    action: () => {},
  }))
  .actions((self) => ({
    set: (title: string, type: TNotificationButton['type'], action: () => void) =>
      Object.assign(self, { title, action, type }),
  }));

const CONFIG_PLACEMENT = ['topLeft', 'topRight', 'bottomLeft', 'bottomRight'];

export const MNotificationConfig = types
  .model({
    placement: types.maybeNull(types.enumeration('placement', CONFIG_PLACEMENT)),
    prefixCls: types.maybeNull(types.string),
    className: types.maybeNull(types.string),
    top: types.maybeNull(types.number),
    bottom: types.maybeNull(types.number),
  })
  .volatile(() => ({
    onClose: () => {},
    onClick: () => {},
    style: null,
  }))
  .actions((self) => ({
    set: (params: TNotificationConfig) => {
      Object.assign(self, { ...params });
    },
  }));

const MNotification = types
  .model({
    id: types.identifier,
    title: types.maybeNull(types.string),
    description: types.maybeNull(types.string),
    type: types.string,
    buttons: types.array(MNotificationButton),
    duration: types.union(types.null, types.number, types.undefined),
    isActive: types.boolean,
    closable: types.boolean,
    notificationConfig: types.maybeNull(MNotificationConfig),
  })
  .actions((self) => ({
    setAsInactive() {
      self.isActive = false;
    },
    closeNotification(id: string) {
      notificationApi.close(id);
    },
  }));

export default MNotification;
