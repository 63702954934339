import React from 'react';
import { CopyButton } from 'components';
import { ContactItemWrapper, ContactItemText, ImageStyled, Link } from './ContactItem.styles';

interface IContactItem {
  icon: string;
  text: string;
  href: string;
}

const ContactItem: React.FC<IContactItem> = ({ icon, text, href }) => (
  <ContactItemWrapper>
    <ImageStyled src={icon} width={20} preview={false} />
    <ContactItemText>
      <Link href={href}>{text}</Link>
    </ContactItemText>
    <CopyButton copyText={text} />
  </ContactItemWrapper>
);

export default ContactItem;
