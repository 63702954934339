import ky from 'ky';
import { makeAutoObservable } from 'mobx';
import { KyInstance as IKy } from 'ky/distribution/types/ky';
import { getMobileApiHost } from 'env';
import store from 'store';
import { ApiHeaders, StatusCodes, REQUEST_TIMEOUT, USER_METRICS_URL } from './constants';
import { makeRequest } from './request';

type TLearningTracksHeaders = { [ApiHeaders.xTrackId]: string } | Record<string, never>;

export const setLearningTracksHeaders = (trackId?: string): TLearningTracksHeaders => {
  return trackId ? { [ApiHeaders.xTrackId]: trackId } : {};
};

class Api {
  public publicId = '';

  public token = '';

  public prefixUrl = '';

  constructor(publicId?: string) {
    makeAutoObservable(this);

    this.publicId = publicId || '';
    this.prefixUrl = getMobileApiHost();
  }

  private get publicApi(): IKy {
    return ky.create({
      prefixUrl: this.prefixUrl,
      headers: {
        [ApiHeaders.xPublicEventId]: this.publicId,
      },
      timeout: REQUEST_TIMEOUT,
      throwHttpErrors: false,
      hooks: {
        afterResponse: [
          (_request, _options, response) => {
            if (
              !response.ok &&
              response.status !== StatusCodes.Unauthorized &&
              response.status !== StatusCodes.NotValid &&
              response.status !== StatusCodes.Blocked &&
              !_request.url.includes(USER_METRICS_URL)
            ) {
              store.UIStore.notifications.setNotification({
                type: 'error',
                closable: true,
              });
            }
          },
        ],
      },
    });
  }

  private get privateApi(): IKy {
    return this.publicApi.extend({
      headers: {
        [ApiHeaders.authorization]: this.token,
      },
      hooks: {
        afterResponse: [
          (_request, _options, response) => {
            if (response && !response.ok && response.status === StatusCodes.Unauthorized) {
              store.DomainStore.clearAuthData();
            }
            if (response && response.status === StatusCodes.NotFound) {
              store.UIStore.layout.onNotFoundStatusReceived();
            }
            if (response && response.status === StatusCodes.Forbidden) {
              store.UIStore.layout.show403placeholder();
            }
          },
        ],
      },
    });
  }

  // TODO: delete, it's deprecated api
  // eslint-disable-next-line class-methods-use-this
  private get deprecatedApi(): IKy {
    return ky.create({
      prefixUrl: 'https://mobile-api-quick-test.eventicious.com/',
      headers: {
        [ApiHeaders.authorization]: 'PIN 18860d4c-e3df-4a27-9742-f40b8a2a5568/11',
        [ApiHeaders.eventId]: '11',
        [ApiHeaders.acceptLanguage]: 'ru',
        [ApiHeaders.installationId]: '36c7948f9bcf76e2',
        [ApiHeaders.xApiToken]: '3e8c8747-81fc-4e11-8b08-fec745ac3f54',
        [ApiHeaders.xInstallationId]: '36c7948f9bcf76e2',
      },
      timeout: REQUEST_TIMEOUT,
      throwHttpErrors: false,
    });
  }

  get privateRequest() {
    return makeRequest(this.privateApi);
  }

  get publicRequest() {
    return makeRequest(this.publicApi);
  }

  // TODO: delete, it's deprecated request
  get deprecatedRequest() {
    return makeRequest(this.deprecatedApi);
  }
}

const api = new Api();

export default api;
