import React, { FC } from 'react';
import { CardBox, CardFooter, CardBody } from './PollCard.styles';

interface IPollCard {
  footer?: React.ReactNode;
}

const PollCard: FC<IPollCard> = ({ children, footer }) => (
  <CardBox>
    <CardBody>{children}</CardBody>
    {!!footer && <CardFooter>{footer}</CardFooter>}
  </CardBox>
);

export default PollCard;
