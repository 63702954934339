import { defineMessages } from 'react-intl';

export default defineMessages({
  okButtonText: {
    id: 'components.modal.okButtonText',
    defaultMessage: 'ОК',
  },
  cancelButtonText: {
    id: 'components.modal.cancelButtonText',
    defaultMessage: 'Отменить',
  },
  confirmButtonText: {
    id: 'components.modal.confirmButtonText',
    defaultMessage: 'Подтвердить',
  },
});
