import { defineMessages } from 'react-intl';

export default defineMessages({
  loginCheckboxTextAgree: {
    id: 'components.loginCheckbox.loginCheckboxTextAgree',
    defaultMessage: 'Я принимаю ',
  },
  loginCheckboxTextLink: {
    id: 'components.loginCheckbox.loginCheckboxTextLink',
    defaultMessage: 'Условия использования и\u00A0Политику конфиденциальности',
  },
});
