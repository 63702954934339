import styled from 'styled-components';
import { Progress } from 'antd';

export const StyledProgress = styled(Progress)`
  &&& {
    line-height: 0;
    .ant-progress {
      border-radius: 4px;
    }
    .ant-progress-bg {
      border-radius: 4px;
    }
  }
`;

export default StyledProgress;
