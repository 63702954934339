import React, { useState, useEffect } from 'react';
import parse, { HTMLReactParserOptions, DOMNode, Element } from 'html-react-parser';
import { MediaModal, Image } from 'components';
import {
  Wrapper,
  StyledText,
  ImagePreviewWrapper,
  Close,
  RichTextImageWrapper,
  FullScreenIcon,
} from './RichText.styles';

interface IReachText {
  content: string;
  onFinishLoading?: () => void;
  onStartLoading?: () => void;
}

const ALLOWED_INLINE_STYLES = ['text-decoration: underline', 'text-decoration: line-through'];

const ReachText: React.FC<IReachText> = ({ content, onFinishLoading, onStartLoading }) => {
  const [preview, setPreview] = useState<{ visible: boolean; src: string } | null>(null);
  const [imageSourcesAreLoading, setImageSourcesAreLoading] = useState<string[]>([]);

  const addImageSrc = (src: string) => {
    setImageSourcesAreLoading((prev) => {
      return prev.find((source) => source === src) ? prev : [...prev, src];
    });
  };

  const imageLoadingIsFinished = (src: string) => {
    setImageSourcesAreLoading(imageSourcesAreLoading.filter((imageId) => imageId !== src));
  };

  const onClosePreview = () => setPreview({ visible: false, src: '' });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => onFinishLoading?.(), []);

  useEffect(() => {
    if (imageSourcesAreLoading.length) {
      onStartLoading?.();
    } else {
      onFinishLoading?.();
    }
  }, [imageSourcesAreLoading.length, onFinishLoading, onStartLoading]);

  // Проверка является ли элемент единственным в абзаце - для добавления отступов заголовков
  const isHeaderType = (domNode: Element) => {
    const { children, parent } = domNode as Element;
    if (children.length === 1) {
      for (let tempParent = parent as Element; tempParent; tempParent = tempParent.parent as Element) {
        if (tempParent.children.length !== 1 || tempParent.name === 'span') {
          break;
        }
        if (tempParent.name === 'p' && tempParent.nextSibling) {
          return true;
        }
      }
    }
    return false;
  };

  const options: HTMLReactParserOptions = {
    replace: (domNode: DOMNode) => {
      const { name, children, attribs } = domNode as Element;
      const child = children && children[0];
      const childName = (child as Element)?.name;

      if (name === 'a' && childName === 'img') {
        const source = (child as Element)?.attribs?.src;
        try {
          const childLink = new URL(source);
          const parentLink = new URL(attribs?.href);
          if (parentLink.host === childLink.host) {
            return (
              <RichTextImageWrapper>
                <Image
                  src={source}
                  alt="image"
                  preview={false}
                  onClick={() => setPreview({ visible: true, src: attribs?.href })}
                  onLoad={() => imageLoadingIsFinished(source)}
                  onError={() => imageLoadingIsFinished(source)}
                  onResize={() => imageLoadingIsFinished(source)}
                  onBeforeLoading={() => addImageSrc(source)}
                />
                <FullScreenIcon onClick={() => setPreview({ visible: true, src: attribs?.href })} />
              </RichTextImageWrapper>
            );
          }
        } catch (e) {
          console.error(e);
        }
      }

      if (name === 'img') {
        const source = (domNode as Element)?.attribs?.src;
        const parent = domNode?.parent;

        if ((parent as Element)?.name !== 'a') {
          return (
            <RichTextImageWrapper>
              <Image
                src={source}
                preview={false}
                onClick={() => {
                  setPreview({ visible: true, src: source });
                }}
                onLoad={() => imageLoadingIsFinished(source)}
                onError={() => imageLoadingIsFinished(source)}
                onResize={() => imageLoadingIsFinished(source)}
                onBeforeLoading={() => addImageSrc(source)}
              />
              <FullScreenIcon
                onClick={() => {
                  setPreview({ visible: true, src: source });
                }}
              />
            </RichTextImageWrapper>
          );
        }
      }

      if (name === 'span' && attribs?.style) {
        // HACK: Удаляем инлайн-стили, не соответствующие дизайн-системе
        attribs.style = ALLOWED_INLINE_STYLES.filter((line) => attribs.style.includes(line)).join(';');
      }

      if (name === 'span' && attribs?.class === 'textlarge') {
        if (isHeaderType(domNode as Element)) {
          attribs.style += 'display: block; margin-bottom:32px;';
        }
      }

      if (name === 'span' && attribs?.class === 'textheader') {
        if (isHeaderType(domNode as Element)) {
          attribs.style += 'display: block; margin-bottom:16px;';
        }
      }

      return null;
    },
  };
  return (
    <Wrapper>
      <StyledText>{parse(content, options)}</StyledText>
      {preview && (
        <ImagePreviewWrapper>
          <MediaModal visible={preview.visible} footer={false} closeIcon={<Close />} onCancel={onClosePreview}>
            <Image src={preview.src} alt="image" preview={false} />
          </MediaModal>
        </ImagePreviewWrapper>
      )}
    </Wrapper>
  );
};

export default ReachText;
